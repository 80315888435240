import { TreeSelect, TreeSelectProps } from 'antd';
import { FieldInputProps, useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { SPECIAL_FOLDERS } from './messagingFolderConstants';

export type BasicProps = FieldInputProps<any> &
  TreeSelectProps & { showHidden: boolean };
/* TURNED-OFF FOLDERS */
// function mapNodes(nodes, fn) {
//   return nodes.map(node => ({
//     ...fn(node),
//     children: mapNodes(node.children, fn),
//   }));
// }
//
// function folderToTreeNode(folder) {
//   const accountLabel = folder.messagingAccount
//     ? ` (${formatMessagingAccount(folder.messagingAccount)})`
//     : '';
//   return {
//     title: folder.displayName + accountLabel,
//     value: folder.id,
//   };
// }

export function MessagingFolderTreeSelect({
  name,
  showHidden,
  ...other
}: BasicProps) {
  const intl = useIntl();
  /* TURNED-OFF FOLDERS */
  // const { folders, loading, error } = useFoldersQuery();
  const { setFieldValue } = useFormikContext();
  const treeData = useMemo(() => {
    /* TURNED-OFF FOLDERS */
    // const nonSpecialFolders = folders?.filter(f => !f.type);
    // const folderTreeData = parseFolderTreeStructure(
    //   nonSpecialFolders as MessagingFolder[],
    //   showHidden
    // );
    const specialFoldersTree = SPECIAL_FOLDERS.map(ft => ({
      value: ft,
      title: intl.formatMessage({ id: `enums.FolderType.${ft}` }),
    }));
    /* TURNED-OFF FOLDERS */
    // const nonSpecialFoldersTree = mapNodes(folderTreeData, folderToTreeNode);
    return [...specialFoldersTree];
  }, [intl]);
  /* TURNED-OFF FOLDERS */
  // if (error) {
  //   return <ErrorAlert error={error} />;
  // }
  return (
    <TreeSelect
      /* TURNED-OFF FOLDERS */
      // loading={loading}
      showSearch
      allowClear
      treeData={treeData}
      treeNodeFilterProp="title"
      {...other}
      onChange={val => setFieldValue(name, val)}
    />
  );
}
