import {
  CheckCircleOutlined,
  CloudSyncOutlined,
  MoreOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';

import { MessagingAccount } from '../../../types/graphqlGenerated';
import {
  renderDynamicMenu,
  renderDynamicMenuAdditionalElements,
} from '../../common/dynamicMenu/dynamicMenuRendering';
import { MessagingAccountLabel } from '../accountFormats';
import styles from './AccountsListItem.module.less';
import { useColorChangeAccountMenuItem } from './colorChangeAccountMenuItem';
import { useDisconnectAccountMenuItem } from './disconnectAccountMenuItem';
import { useEmailSignatureAccountMenuItem } from './emailSignatureAccountMenuItem';
import { useForceReloadAccountMenuItem } from './forceReloadAccountMenuItem';
import { useReconnectAccountMenuItem } from './reconnectAccountMenuItem';

type StatusIconProps = {
  initialized: boolean;
  error?: string;
};

function StatusIcon({ initialized, error }: StatusIconProps) {
  if (initialized) {
    return (
      <Tooltip title={<FormattedMessage id="connectedAccounts.initialized" />}>
        <CheckCircleOutlined className={styles['Icon--Success']} />
      </Tooltip>
    );
  }
  if (error) {
    return (
      <Tooltip
        title={
          <FormattedMessage
            id={`connectedAccounts.error.${error}`}
            defaultMessage={error}
          />
        }
      >
        <WarningOutlined className={styles['Icon--Error']} />
      </Tooltip>
    );
  }
  return (
    <Tooltip title={<FormattedMessage id="connectedAccounts.notInitialized" />}>
      <CloudSyncOutlined className={styles['Icon--Loading']} />
    </Tooltip>
  );
}

type AccountsListItemProps = {
  account: MessagingAccount;
};

export function AccountsListItem({ account }: AccountsListItemProps) {
  const forceReloadMenuItem = useForceReloadAccountMenuItem({ account });
  const disconnectMenuItem = useDisconnectAccountMenuItem({ account });
  const colorChangeMenuItem = useColorChangeAccountMenuItem({ account });
  const emailSignatureMenuItem = useEmailSignatureAccountMenuItem({ account });
  const reconnectAccountMenuItem = useReconnectAccountMenuItem({ account });
  const menuItems = [
    forceReloadMenuItem,
    disconnectMenuItem,
    reconnectAccountMenuItem,
    colorChangeMenuItem,
    emailSignatureMenuItem,
  ];

  return (
    <div className={styles.Item}>
      <MessagingAccountLabel
        account={account}
        labelClassName={styles['Item__Text']}
      />
      <div className={styles['Item__Actions']}>
        <StatusIcon initialized={account.initialized} error={account.error!} />
        <Dropdown overlay={renderDynamicMenu(menuItems)}>
          <Button shape="circle" size="small" icon={<MoreOutlined />} />
        </Dropdown>
      </div>
      {renderDynamicMenuAdditionalElements(menuItems)}
    </div>
  );
}
