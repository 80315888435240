import {
  CheckCircleOutlined,
  ClearOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Form as AntForm, Button, Collapse, Input, Space } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { Form, Formik, useFormikContext } from 'formik';
import { isNumber, isString } from 'lodash-es';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../common/utils/messageUtils';
import FormField from '../../../components/forms/FormField';
import FormItem from '../../../components/forms/FormItem';
import { NullableBooleanControl } from '../../../components/forms/booleanFields';
import { DatePicker } from '../../../components/forms/dateFields';
import {
  MessagingAccountSelect,
  MessagingParticipantSelect,
} from '../../../components/forms/fetchingSelects';
import {
  MessagingFolderType,
  MessagingThreadsFilterInput,
  NamedMessagingThreadsFilter,
  NamedMessagingThreadsFilterInput,
} from '../../../types/graphqlGenerated';
import { formatContact } from '../../contacts/contactFormats';
import { MessagingFolderTreeSelect } from '../folders/MessagingFolderTreeSelect';
import { useMessagesAppViewMode } from '../messagesAppViewMode';
import {
  getFilterEditorEditedFilter,
  getThreadsFilter,
  isFilterEditorOpen,
  useMessagesActions,
} from '../messagesReducer';
import styles from './MessagingFiltersEditor.module.less';
import { convertThreadFilterOutputToInput } from './filterConversions';
import {
  useCreateNamedFilterMutation,
  useUpdateNamedFilterMutation,
} from './filtersGraphql';

type SavePanelContentProps = {
  formValues: FiltersFormValues;
  editedFilter?: NamedMessagingThreadsFilter;
  close: () => void;
  loading: boolean;
  save: (
    input: NamedMessagingThreadsFilterInput
  ) => Promise<
    Pick<NamedMessagingThreadsFilter, 'id' | 'name'> | null | undefined
  >;
};

function SavePanelContent({
  formValues,
  editedFilter,
  loading,
  save,
  close,
}: SavePanelContentProps) {
  const { setNamedFilterMode } = useMessagesAppViewMode();
  return (
    <Formik
      initialValues={{ name: editedFilter?.name ?? '' }}
      onSubmit={async ({ name }) => {
        try {
          const res = await save({ name, content: formToRedux(formValues) });
          showSuccessMessage(
            <FormattedMessage id="filterEditor.action.save.success" />
          );
          if (res) {
            setNamedFilterMode(res.id);
          }
          close();
        } catch (e) {
          showErrorMessage(
            <FormattedMessage id="filterEditor.action.save.error" />
          );
        }
      }}
    >
      {({ handleSubmit }) => (
        <Space direction="vertical" size={10}>
          <FormField name="name" placeholderId="filterEditor.save.name">
            {({ field }) => <Input {...field} disabled={loading} />}
          </FormField>
          <Button
            type="primary"
            disabled={loading}
            onClick={() => handleSubmit()}
          >
            <FormattedMessage id="filterEditor.action.saveAndApply" />
          </Button>
        </Space>
      )}
    </Formik>
  );
}

function CreatePanelContent({
  formValues,
  close,
}: Pick<SavePanelContentProps, 'formValues' | 'close'>) {
  const [create, { loading }] = useCreateNamedFilterMutation();
  return (
    <SavePanelContent
      formValues={formValues}
      close={close}
      save={input => create({ input })}
      loading={loading}
    />
  );
}

function EditPanelContent({
  formValues,
  editedFilter,
  close,
}: Pick<SavePanelContentProps, 'formValues' | 'editedFilter' | 'close'>) {
  const [update, { loading }] = useUpdateNamedFilterMutation();
  return (
    <SavePanelContent
      formValues={formValues}
      editedFilter={editedFilter}
      close={close}
      save={input => update({ id: editedFilter!.id, input })}
      loading={loading}
    />
  );
}

type SaveFormProps = {
  formValues: FiltersFormValues;
  editedFilter?: NamedMessagingThreadsFilter;
  close: () => void;
};

function SaveForm({ formValues, editedFilter, close }: SaveFormProps) {
  const [openKey, setOpenKey] = useState<string | undefined>(undefined);

  return (
    <Collapse
      activeKey={openKey}
      accordion
      onChange={key => setOpenKey(key as string | undefined)}
      ghost
    >
      <Collapse.Panel
        key="saveNew"
        header={<FormattedMessage id="filterEditor.saveNew.header" />}
      >
        <CreatePanelContent formValues={formValues} close={close} />
      </Collapse.Panel>
      {editedFilter && (
        <Collapse.Panel
          key="update"
          header={<FormattedMessage id="filterEditor.update.header" />}
        >
          <EditPanelContent
            formValues={formValues}
            editedFilter={editedFilter}
            close={close}
          />
        </Collapse.Panel>
      )}
    </Collapse>
  );
}

type FiltersFormValues = {
  messagingAccountIds: number[];
  folderIdsOrTypes: (MessagingFolderType | number)[];
  labelIds: number[];
  searchTerm: string | null;
  unread: boolean | null;
  lastMessageOutgoing: boolean | null;
  hasAttachments: boolean | null;
  dateFrom?: Date;
  dateTo?: Date;
  participants: string[];
};

function reduxToForm(filter?: MessagingThreadsFilterInput): FiltersFormValues {
  return {
    messagingAccountIds: filter?.messagingAccount?.idIn || [],
    folderIdsOrTypes: [
      ...(filter?.folder?.idIn || []),
      ...(filter?.folder?.typeIn || []),
    ],
    labelIds: filter?.label?.idIn || [],
    searchTerm: filter?.searchTerm || null,
    unread: filter?.unread ?? null,
    lastMessageOutgoing: filter?.lastMessageOutgoing ?? null,
    hasAttachments: filter?.hasAttachments ?? null,
    dateFrom: filter?.date?.from ? new Date(filter.date.from) : undefined,
    dateTo: filter?.date?.to ? new Date(filter.date.to) : undefined,
    participants: filter?.participant?.refIn || [],
  };
}

function formToRedux(values: FiltersFormValues): MessagingThreadsFilterInput {
  const folderIds = values.folderIdsOrTypes.filter(isNumber);
  const folderTypes = values.folderIdsOrTypes.filter(
    isString
  ) as MessagingFolderType[];

  return {
    messagingAccount: { idIn: values.messagingAccountIds },
    folder: { idIn: folderIds, typeIn: folderTypes },
    label: { idIn: values.labelIds },
    searchTerm: values.searchTerm,
    unread: values.unread,
    lastMessageOutgoing: values.lastMessageOutgoing,
    hasAttachments: values.hasAttachments,
    date: {
      from: values.dateFrom?.toISOString(),
      to: values.dateTo?.toISOString(),
    },
    participant: {
      refIn: values.participants,
    },
  };
}

function MessagingFiltersEditorForm({ close, editedFilter }) {
  const { setValues, values } = useFormikContext<FiltersFormValues>();

  return (
    <Space direction="vertical" size={10}>
      <AntForm
        component={false}
        layout="vertical"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <FormItem labelId="labels.search.term">
          <FormField
            name="searchTerm"
            placeholderId="labels.threadsSearch.placeholder"
          >
            {({ field }) => <Input {...field} />}
          </FormField>
        </FormItem>
        <FormItem labelId="filterEditor.messagingAccounts">
          <FormField name="messagingAccountIds">
            {({ field }) => (
              <MessagingAccountSelect {...field} mode="multiple" />
            )}
          </FormField>
        </FormItem>
        <FormItem labelId="filterEditor.folders">
          <FormField name="folderIdsOrTypes">
            {({ field }) => (
              <MessagingFolderTreeSelect
                {...field}
                multiple
                showHidden={false}
              />
            )}
          </FormField>
        </FormItem>
        <FormItem>
          <FormField name="unread">
            {({ field }) => (
              <NullableBooleanControl
                {...field}
                nullLabelId="filterEditor.both"
                trueLabelId="filterEditor.unread"
                falseLabelId="filterEditor.read"
              />
            )}
          </FormField>
        </FormItem>
        <FormItem labelId="filterEditor.lastMessage">
          <FormField name="lastMessageOutgoing">
            {({ field }) => (
              <NullableBooleanControl
                {...field}
                nullLabelId="filterEditor.both"
                trueLabelId="filterEditor.outgoing"
                falseLabelId="filterEditor.incoming"
              />
            )}
          </FormField>
        </FormItem>
        <FormItem labelId="filterEditor.hasAttachments">
          <FormField name="hasAttachments">
            {({ field }) => <NullableBooleanControl {...field} />}
          </FormField>
        </FormItem>
        <FormItem labelId="filterEditor.dateFrom">
          <FormField name="dateFrom">
            {({ field }) => <DatePicker {...field} showTime />}
          </FormField>
        </FormItem>
        <FormItem labelId="filterEditor.dateTo">
          <FormField name="dateTo">
            {({ field }) => <DatePicker {...field} showTime />}
          </FormField>
        </FormItem>
        <FormItem labelId="filterEditor.participants">
          <FormField name="participants">
            {({ field }) => (
              <MessagingParticipantSelect
                {...field}
                mode="tags"
                tokenSeparators={[',']}
                getValueArray={c => [...c.emails, ...c.phoneNumbers]}
                getValue={c => c.value}
                getLabel={formatContact}
              />
            )}
          </FormField>
        </FormItem>
        <div className={styles.Buttons}>
          <Button onClick={close} icon={<CloseCircleOutlined />}>
            <span>
              <FormattedMessage id="filterEditor.close" />
            </span>
          </Button>
          <Button
            onClick={() => setValues(reduxToForm())}
            icon={<ClearOutlined />}
          >
            <span>
              <FormattedMessage id="filterEditor.clear" />
            </span>
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            icon={<CheckCircleOutlined />}
          >
            <span>
              <FormattedMessage id="filterEditor.apply" />
            </span>
          </Button>
        </div>
      </AntForm>
      <SaveForm formValues={values} editedFilter={editedFilter} close={close} />
    </Space>
  );
}

export function MessagingFiltersEditor() {
  const threadsFilter = useSelector(getThreadsFilter);
  const visible = useSelector(isFilterEditorOpen);
  const editedFilter = useSelector(getFilterEditorEditedFilter);
  const { setFilterEditorOpen } = useMessagesActions();
  const close = () => setFilterEditorOpen(false);
  const { setCustomFilterMode } = useMessagesAppViewMode();

  return (
    <Modal visible={visible} onCancel={close} footer={null} destroyOnClose>
      <Formik
        initialValues={reduxToForm(
          editedFilter
            ? convertThreadFilterOutputToInput(editedFilter.content)
            : threadsFilter
        )}
        onSubmit={async values => {
          setCustomFilterMode(formToRedux(values));
          close();
        }}
      >
        <Form className={styles.Container}>
          <MessagingFiltersEditorForm
            close={close}
            editedFilter={editedFilter}
          />
        </Form>
      </Formik>
    </Modal>
  );
}
