import { parseAddressList } from 'email-addresses';
import { compact, find, uniqBy } from 'lodash-es';
import { useIntl } from 'react-intl';

import {
  EmailFormatterOptions,
  formatEmailParticipant,
  getForwardEmailInitialBody,
  getReplyEmailInitialBody,
} from '../../../../../common/utils/emailUtils';
import {
  UseAsyncResult,
  useAsync,
} from '../../../../../common/utils/hookUtils';
import { sanitizeContent } from '../../../../../common/utils/sanitizeUtils';
import { FileUploadFile } from '../../../../../components/forms/FileUpload';
import {
  RichTextEditorValue,
  richTextEditorValueToString,
} from '../../../../../components/forms/RichEditor';
import {
  MessagingAccount,
  MessagingMessage,
  MessagingMessageInput,
} from '../../../../../types/graphqlGenerated';
import { useEmailSignatureLazyQuery } from '../../../../accounts/accountExtensionsGraphql';
import { useEmailImageTransformation } from '../../../../emails/emailHooks';
import { MessageComposerReaction } from '../messageComposerTypes';

export type EmailMessageComposerFormValues = {
  to?: string[];
  cc?: string[];
  bcc?: string[];
  replyTo?: string[];
  subject?: string;
  body?: RichTextEditorValue;
  files?: FileUploadFile[];
};

type UseInitialValuesInner = {
  reaction?: MessageComposerReaction;
};

async function appendSignature(body: string, signatureBody?: string) {
  return signatureBody
    ? `
  ${body}
  <p>&nbsp</p>
  <div class="signature">
    ${await sanitizeContent(signatureBody)}
  </div>
`
    : body;
}

async function updateSignature(body: string, signatureBody?: string) {
  const signatureDivRegex = /<div class="signature">[\s\S]*?<\/div>/;

  if (signatureDivRegex.test(body)) {
    if (signatureBody) {
      return body.replace(
        signatureDivRegex,
        `<div class="signature">${await sanitizeContent(signatureBody)}</div>`
      );
    } else {
      return body.replace(signatureDivRegex, '');
    }
  } else {
    if (signatureBody) {
      return await appendSignature(body, signatureBody);
    } else {
      return body;
    }
  }
}

function useGetInitialBody({ reaction }: UseInitialValuesInner) {
  const intl = useIntl();
  const files = useFiles({ reaction });
  const { dataGetter: imageDataGetter, transformer: imageTransformerInner } =
    useEmailImageTransformation(reaction?.message?.files ?? []);

  return async (
    msg: MessageComposerReaction['message'],
    formatter: (
      msg: MessagingMessage,
      opts: EmailFormatterOptions
    ) => Promise<string>,
    signatureBody?: string
  ) => {
    const usedFiles: typeof files = [];
    const imageTransformer: typeof imageTransformerInner = (img, data) => {
      if (data?.cid) {
        const file = find(files, f => f.response?.contentId === data.cid);
        if (file) {
          usedFiles.push(file);
        }
      }
      imageTransformerInner(img, data);
    };

    const body = await appendSignature(
      `
  <p>&nbsp</p>
  <p>&nbsp</p>
  <div class="quote">
    <details data-role="email-editor-quote">
      <summary data-role="email-editor-quote" contenteditable="false" class="email-quote-expander">&bull;&bull;&bull;</summary>
      ${(
        await formatter(msg as MessagingMessage, {
          intl,
          sanitizeBody: bodyInner =>
            sanitizeContent(bodyInner, {
              dataGetters: { image: imageDataGetter },
              transformers: { image: imageTransformer },
            }),
        })
      ).replace(/\n/g, '<br/>')}
    <details>
  </div>
`,
      signatureBody
    );

    return { body, usedFiles };
  };
}

function useFiles({ reaction }: UseInitialValuesInner): FileUploadFile[] {
  return (
    reaction?.message?.files?.map(fl => ({
      uid: `file-${fl.id}`,
      name: fl.filename,
      status: 'done',
      type: fl.contentType || '',
      response: {
        id: fl.id,
        contentId: fl.contentId || '',
        src: '',
      },
    })) ?? []
  );
}

type UseInitialValues = UseInitialValuesInner & {
  account: MessagingAccount;
  originalBody?: string;
};

export function useEmailMessageComposerInitialValues({
  reaction,
  account,
  originalBody,
}: UseInitialValues): UseAsyncResult<EmailMessageComposerFormValues> {
  const getInitialBody = useGetInitialBody({ reaction });
  const files = useFiles({ reaction });
  const [loadSignature] = useEmailSignatureLazyQuery({
    messagingAccountId: account.id,
  });

  return useAsync(async () => {
    const signature = await loadSignature(); // most likely is signature already pre-fetched
    if (reaction?.type === 'reply') {
      const { body, usedFiles } = await getInitialBody(
        reaction.message,
        getReplyEmailInitialBody,
        signature?.body
      );
      return {
        to: reaction.message.from.map(formatEmailParticipant),
        cc: [],
        bcc: [],
        replyTo: [],
        subject: `Re: ${reaction.message.subject}`,
        body,
        files: usedFiles,
      };
    }
    if (reaction?.type === 'replyAll') {
      const { body, usedFiles } = await getInitialBody(
        reaction.message,
        getReplyEmailInitialBody,
        signature?.body
      );
      return {
        to: uniqBy(
          [...reaction.message.from, ...reaction.message.to].filter(
            p => p.ref !== reaction.thread.messagingAccount.platformId
          ),
          'ref'
        ).map(formatEmailParticipant),
        cc: reaction.message.cc.map(formatEmailParticipant),
        bcc: reaction.message.bcc.map(formatEmailParticipant),
        subject: `Re: ${reaction.message.subject}`,
        body,
        files: usedFiles,
      };
    }
    if (reaction?.type === 'forward') {
      const { body } = await getInitialBody(
        reaction.message,
        getForwardEmailInitialBody,
        signature?.body
      );
      return {
        to: [],
        cc: [],
        bcc: [],
        replyTo: [],
        subject: `Fwd: ${reaction.message.subject}`,
        body,
        files,
      };
    }

    return {
      to: [],
      cc: [],
      bcc: [],
      replyTo: [],
      subject: '',
      body: await updateSignature(originalBody ?? '', signature?.body),
      files: [],
    };
  });
}

type EmailFormToGraphQlInputOpts = {
  values: EmailMessageComposerFormValues;
  account: MessagingAccount;
  reaction?: MessageComposerReaction;
};

export function removeQuoteExpanderTransformer(node: Element) {
  const role = node.getAttribute('data-role');
  if (role === 'email-editor-quote') {
    if (node.nodeName === 'DETAILS') {
      // @ts-ignore: reason, ts does not know replaceChildren method
      node.parentNode.replaceChildren(...node.childNodes);
    }
    if (node.nodeName === 'SUMMARY') {
      node.parentNode?.removeChild(node);
    }
  }
  // WORKAROUND-001 - ckeditor adding noisy filler attributes
  if (node.nodeName === 'DETAILS' && node.children.length === 0) {
    node.parentNode?.removeChild(node);
  }
}

function parseAddresses(addrs?: string[]) {
  return (
    parseAddressList({
      input: addrs?.join(', ') ?? '',
      atInDisplayName: true,
      rfc6532: true,
    })?.flatMap(addr => {
      if (addr.type === 'mailbox') {
        return { name: addr.name, ref: addr.address };
      } else {
        return addr.addresses.map(a => ({
          name: a.name,
          ref: a.address,
        }));
      }
    }) ?? []
  );
}

export async function emailFormToGraphQlInput({
  values,
  account,
  reaction,
}: EmailFormToGraphQlInputOpts): Promise<MessagingMessageInput> {
  const bodyValue = richTextEditorValueToString(values.body);
  const body = await sanitizeContent(bodyValue!, {
    transformers: {
      image: img => {
        const cid = img.getAttribute('data-cid');
        if (cid) {
          img.setAttribute('src', `cid:${cid}`);
          img.removeAttribute('data-cid');
        }
      },
      any: [removeQuoteExpanderTransformer],
    },
  });

  let reactionFields: Partial<MessagingMessageInput> = {};
  if (/reply/.test(reaction?.type || '')) {
    reactionFields = {
      threadId: reaction?.thread.id,
      replyToMessageId: reaction?.message?.id,
    };
  } else if (reaction?.type === 'forward') {
    reactionFields = { threadId: reaction?.thread.id };
  }

  return {
    ...reactionFields,
    fromMessagingAccountId: account.id,
    to: parseAddresses(values.to),
    cc: parseAddresses(values.cc),
    bcc: parseAddresses(values.bcc),
    replyTo: parseAddresses(values.replyTo),
    subject: values.subject!,
    body,
    fileIds: compact(values.files?.map(f => f.response?.id)),
  };
}
