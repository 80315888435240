import { setDay, setMonth } from 'date-fns';
import { find } from 'lodash-es';
import { FormattedDateParts } from 'react-intl';

type FormattedWeekdayProps = {
  weekday: number; // zero-based
  type?: Parameters<typeof FormattedDateParts>[0]['weekday'];
};

export function FormattedWeekday({
  weekday,
  type = 'long',
}: FormattedWeekdayProps) {
  return (
    <FormattedDateParts value={setDay(new Date(), weekday)} weekday={type}>
      {parts => <>{find(parts, p => p.type === 'weekday')?.value}</>}
    </FormattedDateParts>
  );
}

export const NUM_WEEKDAYS = 7;

type FormattedMonthProps = {
  month: number; // zero-based
  type?: Parameters<typeof FormattedDateParts>[0]['month'];
};

export function FormattedMonth({ month, type = 'long' }: FormattedMonthProps) {
  return (
    <FormattedDateParts value={setMonth(new Date(), month)} month={type}>
      {parts => <>{find(parts, p => p.type === 'month')?.value}</>}
    </FormattedDateParts>
  );
}

export const NUM_MONTHS = 12;
