import { PlusOutlined } from '@ant-design/icons';
import { Button, Tabs } from 'antd';
import { isEmpty } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import RouteDefinitions from '../../../app/routes';
import { useResponsiveQueries } from '../../../common/utils/layoutUtils';
import { AccountsList } from '../../accounts/AccountsList';
import { ConnectAccountButton } from '../../accounts/ConnectAccountButton';
import { useAccountsQuery } from '../../accounts/accountsGraphql';
import { AppSidebar } from '../../layout/AppSidebar';
import { useLayoutActions } from '../../layout/layoutReducer';
import { MessagingFilters } from '../filters/MessagingFilters';
import { MessagingFolders } from '../folders/MessagingFolders';
import { getMessageView, useMessagesActions } from '../messagesReducer';
import style from './MessagesAppSidebar.module.less';

const MessagingActions = () => {
  const navigate = useNavigate();
  const { accounts } = useAccountsQuery();
  const { setSidebarCollapse } = useLayoutActions();

  return (
    <div className={style.Actions}>
      <ConnectAccountButton />
      <Button
        type="primary"
        size="large"
        icon={<PlusOutlined />}
        onClick={() => {
          navigate(RouteDefinitions.sendMessage);
          setSidebarCollapse(true);
        }}
        disabled={isEmpty(accounts)}
      >
        <FormattedMessage id="messagesSidebar.compose" />
      </Button>
    </div>
  );
};

type SidebarTabsProps = {
  activeTab: string;
  onTabChange: (tab: string) => void;
  activeFolderKey?: string;
  activeFilterId?: number;
  className?: string;
};

function SidebarMenuTabs({
  activeTab,
  onTabChange,
  activeFilterId,
  activeFolderKey,
  className,
}: SidebarTabsProps) {
  return (
    <Tabs activeKey={activeTab} onChange={onTabChange} className={className}>
      <Tabs.TabPane
        key="folder"
        tab={<FormattedMessage id="messagesSidebar.tabs.folders" />}
        forceRender
      >
        <MessagingFolders activeFolderKey={activeFolderKey} />
      </Tabs.TabPane>
      <Tabs.TabPane
        key="filter"
        tab={<FormattedMessage id="messagesSidebar.tabs.filters" />}
        forceRender
      >
        <MessagingFilters activeFilterId={activeFilterId} />
      </Tabs.TabPane>
    </Tabs>
  );
}

type MessagesAppSidebarProps = {
  activeFolderKey?: string;
  activeFilterId?: number;
};

export function MessagesAppSidebar({
  activeFolderKey,
  activeFilterId,
}: MessagesAppSidebarProps) {
  const tab = useSelector(getMessageView);
  const { setMessageView } = useMessagesActions();
  const setTab = newTab => setMessageView(newTab);

  const { setSidebarCollapse } = useLayoutActions();

  const { sm: isSmallScreen, md: isMediumScreen } = useResponsiveQueries();

  const isSmall = isSmallScreen || isMediumScreen;

  return (
    <AppSidebar>
      <MessagingActions />
      {isSmall ? (
        <SidebarMenuTabs
          activeTab={tab}
          onTabChange={newTab => {
            setTab(newTab);
            setSidebarCollapse(true);
          }}
          activeFilterId={activeFilterId}
          activeFolderKey={activeFolderKey}
          className={style.Tabs}
        />
      ) : (
        <SidebarMenuTabs
          activeTab={tab}
          onTabChange={setTab}
          activeFilterId={activeFilterId}
          activeFolderKey={activeFolderKey}
          className="Tabs--Flex1InColumn"
        />
      )}
      <AccountsList className={style.Accounts} />
    </AppSidebar>
  );
}
