import { isEqual, isFunction, noop } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';

type UseMountEffectFunc = () => Promise<void> | (() => void) | undefined | void;

export const useMountEffect = (func: UseMountEffectFunc) =>
  useEffect(() => {
    const res = func();
    return isFunction(res) ? res : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

type UseMountAndUpdateEffectOpts<
  A,
  B,
  C,
  D,
  E,
  DEPS extends [] | [A] | [A, B] | [A, B, C] | [A, B, C, D] | [A, B, C, D, E]
> = {
  onMount?: () => void;
  onUpdate?: (prevDeps: DEPS) => void;
};

export function useMountAndUpdateEffect<
  A,
  B,
  C,
  D,
  E,
  DEPS extends [] | [A] | [A, B] | [A, B, C] | [A, B, C, D] | [A, B, C, D, E]
>(
  {
    onMount = noop,
    onUpdate = noop,
  }: UseMountAndUpdateEffectOpts<A, B, C, D, E, DEPS>,
  deps: DEPS
) {
  const wasFired = useRef(false);
  const prevDeps = useRef(deps);
  useEffect(() => {
    let destroy: any;
    if (wasFired.current) {
      destroy = onUpdate(prevDeps.current);
    } else {
      wasFired.current = true;
      destroy = onMount();
    }
    prevDeps.current = deps;
    return isFunction(destroy) ? destroy : noop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

type UseAsyncOpts = {
  deps?: any[];
  skip?: boolean;
};

export type UseAsyncResult<T> = {
  data: T | undefined;
  loading: boolean;
  error: any;
};

export function useAsync<T>(
  dataFactory: () => Promise<T>,
  opts?: UseAsyncOpts
): UseAsyncResult<T> {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(undefined);
  const [data, setData] = useState<T | undefined>(undefined);

  useEffect(() => {
    if (opts?.skip) {
      return;
    }

    let cancelled = false;
    setLoading(true);

    dataFactory()
      .then(dt => {
        if (!cancelled) {
          setData(dt);
        }
      })
      .catch(e => {
        if (!cancelled) {
          setError(e);
        }
      })
      .finally(() => {
        if (!cancelled) {
          setLoading(false);
        }
      });

    return () => {
      cancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(opts?.deps || []), opts?.skip || false]);

  return { data, loading, error };
}

type UseDebouncedVariablesOpts<T> = {
  delay?: number;
  comparator?: (val1: T, val2: T) => boolean;
};
const DEFAULT_COMPARATOR = <T>(val1: T, val2: T) => val1 === val2;

export function useDebouncedVariable<T>(
  value: T,
  {
    delay = 500,
    comparator = DEFAULT_COMPARATOR,
  }: UseDebouncedVariablesOpts<T> = {}
) {
  const [debouncedProp, setDebouncedProp] = useState(value);
  const prevValue = useRef(value);
  const timeout = useRef<number>();
  useEffect(() => {
    if (!comparator(value, prevValue.current)) {
      prevValue.current = value;
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = window.setTimeout(() => setDebouncedProp(value), delay);
      return () => window.clearTimeout(timeout.current);
    }
    return noop;
  }, [comparator, delay, value]);

  return debouncedProp;
}

export function useMemoizedVariable<T>(value: T) {
  const [mem, setMem] = useState(value);

  useEffect(() => {
    if (!isEqual(value, mem)) {
      setMem(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return mem;
}
