import { EventClickArg } from '@fullcalendar/react';
import { subMilliseconds } from 'date-fns';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { generatePath, useNavigate } from 'react-router-dom';

import RouteDefinitions from '../../../app/routes';
import { showErrorMessage } from '../../../common/utils/messageUtils';
import FullCalendar, {
  FullCalendarEvent,
} from '../../../components/FullCalendar';
import InfiniteProgressBar from '../../../components/InfiniteProgressBar';
import { DEFAULT_CALENDAR_COLOR } from '../../../config/constants';
import { Calendar, CalendarEvent } from '../../../types/graphqlGenerated';
import { getSidebarCollapse } from '../../layout/layoutReducer';
import { CreateCalendarEventModal } from '../calendarEventEditing/CreateCalendarEventModal';
import { useCalendarEventsQuery, useCalendarsQuery } from '../calendarGraphql';
import styles from './CalendarContent.module.less';

function mapToFullCalendarEvent(
  event: CalendarEvent,
  calendar?: Calendar
): FullCalendarEvent {
  const color = calendar?.color || DEFAULT_CALENDAR_COLOR;
  const base = {
    id: event.id,
    title: event.title,
    backgroundColor: color,
    borderColor: color,
    calendarId: event.calendarId,
  };
  switch (event.when.__typename) {
    case 'TimespanInstant':
      return {
        ...base,
        start: event.when.startTime,
        end: event.when.endTime,
      };
    case 'DatespanInstant':
      return {
        ...base,
        start: `${event.when.startDate}T00:00:00`,
        // adding time as `end` date is exclusive
        // https://fullcalendar.io/docs/event-parsing
        end: `${event.when.endDate}T24:00:00`,
        allDay: true,
      };
    case 'DateInstant':
      return {
        ...base,
        start: event.when.date,
        end: event.when.date,
        allDay: true,
      };
    case 'TimeInstant':
      return {
        ...base,
        start: event.when.time,
        end: event.when.time,
      };
    default:
      console.error('Unexpected instant type', event);
      throw new Error('Invalid data received');
  }
}

type DateRange = {
  start: Date;
  end: Date;
};

// WORKAROUND: in responsive mode the sidebar toggle is currently setting content to be hidden (display: none),
// what may mess with calendar dimensions and breaks it's layout.
// This will trigger re-render which forces calendar repaint.
function useForceRerenderCalendarOnResponsiveSidebarToggleWorkaround() {
  const sidebarCollapse = useSelector(getSidebarCollapse) as boolean;
  const [, setCounter] = useState(0);
  useEffect(() => {
    setCounter(c => c + 1);
  }, [sidebarCollapse]);
}

export default function CalendarContent() {
  useForceRerenderCalendarOnResponsiveSidebarToggleWorkaround();

  const navigate = useNavigate();
  const { calendars = [] } = useCalendarsQuery();
  const [dateRange, setDateRange] = useState<DateRange>();
  const filters = dateRange
    ? calendars
        .filter(c => c.selected)
        .map(c => ({
          accountId: c.account.id,
          calendarId: c.id,
          endsAfter: dateRange.start.toISOString(),
          startsBefore: dateRange.end.toISOString(),
        }))
        .flatMap(f => [
          { ...f, expandRecurring: false },
          { ...f, expandRecurring: true },
        ])
    : [];
  const { events, loading } = useCalendarEventsQuery(filters, {
    onError: (err, filter) => {
      console.error('error loading calendar events', err, filter);
      const calendar = calendars.find(c => c.id === filter.calendarId);
      showErrorMessage(
        <FormattedMessage
          id="error.loadingCalendarEvents"
          values={{ calendarName: calendar?.name }}
        />
      );
    },
  });

  const onEventClick = ({ event }: EventClickArg) => {
    navigate(
      generatePath(RouteDefinitions.calendarEventDetail, {
        eventId: event.id,
        calendarId: event.extendedProps.calendarId.toString(),
      })
    );
  };

  const [modalVisible, setModalVisible] = useState(false);
  const [newEventDateAndTime, setNewEventDateAndTime] = useState<
    undefined | { allDay: boolean; dates: [Date, Date] }
  >(undefined);

  return (
    <div className={styles.Container}>
      <InfiniteProgressBar loading={loading} />
      <FullCalendar
        selectable
        events={events.map((event: CalendarEvent) => {
          const calendar = calendars.find(c => c.id === event.calendarId);
          return mapToFullCalendarEvent(event, calendar as Calendar);
        })}
        select={args => {
          if (!!args.start && !!args.end) {
            setNewEventDateAndTime({
              allDay: args.allDay,
              dates: [
                args.start,
                args.allDay ? subMilliseconds(args.end, 1) : args.end,
              ],
            });
            setModalVisible(true);
          }
        }}
        onEventClick={onEventClick}
        datesSet={dateInfo =>
          setDateRange({
            start: dateInfo.start,
            end: dateInfo.end,
          })
        }
      />
      <CreateCalendarEventModal
        visible={modalVisible}
        setVisible={setModalVisible}
        dateRange={newEventDateAndTime?.dates}
        allDay={newEventDateAndTime?.allDay}
      />
    </div>
  );
}
