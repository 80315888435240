import { Location, useLocation, useNavigate } from 'react-router';

type State = {
  searchTerm?: string;
};

export function useContactsNavigation() {
  const navigate = useNavigate();
  const { state, pathname } = useLocation() as Location & {
    state?: State;
  };

  const statefulNavigate = (
    to?: string,
    { searchTerm }: { searchTerm?: string } = {}
  ) => {
    navigate(to ?? pathname, {
      state: { searchTerm: searchTerm ?? state?.searchTerm },
    });
  };

  return { searchTerm: state?.searchTerm, navigate: statefulNavigate };
}
