import { differenceInSeconds } from 'date-fns';
import {
  FormattedDate,
  FormattedRelativeTime,
  FormattedTime,
} from 'react-intl';

import { Whitespace } from './Whitespace';

type DateLabelProps = {
  date: Date;
  allDay?: boolean;
  showRelativeTime?: boolean;
};

export function DateLabel({
  date,
  allDay = false,
  showRelativeTime = true,
}: DateLabelProps) {
  return (
    <>
      <FormattedDate value={date} year="numeric" month="short" day="2-digit" />
      {!allDay && (
        <>
          ,<Whitespace />
          <FormattedTime value={date} />
        </>
      )}
      <Whitespace />
      {showRelativeTime && (
        <FormattedRelativeTime
          value={differenceInSeconds(date, Date.now())}
          updateIntervalInSeconds={30}
        />
      )}
    </>
  );
}
