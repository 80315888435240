import { FileAddOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { Form as AntForm, Button, Col, Input, Row, Space, Spin } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { Form, Formik } from 'formik';
import { some } from 'lodash-es';
import { MutableRefObject, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';

import { formatError } from '../../../../../common/utils/errorUtils';
import { showErrorMessage } from '../../../../../common/utils/messageUtils';
import { useCustomValidations } from '../../../../../common/utils/validationUtils';
import OptionalTooltip from '../../../../../components/OptionalTooltip';
import {
  FileUploadButton,
  FileUploadButtonPropsRef,
  FileUploadFile,
  FileUploadList,
  UploadFunction as FileUploadUploadFunction,
} from '../../../../../components/forms/FileUpload';
import FormField from '../../../../../components/forms/FormField';
import { RichTextEditor } from '../../../../../components/forms/RichEditor';
import { RichEditorImageUploadFunction } from '../../../../../components/forms/RichEditorPlugins';
import { EmailParticipantSelect } from '../../../../../components/forms/fetchingSelects';
import { SendIcon } from '../../../../../components/icons';
import { useLocalizedYup } from '../../../../../config/intl/LocaleProvider';
import { TranslationId } from '../../../../../types/appTypes';
import { MessagingAccount } from '../../../../../types/graphqlGenerated';
import { useBackgroundOperation } from '../../../../common/backgroundOperations/backgroundOperations';
import {
  useCreateFileDownloadLinkMutation,
  useMessagingFileUpload,
} from '../../../../files/filesGraphql';
import { useSendMessage } from '../../../messagesGraphql';
import { MessageComposerReaction } from '../messageComposerTypes';
import style from './MessageComposerEmailSubform.module.less';
import {
  EmailMessageComposerFormValues,
  emailFormToGraphQlInput,
  useEmailMessageComposerInitialValues,
} from './emailSubformUtils';

function RecipientField({
  name,
  labelId,
}: {
  name: 'to' | 'cc' | 'bcc' | 'replyTo';
  labelId: TranslationId;
}) {
  return (
    <FormField name={name} labelId={labelId}>
      {({ field, label }) => (
        <Row>
          <Col span={2}>
            <AntForm.Item
              label={label}
              className={style['FormItem--Compact']}
              labelCol={{ span: 24 }}
            >
              {/* Empty Form.Item for label only */}
            </AntForm.Item>
          </Col>
          <Col span={22}>
            <AntForm.Item
              className={style['FormItem--Compact']}
              wrapperCol={{ span: 24 }}
            >
              <EmailParticipantSelect
                {...field}
                // bordered={false}
                mode="tags"
                tokenSeparators={[', ']}
              />
            </AntForm.Item>
          </Col>
        </Row>
      )}
    </FormField>
  );
}

function RecipientFields() {
  return (
    <>
      <RecipientField name="to" labelId="sendMessage.to" />
      <RecipientField name="cc" labelId="sendMessage.cc" />
      <RecipientField name="bcc" labelId="sendMessage.bcc" />
      <RecipientField name="replyTo" labelId="sendMessage.replyTo" />
    </>
  );
}

function SubjectField() {
  return (
    <FormField name="subject" labelId="sendMessage.subject">
      {({ field, label }) => (
        <Row>
          <Col span={2}>
            <AntForm.Item
              label={label}
              className={style['FormItem--Compact']}
              labelCol={{ span: 24 }}
            >
              {/* Empty Form.Item for label only */}
            </AntForm.Item>
          </Col>
          <Col span={22}>
            <AntForm.Item
              className={style['FormItem--Compact']}
              wrapperCol={{ span: 24 }}
            >
              <Input {...field} />
            </AntForm.Item>
          </Col>
        </Row>
      )}
    </FormField>
  );
}

function BodyAndFilesFields({ sending, account }) {
  const uploaderRef = useRef<FileUploadButtonPropsRef>(null);

  const [fileLinkMutate] = useCreateFileDownloadLinkMutation();
  const downloadFile = async (file: FileUploadFile) => {
    const url = await fileLinkMutate({
      id: file.response!.id,
      triggerDownload: true,
    });
    if (url) {
      window.open(url, '__blank');
    }
  };

  const { executeUpload } = useMessagingFileUpload();
  const uploadFile = useCallback<FileUploadUploadFunction>(
    async file => {
      try {
        const { result } = executeUpload(file.originFileObj!, {
          messagingAccountId: account.id,
          contentType: file.type!,
          filename: file.name,
          size: file.size!,
        });
        const { id, contentId } = await result;
        const url = await fileLinkMutate({ id, triggerDownload: false });
        const src = url!;
        return { id, contentId: contentId!, src };
      } catch (e) {
        showErrorMessage(formatError(e));
        throw e;
      }
    },
    [account.id, executeUpload, fileLinkMutate]
  );

  const onDropFile = useCallback<RichEditorImageUploadFunction>(async file => {
    const { src, contentId } = await uploaderRef.current!.insertFile(file);
    return { src, contentId };
  }, []);

  return (
    <div className={style.EditorWrapper}>
      <FormField name="body" placeholderId="sendMessage.body">
        {({ field: bodyField }) => (
          <RichTextEditor
            {...bodyField}
            isInFlex
            uploadFunction={onDropFile}
            autofocus
          >
            <div className={style.CustomButtons}>
              <FormField name="files">
                {({ field: filesField }) => {
                  const files = filesField.value as UploadFile[];
                  const isUploading = some(
                    files,
                    f => f.status === 'uploading'
                  );
                  return (
                    <>
                      <FileUploadButton
                        ref={uploaderRef}
                        {...filesField}
                        multiple
                        upload={uploadFile}
                      >
                        <Button
                          icon={<FileAddOutlined />}
                          size="large"
                          type="text"
                        />
                      </FileUploadButton>
                      <OptionalTooltip
                        titleId="sendMessage.sendDisabled.uploading"
                        enabled={isUploading}
                        color="red"
                      >
                        <Button
                          icon={<SendIcon />}
                          size="large"
                          type="text"
                          htmlType="submit"
                          loading={sending}
                          disabled={sending || isUploading}
                        />
                      </OptionalTooltip>
                    </>
                  );
                }}
              </FormField>
            </div>
          </RichTextEditor>
        )}
      </FormField>
      <FormField name="files">
        {({ field }) => <FileUploadList {...field} download={downloadFile} />}
      </FormField>
    </div>
  );
}

function FormContent({ sending, account }) {
  return (
    <Space
      direction="vertical"
      // split={<div className={style.Divider} />}
      size={0}
    >
      <RecipientFields />
      <SubjectField />
      <div className={style.Divider} />
      <BodyAndFilesFields sending={sending} account={account} />
    </Space>
  );
}

type Props = {
  account: MessagingAccount;
  reaction?: MessageComposerReaction;
  onSendStart: () => void;
  restoreEditState: (values: EmailMessageComposerFormValues) => void;
  initialValues?: EmailMessageComposerFormValues;
  formRef: MutableRefObject<any>;
};

export default function MessageComposerEmailSubform({
  account,
  reaction,
  onSendStart,
  restoreEditState,
  initialValues: initialValuesOuter,
  formRef,
}: Props) {
  const yup = useLocalizedYup();
  const { emailListValidation, notEmptyListValidation } =
    useCustomValidations();

  const validationSchema = yup.object().shape({
    to: yup
      .array()
      .required()
      .test(notEmptyListValidation)
      .test(emailListValidation),
    subject: yup.string().required(),
  });

  const [sendEmail, { loading: sending }] = useSendMessage();
  const [handleBGOp] = useBackgroundOperation();
  const intl = useIntl();

  const { data: computedInitialValues, loading: computingInitialValues } =
    useEmailMessageComposerInitialValues({
      reaction,
      account,
      originalBody: formRef.current?.values?.body.get(),
    });

  const initialValues = initialValuesOuter || computedInitialValues;
  const loading = initialValuesOuter ? false : computingInitialValues;

  const client = useApolloClient();

  const onSubmit = async (values: EmailMessageComposerFormValues) => {
    handleBGOp(
      async () =>
        sendEmail({
          input: await emailFormToGraphQlInput({ values, account, reaction }),
        }),
      {
        loadingText: intl.formatMessage({
          id: 'sendMessage.operation.loading',
        }),
        errorText: intl.formatMessage({ id: 'sendMessage.operation.error' }),
        errorActions: [
          {
            text: intl.formatMessage({
              id: 'sendMessage.operation.error.restore',
            }),
            handler: () => restoreEditState(values),
          },
        ],
        successText: intl.formatMessage({
          id: 'sendMessage.operation.success',
        }),
        onStart: onSendStart,
        onError: e => showErrorMessage(formatError(e)),
        onSuccess: () => {
          client.refetchQueries({ include: ['GetMessagingThreads'] });
        },
      }
    );
  };

  return loading || !initialValues ? (
    <Spin />
  ) : (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      innerRef={formRef}
    >
      <Form>
        <FormContent sending={sending} account={account} />
      </Form>
    </Formik>
  );
}
