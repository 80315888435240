import {
  ArrowLeftOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Col, Row } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { isArray, isEmpty } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router';

import RouteDefinitions from '../../../app/routes';
import { showConfirm } from '../../../common/utils/messageUtils';
import { ErrorAlert } from '../../../components/ErrorAlert';
import { OverlaySpinner } from '../../../components/OverlaySpinner';
import { CircleButton } from '../../../components/buttons';
import { TranslationId } from '../../../types/appTypes';
import { MessagingAccountLabel } from '../../accounts/accountFormats';
import { useContactQuery, useDeleteContactMutation } from '../contactsGraphql';
import { useContactsNavigation } from '../contactsNavigation';
import commonStyle from '../layout/ContactsAppCommon.module.less';
import style from './ContactDetail.module.less';

type ContactDetailProps = {
  id: number;
};

export function ContactDetail({ id }: ContactDetailProps) {
  const { contact, loading, error } = useContactQuery({ id });
  const { navigate } = useContactsNavigation();
  const [executeDelete] = useDeleteContactMutation();

  if (error) {
    return <ErrorAlert error={error} />;
  }
  if (loading) {
    return <OverlaySpinner />;
  }

  if (!contact) {
    return null;
  }

  return (
    <>
      <div className={commonStyle.Toolbar}>
        <div className="FlexRow FlexRow--SpaceSm">
          <CircleButton
            icon={<ArrowLeftOutlined />}
            onClick={() => navigate(RouteDefinitions.contacts)}
          />
        </div>
        <div className="FlexRow FlexRow--SpaceSm">
          <CircleButton
            icon={<EditOutlined />}
            onClick={() =>
              navigate(
                generatePath(RouteDefinitions.editContact, {
                  contactId: `${contact.id}`,
                })
              )
            }
          />
          <CircleButton
            icon={<DeleteOutlined />}
            onClick={() =>
              showConfirm({
                title: <FormattedMessage id="contacts.delete.modalTitle" />,
                content: <FormattedMessage id="contacts.delete.modalContent" />,
                onOk: async () => {
                  await executeDelete({ id: contact.id });
                  navigate(RouteDefinitions.contacts);
                },
              })
            }
          />
        </div>
      </div>
      <div className={commonStyle.Content}>
        <Row align="top" gutter={10}>
          <Col>
            <Avatar
              className={style.Avatar}
              src={contact.pictureUrl}
              size="large"
              shape="square"
            />
          </Col>
          <Col className="Flex--1">
            <LabelAndValue
              labelId="contacts.givenName"
              value={contact.givenName}
            />
            <LabelAndValue
              labelId="contacts.middleName"
              value={contact.middleName}
            />
            <LabelAndValue labelId="contacts.surname" value={contact.surname} />
            <LabelAndValue
              labelId="contacts.emails"
              translationValues={{ count: contact.emails.length }}
              value={contact.emails}
            />
            <LabelAndValue
              labelId="contacts.phoneNumbers"
              translationValues={{ count: contact.phoneNumbers.length }}
              value={contact.phoneNumbers}
            />
            <LabelAndValue
              labelId="contacts.whatsappNumbers"
              translationValues={{ count: contact.whatsappAccounts.length }}
              value={contact.whatsappAccounts.map(w => w.phoneNumber)}
            />
            <LabelAndValue
              labelId="contacts.account"
              value={
                contact.messagingAccount && (
                  <div className="FlexRow FlexRow--SpaceSm">
                    <MessagingAccountLabel account={contact.messagingAccount} />
                  </div>
                )
              }
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

type LabelAndValueProps = {
  labelId: TranslationId;
  translationValues?: Record<string, any>;
  value?: string | string[] | JSX.Element | null;
};

function LabelAndValue({
  labelId,
  value,
  translationValues,
}: LabelAndValueProps) {
  if (!value || isEmpty(value)) {
    return null;
  }
  return (
    <div className={style.LabelRow}>
      <div className={style.Label}>
        <FormattedMessage id={labelId} values={translationValues} />:
      </div>
      <div className={style.LabelValue}>
        {isArray(value) ? value.join(', ') : value}
      </div>
    </div>
  );
}
