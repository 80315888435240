import { Spin } from 'antd';

import styles from './OverlaySpinner.module.less';

export function OverlaySpinner() {
  return (
    <div className={styles.Container}>
      <Spin />
    </div>
  );
}
