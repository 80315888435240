import {
  ErrorBoundary as SentryErrorBoundary,
  init as initSentry,
} from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { ReactNode, useEffect } from 'react';

import { config } from '../config/config';
import { ErrorAlert } from './ErrorAlert';

type ErrorBoundaryProps = {
  children: ReactNode;
};

export default function ErrorBoundary({ children }: ErrorBoundaryProps) {
  useEffect(() => {
    if (config.sentryDsn) {
      initSentry({
        dsn: config.sentryDsn,
        integrations: [new BrowserTracing()],
        tracesSampleRate: 0.1,
        environment: config.sentryEnv,
      });
    }
  }, []);

  return (
    <SentryErrorBoundary fallback={({ error }) => <ErrorAlert error={error} />}>
      {children}
    </SentryErrorBoundary>
  );
}
