import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { compact, isNumber } from 'lodash-es';
import {
  ComponentType,
  ForwardRefExoticComponent,
  Fragment,
  ReactNode,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import styles from './LabelList.module.less';

type LabelListProps = {
  children: ReactNode[];
  delimiter?: string;
  useEllipsis: boolean;
};

export function LabelList({
  children,
  delimiter,
  useEllipsis,
}: LabelListProps) {
  return (
    <>
      {children.map((ch, i) => (
        <span
          key={i}
          className={classNames(
            styles.Label,
            useEllipsis && styles['Label--UseEllipsis']
          )}
        >
          {ch}
          <span className={styles['Label__Delimiter']}>
            {i < children.length - 1 ? delimiter : null}
          </span>
        </span>
      ))}
    </>
  );
}

type ClippedLabelListProps = {
  component?: string | ComponentType<any> | ForwardRefExoticComponent<any>;
  children: ReactNode[];
  delimiter?: string;
  renderOverflow?: (number: number) => ReactNode;
  clip: boolean;
  allowExpand?: boolean;
};

const MIN_LABELS = 1;

const DEFAULT_RENDER_OVERFLOW: ClippedLabelListProps['renderOverflow'] =
  number => <span className={styles.NumericOverflow}>{number}</span>;

export function ClippedLabelList({
  component: C = 'span',
  delimiter = ', ',
  children,
  renderOverflow = DEFAULT_RENDER_OVERFLOW,
  clip,
  allowExpand = false,
}: ClippedLabelListProps) {
  const ref = useRef<HTMLElement>(null);
  const [numVisibleElements, setNumVisibleElements] = useState<
    number | undefined
  >();
  const [expanded, setExpanded] = useState(false);

  const lastClientWidth = useRef(0);
  useLayoutEffect(() => {
    if (!ref.current || !clip) {
      return;
    }
    const { children: elements, clientWidth, scrollWidth } = ref.current;

    const prevClientWidth = lastClientWidth.current;
    lastClientWidth.current = clientWidth;

    if (isNumber(numVisibleElements)) {
      if (prevClientWidth !== clientWidth) {
        // Recompute if the width was changed
        setNumVisibleElements(undefined);
      } else if (scrollWidth > clientWidth && numVisibleElements > MIN_LABELS) {
        // Lower the value if it's overflowing (possibly from the overflow element)
        setNumVisibleElements(numVisibleElements - 1);
        return;
      } else {
        // Don't recompute in case we already have a result and there was no change
        return;
      }
    }

    const acc = { width: 0, num: 0 };
    while (acc.num < elements.length) {
      const index = acc.num;
      const width = elements[index].clientWidth;
      if (acc.width + width <= clientWidth || acc.num < MIN_LABELS) {
        acc.width += width;
        acc.num++;
      } else {
        break;
      }
    }
    setNumVisibleElements(acc.num);
  }, [clip, numVisibleElements]);

  const numOverflow = isNumber(numVisibleElements)
    ? children.length - numVisibleElements
    : 0;

  const clipActive = clip && (!allowExpand || !expanded);

  return (
    <C
      ref={ref}
      className={classNames(
        styles.LabelsContainer,
        !clipActive && styles['LabelsContainer--NoClip']
      )}
    >
      {isNumber(numVisibleElements) && clipActive ? (
        <LabelList
          children={[
            ...children.slice(0, numVisibleElements),
            ...(numOverflow > 0
              ? compact([
                  <Fragment key="overflow">
                    {renderOverflow?.(numOverflow)}
                    {allowExpand && (
                      <PlusCircleOutlined
                        className={styles.CollapseIcon}
                        onClick={() => setExpanded(true)}
                      />
                    )}
                  </Fragment>,
                ])
              : []),
          ]}
          useEllipsis={numVisibleElements === 1}
          delimiter={delimiter}
        />
      ) : (
        <>
          <LabelList
            children={children}
            useEllipsis={false}
            delimiter={delimiter}
          />
          <MinusCircleOutlined
            className={styles.CollapseIcon}
            onClick={() => setExpanded(false)}
          />
        </>
      )}
    </C>
  );
}
