import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DragOutlined,
  EditOutlined,
  PlusCircleOutlined,
  PlusOutlined,
  SortAscendingOutlined,
} from '@ant-design/icons';
import { Button, Empty, Space } from 'antd';
import { Form, Formik } from 'formik';
import { isEmpty } from 'lodash-es';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router-dom';

import RouteDefinitions from '../../../app/routes';
import { showConfirm } from '../../../common/utils/messageUtils';
import { ErrorAlert } from '../../../components/ErrorAlert';
import { OverlaySpinner } from '../../../components/OverlaySpinner';
import SidebarControls, {
  SidebarControlButton,
} from '../../../components/SidebarControls';
import {
  VerticalMenu,
  VerticalMenuItem,
} from '../../../components/VerticalMenu';
import FormField from '../../../components/forms/FormField';
import { SortableList } from '../../../components/forms/sortable';
import {
  GetNamedMessagingThreadFiltersQuery,
  NamedMessagingThreadsFilter,
} from '../../../types/graphqlGenerated';
import { useUpdateUserSettingsMutation } from '../../settings/userSettingsGraphql';
import { useMessagesActions } from '../messagesReducer';
import styles from './MessagingFilters.module.less';
import { MessagingFiltersEditor } from './MessagingFiltersEditor';
import {
  useDeleteNamedFilterMutation,
  useNamedFiltersQuery,
} from './filtersGraphql';

type FilterLabelProps = {
  filter: NamedMessagingThreadsFilter;
  activeFilterId?: number;
};

function FilterMenuItem({ filter, activeFilterId }: FilterLabelProps) {
  const { setFilterEditorOpen } = useMessagesActions();
  const [execDelete] = useDeleteNamedFilterMutation();

  return (
    <VerticalMenuItem
      active={filter.id === activeFilterId}
      to={generatePath(RouteDefinitions.messagesFilterView, {
        filterId: `${filter.id}`,
      })}
      rightContent={
        <div className={styles['MenuItem__Buttons']}>
          <Button
            size="small"
            icon={<EditOutlined />}
            onClick={async () => {
              setFilterEditorOpen(true, filter);
            }}
          />
          <Button
            size="small"
            icon={<DeleteOutlined />}
            onClick={() =>
              showConfirm({
                title: <FormattedMessage id="filterEditor.delete.modalTitle" />,
                content: (
                  <FormattedMessage id="filterEditor.delete.modalContent" />
                ),
                onOk: () => execDelete({ id: filter.id }),
              })
            }
          />
        </div>
      }
    >
      {filter.name}
    </VerticalMenuItem>
  );
}

type MessagingFiltersSortableProps = {
  stopSorting: () => void;
  filters: GetNamedMessagingThreadFiltersQuery['getNamedMessagingThreadFilters'];
};

function MessagingFiltersSortable({
  stopSorting,
  filters,
}: MessagingFiltersSortableProps) {
  const [mutate] = useUpdateUserSettingsMutation();

  return (
    <Formik
      initialValues={{ messagingFilters: filters }}
      onSubmit={async values => {
        // This works optimistically, so we don't need to await
        mutate({
          input: { filtersOrder: values.messagingFilters.map(f => f.id) },
        });
        stopSorting();
      }}
    >
      <Form>
        <FormField name="messagingFilters">
          {({ field }) => (
            <SortableList
              {...field}
              value={field.value as typeof filters}
              renderContainer={({ info, children }) => (
                <VerticalMenu ref={info.innerRef} {...info.droppableProps}>
                  {children}
                </VerticalMenu>
              )}
              renderItem={({ info, item }) => (
                <VerticalMenuItem
                  ref={info.innerRef}
                  {...info.draggableProps}
                  {...info.dragHandleProps}
                  leftContent={<DragOutlined />}
                >
                  {item.name}
                </VerticalMenuItem>
              )}
            />
          )}
        </FormField>
        <SidebarControls>
          <SidebarControlButton
            tooltipId="filterEditor.sort.save"
            htmlType="submit"
            icon={<CheckOutlined />}
          />
          <SidebarControlButton
            tooltipId="filterEditor.sort.cancel"
            icon={<CloseOutlined />}
            onClick={() => stopSorting()}
          />
        </SidebarControls>
      </Form>
    </Formik>
  );
}

type MessagingFiltersProps = {
  activeFilterId?: number;
};

export function MessagingFilters({ activeFilterId }: MessagingFiltersProps) {
  const { loading, error, filters } = useNamedFiltersQuery();
  const { setFilterEditorOpen } = useMessagesActions();
  const [sortActive, setSortActive] = useState(false);

  if (error) {
    return <ErrorAlert error={error} />;
  }
  if (loading) {
    return <OverlaySpinner />;
  }

  if (!filters) {
    return null;
  }

  if (isEmpty(filters)) {
    return (
      <Space direction="vertical" align="center" size={20}>
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() => setFilterEditorOpen(true)}
        >
          <span>
            <FormattedMessage id="filtersList.createFirst" />
          </span>
        </Button>
        <Empty />
      </Space>
    );
  }

  if (sortActive) {
    return (
      <MessagingFiltersSortable
        stopSorting={() => setSortActive(false)}
        filters={filters}
      />
    );
  }

  return (
    <>
      <VerticalMenu>
        {filters.map(f => (
          <FilterMenuItem
            key={f.id}
            filter={f}
            activeFilterId={activeFilterId}
          />
        ))}
      </VerticalMenu>
      <SidebarControls>
        <SidebarControlButton
          tooltipId="filtersList.add"
          icon={<PlusOutlined />}
          onClick={() => setFilterEditorOpen(true)}
        />
        <SidebarControlButton
          tooltipId="filterEditor.manageSort"
          icon={<SortAscendingOutlined />}
          onClick={() => setSortActive(true)}
        />
      </SidebarControls>
      <MessagingFiltersEditor />
    </>
  );
}
