import { EditOutlined } from '@ant-design/icons';
import {
  Form as AntForm,
  Button,
  Col,
  Input,
  Modal,
  Row,
  Space,
  Tabs,
} from 'antd';
import { Form, Formik } from 'formik';
import { isString } from 'lodash-es';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { formatError } from '../../../common/utils/errorUtils';
import { showErrorMessage } from '../../../common/utils/messageUtils';
import { OverlaySpinner } from '../../../components/OverlaySpinner';
import FormField from '../../../components/forms/FormField';
import {
  RichEditorMode,
  RichTextEditor,
  RichTextEditorValue,
  richTextEditorValueToString,
} from '../../../components/forms/RichEditor';
import { RichEditorImageUploadFunction } from '../../../components/forms/RichEditorPlugins';
import { BasicSwitch } from '../../../components/forms/booleanFields';
import {
  MessagingAccount,
  MessagingAccountType,
} from '../../../types/graphqlGenerated';
import { DynamicMenuItem } from '../../common/dynamicMenu/dynamicMenuTypes';
import { usePublicFileUpload } from '../../files/filesGraphql';
import {
  useEmailSignatureQuery,
  useSetEmailSignatureMutation,
} from '../accountExtensionsGraphql';
import styles from './AccountsListItem.module.less';

type SignatureChangeFormProps = {
  account: MessagingAccount;
  close: () => void;
};

type FormValues = {
  active: boolean;
  mode: 'rich' | 'raw';
  richBody: RichTextEditorValue;
  htmlBody: string;
};

function SignatureChangeForm({ account, close }: SignatureChangeFormProps) {
  const { signature, loading } = useEmailSignatureQuery({
    messagingAccountId: account.id,
  });
  const [updateSignature, { loading: saving }] = useSetEmailSignatureMutation();

  const { executeUpload, uploading } = usePublicFileUpload();
  const uploadFile = useCallback<RichEditorImageUploadFunction>(
    async file => {
      try {
        const { result } = executeUpload(file);
        const src = await result;
        return { contentId: '', src };
      } catch (e) {
        showErrorMessage(formatError(e));
        throw e;
      }
    },
    [executeUpload]
  );

  if (loading) {
    return <OverlaySpinner />;
  }

  const initialValues: FormValues = signature
    ? {
        active: signature.active,
        richBody: signature.body,
        htmlBody: signature.body,
        mode: 'rich',
      }
    : { active: true, richBody: '', htmlBody: '', mode: 'rich' };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async values => {
        const body =
          values.mode === 'rich'
            ? richTextEditorValueToString(values.richBody)!
            : values.htmlBody;
        await updateSignature({
          messagingAccountId: account.id,
          input: { active: values.active, body },
        });
        close();
      }}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <Space direction="vertical" size={10}>
            <div>
              <FormField
                name="active"
                labelId="connectedAccounts.setSignature.active"
              >
                {({ field, label }) => (
                  <AntForm.Item label={label}>
                    <BasicSwitch {...field} />
                  </AntForm.Item>
                )}
              </FormField>
              <Tabs
                activeKey={values.mode}
                onChange={tab => {
                  if (tab === 'rich') {
                    if (isString(values.richBody)) {
                      setFieldValue('richBody', values.htmlBody);
                    } else {
                      values.richBody.set(values.htmlBody);
                    }
                  } else {
                    setFieldValue(
                      'htmlBody',
                      richTextEditorValueToString(values.richBody)
                    );
                  }
                  setFieldValue('mode', tab);
                }}
                className={styles.SignatureTabs}
              >
                <Tabs.TabPane
                  key="rich"
                  tab={
                    <FormattedMessage id="connectedAccounts.setSignature.mode.rich" />
                  }
                  className={styles.SignatureEditor}
                >
                  <FormField name="richBody">
                    {({ field }) => (
                      <RichTextEditor
                        mode={RichEditorMode.Simple}
                        isInFlex
                        uploadFunction={uploadFile}
                        {...field}
                      />
                    )}
                  </FormField>
                </Tabs.TabPane>
                <Tabs.TabPane
                  key="raw"
                  tab={
                    <FormattedMessage id="connectedAccounts.setSignature.mode.raw" />
                  }
                  className={styles.SignatureEditor}
                >
                  <FormField name="htmlBody">
                    {({ field }) => <Input.TextArea {...field} />}
                  </FormField>
                </Tabs.TabPane>
              </Tabs>
            </div>
            <div>
              <Row align="middle" justify="end" gutter={10}>
                <Col>
                  <Button onClick={close} disabled={loading}>
                    <FormattedMessage id="connectedAccounts.form.cancel" />
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={saving || loading || uploading}
                    loading={saving}
                  >
                    <FormattedMessage id="connectedAccounts.form.save" />
                  </Button>
                </Col>
              </Row>
            </div>
          </Space>
        </Form>
      )}
    </Formik>
  );
}

export function useEmailSignatureAccountMenuItem({
  account,
}: {
  account: MessagingAccount;
}): DynamicMenuItem {
  useEmailSignatureQuery({ messagingAccountId: account.id }); // pre-fetch signature for later use
  const [modalOpen, setModalOpen] = useState(false);

  return {
    key: 'emailSignature',
    visible: account.accountType === MessagingAccountType.Email,
    icon: <EditOutlined />,
    label: <FormattedMessage id="connectedAccounts.setSignature" />,
    onClick: () => setModalOpen(true),
    additionalElements: (
      <Modal
        visible={modalOpen}
        onCancel={() => setModalOpen(false)}
        width="600px"
        destroyOnClose
        footer={null}
      >
        <SignatureChangeForm
          account={account}
          close={() => setModalOpen(false)}
        />
      </Modal>
    ),
  };
}
