import generatePicker, {
  RangePickerProps as AntRangePickerProps,
  PickerDateProps,
} from 'antd/es/date-picker/generatePicker';
import { FieldInputProps, useFormikContext } from 'formik';
import { identity } from 'lodash-es';
import generateConfig from 'rc-picker/lib/generate/dateFns';

type AntDatePickerProps = Omit<PickerDateProps<Date>, 'picker'>;

const AntDatePicker = generatePicker<Date>(generateConfig);

export type DatePickerProps = AntDatePickerProps &
  FieldInputProps<Date> & {
    formatValue?: (val: Date | null) => any;
  };

export function DatePicker({
  name,
  value,
  onChange: _onChange,
  onBlur: _onBlur,
  formatValue = identity,
  ...rest
}: DatePickerProps) {
  const { setFieldValue, setFieldTouched } = useFormikContext();

  return (
    <AntDatePicker
      name={name}
      onChange={val => setFieldValue(name, formatValue(val))}
      value={value}
      onBlur={() => setFieldTouched(name)}
      {...rest}
    />
  );
}

export type MonthPickerProps = AntDatePickerProps & FieldInputProps<Date>;

export function MonthPicker({
  name,
  onChange: _onChange,
  ...rest
}: DatePickerProps) {
  const { setFieldValue } = useFormikContext();

  return (
    <AntDatePicker.MonthPicker
      name={name}
      onChange={val => setFieldValue(name, val)}
      {...rest}
    />
  );
}

export type RangePickerProps = AntRangePickerProps<Date> &
  FieldInputProps<Date>;

export function RangePicker({
  name,
  onChange: _onChange,
  ...rest
}: RangePickerProps) {
  const { setFieldValue } = useFormikContext();

  return (
    <AntDatePicker.RangePicker
      name={name}
      onChange={val => setFieldValue(name, val)}
      {...rest}
    />
  );
}
