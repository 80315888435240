import { UserOutlined } from '@ant-design/icons';
import { Input, Space } from 'antd';
import { Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';

import {
  showErrorMessage,
  showSuccessMessage,
} from '../../common/utils/messageUtils';
import { PrimaryButton } from '../../components/buttons';
import FormField from '../../components/forms/FormField';
import { useLocalizedYup } from '../../config/intl/LocaleProvider';
import { theme } from '../../styles/theme';
import { useAuthService } from './authService';

type ResetPasswordFormValues = {
  email: string;
};

type ResetPasswordFormProps = {
  onSubmit: (email: string) => void;
  email: string;
};

const ResetPasswordForm = ({
  onSubmit: onSubmitOuter,
  email: initialEmail,
}: ResetPasswordFormProps) => {
  const authService = useAuthService();

  const yup = useLocalizedYup();

  const validationSchema = yup.object().shape({
    email: yup.string().email().required(),
  });

  const onSubmit = async ({ email }: ResetPasswordFormValues) => {
    try {
      await authService.resetPassword(email);
      showSuccessMessage(
        <FormattedMessage id="forgotPassword.sentConfirmationCode" />
      );
      onSubmitOuter(email);
    } catch (error) {
      showErrorMessage(<FormattedMessage id="resetPassword.badEmail" />);
    }
  };

  return (
    <Formik
      initialValues={{ email: initialEmail }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <Form>
        <Space size={20} direction="vertical">
          <FormField
            name="email"
            placeholderId="forgotPassword.placeholder.email"
          >
            {({ field }) => (
              <Input
                {...field}
                size="large"
                prefix={
                  <UserOutlined style={{ color: theme['@primary-color'] }} />
                }
              />
            )}
          </FormField>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <PrimaryButton
              htmlType="submit"
              labelId="forgotPassword.resetPassword"
            />
          </div>
        </Space>
      </Form>
    </Formik>
  );
};

export default ResetPasswordForm;
