import {
  ApolloCache,
  ApolloClient,
  DocumentNode,
  MutationHookOptions,
  QueryHookOptions,
  makeReference,
  useApolloClient,
  useMutation,
  useQuery,
} from '@apollo/client';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import { identity, isNil, stubTrue } from 'lodash-es';
import { useCallback, useEffect } from 'react';

type CreateUseMutationOpts<RESP, VARS, RESULT = RESP> = Omit<
  MutationHookOptions<RESP, VARS>,
  'onCompleted' | 'onError' | 'mutation'
> & {
  extractResult?: (resp: RESP) => RESULT;
  // If boolean is returned, onOperationSuccess / onOperationError is dispatched
  onCompleted?: (
    result: RESULT,
    variables: VARS,
    client: ApolloClient<any>
  ) => void | boolean;
};

type UseMutationOptions<RESULT> = {
  onError?: (error: Error) => void;
  onCompleted?: (result: RESULT) => void;
  onOperationSuccess?: () => void;
  onOperationError?: () => void;
};

export function createUseMutation<RESP, VARS, RESULT>(
  mutation: DocumentNode,
  {
    extractResult = identity,
    onCompleted: onCompletedOuter,
    ...rest
  }: CreateUseMutationOpts<RESP, VARS, RESULT>
) {
  return ({
    onError,
    onCompleted,
    onOperationSuccess,
    onOperationError,
  }: UseMutationOptions<RESULT> = {}) => {
    const client = useApolloClient();
    const [mutate, { loading }] = useMutation<RESP, VARS>(mutation, {
      onError,
      onCompleted: data => {
        const res = extractResult(data);
        onCompleted?.(res);
      },
      ...rest,
    });

    const simpleMutate = useCallback(
      async (variables: VARS) => {
        const { data, errors } = await mutate({ variables });
        if (errors) {
          throw errors[0];
        }
        const res = data ? extractResult(data) : undefined;

        if (!isNil(res) && onCompletedOuter) {
          const operationSuccess = onCompletedOuter?.(res, variables, client);
          if (operationSuccess === true) {
            onOperationSuccess?.();
          } else if (operationSuccess === false) {
            onOperationError?.();
          }
        }

        return res;
      },
      [client, mutate, onOperationError, onOperationSuccess]
    );

    return [simpleMutate, { loading }] as [
      typeof simpleMutate,
      { loading: boolean }
    ];
  };
}

type CreateUseQueryOpts<
  RESP,
  VARS,
  RESULT_NAME extends string,
  RESULT = RESP
> = {
  resultName: RESULT_NAME;
  extractResult?: (resp: RESP) => RESULT;
  opts?: QueryHookOptions<RESP, VARS>;
};

type UseQueryOptions<RESP, VARS, RESULT> = Pick<
  QueryHookOptions<RESP, VARS>,
  'skip' | 'fetchPolicy' | 'notifyOnNetworkStatusChange'
> & { onCompleted?: (result: RESULT) => void };

type CreateUseQueryResult<RESULT_NAME extends string, RESULT, VARS> = {
  [key in RESULT_NAME]?: RESULT;
} & {
  loading: boolean;
  error: any;
  refetch: (variables?: Partial<VARS>) => Promise<RESULT | undefined>;
  fetchMore: (variables?: Partial<VARS>) => Promise<RESULT | undefined>;
};

export function createUseQuery<RESP, VARS, RESULT_NAME extends string, RESULT>(
  query: DocumentNode,
  {
    resultName,
    extractResult = identity,
    opts: outerOpts,
  }: CreateUseQueryOpts<RESP, VARS, RESULT_NAME, RESULT>
) {
  return (variables?: VARS, opts?: UseQueryOptions<RESP, VARS, RESULT>) => {
    const { onCompleted, ...optsRest } = opts || {};
    const {
      data,
      loading,
      error,
      refetch: originalRefetch,
      fetchMore: originalFetchMore,
    } = useQuery<RESP, VARS>(query, {
      ...outerOpts,
      variables,
      ...optsRest,
      onCompleted: resp => {
        outerOpts?.onCompleted?.(resp);
        if (resp) {
          onCompleted?.(extractResult(resp));
        }
      },
    });

    const refetch = useCallback(
      async refetchVariables => {
        const { data: newData } = await originalRefetch(refetchVariables);
        return newData ? extractResult(newData) : undefined;
      },
      [originalRefetch]
    );

    const fetchMore = useCallback(
      async fetchMoreVariables => {
        const { data: newData } = await originalFetchMore({
          variables: fetchMoreVariables,
        });
        return newData ? extractResult(newData) : undefined;
      },
      [originalFetchMore]
    );

    return {
      [resultName]: data ? extractResult(data) : undefined,
      loading,
      error,
      refetch,
      fetchMore,
    } as CreateUseQueryResult<RESULT_NAME, RESULT, VARS>;
  };
}

type CreateUseSubscriptionOpts<RESP, RESULT = RESP> = {
  extractResult?: (resp: RESP) => RESULT;
  onNext?: (result: RESULT, client: ApolloClient<any>) => void;
};

type UseSubscriptionOptions<RESULT> = {
  onData?: (result: RESULT) => void;
};

export function createUseSubscription<RESP, VARS, RESULT>(
  subscription: DocumentNode,
  { extractResult = identity, onNext }: CreateUseSubscriptionOpts<RESP, RESULT>
) {
  return ({ onData }: UseSubscriptionOptions<RESULT> = {}) => {
    const client = useApolloClient();

    useEffect(() => {
      const handle = client
        .subscribe<RESP, VARS>({
          query: subscription,
        })
        .subscribe({
          next: ({ data }) => {
            const res = data ? extractResult(data) : undefined;
            if (res) {
              onNext?.(res, client);
              onData?.(res);
            }
          },
        });
      return () => handle.unsubscribe();
    }, [client, onData]);
  };
}

export function parseStoreFieldName<ARGS>(
  storeFieldName: string
): ARGS | undefined {
  const chunks = storeFieldName.match(/^([a-zA-Z]+):?(.+)?/);
  if (chunks && chunks[2]) {
    const json = chunks[2].replace(/^\(/, '').replace(/\)$/, '');
    try {
      return JSON.parse(json);
    } catch (e) {
      console.warn('Unexpected Apollo cache storeFieldName: ', json, e);
      return undefined;
    }
  }
}

export function getGraphqlRootQueryId(cache: ApolloCache<unknown>) {
  return cache.identify(makeReference('ROOT_QUERY'));
}

type DeleteQueryOptions<ARGS> = {
  type?: 'DELETE' | 'INVALIDATE';
  shouldInvalidate?: (input: { args?: ARGS }) => boolean;
};

/**
 * Function to delete all instances of the query ${queryName} from the cache (in case some mutation invalidates them)
 * `shouldInvalidate` can limit which instances are actually deleted based on args (for example only for one account)
 */
export function deleteQueryFromCache<ARGS>(
  cache: ApolloCache<unknown>,
  queryName: string,
  {
    type: deleteType = 'DELETE',
    shouldInvalidate = stubTrue,
  }: DeleteQueryOptions<ARGS> = {}
) {
  cache.modify({
    id: getGraphqlRootQueryId(cache),
    fields: {
      [queryName]: (
        fieldValue,
        { fieldName, storeFieldName, [deleteType]: DELETE_TOKEN }
      ) => {
        const args = parseStoreFieldName<ARGS>(storeFieldName);
        if (shouldInvalidate({ args })) {
          console.debug(`Deleting ${fieldName}\n`, args);
          return DELETE_TOKEN;
        }
        return fieldValue;
      },
    },
  });
}

/**
 * Function to update all instances of the query ${queryName}
 * `getNewValue` should provide new value based on the old value and query arguments
 */
export function updateQueryInCache<VAL, ARGS>(
  cache: ApolloCache<unknown>,
  queryName: string,
  getNewValue: (input: {
    value: VAL;
    args?: ARGS;
    readField: ReadFieldFunction;
  }) => VAL
) {
  cache.modify({
    id: getGraphqlRootQueryId(cache),
    fields: {
      [queryName]: (fieldValue: VAL, { storeFieldName, readField }) => {
        const args = parseStoreFieldName<ARGS>(storeFieldName);
        return getNewValue({ value: fieldValue, args, readField });
      },
    },
  });
}
