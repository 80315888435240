import { parseOneAddress } from 'email-addresses';
import { compact, find } from 'lodash-es';

import { MessagingContact } from '../../types/graphqlGenerated';

type SimpleMessagingContact = Omit<MessagingContact, 'messagingAccount'>;
type EnrichedMessagingContact = SimpleMessagingContact & { value?: string };

export function formatContactFullName(contact: SimpleMessagingContact) {
  return compact([contact.givenName, contact.middleName, contact.surname]).join(
    ' '
  );
}

export function formatContact(contact: EnrichedMessagingContact) {
  const fullName = formatContactFullName(contact);
  if (contact.value) {
    const namePrefix = fullName ? `${fullName} ` : '';

    const whatsappAccount = find(
      contact.whatsappAccounts,
      w => w.id === contact.value
    );
    const isEmailValue = parseOneAddress({ input: contact.value });

    if (whatsappAccount) {
      return `${namePrefix} (${whatsappAccount.phoneNumber})`;
    }
    if (isEmailValue) {
      return `${namePrefix} <${contact.value}>`;
    }
    return `${namePrefix} (${contact.value})`;
  }
  return formatContactFullName(contact);
}

type ContactLabelProps = {
  contact: EnrichedMessagingContact;
};

export function ContactLabel({ contact }: ContactLabelProps) {
  return <>{formatContact(contact)}</>;
}
