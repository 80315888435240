// TODO: figure out how to share variables with javascript
// KEEP IN SYNC WITH theme.less !!!

export const theme = {
  // override antd theme
  '@font-size-base': '14px',
  '@primary-color': '#1890ff',
  // custom properties
  '@app-siderbar-width': '250px',
};
