import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import classNames from 'classnames';
import { isEmpty, isNumber } from 'lodash-es';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Linkify from 'react-linkify';

import { useAsync } from '../../../common/utils/hookUtils';
import {
  AttachmentsList,
  FileIcon,
  FileInfo,
} from '../../../components/AttachmentButton';
import { ErrorAlert } from '../../../components/ErrorAlert';
import Highlighter from '../../../components/Highlighter';
import { Whitespace } from '../../../components/Whitespace';
import { MessagingMessageFullFragmentFragment } from '../../../types/graphqlGenerated';
import {
  useCreateFileDownloadLinkMutation,
  useCreateFileThumbnailDownloadLinkMutation,
  useFileReupload,
} from '../../files/filesGraphql';
import styles from './TextMessageBody.module.less';
import { useThreadHighlight } from './helpers/threadUtils';

type Files = MessagingMessageFullFragmentFragment['files'];
type File = Files[0];

type InlineFileProps = {
  file: File;
};

function FailedInlineFile({
  file,
  messageId,
}: InlineFileProps & { messageId?: number }) {
  const [createLink] = useCreateFileThumbnailDownloadLinkMutation();
  const { data: url } = useAsync(
    async () => createLink({ id: file.id, triggerDownload: false }),
    {
      skip: !file.hasThumbnail,
      deps: [file.id],
    }
  );

  const disableReupload = !isNumber(messageId);
  const [requestReupload, { events }] = useFileReupload();
  const [reuploading, setReuploading] = useState(false);
  const [reuploadError, setReuploadError] = useState<Error | undefined>();
  const executeRequestReupload = async () => {
    if (disableReupload) {
      return;
    }
    try {
      setReuploading(true);
      await requestReupload(file.id, messageId!);
    } catch (e) {
      setReuploadError(e);
    } finally {
      setReuploading(false);
    }
  };

  if (reuploading || reuploadError) {
    const errCode = (reuploadError as any)?.code;
    return (
      <div className={styles['InlineFile--Failed']}>
        {reuploading ? <LoadingOutlined /> : <ExclamationCircleOutlined />}
        <FormattedMessage id="chatMessage.item.mediaReupload.loading" />
        <div className={styles['InlineFile--Failed__Events']}>
          <div>
            <strong>
              <FormattedMessage id="chatMessage.item.mediaReupload.events" />:
            </strong>
          </div>
          <div>
            <FormattedMessage id="chatMessage.item.mediaReupload.event.requestSent" />
          </div>
          {events.map((ev, i) => (
            <div key={i}>
              <FormattedMessage
                id={`chatMessage.item.mediaReupload.event.${ev}`}
              />
            </div>
          ))}
          {reuploadError && (
            <div className={styles['InlineFile--Failed__Error']}>
              <FormattedMessage
                id={`chatMessage.item.mediaReupload.error.${errCode}`}
              />
              {errCode === 'other' && (
                <>
                  <Whitespace />({reuploadError.message})
                </>
              )}
            </div>
          )}
        </div>
        {reuploadError ? (
          <Button onClick={() => setReuploadError(undefined)}>
            <FormattedMessage id="chatMessage.item.close" />
          </Button>
        ) : (
          <Button
            onClick={() => {
              setReuploadError(undefined);
              setReuploading(false);
            }}
          >
            <FormattedMessage id="chatMessage.item.cancel" />
          </Button>
        )}
      </div>
    );
  }

  return (
    <div className={styles['InlineFile--Failed']}>
      {url && (
        <div
          className={styles['InlineFile--Failed__Thumbnail']}
          style={{ backgroundImage: `url(${url})` }}
        />
      )}
      <FileIcon contentType={file.contentType} />
      <FormattedMessage id="chatMessage.item.mediaFailed" />
      {!disableReupload && (
        <Button type="primary" onClick={executeRequestReupload}>
          <FormattedMessage id="chatMessage.item.tryDownloadMedia" />
        </Button>
      )}
    </div>
  );
}

function InlineFile({ file }: InlineFileProps) {
  const fileClass = file.contentType?.split('/')[0];
  const [createLink] = useCreateFileDownloadLinkMutation();
  const isDisplayedInline = ['image', 'video', 'audio'].some(
    supported => supported === fileClass
  );
  const {
    data: url,
    loading,
    error,
  } = useAsync(
    async () => createLink({ id: file.id, triggerDownload: false }),
    {
      skip: !isDisplayedInline,
      deps: [file.id],
    }
  );

  const [createDownloadLink] = useCreateFileDownloadLinkMutation({
    onCompleted: res => {
      const redirectUrl = res;
      if (redirectUrl) {
        window.open(redirectUrl, '__blank');
      }
    },
  });
  const downloadFile = async (downFile: FileInfo) => {
    await createDownloadLink({ id: downFile.id!, triggerDownload: true });
  };

  if (error) {
    return <ErrorAlert error={error} />;
  }
  if (loading) {
    return <Spin />;
  }
  if (!url && isDisplayedInline) {
    return null;
  }

  if (fileClass === 'image') {
    return <img src={url} alt={file.filename} />;
  }
  if (fileClass === 'video') {
    return (
      <video controls>
        <source src={url} type={file.contentType!} />
        <FormattedMessage id="video.notSupported" />
      </video>
    );
  }
  if (fileClass === 'audio') {
    return (
      <audio controls>
        <source src={url} type={file.contentType!} />
        <FormattedMessage id="audio.notSupported" />
      </audio>
    );
  }
  return (
    <AttachmentsList
      files={[{ ...file, key: file.id, downloadable: true }]}
      download={downloadFile}
    />
  );
}

function FailedMessage() {
  return (
    <div className={classNames(styles.Content, styles['Content--Failed'])}>
      <FormattedMessage id="chatMessage.item.failed" />
    </div>
  );
}

type Props = {
  body: string;
  message?: MessagingMessageFullFragmentFragment;
  files: Files;
};

export function TextMessageBody({ body, message, files }: Props) {
  const { searchMode, searchTerm } = useThreadHighlight();

  if (isEmpty(body) && isEmpty(files)) {
    return <FailedMessage />;
  }

  return (
    <div className={classNames('FlexCol', styles.Content)}>
      <Highlighter enabled={searchMode} searchTerm={searchTerm}>
        <Linkify>{body}</Linkify>
      </Highlighter>
      {files.map(file =>
        file.available ? (
          <InlineFile key={file.id} file={file} />
        ) : (
          <FailedInlineFile key={file.id} file={file} messageId={message?.id} />
        )
      )}
    </div>
  );
}
