import { isNumber, isString } from 'lodash-es';
import { useSelector } from 'react-redux';
import { Language } from 'rrule/dist/esm/src/nlp/i18n';

import { getLocaleData } from '../../../config/intl/intl';
import { getLocale } from '../../settings/settingsReducer';
import { arrayToRrule } from './rruleConversions';

type FormattedRRuleProps = {
  value: string[];
};

// https://github.com/jakubroztocil/rrule/issues/13#issuecomment-496997046
export function FormattedRRule({ value }: FormattedRRuleProps) {
  const locale = useSelector(getLocale);
  const { rruleLocale } = getLocaleData(locale);

  const rruleInstance = arrayToRrule(value);

  if (!rruleLocale) {
    return <>{rruleInstance.toText()}</>;
  }

  const { language, strings } = rruleLocale;
  return (
    <>
      {rruleInstance.toText(id => {
        if (isString(id) || isNumber(id)) {
          return strings[id] || id;
        }
        return id;
      }, language as Language /* We need to cast because `tokens` aren't there, but those are only needed for parsing */)}
    </>
  );
}
