import classNames from 'classnames';
import { pick } from 'lodash-es';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { Draggable, DropResult, Droppable } from 'react-beautiful-dnd';

import { useMemoizedVariable } from '../../../common/utils/hookUtils';
import OptionalTooltip from '../../../components/OptionalTooltip';
import styles from './DropTarget.module.less';
import { useDragAndDropStateContext } from './RichDragAndDropContextProvider';
import { DragAndDropItemType } from './dragAndDropConstants';

const DropTargetContext = createContext({
  acceptItemTypes: [] as DragAndDropItemType[],
  itemType: null as DragAndDropItemType | null,
  draggingOver: false,
});

export type DropTargetHandler = (
  res: Pick<DropResult, 'draggableId' | 'source' | 'combine' | 'destination'>,
  data: any
) => void;

type DropTargetContainerProps = {
  itemType: DragAndDropItemType;
  acceptItemTypes: DragAndDropItemType[];
  children: ReactNode;
  handleDrop: DropTargetHandler;
};

export function DropTargetContainer({
  itemType,
  acceptItemTypes,
  children,
  handleDrop,
}: DropTargetContainerProps) {
  const { registerEndHandler, unregisterEndHandler } =
    useDragAndDropStateContext();
  const memAcceptItemTypes = useMemoizedVariable(acceptItemTypes);
  const endHandler = useCallback(
    (res: DropResult, data: any) => {
      if (!memAcceptItemTypes.includes(res.source.droppableId as any)) {
        return;
      }
      handleDrop(
        pick(res, ['draggableId', 'source', 'destination', 'combine']),
        data
      );
    },
    [handleDrop, memAcceptItemTypes]
  );
  useEffect(() => {
    registerEndHandler(itemType, endHandler);
    return () => unregisterEndHandler(itemType);
  }, [endHandler, itemType, registerEndHandler, unregisterEndHandler]);

  return (
    <Droppable droppableId={itemType} isCombineEnabled>
      {(droppableProvided, droppableSnapshot) => (
        <DropTargetContext.Provider
          value={{
            itemType,
            acceptItemTypes,
            draggingOver: !!droppableSnapshot.draggingOverWith,
          }}
        >
          <div
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
          >
            {children}
            <div style={{ display: 'none' }}>
              {droppableProvided.placeholder}
            </div>
          </div>
        </DropTargetContext.Provider>
      )}
    </Droppable>
  );
}

type DropTargetProps = {
  id: string | number;
  index: number;
  disabled?: boolean;
  disabledTooltip?: ReactNode;
  children: ReactNode;
};

export function DropTarget({
  id,
  index,
  disabled = false,
  disabledTooltip,
  children,
}: DropTargetProps) {
  const { itemType, acceptItemTypes, draggingOver } =
    useContext(DropTargetContext);
  const { lastDragUpdate } = useDragAndDropStateContext();

  return (
    <Draggable draggableId={`${itemType}:${id}`} index={index} isDragDisabled>
      {(
        { innerRef, draggableProps: { style: _style, ...draggableProps } },
        draggableSnapshot
      ) => {
        // react-beautiful-dnd doesn't support drop-only mode and some area is still interpreted
        // as "move before", but we want that to behave as drop too -> therefore the custom mappping of this mode
        const isDroppingAbove =
          lastDragUpdate?.itemType === itemType &&
          lastDragUpdate?.destination?.index === index;

        const isDroppingInto = !!draggableSnapshot.combineTargetFor;

        const isDropping = isDroppingAbove || isDroppingInto;

        const sourceDropItemType =
          draggableSnapshot.combineTargetFor?.split(':')[0] ||
          lastDragUpdate?.draggableId?.split(':')[0];

        const canDrop =
          isDropping &&
          !disabled &&
          acceptItemTypes.includes(sourceDropItemType as DragAndDropItemType);

        return (
          <OptionalTooltip
            title={disabledTooltip}
            enabled={disabled}
            visible={isDropping}
            placement="right"
            color="red"
          >
            <div
              ref={innerRef}
              {...draggableProps}
              className={classNames(canDrop && styles['Item--DropPossible'])}
            >
              {draggingOver ? (
                <div style={{ pointerEvents: 'none' }}>{children}</div>
              ) : (
                children
              )}
            </div>
          </OptionalTooltip>
        );
      }}
    </Draggable>
  );
}
