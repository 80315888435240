import { Button, ButtonProps } from 'antd';
import { FormattedMessage } from 'react-intl';

export function CircleButton(props: ButtonProps) {
  const { style, ...other } = props;
  return (
    <Button shape="circle" style={{ border: 'none', ...style }} {...other} />
  );
}

export type PrimaryButtonProps = ButtonProps & { labelId?: string };
export function PrimaryButton(props: PrimaryButtonProps) {
  const { labelId, ...other } = props;
  return (
    <Button
      type="primary"
      size="large"
      children={
        labelId && (
          <span>
            <FormattedMessage id={labelId} />
          </span>
        )
      }
      {...other}
    />
  );
}
