import { Checkbox } from 'antd';
import classNames from 'classnames';

import styles from './ColoredCheckbox.module.less';

export default function ColoredCheckbox(props) {
  const style = {
    ...props.style,
  };
  if (props.color) {
    style['--custom-checkbox-color'] = props.color;
  }
  return (
    <Checkbox
      {...props}
      className={classNames(styles.Container, props.className)}
      style={style}
    />
  );
}
