import { pick } from 'lodash-es';

import { config } from '../../config/config';

export function getDebugEnvInfo() {
  return {
    Date: new Date().toISOString(),
    Browser: navigator.userAgent,
    URL: window.location.href,
    Config: JSON.stringify(pick(config, ['version', 'serverName'])),
  };
}
