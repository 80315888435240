import { Modal, ModalProps } from 'antd';
import { Form, FormikContextType, FormikProvider } from 'formik';
import { isEmpty } from 'lodash-es';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { TranslationId } from '../types/appTypes';

export type SimpleFormModalProps<VALUES> = Omit<
  ModalProps,
  'title' | 'onOk' | 'onCancel' | 'confirmLoading'
> & {
  titleId: TranslationId;
  formik: FormikContextType<VALUES>;
  onClose: () => void;
  children: ReactNode;
};

export function SimpleFormModal<VALUES>({
  titleId,
  formik,
  visible,
  onClose,
  children,
  ...other
}: SimpleFormModalProps<VALUES>) {
  return (
    <Modal
      title={<FormattedMessage id={titleId} />}
      visible={visible}
      onOk={async () => {
        const validationResult = await formik.validateForm();
        await formik.submitForm();
        if (!isEmpty(validationResult)) {
          return;
        }
        onClose();
        formik.resetForm();
      }}
      onCancel={() => {
        onClose();
        formik.resetForm();
      }}
      destroyOnClose
      confirmLoading={formik.isSubmitting}
      {...other}
    >
      <FormikProvider value={formik}>
        <Form>{children}</Form>
      </FormikProvider>
    </Modal>
  );
}
