import { toNumber } from 'lodash-es';
import { Params, generatePath, useNavigate, useParams } from 'react-router';

import RouteDefinitions from '../../app/routes';
import {
  MessagingFolderType,
  MessagingThreadsFilterInput,
} from '../../types/graphqlGenerated';
import { UseParams } from '../../types/libHelperTypes';
import { useMessagesActions } from './messagesReducer';

function isFilterView(
  params: Params
): params is Required<UseParams<RouteDefinitions.messagesFilterView>> {
  return !!(params as UseParams<RouteDefinitions.messagesFilterView>).filterId;
}
function isFolderView(
  params: Params
): params is Required<UseParams<RouteDefinitions.messagesFolderView>> {
  return !!(params as UseParams<RouteDefinitions.messagesFolderView>)
    .folderTypeOrId;
}

type ViewMode =
  | { type: 'customFilter' }
  | { type: 'namedFilter'; filterId: number }
  | {
      type: 'folder';
      folderTypeOrId: string;
      folderId?: number;
      folderType?: MessagingFolderType;
    };

function useGetViewMode(): ViewMode | undefined {
  const params = useParams();
  if (isFilterView(params)) {
    const { filterId } = params;
    const filterIdNum = toNumber(filterId);
    if (isNaN(filterIdNum)) {
      return { type: 'customFilter' };
    }
    return { type: 'namedFilter', filterId: filterIdNum };
  }

  if (isFolderView(params)) {
    const { folderTypeOrId } = params;
    const folderId = toNumber(folderTypeOrId);
    if (!isNaN(folderId)) {
      return { type: 'folder', folderTypeOrId, folderId };
    }
    return {
      type: 'folder',
      folderTypeOrId,
      folderType: folderTypeOrId as MessagingFolderType,
    };
  }
}

export function useMessagesAppViewMode() {
  const viewMode = useGetViewMode();
  const navigate = useNavigate();
  const { setThreadsFilter } = useMessagesActions();

  const setCustomFilterMode = (
    filter: MessagingThreadsFilterInput,
    forceRedirect = true
  ) => {
    setThreadsFilter(filter);
    if (forceRedirect || viewMode?.type !== 'customFilter') {
      navigate(
        generatePath(RouteDefinitions.messagesFilterView, {
          filterId: 'custom',
        })
      );
    }
  };

  const setNamedFilterMode = (filterId: number) =>
    navigate(
      generatePath(RouteDefinitions.messagesFilterView, {
        filterId: `${filterId}`,
      })
    );

  const setFolderMode = (folderTypeOrId: number | string) => {
    navigate(
      generatePath(RouteDefinitions.messagesFolderView, {
        folderTypeOrId: `${folderTypeOrId}`,
      })
    );
  };

  return { viewMode, setCustomFilterMode, setFolderMode, setNamedFilterMode };
}
