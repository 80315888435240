import { Typography } from 'antd';

import NonLoggedPageTemplate from '../modules/messagesApp/layout/NonLoggedPageTemplate';
const { Title, Text, Paragraph } = Typography;

const RED_TEXT = { color: 'red' };
export default function TermsAndConditionsPage() {
  return (
    <NonLoggedPageTemplate contentWidth="75vw">
      <Typography>
        <Title level={5}>TERMS</Title>
        <Paragraph>Bedingungen und Konditionen</Paragraph>

        <Title level={5}>WICHTIG. SORGFÄLTIG LESEN.</Title>
        <Paragraph>
          Diese Bedingungen und Konditionen für Softwarelizenzen und deren
          Dienstleistungen („Nutzungsbedingungen“) sind eine rechtliche
          Vereinbarung zwischen Data 01 – Manfred Walcher. das im eigenen Namen
          und/oder im Namen seiner verbundenen Unternehmen und/oder seiner
          Drittanbieter und/oder Lizenzgeber und/oder Dienstleister handelt (im
          Folgenden als „Unternehmen“ oder „Unser“ oder „Uns“ bezeichnet) und
          Händlern (sei es eine Einzelperson oder eine rechtlich anerkannte
          Einheit), die an einem rechtmäßigen Geschäft zur Erbringung von
          Dienstleistungen oder Waren. („Kunde“, „Sie“ oder „Ihr“).
        </Paragraph>
        <Paragraph>
          Das Unternehmen bietet unter anderem eine Suite von
          Messaging-Software-as-a-Service-Lösungen über eine einzige Plattform
          an, um seinen Kunden die Kommunikation mit ihren Nutzern unter dem
          Namen „Vifzack“ („Lösung“) zu ermöglichen.
        </Paragraph>
        <Paragraph>
          Das Unternehmen kann nach eigenem Ermessen von Zeit zu Zeit Personen
          ermächtigen, die alle oder einen Teil seiner Rechte, Pflichten und
          Verpflichtungen gemäß diesen Nutzungsbedingungen ausführen, und jede
          Bezugnahme auf „DATA 01“ in diesen Nutzungsbedingungen wird so
          verstanden, dass sie solche Personen umfasst, die ordnungsgemäß
          ermächtigt sind und im Namen des Unternehmens handeln.
        </Paragraph>
        <Paragraph>
          Diese Nutzungsbedingungen sind ein elektronisches Dokument im Sinne
          des Information Technology Act, 2000, der entsprechenden Regeln und
          der Bestimmungen über elektronische Dokumente in verschiedenen
          Gesetzen, die durch den Information Technology Act, 2000 geändert
          wurden. Diese Nutzungsbedingungen werden von einem Computersystem
          erstellt und erfordern keine physische oder digitale Unterschrift.
        </Paragraph>
        <Paragraph>
          Diese Nutzungsbedingungen werden gemäß den Bestimmungen von Regel 3
          (1) der Information Technology (Intermediaries Guidelines) Rules, 2011
          veröffentlicht, die die Veröffentlichung von Regeln und Vorschriften,
          Datenschutzrichtlinien und Nutzungsbedingungen für den Zugriff auf
          oder die Nutzung von elektronischen Geräten und Dienstleistungen
          verlangen.
        </Paragraph>

        <Paragraph>
          DURCH DIE NUTZUNG DER LÖSUNG AKZEPTIERT DER KUNDE DIE BEDINGUNGEN
          DIESER NUTZUNGSBEDINGUNGEN UND JEDE ERGÄNZUNG ODER ANHANG ALS TEIL
          DIESER NUTZUNGSBEDINGUNGEN. WENN DER KUNDE DIESE BEDINGUNGEN NICHT
          AKZEPTIERT, SOLLTE DER KUNDE DIE LÖSUNG NICHT NUTZEN. DAS UNTERNEHMEN
          KANN DIESE NUTZUNGSBEDINGUNGEN VON ZEIT ZU ZEIT ÄNDERN ODER
          AKTUALISIEREN UND DIESE WERDEN UNTER
          <Text style={RED_TEXT}>
            [https://www.interakt.shop/terms-of-service]
          </Text>{' '}
          FÜR DEN ZUGRIFF DURCH DEN KUNDEN BEREITGESTELLT.
        </Paragraph>

        <Title level={5}>DEFINITIONEN:</Title>
        <Paragraph>
          In diesen Nutzungsbedingungen haben (i) großgeschriebene Begriffe, die
          durch Anführungszeichen und/oder Klammern definiert sind, die ihnen so
          zugewiesene Bedeutung; und (ii) die nachstehend aufgeführten
          großgeschriebenen Begriffe die dort angegebenen Bedeutungen. In diesen
          Nutzungsbedingungen dienen die Überschriften nur der Übersichtlichkeit
          und definieren oder beschränken in keiner Weise den Umfang.
        </Paragraph>
        <Paragraph>
          „Anwendbares Recht“ bedeutet und umfasst alle geltenden Gesetze,
          Erlasse, Handlungen der Legislative oder des Parlaments, Gesetze,
          Verordnungen, Regeln, Satzungen, Vorschriften, Richtlinien,
          Anweisungen und Befehle einer Regierungsbehörde oder
          Selbstregulierungsbehörde, einer gesetzlichen Behörde, eines Gerichts.
        </Paragraph>
        <Paragraph>
          „Anwendbares Datenschutzgesetz“ bezieht sich auf alle relevanten
          Gesetze, Vorschriften und Richtlinien, die von der in Bezug auf
          Aspekte wie Datenschutz, Datenverarbeitung, Datenschutz,
          Datensicherheit, Verschlüsselung oder Vertraulichkeit erlassen wurden.
          Dazu gehört unter anderem das indische Information Technology Act,
          2000. Die Parteien erkennen an und stimmen zu, dass das anwendbare
          Datenschutzgesetz bei Einführung und Durchsetzung des Digital Personal
          Data Protection Act (DPDPA) von 2023 aktualisiert wird, um das
          IT-Gesetz von 2000 durch das DPDPA, 2023 zu ersetzen, und rückwirkend
          gelten wird.
        </Paragraph>
        <Paragraph>
          „Beta-Produkte“ bedeutet eine vorveröffentlichte Version der Lösung
          oder ihrer Komponenten, die Ihnen zur Erprobung unter realistischen
          Bedingungen zur Verfügung gestellt wird, die nicht vorab simuliert
          wurden.
        </Paragraph>
        <Paragraph>
          „Geschäftstag“ bezeichnet jeden Tag außer Samstag, Sonntag oder
          Feiertagen, an dem die Banken geöffnet sind.
        </Paragraph>
        <Paragraph>
          „Geistiges Eigentum“ bedeutet und umfasst alle geistigen
          Eigentumsrechte, in jeder Region der Welt, ob registriert oder nicht
          registriert, und insbesondere (i) alle Marken, Dienstleistungsmarken,
          Handelsnamen, Logos, Domainnamen; Patente, Designrechte;
          Geschäftsgeheimnisse, einschließlich Know-how, Technologie, Formeln,
          industrielle und kommerzielle Informationen, Techniken und
          Erfindungen; Prozesse, Handbücher, Dokumentationen und technische
          Daten und Informationen; Urheberrechte, Werke des Urheberrechts und
          Topographierechte, Datenbankrechte; Computerhardware und -software
          einschließlich Quellcode, Computerprogramme, Benutzeroberflächen,
          Softwareanwendungen, Softwareplattformen oder -infrastrukturen und
          alle anderen Informationen in Bezug auf das Vorstehende; (ii) alle
          Rechte aus Lizenzen in Bezug auf alle oben genannten Rechte; (iii)
          alle Anträge oder Registrierungen zum Schutz aller unter Ziffer (i)
          und (ii) oben angegebenen Rechte; und (iv) alle Erneuerungen und
          Erweiterungen davon; und der Begriff „Rechte an geistigem Eigentum“
          wird entsprechend ausgelegt.
        </Paragraph>
        <Paragraph>
          „Personenbezogene Daten“ bedeutet alle Informationen, die sich auf
          eine identifizierte oder identifizierbare natürliche Person beziehen
          oder anderweitig als persönlich identifizierbare Informationen,
          persönliche Informationen oder personenbezogene Daten gemäß dem
          anwendbaren Datenschutzgesetz angesehen werden.
        </Paragraph>
        <Paragraph>„SaaS“ bedeutet Software als Dienstleistung.</Paragraph>
        <Paragraph>
          „Beginn des Abonnements“ bezeichnet das Datum, an dem der Kunde diese
          Nutzungsbedingungen akzeptiert und bestätigt.
        </Paragraph>
        <Paragraph>
          „Geplante Wartung“ bezeichnet die geplante routinemäßige Wartung der
          Lösung durch das Unternehmen, für die der Kunde mindestens zwei (2)
          Stunden im Voraus benachrichtigt wird und die acht (8) Stunden pro
          Woche nicht überschreitet.
        </Paragraph>
        <Paragraph>
          „Drittanbieterdienste“ bedeutet die vom Unternehmen im Rahmen der
          Lösung bereitgestellten Drittanbieterdienste, einschließlich, aber
          nicht beschränkt auf WhatsApp.
        </Paragraph>
        <Paragraph>
          „Update“ bedeutet die Modifikationen oder Überarbeitungen der Lösung:
          (i) zur Verbesserung oder Reparatur bestehender Funktionen und
          Vorgänge innerhalb der Lösung; (ii) zur Gewährleistung der
          Kompatibilität mit neuen Versionen bestehender Systeme (einschließlich
          Hardware, Betriebssysteme und Middleware) und externer Dienste über
          standardisierte Schnittstellen; (iii) zur Einhaltung anwendbarer
          Gesetze, Vorschriften, Industriestandards oder Marktpraktiken, außer
          bei einem Upgrade.
        </Paragraph>
        <Paragraph>
          „Upgrades“ bedeutet neue Versionen der Lösung, die dazu dienen, die
          Funktionalität der Lösung zu verbessern und die Versionsnummer der
          Lösung ändern können.
        </Paragraph>

        <Title level={5}>LAUFZEIT:</Title>
        <Paragraph>
          Diese Nutzungsbedingungen sind bis zur Beendigung gemäß den hierin
          festgelegten Bedingungen („Laufzeit“) wirksam und werden automatisch
          über die Laufzeit hinaus verlängert („automatische Verlängerung“),
          unter denselben Bedingungen und Konditionen wie hierin festgelegt, es
          sei denn, der Kunde widerruft ausdrücklich schriftlich.
        </Paragraph>

        <Title level={5}>LIZENZGEWÄHRUNG:</Title>
        <Paragraph>
          Während der Laufzeit, in Anbetracht der Zahlung der Gebühren durch den
          Kunden an das Unternehmen und vorbehaltlich der hierin enthaltenen
          Bedingungen und Konditionen, gewährt das Unternehmen dem Kunden eine
          beschränkte, nicht-exklusive, persönliche, widerrufliche,
          nicht-übertragbare und nicht-lizenzierbare Lizenz zur Nutzung der
          Lösung auf Abonnementbasis, gemäß diesen Nutzungsbedingungen. Nichts
          hierin soll so ausgelegt werden, dass eine Vereinbarung zur
          Übertragung von Titel, Eigentum oder Interesse, einschließlich der
          Rechte an geistigem Eigentum an oder von der Lösung, zugunsten des
          Kunden geschaffen wird.
        </Paragraph>
        <Paragraph>
          Die Lösung wird vom Unternehmen dem Kunden als Dienstleistung auf
          einem SaaS-Modell zur Verfügung gestellt, wobei das Unternehmen dem
          Kunden den Zugriff auf die Lösung durch Hosting der Lösung auf einem
          zentral gehosteten System ermöglicht, auf das der Kunde den
          notwendigen Zugang erhält. Das Unternehmen behält sich alle anderen
          nicht ausdrücklich gewährten Rechte und Interessen vor. Das Abonnement
          des Kunden für die Lösung beginnt am Beginn des Abonnements,
          unabhängig von der Testphase. Der Kunde erkennt an und stimmt zu, dass
          eine Verzögerung bei der Implementierung der Lösung, die dem Kunden
          zuzurechnen ist, beispielhaft aufgrund der Nichtbereitstellung oder
          Verzögerung bei der Bereitstellung von Material, Informationen oder
          Dokumenten, die vom Unternehmen zur Implementierung der Lösung
          angefordert wurden, zu keiner Änderung des Beginns des Abonnements
          führt und die Gebühren gemäß den in diesen Nutzungsbedingungen
          festgelegten Bedingungen zu zahlen sind.
        </Paragraph>
        <Paragraph>
          Ungeachtet des Vorstehenden erkennt der Kunde an und stimmt zu, dass
          die Nutzung der Lösung zusätzlichen Bedingungen und der
          Datenschutzrichtlinie unterliegt, wie dies hier angegeben ist und von
          Zeit zu Zeit aktualisiert wird. Zur Klarstellung, diese
          Nutzungsbedingungen ergänzen und ersetzen keine anderen anwendbaren
          Bedingungen und Datenschutzrichtlinien der Lösung. Solche Bedingungen
          und Datenschutzrichtlinien sind Bestandteil dieser
          Nutzungsbedingungen.
        </Paragraph>

        <Title level={5}>LIZENZ UND NUTZUNG DER LÖSUNG</Title>
        <Paragraph>
          Um die Lösung in Anspruch zu nehmen, stimmt der Kunde ausdrücklich zu,
          alle vom Unternehmen geforderten Informationen, einschließlich aller
          anwendbaren Dokumente, die die Identität, Adresse und Zahlungsdetails
          des Kunden betreffen, zur Verfügung zu stellen.
        </Paragraph>
        <Paragraph>
          Der Kunde erkennt an und stimmt zu, dass die Lösung während der
          Laufzeit nicht-exklusiv ist und das Unternehmen jederzeit berechtigt
          ist, die Lösung in beliebiger Weise zu nutzen, einschließlich der
          Bereitstellung von Dienstleistungen unter Verwendung der Lösung durch
          sich selbst oder durch eine Variation, Update oder Upgrades durch
          Dritte, von Zeit zu Zeit. Zu jedem Zeitpunkt umfasst die Lösung alle
          Modifikationen, Updates, zukünftige oder neue Upgrades, Ergänzungen
          nach alleinigem Ermessen des Unternehmens. Es wird klargestellt, dass
          die fortgesetzte Nutzung der Lösung durch den Kunden nach solchen
          Updates und Upgrades als stillschweigende Annahme solcher Updates und
          Upgrades gilt.
        </Paragraph>
        <Paragraph>
          Die unter diesen Nutzungsbedingungen gewährte Lizenz ist nur zur
          beschränkten Nutzung der Lösung durch den Kunden für seine
          Geschäftstätigkeit und umfasst nicht, ohne Einschränkung, das Recht
          zu:
        </Paragraph>
        <Paragraph>
          (a) lizenzieren, unterlizenzieren, verkaufen, weiterverkaufen,
          übertragen, abtreten, verteilen oder anderweitig kommerziell zu
          verwerten oder Dritten zur Verfügung zu stellen die Lösung oder den
          Inhalt in irgendeiner Weise;
        </Paragraph>
        <Paragraph>
          (b) technische oder andere Sicherheitsfunktionen der Lösung zu umgehen
          oder zu deaktivieren;
        </Paragraph>
        <Paragraph>
          (c) die Lösung oder den Inhalt zu modifizieren, zu reproduzieren oder
          abgeleitete Werke zu erstellen;
        </Paragraph>
        <Paragraph>
          (d) Internet-„Links“ zur Lösung zu erstellen oder Inhalte auf anderen
          Servern oder drahtlosen oder internetbasierten Geräten zu „rahmen“
          oder zu „spiegeln“;
        </Paragraph>
        <Paragraph>
          (e) die Lösung für irgendeinen Zweck rückzuentwickeln oder darauf
          zuzugreifen, einschließlich, ohne Einschränkung, zum (i) Aufbau eines
          konkurrierenden Produkts oder Dienstes, (ii) Aufbau eines Produkts mit
          ähnlichen Ideen, Funktionen, Merkmalen oder Grafiken der Lösung; oder
          (iii) Kopieren von Ideen, Funktionen, Merkmalen oder Grafiken der
          Lösung; oder
        </Paragraph>
        <Paragraph>
          (f) die Lösung für andere Zwecke als die internen Geschäftstätigkeiten
          des Kunden zu nutzen.
        </Paragraph>
        <Paragraph>
          Die Bestimmungen dieser Klausel 5 (Lizenz und Nutzung der Lösung)
          stellen einen wesentlichen Bestandteil dieser Nutzungsbedingungen dar;
          ein Verstoß des Kunden gegen diese Bestimmungen führt zur sofortigen
          und wesentlichen Beendigung dieser Vereinbarung. Der Kunde stimmt zu,
          dass die Nutzung der Lösung oder eines Teils davon durch Dritte ohne
          ausdrückliche Genehmigung des Unternehmens nicht gestattet ist.
        </Paragraph>
        <Paragraph>
          WICHTIG: Der Kunde muss den Zugang zur registrierten SIM-Karte
          aufrechterhalten und ist allein dafür verantwortlich, diese im Notfall
          innerhalb von maximal 30 Minuten vorzulegen.
        </Paragraph>
        <Paragraph>
          Die Lösung umfasst unter anderem folgende Komponenten:
        </Paragraph>
        <Paragraph>
          (a) Kundendaten. Die Lösung basiert vollständig auf der proprietären
          Plattform des Unternehmens unter dem Namen [„Vifzack“] und integriert
          verschiedene Informationen und Eingaben in Bezug auf das Geschäft und
          die Benutzer-/Kundendaten des Kunden, die dem Unternehmen am Beginn
          des Abonnements oder jederzeit während der Nutzung der Lösung
          bereitgestellt werden.
        </Paragraph>
        <Paragraph>
          (b) Inbox-Dashboard: Der Kunde hat Zugang zu seinem „Inbox-Dashboard“,
          einer webbasierten Messaging-Anwendung, die Teil der Lösung ist, in
          der die vom Kunden gesendeten Nachrichten automatisch in ihrer
          Messaging-Anwendung wie WhatsApp/WhatsApp Business sichtbar sind.
        </Paragraph>
        <Paragraph>
          (c) Support und Unterstützung: Das Unternehmen stellt dem Kunden im
          Falle von Notfällen, Ausfällen, Fehlern oder Mängeln in der Lösung,
          die die Nutzung der Lösung durch den Kunden beeinträchtigen können,
          sofortigen Support und Unterstützung zur Verfügung.
        </Paragraph>
        <Paragraph>
          (d) Früher Zugang zu Beta-Produkten: Das Unternehmen kann den Kunden
          im Falle von Tests von Beta-Produkten schriftlich ansprechen. Ein
          solcher früher Zugang zu Beta-Produkten wird dem Kunden auf einer „as
          is“- und „as available“-Basis und im Rahmen der anwendbaren Gesetze
          ohne jegliche Garantien, Zusicherungen, Entschädigungen oder
          vertraglichen Verpflichtungen zur Verfügung gestellt.
        </Paragraph>

        <Title level={5}>RECHTE UND PFLICHTEN DES KUNDEN</Title>
        <Paragraph>
          (i) Unbefugter Zugang des Kunden: Der Kunde muss alle angemessenen
          Maßnahmen ergreifen, um sicherzustellen, dass die Informationen, die
          zum oder vom Server des Unternehmens übertragen werden, sicher sind,
          und darf keine Drittperson zu einem unbefugten Zugang zum Server des
          Unternehmens autorisieren. Der Kunde kann einem seiner Mitarbeiter die
          Nutzung der Lösung autorisieren und muss sicherstellen, dass solche
          autorisierten Mitarbeiter angemessene Sicherheitsmaßnahmen befolgen,
          die der Kunde selbst angewendet hätte, und im Falle eines Verstoßes
          durch solche autorisierten Mitarbeiter wird dies als Verstoß des
          Kunden gegen diese Nutzungsbedingungen angesehen.
        </Paragraph>
        <Paragraph>
          (ii) Einhaltung der Gesetze durch den Kunden: Der Kunde muss
          sicherstellen, dass er alle notwendigen Genehmigungen, Lizenzen von
          den zuständigen Behörden innerhalb oder außerhalb des Territoriums für
          die Nutzung der Lösung vom Unternehmen eingeholt hat. Der Kunde haftet
          für jeden Verstoß, der von ihm oder seinen Mitarbeitern, Beratern und
          anderen autorisierten Personen begangen wird, und ist allein
          verantwortlich für alle Ansprüche oder Verbindlichkeiten, die sich aus
          einem solchen Verstoß ergeben können.
        </Paragraph>
        <Paragraph>
          (iii) Zusammenarbeit des Kunden: Der Kunde muss mit dem Unternehmen
          für jede Integration zusammenarbeiten, die gemäß diesen
          Nutzungsbedingungen in sein System erforderlich ist, und dem
          Unternehmen uneingeschränkten Zugang zu seinen Systemen und seiner
          Computerplattform gewähren, wann immer dies erforderlich ist.
        </Paragraph>
        <Paragraph>
          (iv) Zahlung des Kunden: Der Kunde muss alle fälligen Zahlungen gemäß
          diesen Nutzungsbedingungen leisten, wann immer das Unternehmen dies
          verlangt.
        </Paragraph>
        <Paragraph>
          (v) Testphase: Im Rahmen der Bemühungen des Unternehmens, die
          Kundenzufriedenheit sicherzustellen, kann der Kunde während der
          Nutzung der Lösung für eine Testphase von 7-14 Tagen („Testphase“) ab
          dem Beginn des Abonnements platziert werden und hat möglicherweise
          Anspruch auf eine begrenzte oder vollständige Nutzung der Lösung
          während dieser Testphase. Die in diesen Nutzungsbedingungen
          festgelegten Rechte und Pflichten gelten auch für die Nutzung der
          Lösung während der Testphase. Der Kunde hat das Recht, diese
          Nutzungsbedingungen vor Ablauf der Testphase ohne Zahlung von
          Abonnementgebühren zu kündigen. Nach Ablauf der Testphase werden dem
          Kunden jedoch automatisch die Abonnementgebühren und/oder die
          Nutzungsgebühren in Rechnung gestellt.
        </Paragraph>

        <Title level={5}>LIZENZGEBÜHREN UND ZAHLUNGSBEDINGUNGEN</Title>
        <Paragraph>
          Als Gegenleistung für die Lizenzgewährung zahlt der Kunde dem
          Unternehmen die nachstehenden Zahlungen:
        </Paragraph>
        <Paragraph>
          (a) Abonnementgebühr: Die Abonnementlaufzeit der Lösung beginnt am
          Beginn des Abonnements und läuft bis zum Ende des Abonnements, es sei
          denn, die Parteien haben etwas anderes vereinbart. Am Beginn des
          Abonnements stimmt der Kunde zu, dem Unternehmen geeignete Daten zur
          Verarbeitung der Zahlung einer nicht übertragbaren, nicht
          erstattungsfähigen Gebühr für das Abonnement der Lösung zur Verfügung
          zu stellen, die am Ende der Testphase fällig ist. Der Kunde muss die
          Zahlung des vom Unternehmen für die Lizenz der Lösung angegebenen
          Betrags leisten („Abonnementgebühren“).
        </Paragraph>
        <Paragraph>
          (b) Nutzungsgebühr: Zusätzlich zu den oben genannten
          Abonnementgebühren muss der Kunde sein Wallet nach Bedarf aufladen, um
          Konversationsnachrichten von seiner verbundenen WhatsApp Business
          API-Nummer auf Vifzack gemäß den auf der Preisseite von Vifzack
          definierten Konversationsnachrichtengebühren zu senden und zu
          empfangen. Wallet-Aufladungen sind nicht übertragbar und nicht
          erstattungsfähig („Nutzungsgebühren“). (Die Abonnementgebühren und die
          Nutzungsgebühren werden im Folgenden zusammen als „Gebühren“
          bezeichnet).
        </Paragraph>
        <Paragraph>
          Alle in diesen Nutzungsbedingungen festgelegten Zahlungen verstehen
          sich zuzüglich aller staatlichen Abgaben und Steuern und alle
          anwendbaren Steuern und Abgaben, die auf die Gebühren zu zahlen sind,
          einschließlich jeder Waren- und Dienstleistungssteuer, trägt der Kunde
          jederzeit. Der Kunde trägt außerdem alle sonstigen anfallenden Steuern
          für die an das Unternehmen geleisteten Zahlungen gemäß diesen
          Nutzungsbedingungen selbst. Der Kunde muss alle Einreichungsformulare
          und Anforderungen in Bezug auf etwaige Quellensteuerabzüge einhalten,
          einschließlich der Einreichung der Quellensteuererklärung gemäß den
          geltenden Steuergesetzen und dem Unternehmen eine Bescheinigung über
          die einbehaltene oder im Namen des Unternehmens gezahlte Steuer in
          Form einer Bescheinigung gemäß den geltenden Steuergesetzen
          ausstellen. Der Kunde bemüht sich nach besten Kräften,
          sicherzustellen, dass das Unternehmen eine Gutschrift für alle
          einbehaltenen Steuern erhält und stellt dem Unternehmen alle
          erforderlichen Informationen zur Verfügung, die notwendig sind, um das
          Unternehmen in Bezug auf seine steuerlichen und gesetzlichen
          Verpflichtungen zu unterstützen.
        </Paragraph>

        <Paragraph>Allgemeine Zahlungsbedingungen:</Paragraph>
        <Paragraph>
          Der Kunde stimmt zu, dass alle Zahlungen, die vom Kunden an das
          Unternehmen gemäß diesen Nutzungsbedingungen zu leisten sind, den
          folgenden Bedingungen unterliegen:
        </Paragraph>
        <Paragraph>
          (a) Die Verpflichtung des Kunden zur Zahlung der in diesen
          Nutzungsbedingungen festgelegten Beträge ist absolut und wird nicht
          durch das Unternehmen aufgehoben, indem keine Rechnung gestellt wird.
        </Paragraph>
        <Paragraph>
          (b) Der Kunde muss die Abonnementgebühr sofort oder am Ende der
          Testphase zahlen, falls zutreffend; und (ii) die Nutzungsgebühr bis
          zum 5. (fünften) Tag jedes Monats für die im Vormonat erbrachten
          Leistungen.
        </Paragraph>
        <Paragraph>
          (c) Das Unternehmen hat das Recht, den Zugang des Kunden zur Lösung zu
          sperren, wenn die Nutzungsgebühr nicht innerhalb des vom Unternehmen
          angegebenen Zeitraums bezahlt wird.
        </Paragraph>
        <Paragraph>
          (d) Im Falle eines Zahlungsrückstands des Kunden bei einer Zahlung
          (ganz oder teilweise) unterliegt der ausstehende Betrag ab dem
          Fälligkeitsdatum bis zum Rückzahlungstag einem Zinssatz von 2% (zwei
          Prozent) pro Monat (d. h. 24% (vierundzwanzig Prozent) jährlich) oder
          dem gesetzlich zulässigen Höchstbetrag, je nachdem, welcher Wert
          niedriger ist.
        </Paragraph>

        <Title level={5}>VERTRETUNGEN UND GEWÄHRLEISTUNGEN</Title>
        <Paragraph>
          Jede Partei versichert und garantiert hiermit der anderen Partei,
          dass:
        </Paragraph>
        <Paragraph>
          sie ordnungsgemäß nach den anwendbaren Gesetzen gegründet und gültig
          existiert; sie alle Maßnahmen, einschließlich etwaiger
          Unternehmensmaßnahmen, ergriffen hat, die erforderlich sind, um diese
          Nutzungsbedingungen auszuführen, zu liefern und die Verpflichtungen
          aus diesen Nutzungsbedingungen zu erfüllen; sie alle erforderlichen
          behördlichen Genehmigungen/Nachweise/Unbedenklichkeitsbescheinigungen
          gemäß den anwendbaren Gesetzen für die Durchführung ihrer Tätigkeiten
          und zugehörigen Dienstleistungen erhalten hat; keine gerichtlichen
          oder administrativen Verfahren, Prozesse oder Ermittlungen anhängig
          sind oder ihr nach ordnungsgemäßer Prüfung und bestem Wissen drohen,
          die ihre Fähigkeit, die Verpflichtungen aus diesen Nutzungsbedingungen
          zu erfüllen, erheblich beeinträchtigen würden; sie keinen
          Verpflichtungen aus einem Vertrag unterliegt, aufgrund derer sie gegen
          die Verpflichtungen und Zusagen aus diesen Nutzungsbedingungen
          verstoßen könnte.
        </Paragraph>
        <Paragraph>
          Zusätzlich zu den allgemeinen Zusicherungen wie oben dargelegt,
          versichert und garantiert der Kunde ferner Folgendes:
        </Paragraph>
        <Paragraph>
          er besitzt die finanzielle Fähigkeit zur Zahlung der Gebühren in Bezug
          auf die Lösung; er hat alle anwendbaren und notwendigen
          Drittgenehmigungen/Genehmigungen, behördlichen Genehmigungen,
          Registrierungen, Autorisierungen, Lizenzen, Genehmigungen und alle
          sonstigen erforderlichen Erlaubnisse gemäß den anwendbaren Gesetzen
          für die Nutzung der Lösung erhalten und hält diese während der
          Laufzeit aufrecht; und er ist am Beginn des Abonnements und bleibt
          während der gesamten Laufzeit in vollständiger Übereinstimmung mit
          allen anwendbaren Gesetzen, einschließlich der anwendbaren
          Datenschutzgesetze.
        </Paragraph>

        <Title level={5}>
          RECHT ZUR SPERRUNG DES ZUGANGS DES KUNDEN ZUR LÖSUNG
        </Title>
        <Paragraph>
          (i) Recht des Unternehmens zur Sperrung des Zugangs zur Lösung: Das
          Unternehmen kann das Recht des Kunden auf Zugang oder Nutzung eines
          Teils oder der gesamten Lösung ohne Vorankündigung sofort aussetzen,
          wenn es feststellt, dass: Die Nutzung der Lösung durch den Kunden: (i)
          ein Sicherheitsrisiko für das Unternehmen, die Lösung oder Dritte
          darstellt oder; (ii) die anderen Dienste und Produkte des Unternehmens
          einschließlich der Lösung oder die Systeme oder Inhalte anderer Kunden
          des Unternehmens beeinträchtigen könnte; (iii) das Unternehmen oder
          seine verbundenen Unternehmen oder Dritte haftbar machen könnte; oder
          (iv) betrügerisch sein könnte; Der Kunde gegen diese
          Nutzungsbedingungen verstößt, einschließlich, wenn der Kunde mit
          seinen Zahlungsverpflichtungen gemäß Klausel 7 (Lizenzgebühren und
          Zahlungsbedingungen) in Verzug ist; oder Der Kunde die
          Geschäftstätigkeit im normalen Geschäftsablauf einstellt, eine
          Abtretung zugunsten von Gläubigern oder eine ähnliche Verfügung über
          Vermögenswerte vornimmt oder Gegenstand eines Insolvenz-,
          Reorganisations-, Liquidations-, Auflösungs- oder ähnlichen Verfahrens
          wird.
        </Paragraph>
        <Paragraph>
          (ii) Auswirkungen der Sperrung: Wenn das Unternehmen das Recht des
          Kunden auf Zugang oder Nutzung eines Teils oder der gesamten Lösung
          gemäß Klausel 9(i) oben aussetzt;
        </Paragraph>
        <Paragraph>
          Der Kunde bleibt verantwortlich für alle bis zum Datum der Sperrung
          angefallenen Gebühren; Der Kunde bleibt verantwortlich für alle
          anfallenden Gebühren für jeden Teil der Lösung, auf den der Kunde
          weiterhin Zugriff hat, sowie alle anfallenden Datenbankgebühren und
          Gebühren für laufende Aufgaben, die bis zum Datum der Sperrung
          abgeschlossen und angefallen sind; Das Unternehmen löscht keine
          Inhalte oder Daten des Kunden, die vor der Sperrung hochgeladen
          wurden, außer wie in diesen Nutzungsbedingungen anders angegeben. Das
          Recht des Unternehmens, das Recht des Kunden auf Zugang oder Nutzung
          der Lösung auszusetzen, besteht zusätzlich zum Recht des Unternehmens,
          diese Nutzungsbedingungen gemäß Klausel 12 (Beendigung der
          Vereinbarung) hierin zu kündigen.
        </Paragraph>

        <Title level={5}>VERTRAULICHKEIT</Title>
        <Title level={5}>(i) Definition und Ausschlüsse:</Title>
        <Paragraph>
          (a) Für Zwecke dieser Nutzungsbedingungen umfasst „vertrauliche
          Informationen“ alle vertraulichen und proprietären Informationen oder
          Materialien (einschließlich Informationen, Daten und Materialien in
          Bezug auf aktuelle oder zukünftige Produkte und Prozesse), die einer
          Partei oder ihren verbundenen Unternehmen oder Unterauftragnehmern
          oder ihren oder ihren verbundenen Unternehmen oder
          Unterauftragnehmern, Mitarbeitern, Agenten und anderen Vertretern von
          oder im Namen der anderen Partei zur Verfügung gestellt werden (ob
          absichtlich oder nicht) und die sich auf die Geschäftstätigkeiten,
          Vorgänge, Angelegenheiten, Technologien, Pläne und Strategien dieser
          anderen Partei beziehen, unabhängig davon, ob sie mündlich,
          schriftlich, grafisch oder elektronisch oder in Form von Mustern
          bereitgestellt werden und unabhängig davon, ob sie als „vertraulich“,
          „geheim“ oder „proprietär“ gekennzeichnet, beschriftet oder
          anderweitig identifiziert sind (es wird anerkannt und zugestimmt, dass
          die Existenz und die Bedingungen dieser Nutzungsbedingungen als
          vertrauliche Informationen beider Parteien gelten).
        </Paragraph>
        <Paragraph>
          (b) Ungeachtet des Vorstehenden umfassen vertrauliche Informationen
          keine Informationen oder Gegenstände, die: (i) zum Zeitpunkt des
          Erhalts durch die empfangende Partei allgemein öffentlich zugänglich
          oder anderweitig Teil der öffentlichen Domain waren; (ii) allgemein
          öffentlich zugänglich werden oder anderweitig Teil der öffentlichen
          Domain werden, außer durch Verstoß gegen diese Nutzungsbedingungen;
          (iii) von der empfangenden Partei nachweislich bereits vor dem
          Zeitpunkt des Erhalts von der offenlegenden Partei im Besitz waren
          oder anderweitig bekannt waren; (iv) von der empfangenden Partei von
          einem Dritten erhalten wurden, der (1) rechtmäßiger Eigentümer davon
          ist und (2) keiner Vertraulichkeitsverpflichtung gegenüber der
          offenlegenden Partei unterliegt; oder (v) unabhängig von der
          empfangenden Partei ohne Nutzung der vertraulichen Informationen der
          offenlegenden Partei entwickelt wurden, die oder die kommerziellen
          Wert oder andere Nützlichkeit in den Geschäftstätigkeiten der
          offenlegenden Partei haben oder vertraulich behandelt werden.
        </Paragraph>

        <Title level={5}>(ii) Offenlegung vertraulicher Informationen</Title>
        <Paragraph>
          Jede Partei stimmt hiermit zu und erkennt an, dass sie:
        </Paragraph>
        <Paragraph>
          (a) den gleichen Sorgfaltsmaßstab anwendet, um die Offenlegung der
          vertraulichen Informationen der offenlegenden Partei gegenüber Dritten
          zu verhindern, wie sie ihn anwendet, um die Offenlegung,
          Veröffentlichung oder Verbreitung ihrer eigenen Informationen
          ähnlicher Art zu vermeiden, jedoch in keinem Fall weniger als einen
          angemessenen Sorgfaltsmaßstab;
        </Paragraph>
        <Paragraph>
          (b) die vertraulichen Informationen der offenlegenden Partei
          ausschließlich zum Zweck der Erfüllung ihrer Verpflichtungen gemäß
          diesen Nutzungsbedingungen verwendet;
        </Paragraph>
        <Paragraph>
          (c) keine Rechte an vertraulichen Informationen der offenlegenden
          Partei erwirbt oder ein Pfandrecht darauf geltend macht;
        </Paragraph>
        <Paragraph>
          (d) die vertraulichen Informationen auf Anforderung der offenlegenden
          Partei unverzüglich zurückgibt oder eine Kopie davon bereitstellt;
        </Paragraph>
        <Paragraph>
          (e) ungeachtet des Vorstehenden darf die empfangende Partei
          vertrauliche Informationen der offenlegenden Partei ihren
          Mitarbeitern, Agenten und Unterauftragnehmern offenlegen, die: (i)
          solche vertraulichen Informationen kennen müssen, um ihre Aufgaben zu
          erfüllen; und (ii) eine rechtliche Verpflichtung haben, die
          vertraulichen Informationen zu schützen. Die empfangende Partei
          übernimmt die volle Verantwortung für das Handeln oder Unterlassen
          ihrer Unterauftragnehmer und Mitarbeiter in Bezug auf solche
          vertraulichen Informationen; und
        </Paragraph>
        <Paragraph>
          (f) die offenlegende Partei unverzüglich im Falle einer unerwünschten
          Offenlegung, eines Verlusts oder eines Verstoßes gegen vertrauliche
          Informationen benachrichtigt.
        </Paragraph>

        <Title level={5}>(iii) Erforderliche Offenlegung</Title>
        <Paragraph>
          Die empfangende Partei darf vertrauliche Informationen, die von der
          offenlegenden Partei erhalten wurden, im Umfang der anwendbaren
          Gesetze oder durch Anordnung eines Gerichts oder einer
          Regierungsbehörde offenlegen; vorausgesetzt, dass die empfangende
          Partei die offenlegende Partei unverzüglich benachrichtigt und ihre
          besten Anstrengungen unternimmt, mit der offenlegenden Partei
          zusammenzuarbeiten, wenn diese eine Schutzanordnung oder anderweitig
          den Schutz der Vertraulichkeit solcher vertraulichen Informationen
          wünscht, je nach Fall. Darüber hinaus kann die empfangende Partei die
          Bedingungen dieser Nutzungsbedingungen im erforderlichen Umfang
          offenlegen, um ihre Bedingungen oder Rechte durchzusetzen. Die
          Vertraulichkeitsverpflichtungen bleiben auch nach Beendigung dieser
          Nutzungsbedingungen bestehen.
        </Paragraph>

        <Title level={5}>(iv) Billigkeitsrechtsschutz</Title>
        <Paragraph>
          Jede Partei erkennt an, dass ein Verstoß gegen die
          Vertraulichkeitsverpflichtungen gemäß diesen Nutzungsbedingungen durch
          die empfangende Partei oder deren Mitarbeiter oder Unterauftragnehmer
          der offenlegenden Partei sofortigen und irreparablen Schaden zufügt,
          und im Falle eines solchen Verstoßes hat die offenlegende Partei
          Anspruch auf Unterlassungsschutz ohne Sicherheitsleistung und auf alle
          anderen gesetzlichen oder billigen Rechtsmittel.
        </Paragraph>

        <Title level={5}>(v) Rückgabe vertraulicher Informationen</Title>
        <Paragraph>
          Sofern nicht ausdrücklich in diesen Nutzungsbedingungen festgelegt,
          ist die empfangende Partei verpflichtet, auf Verlangen der
          offenlegenden Partei oder bei Beendigung und/oder Ablauf dieser
          Nutzungsbedingungen alle vertraulichen Informationen und alle Kopien
          davon unverzüglich zurückzugeben oder zu vernichten.
        </Paragraph>

        <Title level={5}>BEENDIGUNG:</Title>
        <Paragraph>
          Diese Nutzungsbedingungen können vom Kunden vor Ablauf der Testphase
          gekündigt werden. Der Kunde kann diese Nutzungsbedingungen nach Ablauf
          der Testphase kündigen, wenn er die Option der automatischen
          Verlängerung nicht ausübt und dies dem Unternehmen schriftlich
          mitteilt. Nach Erhalt dieser schriftlichen Mitteilung wird das
          Unternehmen den Zugang des Kunden zur Lösung beenden
          („Abonnement-Enddatum“).
        </Paragraph>
        <Paragraph>
          (ii) Auswirkungen der Beendigung: (a) Am Abonnement-Enddatum fallen
          alle hierin gewährten Rechte und Vorteile an die jeweiligen Parteien
          zurück und alle bis zum Abonnement-Enddatum vom Kunden geschuldeten
          Beträge bleiben zahlbar. (b) Am Abonnement-Enddatum endet das Recht
          des Kunden auf Zugang und/oder Nutzung der Lösung sofort und der Kunde
          hat kein weiteres oder zusätzliches Recht auf Zugang und Nutzung der
          Lösung. (c) Der Kunde ist verpflichtet, alle vertraulichen
          Informationen, Werbe- und andere Materialien oder Dokumentationen
          (falls vorhanden) sowie alle Handbücher und Anleitungsbücher in jeder
          Version oder jedem Medium, die vom Unternehmen dem Kunden gemäß diesen
          Nutzungsbedingungen zur Verfügung gestellt wurden, zu vernichten. Wenn
          der Kunde jedoch Kopien der vertraulichen Informationen nach dem
          Abonnement-Enddatum gemäß automatischen Archivierungs- und
          Sicherungsverfahren aufbewahrt, gelten die in Klausel 10
          (Vertraulichkeit) festgelegten Verpflichtungen weiterhin.
        </Paragraph>

        <Title level={5}>SERVICELEVELS:</Title>
        <Paragraph>
          Die Nutzung der Lösung gemäß diesen Nutzungsbedingungen wird anhand
          der in gutem Glauben von den Parteien vereinbarten Servicelevels und
          Standards gemessen („SLA“).
        </Paragraph>
        <Paragraph>
          Zielverfügbarkeit. Das Unternehmen wird sich bemühen, die Lösung mit
          einer Betriebszeit von 95% (fünfundneunzig Prozent) jedes
          Geschäftsjahres verfügbar zu machen („Zielverfügbarkeit“).
        </Paragraph>
        <Paragraph>
          Ausschlüsse. Die Berechnung der Betriebszeit umfasst keine
          Verfügbarkeitseinschränkungen aufgrund von: (a) Nutzung der Lösung
          durch den Kunden in einer nicht autorisierten Weise gemäß diesen
          Nutzungsbedingungen oder anderen anwendbaren Dokumenten; (b)
          allgemeinen Internetproblemen, höherer Gewalt oder anderen Faktoren,
          die außerhalb der angemessenen Kontrolle des Unternehmens liegen; (c)
          Geräte, Software, Netzwerkverbindungen oder andere Infrastrukturen des
          Kunden; (d) Handlungen oder Unterlassungen Dritter; oder (e) geplante
          Wartung oder angemessene Notfallwartung.
        </Paragraph>
        <Paragraph>
          Recht auf Abhilfe bei Nichterreichen der Zielverfügbarkeit. Wenn ein
          verifizierter Ausfall der Dienste die Zielverfügbarkeit für zwei (2)
          aufeinanderfolgende Monate nicht erreicht, hat der Kunde das Recht,
          diese Nutzungsbedingungen zu kündigen, indem er das Unternehmen nach
          dem Ende des zweiten Monats über die kontinuierliche
          Nichtverfügbarkeit informiert. In einem solchen Fall der
          Zielverfügbarkeit erstattet das Unternehmen dem Kunden alle Gebühren,
          die der Kunde für die Nutzung der Lösung gezahlt hat.
        </Paragraph>

        <Title level={5}>DRITTANWENDUNGEN</Title>
        <Paragraph>
          Drittprodukte, -software, -dienste und -anwendungen, einschließlich,
          aber nicht beschränkt auf Drittanbieterdienste, können in die Lösung
          aufgenommen oder im Rahmen der Nutzung der Lösung vom Kunden
          heruntergeladen werden. Ungeachtet gegenteiliger Bestimmungen in
          diesen Nutzungsbedingungen macht das Unternehmen keine Zusicherungen
          in Bezug auf solche Drittprodukte, -software, -dienste und
          -anwendungen. Da das Unternehmen keine Kontrolle über solche Produkte,
          Software, Dienste und Anwendungen hat, erkennt der Kunde an und stimmt
          zu, dass das Unternehmen nicht für die Verfügbarkeit solcher Produkte,
          Software, Dienste und Anwendungen verantwortlich ist und nicht haftbar
          gemacht werden kann für Inhalte, Werbung, Produkte, Dienstleistungen
          oder andere Materialien, die von solchen Drittparteien zur Verfügung
          gestellt werden oder verfügbar sind. Der Kunde erkennt ausdrücklich an
          und stimmt zu, dass die Nutzung solcher Drittprodukte, -software,
          -dienste und -anwendungen auf eigenes Risiko des Kunden erfolgt und
          der gesamte Risikos von unbefriedigender Qualität, Leistung,
          Genauigkeit und Aufwand beim Kunden liegt und der Kunde das
          Unternehmen von solchen Risiken freistellt und schadlos hält. Der
          Kunde erkennt an und stimmt zu, dass die Nutzung solcher
          Drittprodukte, -software, -dienste und -anwendungen den Bedingungen
          der jeweiligen Drittparteien-Nutzungsbedingungen,
          Lizenzvereinbarungen, Datenschutzrichtlinien oder anderen
          Vereinbarungen unterliegt und dass alle Informationen oder
          personenbezogenen Daten, die der Kunde solchen Drittparteien
          wissentlich oder unwissentlich zur Verfügung stellt, den
          Datenschutzrichtlinien dieser Drittparteien unterliegen, falls eine
          solche Richtlinie existiert und in diesen Nutzungsbedingungen durch
          Bezugnahme aufgenommen wird, soweit dies anwendbar ist.
        </Paragraph>

        <Title level={5}>DATENSCHUTZ & DATENSICHERHEIT</Title>
        <Paragraph>
          (i) Sofern in diesen Nutzungsbedingungen nicht ausdrücklich anders
          angegeben, ist der Kunde dafür verantwortlich sicherzustellen, dass
          relevante Einwilligungen gemäß den anwendbaren Datenschutzgesetzen von
          den Nutzern und/oder Einzelpersonen/Datenbetroffenen eingeholt wurden.
          Der Kunde stimmt hiermit zu und erkennt an, dass das Unternehmen keine
          Haftung gegenüber Nutzern übernimmt, die sich aus der Erhebung und
          Verarbeitung personenbezogener Daten durch den Kunden ergeben.
        </Paragraph>
        <Paragraph>
          (ii) Der Kunde ist dafür verantwortlich, Aufzeichnungen über alle
          gesammelten Einwilligungen gemäß den anwendbaren Datenschutzgesetzen
          zu führen. Der Kunde haftet für Schäden, die dem Unternehmen aufgrund
          ungenauer und/oder unzureichender Einwilligungen von Nutzern
          entstehen.
        </Paragraph>
        <Paragraph>
          (iii) Das Unternehmen wird personenbezogene Daten, die im Namen des
          Kunden gesammelt, vom Kunden oder seinen autorisierten Mitarbeitern
          erhalten oder anderweitig im Namen des Kunden verarbeitet werden,
          nicht für andere Zwecke als zur Nutzung der Lösung gemäß diesen
          Nutzungsbedingungen verwenden.
        </Paragraph>
        <Paragraph>
          (iv) Die Parteien müssen die Bestimmungen der anwendbaren
          Datenschutzgesetze einhalten und angemessene Sicherheitspraktiken
          durchführen, wie sie gemäß den anwendbaren Datenschutzgesetzen
          vorgeschrieben sind.
        </Paragraph>
        <Paragraph>
          (v) Cloud API: WhatsApp-Infrastruktur bedeutet WhatsApp Enterprise
          Client-Bereitstellung, die die Kommunikation zwischen Benutzern und
          der Interakt-Plattform unterstützt.
          <ul>
            <li>
              Der Kunde bestätigt und stimmt zu, dass bei Weitergabe der Daten
              an die WhatsApp-Infrastruktur alle vom Kunden im Zusammenhang mit
              der WhatsApp-Nachrichtenübermittlung bereitgestellten Daten jetzt
              auf Cloud-APIs („Datenaufbewahrung“), einem Meta-Produkt,
              gespeichert werden.
            </li>
            <li>
              Es wird ferner verstanden und akzeptiert, dass alle zuvor auf der
              WhatsApp-Infrastruktur weitergeleiteten und gespeicherten
              Kundendaten für den Zweck der
              Nachrichtenübermittlung/Benachrichtigung auf WhatsApp nun an die
              Datenzentren von Meta migriert werden.
            </li>
            <li>
              Für die Erbringung der Dienstleistungen gemäß dieser Vereinbarung
              erkennt der Kunde hiermit an, dass für die WhatsApp-Infrastruktur
              keine Datenlokalisierung vom Unternehmen angeboten wird für alle
              solchen Daten, die im Rahmen der Bereitstellung der
              WhatsApp-Nachrichtenübermittlung an den Kunden weitergegeben oder
              erhalten werden.
            </li>
          </ul>
        </Paragraph>

        <Title level={5}>HAFTUNGSAUSSCHLUSS</Title>
        <Paragraph>
          (i) Soweit dies nach den anwendbaren Gesetzen zulässig ist, lehnt das
          Unternehmen jegliche und alle Zusicherungen und Garantien ab, dass die
          gemäß diesen Nutzungsbedingungen bereitgestellte Lösung
          ununterbrochen, fehlerfrei und frei von Fehlern, Viren, Bots oder dass
          die Lösung rechtzeitig und/oder sicher und/oder kontinuierlich
          bereitgestellt wird.
        </Paragraph>
        <Paragraph>
          (ii) Das Unternehmen haftet nicht für Fehler, Auslassungen,
          Unterbrechungen, Löschung von Dateien oder E-Mails, Verlust oder
          Beschädigung von Daten, Fehler, Mängel, Viren, Verzögerungen im
          Betrieb oder in der Übertragung, Leistungsversagen,
          Kommunikationsausfälle, Datenmissbrauch aufgrund der Nutzung von Daten
          oder Informationen in Bezug auf die Lösung.
        </Paragraph>

        <Title level={5}>ENTSCHÄDIGUNG</Title>
        <Paragraph>
          (i) Entschädigung des Kunden: Der Kunde stimmt hiermit zu, das
          Unternehmen, seine verbundenen Unternehmen, Direktoren, Mitarbeiter,
          Agenten und Angestellten von und gegen jegliche und alle Verluste,
          Verbindlichkeiten, Ansprüche, Schäden, Forderungen, Klagen, Verfahren,
          Kosten und Ausgaben, die dem Unternehmen entstehen oder erleidet, in
          Verbindung mit oder aus: (a) Verstoß oder Verletzung oder
          Nichteinhaltung einer der Verpflichtungen, Garantien, Zusicherungen,
          Zusagen und Vereinbarungen hierin durch den Kunden oder einen seiner
          Vertreter; (b) Verletzung der geistigen Eigentumsrechte Dritter für
          die Nutzung der Lösung vom Unternehmen.
        </Paragraph>
        <Paragraph>
          (ii) Entschädigung des Unternehmens: Das Unternehmen stimmt zu, den
          Kunden von und gegen jegliche und alle Verluste, Verbindlichkeiten,
          Ansprüche, Schäden, Forderungen, Klagen, Verfahren, Kosten und
          Ausgaben in Verbindung mit oder aus jeglichen Ansprüchen Dritter für
          die Bereitstellung der Lösung an den Kunden zu entschädigen.
        </Paragraph>

        <Title level={5}>HAFTUNGSBESCHRÄNKUNG</Title>
        <Paragraph>
          (i) Keine Partei haftet gegenüber der anderen Partei für indirekte,
          spezielle, zufällige, strafbare oder Folgeschäden jeglicher Art oder
          für Verluste, die durch die Anwendung angemessener Sorgfalt hätten
          vermieden werden können, die im Zusammenhang mit diesen
          Nutzungsbedingungen entstehen, auch wenn die verantwortliche Partei
          für die Schäden auf die Möglichkeit solcher Schäden hingewiesen wurde
          oder hätte wissen müssen.
        </Paragraph>
        <Paragraph>
          (ii) In keinem Fall übersteigt die maximale Gesamthaftung des
          Unternehmens unter diesen Nutzungsbedingungen gegenüber dem Kunden,
          unabhängig davon, ob sie aus Vertrag, Entschädigung, Delikt oder
          anderweitig entsteht, den Betrag der Gebühren, die der Kunde dem
          Unternehmen in den zwölf (12) Monaten vor dem Datum, an dem der
          Anspruch entstanden ist, zu zahlen hat.
        </Paragraph>

        <Title level={5}>GEISTIGE EIGENTUMSRECHTE</Title>
        <Paragraph>
          Alle geistigen Eigentumsrechte und Titel an der Lösung, den
          gegenwärtigen oder zukünftigen Modifikationen, Updates und Upgrades
          verbleiben bedingungslos im ausschließlichen Eigentum des Unternehmens
          zu jeder Zeit. Diese Nutzungsbedingungen übertragen weder das Eigentum
          noch ein proprietäres Interesse an der Lösung vom Unternehmen an den
          Kunden, außer wie hierin ausdrücklich vorgesehen oder schriftlich von
          den Parteien vereinbart. Der Kunde versteht und erkennt an, dass die
          hierin gewährte Lizenz nur die vom Unternehmen entwickelte Lösung
          betrifft und keine Lizenz für jegliche Drittsoftware oder geistiges
          Eigentum beinhaltet. Sofern nicht ausdrücklich anders vereinbart, ist
          der Kunde allein verantwortlich für die Beschaffung und Wartung
          solcher Drittsoftware und geistigen Eigentumsrechte auf eigene Kosten
          und Aufwand. Der Kunde hat keinerlei Rechte an anderen
          Softwareprodukten oder Lösungen des Unternehmens, die nicht
          ausdrücklich gemäß diesen Nutzungsbedingungen abonniert oder
          lizenziert wurden. Im Falle, dass der Kunde nachträglich eine Lizenz
          oder andere Vereinbarungen für andere vom Unternehmen entwickelte
          Software wünscht, verhandeln die Parteien in gutem Glauben über die
          Bedingungen, unter denen solche andere Software/Lösung dem Kunden zur
          Verfügung gestellt wird. Der Kunde wird zu keiner Zeit während der
          Laufzeit oder nach deren Beendigung die Gültigkeit oder das Eigentum
          an dem geistigen Eigentum des Unternehmens anfechten. Der Kunde darf
          keine Marke, Design, Produktnamen oder Handelsnamen verwenden oder
          registrieren, die verwirrend ähnlich den Marken, Produktnamen oder
          Handelsnamen des Unternehmens sind. Der Kunde verpflichtet sich, das
          Unternehmen unverzüglich über Ansprüche, Forderungen oder
          Benachrichtigungen im Zusammenhang mit dem geistigen Eigentum des
          Unternehmens zu informieren. Der Kunde unterstützt das Unternehmen bei
          Bedarf, führt Dokumente aus und ergreift Maßnahmen, die das
          Unternehmen in diesem Zusammenhang vernünftigerweise anfordert.
        </Paragraph>

        <Title level={5}>UNABHÄNGIGER AUFTRAGNEHMER</Title>
        <Paragraph>
          Das Verhältnis zwischen den Parteien besteht auf einer
          Principal-to-Principal-Basis. Jede Partei ist ein unabhängiger
          Auftragnehmer und ist kein gesetzlicher Vertreter, Partner oder Agent
          der anderen Partei. Keine Partei hat das Recht, die Befugnis oder die
          Autorität, sei sie ausdrücklich oder impliziert, eine Verpflichtung im
          Namen der anderen Partei einzugehen oder die andere Partei zu binden.
        </Paragraph>

        <Title level={5}>
          ANWENDBARES RECHT, SCHIEDSVERFAHREN UND GERICHTSBARKEIT
        </Title>
        <Paragraph>
          Alle Fragen der Durchsetzbarkeit und Auslegung, die sich aus diesen
          Nutzungsbedingungen ergeben können, werden nach indischem Recht
          geregelt und unterliegen der Schiedsklausel wie unten festgelegt. Die
          Gerichte in Mumbai haben die ausschließliche Zuständigkeit. Im Falle
          eines Streitfalls, der sich aus oder im Zusammenhang mit diesen
          Nutzungsbedingungen ergibt, bemühen sich die Parteien, diesen
          einvernehmlich durch ihre jeweiligen Vertreter/Senior Management
          beider Parteien zu lösen. Wenn der Streitfall nicht innerhalb von 30
          (dreißig) Tagen nach Entstehung einvernehmlich gelöst wird, wird der
          Streitfall einem Schiedsverfahren vor einem gemeinsamen Schiedsrichter
          unterzogen, der von beiden Parteien gemeinsam ernannt wird, gemäß dem
          Arbitration and Conciliation Act, 1996 und dessen nachträglichen
          Änderungen. Der Schiedsort ist Mumbai. Die Sprache des
          Schiedsverfahrens ist Englisch. Ungeachtet gegenteiliger Bestimmungen
          können die Parteien zustimmen, das Schiedsverfahren virtuell
          durchzuführen, z. B. durch Videokonferenzen oder andere audiovisuelle
          Mittel, die zwischen den Parteien vereinbart werden.
        </Paragraph>

        <Title level={5}>WERBUNG</Title>
        <Paragraph>
          Es wird zwischen den Parteien vereinbart, dass das Unternehmen sich
          das Recht vorbehält, bestimmte Details des Kunden, einschließlich,
          aber nicht beschränkt auf das Logo oder den Namen des Kunden und
          Leistungskennzahlen gemäß diesen Nutzungsbedingungen, für eigene
          Marketing- und Werbezwecke zu verwenden. Der Kunde stimmt hiermit
          ausdrücklich der Nutzung solcher Details durch das Unternehmen zu und
          gewährt dem Unternehmen eine ewige, unwiderrufliche, weltweite,
          gebührenfreie Lizenz für diese Nutzung.
        </Paragraph>

        <Title level={5}>HÖHERE GEWALT</Title>
        <Paragraph>
          Mit Ausnahme der Verpflichtung zur Zahlung hierunter und der
          Entschädigungsbestimmungen haften weder das Unternehmen noch der Kunde
          aufgrund eines Versäumnisses oder einer Verzögerung bei der Erfüllung
          seiner Verpflichtungen aufgrund eines Ereignisses höherer Gewalt.
          „Ereignis höherer Gewalt“ bedeutet: jedes Naturereignis, Naturereignis
          oder Element, Terrorismus, Aufstand, Revolution oder Bürgerkrieg,
          Piraterie, Bürgerkrieg oder feindliche Handlung, Arbeitsstreik,
          Handlungen öffentlicher Feinde, Bundes- oder Landesgesetze, Regeln und
          Vorschriften der zuständigen Behörden, Unfähigkeit, Material,
          Ausrüstung oder notwendige Arbeitskräfte auf dem freien Markt zu
          beschaffen, akute und ungewöhnliche Arbeits-, Material- oder
          Ausrüstungsknappheit oder andere Ursachen (außer finanziellen)
          außerhalb der Kontrolle einer Partei. Wenn ein oder mehrere Ereignisse
          höherer Gewalt zu einer Verzögerung von 15 (fünfzehn)
          aufeinanderfolgenden Tagen bei der Erfüllung der Verpflichtungen gemäß
          diesen Nutzungsbedingungen führen, besprechen die Parteien die
          Beendigung dieser Nutzungsbedingungen ohne weitere Haftung. Der Kunde
          bleibt jedoch für die Nutzung der Lösung gemäß diesen
          Nutzungsbedingungen haftbar.
        </Paragraph>

        <Title level={5}>KONTAKTDATEN</Title>
        <Paragraph>
          Alle Mitteilungen oder Benachrichtigungen, die dem Unternehmen gemäß
          diesen Nutzungsbedingungen gestattet oder erforderlich sind, müssen
          schriftlich erfolgen und an die unten aufgeführten Kontaktdaten des
          Unternehmens gerichtet werden. Diese Details können von Zeit zu Zeit
          vom Unternehmen aktualisiert und auf der Lösung bereitgestellt werden.
        </Paragraph>
        <Paragraph>
          <Text style={RED_TEXT}>E-Mail: hello@interakt.ai</Text>
        </Paragraph>

        <Title level={5}>ÜBERLEBEN UND TEILBARKEIT</Title>
        <Paragraph>
          Die sich aus diesen Nutzungsbedingungen ergebenden Rechte und
          Pflichten, die ihrer Natur nach fortbestehen sollten oder ausdrücklich
          so angegeben sind, bleiben in vollem Umfang in Kraft, soweit dies nach
          Ablauf oder Beendigung dieser Nutzungsbedingungen festgelegt ist.
          Sollte eine Bestimmung dieser Nutzungsbedingungen (oder ein Teil
          davon) ungültig oder nicht durchsetzbar sein, bleiben die übrigen
          Bestimmungen dieser Nutzungsbedingungen unberührt und sind für die
          Parteien verbindlich und durchsetzbar.
        </Paragraph>

        <Title level={5}>VERZICHT</Title>
        <Paragraph>
          Keine Partei wird durch bloßen Zeitablauf ohne Benachrichtigung davon
          als Verzicht auf ein Recht oder Rechtsmittel gemäß oder in Verbindung
          mit einer Verletzung oder Ungesetzlichkeit der anderen Partei
          angesehen. Der Verzicht einer Partei auf ein solches Recht oder
          Rechtsmittel wird nicht als Verzicht auf ein anderes Recht oder
          Rechtsmittel oder als fortgesetzter Verzicht im Zusammenhang mit
          ähnlichen, laufenden oder wiederholten Umständen ausgelegt.
        </Paragraph>

        <Title level={5}>ABTRETUNG</Title>
        <Paragraph>
          Das Unternehmen kann diese Nutzungsbedingungen und/oder einen Teil
          davon nach eigenem Ermessen jederzeit an verbundene Unternehmen,
          Gruppengesellschaften, Muttergesellschaften, Tochtergesellschaften
          oder Dritte ohne Mitteilung an den Kunden abtreten. Der Kunde darf
          diese Nutzungsbedingungen oder einen Teil davon ohne vorherige
          schriftliche Zustimmung des Unternehmens nicht abtreten.
        </Paragraph>
      </Typography>
    </NonLoggedPageTemplate>
  );
}
