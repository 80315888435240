import { Input, Typography } from 'antd';
import { Form, Formik } from 'formik';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';

import RouteDefinitions from '../../../app/routes';
import { PrimaryButton } from '../../../components/buttons';
import FormField from '../../../components/forms/FormField';
import { useConnectEmailAccountViaRedirect } from '../accountsGraphql';
import styles from './ConnectEmailAccountForm.module.less';

type FormValues = {
  email: string;
};

const INITIAL_VALUES: FormValues = { email: '' };

type ConnectEmailAccountFormProps = {
  onClose: () => void;
  termsAcceptanceTimestamp: Date;
};

export function ConnectEmailAccountForm({
  onClose,
  termsAcceptanceTimestamp,
}: ConnectEmailAccountFormProps) {
  const navigate = useNavigate();
  const [generateConnectAccountUrl, { loading }] =
    useConnectEmailAccountViaRedirect({
      onError: () => {
        onClose();
        navigate(RouteDefinitions.connectFail);
      },
    });

  const onSubmit = (values: FormValues) =>
    generateConnectAccountUrl({
      input: {
        redirectUri: `https://${window.location.hostname}${RouteDefinitions.connectSuccess}`,
        emailAddress: values.email,
        termsAcceptanceTimestamp: termsAcceptanceTimestamp.toISOString(),
      },
    });

  return (
    <Formik initialValues={INITIAL_VALUES} onSubmit={onSubmit}>
      <Form>
        <Typography.Paragraph>
          <FormattedMessage id="connectAccount.dialog.email.description" />
        </Typography.Paragraph>
        <Typography.Paragraph>
          <FormattedMessage id="connectAccount.dialog.email.note" />
        </Typography.Paragraph>
        <div className={styles.FormContainer}>
          <FormField name="email" placeholderId="labels.email">
            {({ field }) => <Input size="large" {...field} />}
          </FormField>
          <PrimaryButton
            htmlType="submit"
            loading={loading}
            labelId="connectAccount.dialog.button.connect"
          />
        </div>
      </Form>
    </Formik>
  );
}
