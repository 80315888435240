import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Tooltip, Typography } from 'antd';
import { ErrorMessage, FieldArray, useFormikContext } from 'formik';
import { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { CircleButton, PrimaryButton } from '../buttons';
import FormField, { FormFieldProps } from './FormField';
import style from './FormFieldArray.module.less';

type FormFieldArrayProps<T> = {
  name: string;
  getEmpty: () => T;
  renderOne: NonNullable<FormFieldProps['children']>;
  disabled?: boolean;
};

export default function FormFieldArray<T>({
  name,
  getEmpty,
  renderOne,
  disabled = false,
}: FormFieldArrayProps<T>) {
  const { values } = useFormikContext<any>();
  const value = values[name] as T[] | undefined;

  return (
    <FieldArray
      name={name}
      render={({ remove, insert, push }) => (
        <div className={style.Container}>
          {value?.map((_val, index) => {
            const subName = `${name}[${index}]`;
            return (
              <Fragment key={index}>
                <div className={style.Item}>
                  <div className="Flex--1">
                    <FormField name={subName} disabled={disabled}>
                      {renderOne}
                    </FormField>
                  </div>
                  {!disabled && (
                    <div className={style.ItemControls}>
                      <Tooltip
                        title={<FormattedMessage id="labels.addBefore" />}
                      >
                        <CircleButton
                          icon={<PlusOutlined />}
                          onClick={() => insert(index, getEmpty())}
                        />
                      </Tooltip>
                      <Tooltip title={<FormattedMessage id="labels.delete" />}>
                        <CircleButton
                          icon={<DeleteOutlined />}
                          onClick={() => remove(index)}
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
                <ErrorMessage
                  name={subName}
                  render={msg => (
                    <Typography.Text type="danger">{msg}</Typography.Text>
                  )}
                />
              </Fragment>
            );
          })}
          {!disabled && (
            <div>
              <PrimaryButton
                labelId="labels.add"
                icon={<PlusOutlined />}
                onClick={() => push(getEmpty())}
              />
            </div>
          )}
        </div>
      )}
    />
  );
}
