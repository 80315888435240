import { CloseOutlined } from '@ant-design/icons';
import { Form as AntForm, Col, Row, Space, Typography } from 'antd';
import { Formik, useFormikContext } from 'formik';
import { omit, pick } from 'lodash-es';
import { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import RouteDefinitions from '../../../app/routes';
import { asyncNoop } from '../../../common/utils/funcUtils';
import { useResponsiveQueries } from '../../../common/utils/layoutUtils';
import { CircleButton } from '../../../components/buttons';
import FormField from '../../../components/forms/FormField';
import { MessagingAccountSelect } from '../../../components/forms/fetchingSelects';
import { useLocalizedYup } from '../../../config/intl/LocaleProvider';
import { MessagingAccountType } from '../../../types/graphqlGenerated';
import { useAccountsQuery } from '../../accounts/accountsGraphql';
import { SubHeader } from '../../layout/header/MobileAndTabletHeader';
import style from './MessageComposer.module.less';
import MessageComposerEmailSubform from './messageComposer/email/MessageComposerEmailSubform';
import { MessageComposerReaction } from './messageComposer/messageComposerTypes';
import MessageComposerWhatsappSubform from './messageComposer/whatsapp/MessageComposerWhatsappSubform';

type MessageComposerFormValues = {
  from?: number;
};

type GetInitialValues = {
  reaction?: MessageComposerReaction;
};

function getInitialValues({
  reaction,
}: GetInitialValues): MessageComposerFormValues {
  return {
    from: reaction?.thread?.messagingAccount?.id ?? undefined,
  };
}
function FromField({ reaction }) {
  const intl = useIntl();
  return (
    <FormField name="from" labelId="sendMessage.fromAccount">
      {({ field, label }) => (
        <Row>
          <Col span={2}>
            <AntForm.Item
              label={label}
              colon={true}
              className={style.FormItem}
              labelCol={{ span: 24 }}
            >
              {/* Empty Form.Item for label only */}
            </AntForm.Item>
          </Col>
          <Col span={22}>
            <AntForm.Item className={style.FormItem} wrapperCol={{ span: 24 }}>
              <MessagingAccountSelect
                {...field}
                className={style.FromSelect}
                placeholder={intl.formatMessage({
                  id: 'sendMessage.selectAccount',
                })}
                accountType={reaction?.thread?.messagingAccount?.accountType}
                disabled={
                  reaction?.thread?.messagingAccount?.accountType ===
                  MessagingAccountType.Whatsapp
                }
              />
            </AntForm.Item>
          </Col>
        </Row>
      )}
    </FormField>
  );
}

export type MessageComposerProps<F extends Record<string, any>> = {
  reaction?: MessageComposerReaction;
  onSendStart: () => void;
  restoreEditState: (values: F) => void;
  initialValues?: F;
};

function MessageComposerForm<F>({
  reaction,
  onSendStart,
  restoreEditState: restoreEditStateOuter,
  initialValues,
}: MessageComposerProps<F>) {
  const { accounts } = useAccountsQuery();
  const { values } = useFormikContext<MessageComposerFormValues>();
  // used to keep the body when changing sender and message has been already written
  const emailFormRef = useRef<any>();
  const account =
    accounts && values.from
      ? accounts.find(acc => acc.id === values.from)
      : undefined;

  const restoreEditState = innerValues =>
    restoreEditStateOuter({ ...innerValues, ...values });

  useEffect(() => {
    console.log('RELOAD');
  }, []);

  return (
    <div className={style.Form}>
      <FromField reaction={reaction} />
      {account?.accountType === MessagingAccountType.Email && (
        <MessageComposerEmailSubform
          key={values.from}
          account={account}
          reaction={reaction}
          onSendStart={onSendStart}
          restoreEditState={restoreEditState}
          initialValues={initialValues}
          formRef={emailFormRef}
        />
      )}
      {account?.accountType === MessagingAccountType.Whatsapp && (
        <MessageComposerWhatsappSubform
          key={values.from}
          account={account}
          reaction={reaction}
          onSendStart={onSendStart}
          restoreEditState={restoreEditState}
          initialValues={initialValues}
        />
      )}
    </div>
  );
}

export function InlineMessageComposer<F extends MessageComposerFormValues>({
  reaction,
  onSendStart,
  restoreEditState,
  initialValues: initialValuesOuter,
}: MessageComposerProps<F>) {
  const yup = useLocalizedYup();
  const validationSchema = yup.object().shape({
    from: yup.number().required(),
  });

  const initialValues = initialValuesOuter
    ? pick(initialValuesOuter, ['from'])
    : getInitialValues({ reaction });

  return (
    <div className={style.InlineContainer}>
      <Formik
        initialValues={initialValues}
        onSubmit={asyncNoop}
        validationSchema={validationSchema}
      >
        <MessageComposerForm
          reaction={reaction}
          onSendStart={onSendStart}
          restoreEditState={restoreEditState}
          initialValues={
            initialValuesOuter
              ? omit(initialValuesOuter as any, ['from'])
              : undefined
          }
        />
      </Formik>
    </div>
  );
}

export default function MessageComposer<F extends MessageComposerFormValues>({
  reaction,
  onSendStart,
  restoreEditState,
  initialValues,
}: MessageComposerProps<F>) {
  const { sm: isSmallScreen, md: isMediumScreen } = useResponsiveQueries();

  return (
    <div className={style.Container}>
      <Space
        direction="vertical"
        size={isSmallScreen || isMediumScreen ? 0 : 15}
      >
        {isSmallScreen || isMediumScreen ? (
          <SubHeader
            leftCornerNodes={
              <Link to={RouteDefinitions.root}>
                <CircleButton
                  icon={<CloseOutlined className={style.CloseIcon} />}
                />
              </Link>
            }
            pageName={<FormattedMessage id="sendMessage.title" />}
          />
        ) : (
          <Typography.Title level={4}>
            <FormattedMessage id="sendMessage.title" />
          </Typography.Title>
        )}
        <InlineMessageComposer
          reaction={reaction}
          onSendStart={onSendStart}
          restoreEditState={restoreEditState}
          initialValues={initialValues}
        />
      </Space>
    </div>
  );
}
