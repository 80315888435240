import { gql, useApolloClient } from '@apollo/client';
import { isEmpty, property } from 'lodash-es';
import { useEffect, useState } from 'react';

import {
  createUseMutation,
  createUseQuery,
} from '../../common/utils/graphqlUtils';
import { useMemoizedVariable } from '../../common/utils/hookUtils';
import {
  CalendarEventsFilter,
  CreateCalendarEventMutation,
  CreateCalendarEventMutationVariables,
  CreateCalendarMutation,
  CreateCalendarMutationVariables,
  DeleteCalendarEventMutation,
  DeleteCalendarEventMutationVariables,
  DeleteCalendarMutation,
  DeleteCalendarMutationVariables,
  GetCalendarEventDetailQuery,
  GetCalendarEventDetailQueryVariables,
  GetCalendarsByAccountQuery,
  GetCalendarsByAccountQueryVariables,
  SendCalendarEventRsvpMutation,
  SendCalendarEventRsvpMutationVariables,
  UpdateCalendarEventMutation,
  UpdateCalendarEventMutationVariables,
} from '../../types/graphqlGenerated';

const CALENDARS_QUERY = gql`
  query GetCalendarsByAccount {
    getCalendarsByAccount {
      account {
        id
        platformId
        accountType
        color
      }
      calendars {
        id
        name
        color
        selected
        account {
          id
          accountType
          platformId
        }
      }
      error {
        code
        message
      }
    }
  }
`;

const useCalendarsByAccountQuery = createUseQuery<
  GetCalendarsByAccountQuery,
  GetCalendarsByAccountQueryVariables,
  'calendarsByAccount',
  GetCalendarsByAccountQuery['getCalendarsByAccount']
>(CALENDARS_QUERY, {
  extractResult: resp => resp.getCalendarsByAccount,
  resultName: 'calendarsByAccount',
});
export function useCalendarsQuery(
  opts?: Parameters<typeof useCalendarsByAccountQuery>[1]
) {
  const { calendarsByAccount, loading, error, fetchMore, refetch } =
    useCalendarsByAccountQuery({}, opts);

  const calendars = calendarsByAccount?.flatMap(
    ({ calendars }) => calendars || []
  );
  const errors = calendarsByAccount
    ?.filter(property('error'))
    ?.map(({ account, error }) =>
      Object.assign(new Error(error?.message), { account, code: error?.code })
    );

  return { calendars, loading, error, errors, fetchMore, refetch };
}

const CREATE_CALENDAR_MUTATION = gql`
  mutation CreateCalendar($input: CreateCalendarInput!) {
    createCalendar(input: $input) {
      id
    }
  }
`;

export const useCreateCalendarMutation = createUseMutation<
  CreateCalendarMutation,
  CreateCalendarMutationVariables,
  CreateCalendarMutation['createCalendar']
>(CREATE_CALENDAR_MUTATION, {
  extractResult: resp => resp.createCalendar,
  refetchQueries: ['GetCalendars'],
  awaitRefetchQueries: true,
});

const UPDATE_CALENDAR_MUTATION = gql`
  mutation UpdateCalendar($id: Int!, $input: UpdateCalendarInput!) {
    updateCalendar(id: $id, input: $input) {
      id
      color
      selected
    }
  }
`;

export function useUpdateCalendarMutation() {
  const client = useApolloClient();
  async function mutate(calendarId, { color, selected, accountId }) {
    await client.mutate({
      mutation: UPDATE_CALENDAR_MUTATION,
      variables: {
        id: calendarId,
        input: { color, selected, accountId },
      },
      optimisticResponse: {
        updateCalendar: {
          id: calendarId,
          __typename: 'Calendar',
          color,
          selected,
        },
      },
    });
  }
  return [mutate];
}

const DELETE_CALENDAR_MUTATION = gql`
  mutation DeleteCalendar($id: Int!) {
    deleteCalendar(id: $id) {
      id
    }
  }
`;

export const useDeleteCalendarMutation = createUseMutation<
  DeleteCalendarMutation,
  DeleteCalendarMutationVariables,
  DeleteCalendarMutation['deleteCalendar']
>(DELETE_CALENDAR_MUTATION, {
  extractResult: resp => resp.deleteCalendar,
  // No need to refetch events - removing calendar will also unselect it and remove all its events
  // On the contrary, refetching events causes error because refetches are run concurrently and the calendar doesn't exist anymore
  refetchQueries: ['GetCalendars'],
  awaitRefetchQueries: true,
});

const EventWhenFragment = gql`
  fragment CalendarEventWhenFragment on CalendarEvent {
    when {
      __typename
      ... on TimespanInstant {
        startTime
        endTime
      }
      ... on TimeInstant {
        time
      }
      ... on DatespanInstant {
        startDate
        endDate
      }
      ... on DateInstant {
        date
      }
    }
  }
`;

export const CalendarEventFullFragment = gql`
  ${EventWhenFragment}
  fragment CalendarEventFullFragment on CalendarEvent {
    id
    title
    accountId
    calendarId
    description
    readOnly
    status
    participants {
      name
      email
      status
    }
    location
    ...CalendarEventWhenFragment
    masterEvent {
      recurrence {
        rrule
      }
      ...CalendarEventWhenFragment
    }
  }
`;

const CALENDAR_EVENTS_QUERY = gql`
  ${CalendarEventFullFragment}
  query GetCalendarEvents($filter: CalendarEventsFilter!) {
    getCalendarEvents(filter: $filter) {
      ...CalendarEventFullFragment
    }
  }
`;

/* Fetch calendar events for each calendar id */
export function useCalendarEventsQuery(
  filters: CalendarEventsFilter[],
  { onError }: { onError: (e: Error, filter: CalendarEventsFilter) => void }
) {
  const client = useApolloClient();
  const [events, setEvents] = useState({});
  const [loading, setLoading] = useState(false);
  const memoizedFilters = useMemoizedVariable(filters);
  useEffect(() => {
    if (isEmpty(memoizedFilters)) {
      setEvents({});
      setLoading(false);
      return;
    }
    setEvents({});
    setLoading(true);
    let cancelled = false;

    function updateEvents(data, calendarId) {
      if (cancelled) {
        // unmounted or another effect in progress
        return;
      }
      // replace events for this calendar id
      // merging with old doesn't work for deletes
      setEvents(old => ({ ...old, [calendarId]: data.data.getCalendarEvents }));
    }

    async function fetchCalendarEvents(filter) {
      try {
        const queryOpts = {
          query: CALENDAR_EVENTS_QUERY,
          variables: { filter },
        };
        const data = await client.query(queryOpts);
        updateEvents(data, filter.calendarId);
        return client
          .watchQuery({ ...queryOpts, fetchPolicy: 'cache-and-network' })
          .subscribe(newData => updateEvents(newData, filter.calendarId));
      } catch (err) {
        onError?.(err, filter);
      }
    }

    const queryPromises = memoizedFilters.map(fetchCalendarEvents);
    Promise.all(queryPromises).finally(() => {
      setLoading(false);
    });

    return () => {
      Promise.all(queryPromises).then(subscriptions => {
        subscriptions.forEach(sub => sub?.unsubscribe());
      });
      cancelled = true;
    };
    // eslint-disable-next-line
  }, [memoizedFilters]);

  return { events: Object.values(events).flatMap(evs => evs), loading };
}

export const CALENDAR_EVENT_DETAIL_QUERY = gql`
  ${CalendarEventFullFragment}
  query GetCalendarEventDetail($eventId: String!, $calendarId: Int!) {
    getCalendarEventDetail(eventId: $eventId, calendarId: $calendarId) {
      ...CalendarEventFullFragment
    }
  }
`;

export const useCalendarEventDetailQuery = createUseQuery<
  GetCalendarEventDetailQuery,
  GetCalendarEventDetailQueryVariables,
  'event',
  GetCalendarEventDetailQuery['getCalendarEventDetail']
>(CALENDAR_EVENT_DETAIL_QUERY, {
  extractResult: resp => resp.getCalendarEventDetail,
  resultName: 'event',
});

const CREATE_CALENDAR_EVENT_MUTATION = gql`
  ${CalendarEventFullFragment}
  mutation CreateCalendarEvent(
    $input: CalendarEventInput!
    $notifyParticipants: Boolean!
    $accountId: Int!
  ) {
    createCalendarEvent(
      input: $input
      notifyParticipants: $notifyParticipants
      accountId: $accountId
    ) {
      ...CalendarEventFullFragment
    }
  }
`;

export const useCreateCalendarEvent = createUseMutation<
  CreateCalendarEventMutation,
  CreateCalendarEventMutationVariables,
  CreateCalendarEventMutation['createCalendarEvent']
>(CREATE_CALENDAR_EVENT_MUTATION, {
  extractResult: resp => resp.createCalendarEvent,
  refetchQueries: ['GetCalendarEvents'],
  awaitRefetchQueries: true,
});

const DELETE_CALENDAR_EVENT_MUTATION = gql`
  mutation DeleteCalendarEvent(
    $eventId: String!
    $calendarId: Int!
    $scope: CalendarRecurrenceEditScope
  ) {
    deleteCalendarEvent(
      eventId: $eventId
      calendarId: $calendarId
      scope: $scope
    ) {
      id
    }
  }
`;

export const useDeleteCalendarEventMutation = createUseMutation<
  DeleteCalendarEventMutation,
  DeleteCalendarEventMutationVariables,
  DeleteCalendarEventMutation['deleteCalendarEvent']
>(DELETE_CALENDAR_EVENT_MUTATION, {
  extractResult: resp => resp.deleteCalendarEvent,
  refetchQueries: ['GetCalendarEvents'],
  awaitRefetchQueries: true,
});

const UPDATE_CALENDAR_EVENT_MUTATION = gql`
  ${CalendarEventFullFragment}
  mutation UpdateCalendarEvent(
    $id: String!
    $input: CalendarEventInput!
    $notifyParticipants: Boolean!
    $scope: CalendarRecurrenceEditScope
  ) {
    updateCalendarEvent(
      id: $id
      input: $input
      notifyParticipants: $notifyParticipants
      scope: $scope
    ) {
      ...CalendarEventFullFragment
    }
  }
`;

export const useUpdateCalendarEventMutation = createUseMutation<
  UpdateCalendarEventMutation,
  UpdateCalendarEventMutationVariables,
  UpdateCalendarEventMutation['updateCalendarEvent']
>(UPDATE_CALENDAR_EVENT_MUTATION, {
  extractResult: resp => resp.updateCalendarEvent,
  // We cannot refetch the detail because id may have changed, which results in error
});

const SEND_EVENT_RSVP_MUTATION = gql`
  ${CalendarEventFullFragment}
  mutation SendCalendarEventRSVP($input: CalendarEventSendRSVPInput!) {
    sendCalendarEventRSVP(input: $input) {
      ...CalendarEventFullFragment
    }
  }
`;

export const useSendRSVP = createUseMutation<
  SendCalendarEventRsvpMutation,
  SendCalendarEventRsvpMutationVariables,
  SendCalendarEventRsvpMutation['sendCalendarEventRSVP']
>(SEND_EVENT_RSVP_MUTATION, {
  extractResult: resp => resp.sendCalendarEventRSVP,
});
