import { ParsedMailbox, parseAddressList } from 'email-addresses';
import { formatDate, parseDate, parseDateTime } from 'vifzack-common-utils';

import { getBrowserTimezone } from '../../../common/utils/dateUtils';
import { richTextEditorValueToString } from '../../../components/forms/RichEditor';
import {
  CalendarEvent,
  CalendarEventFullFragmentFragment,
  CalendarEventInput,
  CalendarEventInstantInput,
  CalendarEventParticipantInput,
} from '../../../types/graphqlGenerated';
import { CalendarEventFormValues } from './calendarEventTypes';
import { buildRRule, parseRRule } from './rruleConversions';

function mapFormWhenToGraphqlWhen(
  when: CalendarEventFormValues['when'],
  allDay: boolean
): CalendarEventInstantInput | null {
  if (allDay && when?.length === 2) {
    return {
      startDate: formatDate(when[0]),
      endDate: formatDate(when[1]),
    };
  }
  if (!allDay && when?.length === 2) {
    return {
      startTime: when[0].toISOString(),
      endTime: when[1].toISOString(),
    };
  }
  if (allDay && when?.length === 1) {
    return {
      date: formatDate(when[0]),
    };
  }
  if (!allDay && when?.length === 1) {
    return {
      time: when[0].toISOString(),
    };
  }
  return null;
}

function mapFormParticipantsToGraphqlParticipants(
  participants: CalendarEventFormValues['participants']
): CalendarEventParticipantInput[] {
  return (
    parseAddressList({
      input: participants?.join(', ') ?? '',
      atInDisplayName: true,
      rfc6532: true,
    })?.map((participant: ParsedMailbox) => ({
      name: participant.name ?? undefined,
      email: participant.address,
    })) ?? []
  );
}

function mapFormToGraphqlRecurence(
  values: CalendarEventFormValues
): CalendarEventInput['recurrence'] {
  if (!values.recurrence?.enabled) {
    return undefined;
  }
  return {
    timezone: getBrowserTimezone(),
    rrule: [buildRRule(values.recurrence)],
  };
}

export function mapFormToGraphqlCalendarEvent(
  values: CalendarEventFormValues
): CalendarEventInput {
  return {
    title: values.title,
    participants: mapFormParticipantsToGraphqlParticipants(values.participants),
    calendarId: values.calendarId!,
    description: richTextEditorValueToString(values.description),
    // UI shouldn't allow the null case if null is not allowed, so '!' is okay
    when: mapFormWhenToGraphqlWhen(values.when, values.allDay)!,
    recurrence: mapFormToGraphqlRecurence(values),
  };
}

type ParsedWhen = {
  start: Date;
  end: Date;
  allDay: boolean;
};

export function parseGraphqlWhen(event: CalendarEvent): ParsedWhen {
  switch (event.when.__typename) {
    case 'TimespanInstant':
      return {
        start: parseDateTime(event.when.startTime),
        end: parseDateTime(event.when.endTime),
        allDay: false,
      };
    case 'DatespanInstant':
      return {
        start: parseDate(event.when.startDate),
        end: parseDate(event.when.endDate),
        allDay: true,
      };
    case 'DateInstant':
      return {
        start: parseDate(event.when.date),
        end: parseDate(event.when.date),
        allDay: true,
      };
    case 'TimeInstant':
      return {
        start: parseDateTime(event.when.time),
        end: parseDateTime(event.when.time),
        allDay: false,
      };
    default:
      console.error('Unexpected instant type', event);
      throw new Error('Invalid data received');
  }
}

function mapGraphqlWhenToFormWhen(
  when: CalendarEvent['when']
): CalendarEventFormValues['when'] | undefined {
  switch (when.__typename) {
    case 'TimespanInstant':
      return [parseDateTime(when.startTime), parseDateTime(when.endTime)];
    case 'DatespanInstant':
      return [parseDate(when.startDate), parseDate(when.endDate)];
    case 'DateInstant':
      return [parseDate(when.date)];
    case 'TimeInstant':
      return [parseDateTime(when.time)];
    default:
      console.error('Unexpected instant type', when);
      return undefined;
  }
}

export function mapGraphqlToFormCalendarEvent(
  event: CalendarEventFullFragmentFragment
): CalendarEventFormValues {
  return {
    calendarId: event.calendarId,
    title: event.title,
    description: event.description || '',
    participants:
      event.participants?.map(p =>
        p.name ? `${p.name} <${p.email}>` : p.email
      ) ?? [],
    allDay: ['DateInstant', 'DatespanInstant'].includes(
      event.when.__typename || ''
    ),
    when: mapGraphqlWhenToFormWhen(event.when),
    sendInvitations: false,
    recurrence: parseRRule(event.masterEvent),
  };
}
