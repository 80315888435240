import { gql, useApolloClient } from '@apollo/client';
import { useCallback } from 'react';

import { createUseMutation } from '../../common/utils/graphqlUtils';
import {
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables,
} from '../../types/graphqlGenerated';
import {
  readNamedThreadsFilters,
  writeNamedThreadsFilters,
} from '../messagesApp/filters/filtersGraphql';

const UPDATE_USER_SETTINGS_MUTATION = gql`
  mutation UpdateUserSettings($input: UserSettingsInput!) {
    updateUserSettings(input: $input) {
      filtersOrder
    }
  }
`;

const useUpdateUserSettingsMutationInner = createUseMutation<
  UpdateUserSettingsMutation,
  UpdateUserSettingsMutationVariables,
  UpdateUserSettingsMutation['updateUserSettings']
>(UPDATE_USER_SETTINGS_MUTATION, {
  extractResult: resp => resp.updateUserSettings,
});

export const useUpdateUserSettingsMutation: typeof useUpdateUserSettingsMutationInner =
  opts => {
    const client = useApolloClient();
    const [mutate, { loading }] = useUpdateUserSettingsMutationInner(opts);

    const decoratedMutate = useCallback<typeof mutate>(
      async variables => {
        const initialFiltersOrder = readNamedThreadsFilters(client).map(
          f => f.id
        );
        // Direct cache update
        if (variables.input.filtersOrder) {
          writeNamedThreadsFilters(client, variables.input.filtersOrder);
        }

        try {
          return await mutate(variables);
        } catch (e) {
          // Rollback
          if (variables.input.filtersOrder) {
            writeNamedThreadsFilters(client, initialFiltersOrder);
          }
        }
      },
      [client, mutate]
    );

    return [decoratedMutate, { loading }];
  };
