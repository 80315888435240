import {
  RadioGroupProps as AntRadioGroupProps,
  CheckboxOptionType,
  Radio,
} from 'antd';
import classNames from 'classnames';
import { FieldInputProps } from 'formik';

import style from './radioFields.module.less';

type RadioGroupProps = AntRadioGroupProps &
  FieldInputProps<string> & {
    options: CheckboxOptionType[];
    vertical?: boolean;
  };

export function RadioGroup({
  name,
  value,
  options,
  vertical,
  className,
  ...rest
}: RadioGroupProps) {
  return (
    <Radio.Group
      className={classNames(
        vertical && style['Container--Vertical'],
        className
      )}
      name={name}
      {...rest}
      value={value}
      options={options}
    />
  );
}
