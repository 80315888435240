import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Avatar, Checkbox, List, Tooltip } from 'antd';
import classNames from 'classnames';
import { Set } from 'immutable';
import { noop } from 'lodash-es';
import { MouseEventHandler, useCallback, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedTime } from 'react-intl';

import { useMountEffect } from '../../../common/utils/hookUtils';
import { useResponsiveQueries } from '../../../common/utils/layoutUtils';
import { Whitespace } from '../../../components/Whitespace';
import {
  MessagingFolderType,
  MessagingThreadListFragmentFragment,
  MessagingThreadsFilterInput,
} from '../../../types/graphqlGenerated';
import {
  MessagingAccountIcon,
  MessagingAccountLabel,
} from '../../accounts/accountFormats';
import { DragPlaceholder } from '../../common/dragAndDrop/DragPlaceholder';
import {
  DragSource,
  DragSourceContainer,
} from '../../common/dragAndDrop/DragSource';
import { DragAndDropItemType } from '../../common/dragAndDrop/dragAndDropConstants';
import { ThreadParticipantsList } from '../ThreadParticipantsList';
import style from './ThreadsListContent.module.less';

const DateAndTime = ({ value }: { value: Date }) => {
  const media = useResponsiveQueries();
  return media.lg ? (
    <div>
      <FormattedDate value={value} />
      <Whitespace />
      <FormattedTime value={value} />
    </div>
  ) : (
    <FormattedDate value={value} month="numeric" day="numeric" year="2-digit" />
  );
};

type ItemProps = {
  item: MessagingThreadListFragmentFragment;
  selected: boolean;
  onSelected: (checked: boolean) => void;
  onClick?: MouseEventHandler<HTMLDivElement>;
  activeFolderType?: MessagingFolderType;
  index: number;
};

function Item({
  item,
  selected,
  onSelected,
  onClick,
  activeFolderType,
  index,
}: ItemProps) {
  const { unread, hasAttachments, lastMessageOutgoing } = item;

  return (
    <DragSource id={item.id} index={index}>
      <List.Item
        className={classNames(
          style.ListItem,
          unread && style['ListItem--Unread'],
          selected && style['ListItem--Selected']
        )}
        onClick={onClick}
      >
        <div className={style.Container}>
          <div onClick={e => e.stopPropagation()}>
            <Checkbox
              className="Checkbox--ClickAreaX3"
              checked={selected}
              onChange={e => onSelected(e.target.checked)}
            />
          </div>
          <div className="FlexRow FlexRow--SpaceSm">
            <Tooltip
              title={
                <div className="FlexRow FlexRow--SpaceSm">
                  <MessagingAccountLabel account={item.messagingAccount} />
                </div>
              }
            >
              <Avatar
                shape="circle"
                className={style.AccountType}
                style={{ borderColor: item.messagingAccount.color }}
              >
                <MessagingAccountIcon account={item.messagingAccount} />
              </Avatar>
            </Tooltip>
            {lastMessageOutgoing ? (
              <Tooltip
                title={
                  <FormattedMessage id="threadsList.lastMesssageOutgoing" />
                }
              >
                <ArrowRightOutlined />
              </Tooltip>
            ) : (
              <Tooltip
                title={
                  <FormattedMessage id="threadsList.lastMesssageIncoming" />
                }
              >
                <ArrowLeftOutlined className={style.IconIncoming} />
              </Tooltip>
            )}
          </div>
          <div
            className={classNames(style.ItemParticipants, 'Hide--MdAndLower')}
          >
            <ThreadParticipantsList
              thread={item}
              activeFolderType={activeFolderType}
              modifyPrefix={node =>
                activeFolderType === MessagingFolderType.Sent ? node : null
              }
              showFull={false}
            />
          </div>
          <div
            className={classNames(
              style.ItemParticipantsAndDescription,
              'Hide--LgAndHigher'
            )}
          >
            <ThreadParticipantsList
              thread={item}
              activeFolderType={activeFolderType}
              modifyPrefix={node =>
                activeFolderType === MessagingFolderType.Sent ? node : null
              }
              showFull={false}
            />
            <div className={style['ItemDescription__Name']}>
              {item.userMetadata?.name ?? ''}
            </div>
            <div className={style['ItemDescription__Subject']}>
              {item.subject}
            </div>
            <div className={style['ItemDescription__Snippet']}>
              {item.snippet}
            </div>
          </div>
          {item.lastMessageTimestamp && (
            <div className={style.ItemDate}>
              <DateAndTime value={new Date(item.lastMessageTimestamp)} />
            </div>
          )}
          <div
            className={classNames(
              style.ItemAttachment,
              hasAttachments && style['ItemAttachment--Active'],
              'Hide--MdAndLower'
            )}
          />
          <div
            className={classNames(style.ItemDescription, 'Hide--MdAndLower')}
          >
            <div className={style['ItemDescription__Name']}>
              {item.userMetadata?.name ?? ''}
            </div>
            <div className={style['ItemDescription__Subject']}>
              {item.subject}
            </div>
            <div className={style['ItemDescription__Snippet']}>
              {item.snippet}
            </div>
          </div>
        </div>
      </List.Item>
    </DragSource>
  );
}

type ThreadMovePlaceholderProps = {
  selectedKeys: Set<number>;
  onSelectedKeysChange: (newSet: Set<number>) => void;
  draggedId: number;
  allItemsSize: number;
  allSelected: boolean;
};

function ThreadMovePlaceholder({
  draggedId,
  selectedKeys,
  onSelectedKeysChange,
  allItemsSize,
  allSelected,
}: ThreadMovePlaceholderProps) {
  const [initialSelectedKeys] = useState(selectedKeys);
  const isDraggedInSelection = allSelected || selectedKeys.has(draggedId);
  useMountEffect(() => {
    if (!isDraggedInSelection) {
      onSelectedKeysChange(Set([draggedId]));
      return () => onSelectedKeysChange(initialSelectedKeys);
    } else {
      return noop;
    }
  });

  let numberOfThreads = 1;
  if (allSelected) {
    numberOfThreads = allItemsSize;
  } else if (isDraggedInSelection) {
    numberOfThreads = selectedKeys.size;
  }

  return (
    <DragPlaceholder>
      {numberOfThreads === 1 ? (
        <FormattedMessage id="moveThreads.singular" />
      ) : (
        <FormattedMessage
          id="moveThreads.plural"
          values={{ number: numberOfThreads }}
        />
      )}
    </DragPlaceholder>
  );
}

type Props = {
  items: MessagingThreadListFragmentFragment[];
  selectedKeys: Set<number>;
  onSelectedKeysChange: (newSet: Set<number>) => void;
  onItemClick: (item: MessagingThreadListFragmentFragment) => void;
  activeFolderType?: MessagingFolderType;
  allItemsSize: number;
  filter: MessagingThreadsFilterInput;
  allSelected: boolean;
  onMoveToFolderSuccess: () => void;
};

export function ThreadsListContent({
  items,
  selectedKeys,
  onSelectedKeysChange,
  onItemClick,
  activeFolderType,
  allItemsSize,
  filter,
  allSelected,
  onMoveToFolderSuccess,
}: Props) {
  const prepareData = useCallback(
    () => ({
      filter: allSelected ? filter : { id: { in: selectedKeys.toJS() } },
      onSuccess: onMoveToFolderSuccess,
    }),
    // callbacks excluded
    // eslint-disable-next-line
    [allSelected, filter, selectedKeys]
  );

  return (
    <DragSourceContainer
      itemType={DragAndDropItemType.Thread}
      renderPlaceholder={({ id }) => (
        <ThreadMovePlaceholder
          draggedId={id as number}
          selectedKeys={selectedKeys}
          onSelectedKeysChange={onSelectedKeysChange}
          allSelected={allSelected}
          allItemsSize={allItemsSize}
        />
      )}
      prepareData={prepareData}
    >
      <List rowKey="id">
        {items?.map((item, i) => (
          <Item
            key={item.id}
            item={item}
            index={i}
            selected={selectedKeys.has(item.id)}
            onClick={() => onItemClick(item)}
            onSelected={selected => {
              if (selected) {
                onSelectedKeysChange(selectedKeys.add(item.id));
              } else {
                onSelectedKeysChange(selectedKeys.delete(item.id));
              }
            }}
            activeFolderType={activeFolderType}
          />
        ))}
      </List>
    </DragSourceContainer>
  );
}
