import { Upload, message } from 'antd';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';
import { FieldInputProps, useFormikContext } from 'formik';
import { find } from 'lodash-es';
import {
  ForwardedRef,
  ReactNode,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { useIntl } from 'react-intl';

import { formatError } from '../../common/utils/errorUtils';
import { showErrorMessage } from '../../common/utils/messageUtils';
import { AttachmentsList, FileInfo } from '../AttachmentButton';
import styles from './FileUpload.module.less';

type FileResponse = { id: number; contentId: string; src: string };
export type FileUploadFile = UploadFile<FileResponse>;

export type UploadFunction = (
  file: UploadFile,
  callbacks: {
    onProgress: (percent: number) => void;
    setAbort: (abort: () => void) => void;
  }
) => Promise<FileResponse>;

type FileUploadButtonProps = FieldInputProps<FileUploadFile[]> &
  Pick<
    UploadProps,
    'multiple' | 'maxCount' | 'accept' | 'className' | 'disabled'
  > & {
    upload: UploadFunction;
    children: ReactNode;
    maxSize?: number;
  };

export type FileUploadButtonPropsRef = {
  insertFile: (file: File) => Promise<FileResponse>;
};

const newFramePromise = () =>
  new Promise(resolve => requestAnimationFrame(resolve));

type UseExecuteAntUploadOpts = { name: string; upload: UploadFunction };

export function useExecuteAntUpload({ name, upload }: UseExecuteAntUploadOpts) {
  const { setFieldValue } = useFormikContext();
  const uploadRef = useRef();

  const executeUpload = async (uplFile: UploadFile) => {
    try {
      (uploadRef.current as any)?.onProgress?.({ percent: 0 }, uplFile);
      let newUplFile: UploadFile = uplFile;
      if (!newUplFile.originFileObj) {
        await newFramePromise();
        newUplFile = (uploadRef.current as any)?.fileList.find(
          (f: UploadFile) => f.uid === uplFile.uid
        );
      }
      const res = await upload(newUplFile, {
        onProgress: percent =>
          (uploadRef.current as any)?.onProgress?.({ percent }, uplFile),
        setAbort: abort => {
          const fileList: UploadFile[] = (uploadRef.current as any)?.fileList;
          if (fileList) {
            setFieldValue(
              name,
              fileList.map(f => (f.uid === uplFile.uid ? { ...f, abort } : f))
            );
          }
        },
      });
      (uploadRef.current as any)?.onSuccess?.(res, uplFile);
      return res;
    } catch (e) {
      (uploadRef.current as any)?.onError?.(e, null, uplFile);
      throw e;
    }
  };

  return { uploadRef, executeUpload };
}

function FileUploadButtonInternal(
  {
    value,
    name,
    children,
    multiple,
    maxCount,
    accept,
    className,
    upload,
    maxSize,
  }: FileUploadButtonProps,
  ref: ForwardedRef<FileUploadButtonPropsRef>
) {
  const { setFieldValue } = useFormikContext();
  const { uploadRef, executeUpload } = useExecuteAntUpload({ name, upload });
  const intl = useIntl();

  useImperativeHandle(ref, () => ({
    insertFile: async file => {
      (uploadRef.current as any)?.onBatchStart?.([{ file }]);
      await newFramePromise();
      const uplFile = (uploadRef.current as any)?.fileList.find(
        (f: UploadFile) => f.originFileObj === file
      );
      if (!uplFile) {
        throw new Error('Internal error');
      }
      return executeUpload(uplFile);
    },
  }));

  return (
    <Upload
      ref={uploadRef}
      fileList={value}
      onChange={({ fileList }) => setFieldValue(name, fileList)}
      multiple={multiple}
      maxCount={maxCount}
      accept={accept}
      className={className}
      showUploadList={false}
      beforeUpload={uplFile => {
        requestAnimationFrame(async () => {
          if (accept && !accept.includes(uplFile.type)) {
            message.error(
              intl.formatMessage(
                {
                  id: 'upload.error.invalid-format',
                },
                {
                  fileFormat: uplFile.type,
                }
              )
            );
          } else if (maxSize && maxSize < uplFile.size) {
            message.error(
              intl.formatMessage(
                {
                  id: 'upload.error.file-too-big',
                },
                {
                  size: maxSize / 1048576,
                }
              )
            );
          } else {
            executeUpload(uplFile);
          }
        });
        return false;
      }}
    >
      {children}
    </Upload>
  );
}

export const FileUploadButton = forwardRef(FileUploadButtonInternal);

type FileUploadListProps = FieldInputProps<FileUploadFile[]> & {
  download?: (file: FileUploadFile) => Promise<void>;
  remove?: (file: FileUploadFile) => Promise<void>;
};

export function FileUploadList({
  name,
  value,
  remove,
  download,
}: FileUploadListProps) {
  const { setFieldValue } = useFormikContext();

  const removeFile = async (fileInfo: FileInfo) => {
    const file = find(value, f => f.uid === fileInfo.key);
    if (file) {
      try {
        (file as any)?.abort?.();
        await remove?.(file);
        setFieldValue(
          name,
          value.filter(f => f.uid !== file.uid)
        );
      } catch (e) {
        showErrorMessage(formatError(e));
      }
    }
  };
  const downloadFile = async (fileInfo: FileInfo) => {
    const file = find(value, f => f.uid === fileInfo.key);
    if (file) {
      try {
        await download!(file);
      } catch (e) {
        showErrorMessage(formatError(e));
      }
    }
  };

  return (
    <div className={styles.FileList}>
      <AttachmentsList
        files={value.map(file => ({
          key: file.uid,
          id: file.response?.id,
          contentId: file.response?.contentId,
          contentType: file.type,
          filename: file.name,
          size: file.size,
          uploading: file.status === 'uploading',
          downloadable: file.status === 'done',
          removable: true,
          error: file.error,
        }))}
        remove={removeFile}
        download={download ? downloadFile : undefined}
      />
    </div>
  );
}
