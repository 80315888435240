import { find } from 'lodash-es';

import { MessagingMessageFullFragmentFragment } from '../../types/graphqlGenerated';
import { useCreateFileDownloadLinkMutation } from '../files/filesGraphql';

const CID_REGEX = /cid:(.+)/;

type Files = MessagingMessageFullFragmentFragment['files'];

export function useEmailImageTransformation(files: Files) {
  const [createLink] = useCreateFileDownloadLinkMutation();

  const dataGetter = async (img: HTMLImageElement) => {
    const cidMatch = img.src.match(CID_REGEX);
    if (cidMatch) {
      const cid = cidMatch[1];
      const fileId = find(files, f => f.contentId === cid)?.id;
      if (cid && fileId) {
        const src = await createLink({ id: fileId, triggerDownload: false });
        return { src, cid };
      }
    }
  };

  const transformer = (img: HTMLImageElement, data: any) => {
    if (data) {
      img.setAttribute('src', data.src);
      img.setAttribute('data-cid', data.cid);
    }
  };

  return { dataGetter, transformer };
}
