import { FormattedMessage } from 'react-intl';

import { config } from '../config/config';
import { Whitespace } from './Whitespace';

export default function Version() {
  return (
    <span>
      <FormattedMessage id="labels.version" />:<Whitespace />
      {config.version}
    </span>
  );
}
