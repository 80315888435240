import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { showSuccessMessage } from '../../../common/utils/messageUtils';
import { PrimaryButton } from '../../../components/buttons';
import { CalendarEventFullFragmentFragment } from '../../../types/graphqlGenerated';
import {
  mapFormToGraphqlCalendarEvent,
  mapGraphqlToFormCalendarEvent,
} from '../calendarEventCommon/calendarEventConversions';
import { CalendarEventFormValues } from '../calendarEventCommon/calendarEventTypes';
import { useUpdateCalendarEventMutation } from '../calendarGraphql';
import {
  CalendarEventFormBase,
  CalendarEventFormBaseProps,
} from './CalendarEventFormBase';
import { RecurrenceEditScopeDialog } from './RecurrenceEditScopeDialog';

export type CalendarEventEditFormProps = {
  eventId: string;
  calendarId: number;
  event: CalendarEventFullFragmentFragment;
  onCompleted: (res: { id: string }) => void;
  disableTime?: boolean;
};

export function CalendarEventEditForm({
  eventId,
  calendarId,
  event,
  onCompleted,
}: CalendarEventEditFormProps) {
  const [updateCalendarEvent] = useUpdateCalendarEventMutation({
    onCompleted: res => {
      showSuccessMessage(
        <FormattedMessage id="calendar.dialog.update.success" />
      );
      onCompleted({ id: res?.id });
    },
  });

  const performMutation: CalendarEventFormBaseProps['performMutation'] =
    async values => {
      await updateCalendarEvent({
        id: eventId,
        input: mapFormToGraphqlCalendarEvent({ ...values, calendarId }),
        notifyParticipants: values.sendInvitations,
      });
    };

  const [preparedValues, setPreparedValues] = useState<
    CalendarEventFormValues | undefined
  >(undefined);

  return (
    <>
      <CalendarEventFormBase
        performMutation={performMutation}
        initialValues={mapGraphqlToFormCalendarEvent(event)}
        showCalendarSelect={false}
        renderButtons={({ isSubmitting, values, validateForm }) =>
          event.masterEvent ? (
            <PrimaryButton
              labelId="eventDetail.editForm.update"
              onClick={async () => {
                await validateForm();
                setPreparedValues(values);
              }}
            />
          ) : (
            <PrimaryButton
              htmlType="submit"
              labelId="eventDetail.editForm.update"
              loading={isSubmitting}
              disabled={isSubmitting}
            />
          )
        }
      />
      <RecurrenceEditScopeDialog
        visible={!!preparedValues}
        onClose={() => setPreparedValues(undefined)}
        titleId="calendar.dialog.editRecurrence.editScope.title"
        onConfirm={async val => {
          await updateCalendarEvent({
            id: eventId,
            input: mapFormToGraphqlCalendarEvent({
              ...preparedValues!,
              calendarId,
            }),
            notifyParticipants: preparedValues!.sendInvitations,
            scope: val,
          });
        }}
      />
    </>
  );
}
