import {
  ApolloCache,
  ApolloClient,
  gql,
  useApolloClient,
  useQuery,
} from '@apollo/client';
import { compact, maxBy, throttle } from 'lodash-es';
import { parseDateTime } from 'vifzack-common-utils';

import {
  createUseMutation,
  createUseQuery,
  createUseSubscription,
  deleteQueryFromCache,
  updateQueryInCache,
} from '../../common/utils/graphqlUtils';
import {
  DeleteMessagingThreadMutation,
  DeleteMessagingThreadMutationVariables,
  DeleteMessagingThreadsMutation,
  DeleteMessagingThreadsMutationVariables,
  GetMessagingThreadQuery,
  GetMessagingThreadQueryVariables,
  GetMessagingThreadSimpleQuery,
  GetMessagingThreadSimpleQueryVariables,
  GetMessagingThreadsQuery,
  GetMessagingThreadsQueryVariables,
  MoveMessagingThreadsToFolderMutation,
  MoveMessagingThreadsToFolderMutationVariables,
  MoveMessagingThreadsToSpecialFolderMutation,
  MoveMessagingThreadsToSpecialFolderMutationVariables,
  OnMessagingThreadUpdatedSubscription,
  OnMessagingThreadUpdatedSubscriptionVariables,
  SetMessagingThreadsReadUnreadMutation,
  SetMessagingThreadsReadUnreadMutationVariables,
  UpdateMessagingThreadUserMetadataMutation,
  UpdateMessagingThreadUserMetadataMutationVariables,
} from '../../types/graphqlGenerated';
import {
  ThreadDetailFragment,
  ThreadListFragment,
} from './threadsMessagesGraphql';

const THREADS_QUERY = gql`
  ${ThreadListFragment}
  query GetMessagingThreads(
    $filter: MessagingThreadsFilterInput
    $pagination: PaginationInput
  ) {
    getMessagingThreads(filter: $filter, pagination: $pagination) {
      pagination {
        count
      }
      data {
        ...MessagingThreadListFragment
      }
    }
  }
`;

export function readThreadFromCache(id: number, cache: ApolloCache<any>) {
  return cache.readQuery<
    GetMessagingThreadQuery,
    GetMessagingThreadQueryVariables
  >({
    query: THREAD_DETAIL_QUERY,
    variables: { id },
  })?.getMessagingThread;
}

export function writeThreadToCache(
  id: number,
  thread: GetMessagingThreadQuery['getMessagingThread'],
  cache: ApolloCache<any>
) {
  return cache.writeQuery<
    GetMessagingThreadQuery,
    GetMessagingThreadQueryVariables
  >({
    query: THREAD_DETAIL_QUERY,
    variables: { id },
    data: { getMessagingThread: thread },
  });
}

type GTsD = GetMessagingThreadsQuery['getMessagingThreads'];
type ThreadsData = { threads: GTsD['data']; pagination: GTsD['pagination'] };

type UseThreadsQueryOpts = {
  onCompleted: (data: ThreadsData) => void;
  skip?: boolean;
};

export function useThreadsQuery(
  variables: GetMessagingThreadsQueryVariables,
  options: UseThreadsQueryOpts
) {
  const {
    previousData,
    // keeps previous data while fetching new data
    data = previousData,
    loading,
    error,
    refetch,
  } = useQuery<GetMessagingThreadsQuery, GetMessagingThreadsQueryVariables>(
    THREADS_QUERY,
    {
      variables,
      onCompleted: resp =>
        options?.onCompleted?.({
          threads: resp?.getMessagingThreads.data,
          pagination: resp?.getMessagingThreads.pagination,
        }),
      skip: options.skip,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    }
  );

  return {
    threads: data?.getMessagingThreads.data,
    pagination: data?.getMessagingThreads.pagination,
    loading,
    error,
    refetch,
  };
}

export function useFetchThreads() {
  const client = useApolloClient();
  return async function fetchThreads(
    variables: GetMessagingThreadsQueryVariables
  ) {
    const { data } = await client.query({
      query: THREADS_QUERY,
      variables,
    });
    return {
      threads: data?.getMessagingThreads.data,
      pagination: data?.getMessagingThreads.pagination,
    };
  };
}

const THREAD_DETAIL_QUERY = gql`
  ${ThreadDetailFragment}
  query GetMessagingThread(
    $id: Int!
    $cursor: CursorInput
    $subset: MessagingThreadMessagesSubsetInput
  ) {
    getMessagingThread(id: $id) {
      ...MessagingThreadDetailFragment
    }
  }
`;

export const useThreadDetailQuery = createUseQuery<
  GetMessagingThreadQuery,
  GetMessagingThreadQueryVariables,
  'thread',
  GetMessagingThreadQuery['getMessagingThread']
>(THREAD_DETAIL_QUERY, {
  extractResult: resp => resp.getMessagingThread,
  resultName: 'thread',
  opts: {
    fetchPolicy: 'cache-and-network',
  },
});

const THREAD_SIMPLE_QUERY = gql`
  query GetMessagingThreadSimple($id: Int!) {
    getMessagingThread(id: $id) {
      id
      unread
    }
  }
`;

export const useThreadSimpleQuery = createUseQuery<
  GetMessagingThreadSimpleQuery,
  GetMessagingThreadSimpleQueryVariables,
  'thread',
  GetMessagingThreadSimpleQuery['getMessagingThread']
>(THREAD_SIMPLE_QUERY, {
  extractResult: resp => resp.getMessagingThread,
  resultName: 'thread',
  opts: {
    fetchPolicy: 'cache-and-network',
  },
});

export function useFetchThreadDetail() {
  const client = useApolloClient();
  return async function fetchThreadDetail(id: number) {
    const { data } = await client.query({
      query: THREAD_DETAIL_QUERY,
      variables: { id },
    });
    return data?.getMessagingThread;
  };
}

const ON_THREAD_UPDATED_SUBSCRIPTION = gql`
  subscription OnMessagingThreadUpdated {
    onMessagingThreadUpdated {
      id
    }
  }
`;

type ReloadThreadsRes =
  OnMessagingThreadUpdatedSubscription['onMessagingThreadUpdated'];
const RELOAD_THREADS_THROTTLE_MS = 1000;
function reloadThreadsAfterSubscription(
  res: ReloadThreadsRes,
  client: ApolloClient<any>
) {
  client.refetchQueries({
    include: ['GetMessagingFoldersStats', 'GetMessagingThreads'],
  });

  // Reload the thread directly, but only load the new messages - cache will handle the merge
  const thread = readThreadFromCache(res.id, client.cache);
  const newestId = maxBy(thread?.messages.data, msg =>
    msg.date ? parseDateTime(msg.date) : 0
  )?.id;
  client.query({
    query: THREAD_DETAIL_QUERY,
    variables: {
      id: res.id,
      cursor: {
        beforeId: newestId,
        size: 9999, // We want to load all updates
      },
    },
    fetchPolicy: 'network-only',
  });
}
// throttle reload as during an account sync every new message triggers it
const reloadThreadsAfterSubscriptionThrottled = throttle(
  reloadThreadsAfterSubscription,
  RELOAD_THREADS_THROTTLE_MS,
  { leading: true, trailing: true }
);

export const useOnThreadUpdatedSubscription = createUseSubscription<
  OnMessagingThreadUpdatedSubscription,
  OnMessagingThreadUpdatedSubscriptionVariables,
  OnMessagingThreadUpdatedSubscription['onMessagingThreadUpdated']
>(ON_THREAD_UPDATED_SUBSCRIPTION, {
  extractResult: resp => resp.onMessagingThreadUpdated,
  onNext: reloadThreadsAfterSubscriptionThrottled,
});

const SET_THREADS_READ_UNREAD_MUTATION = gql`
  mutation SetMessagingThreadsReadUnread(
    $filter: MessagingThreadsFilterInput!
    $unread: Boolean!
  ) {
    setMessagingThreadsReadUnread(filter: $filter, unread: $unread)
  }
`;

export const useSetThreadsReadUnreadMutation = createUseMutation<
  SetMessagingThreadsReadUnreadMutation,
  SetMessagingThreadsReadUnreadMutationVariables,
  SetMessagingThreadsReadUnreadMutation['setMessagingThreadsReadUnread']
>(SET_THREADS_READ_UNREAD_MUTATION, {
  extractResult: resp => resp.setMessagingThreadsReadUnread,
  refetchQueries: ['GetMessagingFoldersStats', 'GetMessagingThreads'],
  onCompleted: (res, { filter, unread }, client) => {
    const idObject = filter.id;
    if (res && idObject) {
      const modifiedIds = compact([filter.id?.eq, ...(filter.id?.in || [])]);

      for (const id of modifiedIds) {
        client.cache.modify({
          id: `MessagingThread:${id}`,
          fields: {
            unread: () => unread,
          },
        });
      }
    }
  },
});

const UPDATE_THREAD_USER_METADATA_MUTATION = gql`
  mutation UpdateMessagingThreadUserMetadata(
    $threadId: Int!
    $input: MessagingThreadUserMetadataInput!
  ) {
    updateMessagingThreadUserMetadata(threadId: $threadId, input: $input) {
      id
      userMetadata {
        name
      }
    }
  }
`;

export const useUpdateThreadUserMetadataMutation = createUseMutation<
  UpdateMessagingThreadUserMetadataMutation,
  UpdateMessagingThreadUserMetadataMutationVariables,
  UpdateMessagingThreadUserMetadataMutation['updateMessagingThreadUserMetadata']
>(UPDATE_THREAD_USER_METADATA_MUTATION, {
  extractResult: resp => resp.updateMessagingThreadUserMetadata,
});

const MOVE_THREADS_TO_FOLDER_MUTATION = gql`
  mutation MoveMessagingThreadsToFolder(
    $filter: MessagingThreadsFilterInput!
    $folderId: Int!
  ) {
    moveMessagingThreadsToFolder(filter: $filter, folderId: $folderId)
  }
`;

export const useMoveThreadsToFolderMutation = createUseMutation<
  MoveMessagingThreadsToFolderMutation,
  MoveMessagingThreadsToFolderMutationVariables,
  MoveMessagingThreadsToFolderMutation['moveMessagingThreadsToFolder']
>(MOVE_THREADS_TO_FOLDER_MUTATION, {
  extractResult: resp => resp.moveMessagingThreadsToFolder,
  awaitRefetchQueries: true,
  refetchQueries: ['GetMessagingFoldersStats', 'GetMessagingThreads'],
});

const MOVE_THREADS_TO_SPECIAL_FOLDER_MUTATION = gql`
  mutation MoveMessagingThreadsToSpecialFolder(
    $filter: MessagingThreadsFilterInput!
    $folderType: MessagingFolderType!
  ) {
    moveMessagingThreadsToSpecialFolder(
      filter: $filter
      folderType: $folderType
    )
  }
`;

export const useMoveThreadsToSpecialFolderMutation = createUseMutation<
  MoveMessagingThreadsToSpecialFolderMutation,
  MoveMessagingThreadsToSpecialFolderMutationVariables,
  MoveMessagingThreadsToSpecialFolderMutation['moveMessagingThreadsToSpecialFolder']
>(MOVE_THREADS_TO_SPECIAL_FOLDER_MUTATION, {
  extractResult: resp => resp.moveMessagingThreadsToSpecialFolder,
  refetchQueries: ['GetMessagingFoldersStats', 'GetMessagingThreads'],
});

const DELETE_THREAD_MUTATION = gql`
  mutation DeleteMessagingThread($id: Int!) {
    deleteMessagingThread(id: $id)
  }
`;

export const useDeleteThreadMutation = createUseMutation<
  DeleteMessagingThreadMutation,
  DeleteMessagingThreadMutationVariables,
  DeleteMessagingThreadMutation['deleteMessagingThread']
>(DELETE_THREAD_MUTATION, {
  extractResult: resp => resp.deleteMessagingThread,
  refetchQueries: ['GetMessagingFoldersStats'],
  onCompleted: (_res, variables, client) => {
    updateQueryInCache<
      GetMessagingThreadsQuery['getMessagingThreads'],
      GetMessagingThreadsQueryVariables
    >(client.cache, 'getMessagingThreads', ({ value, readField }) => ({
      ...value,
      data: value.data.filter(
        thread => readField('id', thread) !== variables.id
      ),
    }));
    client.refetchQueries({
      include: ['GetMessagingThreads', 'GetMessagingFoldersStats'],
    });
  },
});

const DELETE_THREADS_MUTATION = gql`
  mutation DeleteMessagingThreads($filter: MessagingThreadsFilterInput!) {
    deleteMessagingThreads(filter: $filter)
  }
`;

export const useDeleteThreadsMutation = createUseMutation<
  DeleteMessagingThreadsMutation,
  DeleteMessagingThreadsMutationVariables,
  DeleteMessagingThreadsMutation['deleteMessagingThreads']
>(DELETE_THREADS_MUTATION, {
  extractResult: resp => resp.deleteMessagingThreads,
  refetchQueries: ['GetMessagingFoldersStats'],
  onCompleted: (res, _, client) => {
    if (res) {
      deleteQueryFromCache<GetMessagingThreadsQueryVariables>(
        client.cache,
        'getMessagingThreads',
        { type: 'DELETE' }
      );
    }
  },
});
