import fcDe from '@fullcalendar/core/locales/de';
import fcEnGb from '@fullcalendar/core/locales/en-gb';
import antDeDe from 'antd/lib/locale-provider/de_DE';
import antEnGb from 'antd/lib/locale-provider/en_GB';
import antEnUs from 'antd/lib/locale-provider/en_US';

import appDe from './de.json';
import appEn from './en.json';
import rruleDe from './rruleDe';
import yupDe from './yup-de.json';
import yupEn from './yup-en.json';

const fcEnUs = undefined; // en-us is missing in core module but it is a default module

const rruleEn = undefined; // applied by default

// Apply patches
if (antDeDe.DatePicker?.lang.locale) {
  antDeDe.DatePicker.lang.locale = 'de'; // date-fns use 'de'
}

export enum Locale {
  EnUs = 'en-US',
  EnGb = 'en-GB',
  DeDe = 'de-DE',
}

const LocaleData = {
  [Locale.EnUs]: {
    locale: 'en-US',
    language: 'en',
    antLocale: antEnUs,
    yupLocale: yupEn,
    fcLocale: fcEnUs,
    messages: appEn,
    label: 'EN (US)',
    weekInfo: { firstDay: 0 }, // `Intl.Locale.weekInfo` is not yet widely supported
    rruleLocale: rruleEn,
  },
  [Locale.EnGb]: {
    locale: 'en-GB',
    language: 'en',
    antLocale: antEnGb,
    yupLocale: yupEn,
    fcLocale: fcEnGb,
    messages: appEn,
    label: 'EN (GB)',
    weekInfo: { firstDay: 1 },
    rruleLocale: rruleEn,
  },
  [Locale.DeDe]: {
    locale: 'de-DE',
    language: 'de',
    antLocale: antDeDe,
    yupLocale: yupDe,
    fcLocale: fcDe,
    messages: appDe,
    label: 'DE (DE)',
    weekInfo: { firstDay: 1 },
    rruleLocale: rruleDe,
  },
};

export function getLocaleData(locale: Locale) {
  return LocaleData[locale];
}
