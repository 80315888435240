export function blobToDataUrl(blob: File | Blob): Promise<string> {
  const fileReader = new FileReader();
  return new Promise((resolve, reject) => {
    fileReader.readAsDataURL(blob);
    fileReader.onload = () => resolve(fileReader.result as string);
    fileReader.onerror = () => reject();
  });
}

type SourceCoords = {
  left: number;
  top: number;
  width: number;
  height: number;
};

type DestCoords = {
  left?: number;
  top?: number;
  width: number;
  height: number;
};

export function getCroppedCanvas(
  source: CanvasImageSource,
  sourceCoords: SourceCoords,
  destCoords: DestCoords
) {
  const canvas = document.createElement('canvas');
  canvas.width = destCoords.width;
  canvas.height = destCoords.height;
  canvas
    .getContext('2d')!
    .drawImage(
      source,
      sourceCoords.left,
      sourceCoords.top,
      sourceCoords.width,
      sourceCoords.height,
      destCoords.left ?? 0,
      destCoords.top ?? 0,
      destCoords.width,
      destCoords.height
    );
  return canvas;
}
