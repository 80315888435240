import { range } from 'lodash-es';
import { useSelector } from 'react-redux';

import { getLocaleData } from '../../config/intl/intl';
import { getLocale } from '../../modules/settings/settingsReducer';

/**
 * @param firstDayOfWeek 0-6, where 1 is Monday and 0 is Sunday
 * @returns array of sorted day indices (0-6), where 1 is Monday and 0 is Sunday
 */
export function getSortedWeekdays(firstDayOfWeek: number) {
  return range(firstDayOfWeek, firstDayOfWeek + 7).map(i => i % 7);
}

export function useLocaleSortedWeekdays() {
  const locale = useSelector(getLocale);
  const localeData = getLocaleData(locale);
  return getSortedWeekdays(localeData.weekInfo.firstDay);
}

export const getBrowserTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export function toDateOptional(dateStr: string | null | undefined) {
  return dateStr ? new Date(dateStr) : undefined;
}
