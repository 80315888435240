import styles from './InfiniteProgressBar.module.less';

export default function InfiniteProgressBar({ loading = true }) {
  return (
    <div
      className={styles.ProgressBar}
      style={{ visibility: loading ? 'visible' : 'hidden' }}
    >
      <div className={styles.ProgressBarValue} />
    </div>
  );
}
