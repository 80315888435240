import { isEmpty, sortBy } from 'lodash-es';
import { ReactNode, useCallback, useLayoutEffect, useRef } from 'react';

function replaceHtmlNode(node: Node, newNodes: Node[]) {
  const parentNode = node.parentNode!;
  for (let i = newNodes.length - 1; i >= 0; i--) {
    const newNode = newNodes[i];
    if (i === newNodes.length - 1) {
      parentNode.insertBefore(newNode, node);
      parentNode.removeChild(node);
    } else {
      parentNode.insertBefore(newNode, newNodes[i + 1]);
    }
  }
}

function walkHtml(node: Node, visitor: (node: Node) => void) {
  [...node.childNodes].forEach(child => walkHtml(child, visitor));
  visitor(node);
}

const HIGHLIGHT_ELEMENT = 'mark';
const HIGHLIGHT_CLASS = 'SearchHighlight';

function buildNewNodes(remainingText: string, regExp: RegExp, acc: Node[]) {
  if (isEmpty(remainingText)) {
    return acc;
  }
  // This is necessary to reset RegExp state machine
  const match = new RegExp(regExp.source, regExp.flags).exec(remainingText);
  if (!match) {
    acc.push(document.createTextNode(remainingText));
    return acc;
  }
  if (match.index > 0) {
    acc.push(document.createTextNode(remainingText.slice(0, match.index)));
  }
  const highlight = document.createElement(HIGHLIGHT_ELEMENT);
  highlight.className = HIGHLIGHT_CLASS;
  highlight.textContent = match[0];
  acc.push(highlight);

  return buildNewNodes(
    remainingText.slice(match.index + match[0].length),
    regExp,
    acc
  );
}

function textNodeToHighlightedNodes(node: Node, regExp: RegExp) {
  if (node.nodeName !== '#text') {
    return;
  }
  if (node.parentElement?.className === HIGHLIGHT_CLASS) {
    return;
  }

  if (!regExp.test(node.textContent || '')) {
    return;
  }

  const newNodes = buildNewNodes(node.textContent || '', regExp, []);
  replaceHtmlNode(node, newNodes);
}

type UseHighlightTransformerOpts = {
  enabled?: boolean;
  searchTerm?: string | null;
};

export function useHighlightTransformer({
  enabled = true,
  searchTerm,
}: UseHighlightTransformerOpts) {
  const subterms = sortBy(
    (searchTerm || '').split(
      // Keep letters, numbers and diacritics (https://stackoverflow.com/a/30225759)
      /[^0-9A-zÀ-ÖØ-öø-įĴ-őŔ-žǍ-ǰǴ-ǵǸ-țȞ-ȟȤ-ȳɃɆ-ɏḀ-ẞƀ-ƓƗ-ƚƝ-ơƤ-ƥƫ-ưƲ-ƶẠ-ỿ]+/g
    ),
    term => -term.length
  );

  return useCallback(
    (topNode: Node) => {
      if (!enabled || isEmpty(searchTerm)) {
        return;
      }
      return walkHtml(topNode, node =>
        textNodeToHighlightedNodes(node, new RegExp(subterms.join('|'), 'ig'))
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [enabled, searchTerm]
  );
}

type HighlighterProps = {
  searchTerm?: string | null;
  enabled?: boolean;
  children?: ReactNode;
  component?: any;
};

export default function Highlighter({
  children,
  searchTerm,
  enabled,
  component: C = 'div',
}: HighlighterProps) {
  const ref = useRef<HTMLElement>();
  const addHighlightMarks = useHighlightTransformer({ searchTerm, enabled });

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }
    if (!enabled || isEmpty(searchTerm)) {
      return undefined;
    }
    return walkHtml(ref.current, addHighlightMarks);
  }, [addHighlightMarks, enabled, searchTerm]);

  return <C ref={ref}>{children}</C>;
}
