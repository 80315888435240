import DOMPurify from 'dompurify';

import { useAsync } from './hookUtils';

export const SanitizeTransformers = {
  linkInNewWindow: (node: Element) => {
    // https://github.com/cure53/DOMPurify/tree/main/demos#hook-to-open-all-links-in-a-new-window-link
    if ('target' in node) {
      node.setAttribute('target', '_blank');
    }
    if (
      !node.hasAttribute('target') &&
      (node.hasAttribute('xlink:href') || node.hasAttribute('href'))
    ) {
      node.setAttribute('xlink:show', 'new');
    }
  },
};

type Transformer<EL extends Element> = (el: EL, data?: any) => void;
type DataGetter<EL extends Element> = (el: EL) => Promise<any>;

type SanitizeContentOpts = {
  transformers?: {
    image?: Transformer<HTMLImageElement>;
    body?: Transformer<HTMLBodyElement>;
    any?: Transformer<Element>[];
  };
  dataGetters?: {
    image?: DataGetter<HTMLImageElement>;
  };
};

export async function sanitizeContent(
  html: string,
  opts?: SanitizeContentOpts
) {
  const promises: Promise<any>[] = [];

  // This step is just to gather asynchronous data
  // DOMPurify doesn't support async hooks, so it has to be done in 2 steps
  DOMPurify.addHook('afterSanitizeAttributes', node => {
    if (node instanceof HTMLImageElement && opts?.dataGetters?.image) {
      const index = promises.length;
      promises.push(opts.dataGetters.image(node));
      node.setAttribute('data-promise-id', `${index}`);
    }
  });
  const htmlWithDataReferences = DOMPurify.sanitize(html);
  DOMPurify.removeHook('afterSanitizeAttributes');

  const promiseResults = await Promise.all(promises);

  DOMPurify.addHook('afterSanitizeAttributes', node => {
    opts?.transformers?.any?.forEach(transformer => {
      transformer(node);
    });
    if (node instanceof HTMLBodyElement) {
      opts?.transformers?.body?.(node);
    }
    if (node instanceof HTMLImageElement) {
      const promiseId = parseInt(
        node.getAttribute('data-promise-id') || '',
        10
      );
      const nodeData = !isNaN(promiseId)
        ? promiseResults[promiseId]
        : undefined;

      opts?.transformers?.image?.(node, nodeData);
      node.removeAttribute('data-promise-id');
    }
  });
  const result = DOMPurify.sanitize(htmlWithDataReferences);
  DOMPurify.removeHook('afterSanitizeAttributes');

  return result;
}

export function useSanitizedContent(html: string, opts?: SanitizeContentOpts) {
  return useAsync(() => sanitizeContent(html, opts));
}
