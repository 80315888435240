import { PlusCircleOutlined } from '@ant-design/icons';

import RouteDefinitions from '../../../app/routes';
import { PrimaryButton } from '../../../components/buttons';
import { AppSidebar } from '../../layout/AppSidebar';
import { useContactsNavigation } from '../contactsNavigation';

export function ContactsAppSidebar() {
  const { navigate } = useContactsNavigation();

  return (
    <AppSidebar>
      <PrimaryButton
        labelId="labels.create"
        icon={<PlusCircleOutlined />}
        onClick={() => navigate(RouteDefinitions.createContact)}
      />
    </AppSidebar>
  );
}
