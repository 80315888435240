import {
  Form as AntForm,
  Button,
  CheckboxOptionType,
  Col,
  Row,
  Typography,
} from 'antd';
import { differenceInMonths, isLastDayOfMonth, startOfMonth } from 'date-fns';
import { FieldInputProps, useFormikContext } from 'formik';
import { compact, isEmpty, range, some } from 'lodash-es';
import { useCallback } from 'react';
import { FormattedMessage, FormattedTime, useIntl } from 'react-intl';

import { useLocaleSortedWeekdays } from '../../../common/utils/dateUtils';
import { Whitespace } from '../../../components/Whitespace';
import {
  FormattedMonth,
  FormattedWeekday,
  NUM_MONTHS,
  NUM_WEEKDAYS,
} from '../../../components/dateFormats';
import FormField from '../../../components/forms/FormField';
import { BasicSwitch } from '../../../components/forms/booleanFields';
import { DatePicker, MonthPicker } from '../../../components/forms/dateFields';
import { NumberInput } from '../../../components/forms/numericFields';
import { RadioGroup } from '../../../components/forms/radioFields';
import { BasicSelect } from '../../../components/forms/selects';
import {
  CalendarEventFormValues,
  RecurrenceEndType,
  RecurrenceFormValues,
  RecurrenceFrequency,
} from '../calendarEventCommon/calendarEventTypes';
import style from './RecurrenceField.module.less';
import { RECURRENCE_FREQUENCY_OPTIONS } from './recurrenceConstants';

type RecurrenceFieldProps = {
  dateFrom?: Date;
  dateTo?: Date;
} & FieldInputProps<RecurrenceFormValues> & { disabled?: boolean };

export function RecurrenceField({
  name: namespace,
  dateFrom,
  dateTo,
  value,
}: RecurrenceFieldProps) {
  const makeName = useCallback(
    (name: string) => `${namespace}.${name}`,
    [namespace]
  );
  const { setFieldValue, values } = useFormikContext<CalendarEventFormValues>();
  const intl = useIntl();

  const intervalLabelPostfix =
    (value.frequencyInterval ?? 0) > 1 ? 'plural' : 'singular';
  const intervalLabel = `calendar.dialog.recurrence.${value.frequencyType!}.${intervalLabelPostfix}`;

  const weeklyValidation = () => {
    if (isEmpty(value.selectedWeekDays)) {
      return intl.formatMessage({ id: 'calendar.dialog.warning.atLeastOne' });
    }
  };

  const yearlyValidation = () => {
    if (isEmpty(value.selectedMonths)) {
      return intl.formatMessage({ id: 'calendar.dialog.warning.atLeastOne' });
    }
  };

  const validateEndType = () => {
    const { endType } = value;
    if (RecurrenceEndType.UNTIL === endType) {
      if (!value.lastOccurenceDate) {
        return intl.formatMessage({ id: 'error.required' });
      }
      const isLastOccuranceDateValid = !isInappropriateDate(
        value.lastOccurenceDate
      );
      if (!isLastOccuranceDateValid) {
        return intl.formatMessage({
          id: 'calendar.dialog.warning.invalidLastOccurenceDate',
        });
      }
    }
  };

  const monthDay = dateFrom?.getDate();
  const weekDay = dateFrom?.getDay();
  const monthlyFrequencyOptions: CheckboxOptionType[] = compact([
    {
      label: (
        <>
          {monthDay}.<Whitespace />
          <FormattedMessage id="calendar.dialog.ofTheMonth" />
        </>
      ),
      value: monthDay!,
    },
    dateFrom &&
      isLastDayOfMonth(dateFrom) && {
        label: <FormattedMessage id="calendar.dialog.lastMonthDay" />,
        value: -1,
      },
  ]);

  const isInappropriateDate = (date: Date): boolean => {
    const { frequencyType } = value;
    const dateTimeFrom = dateFrom!;
    switch (frequencyType) {
      case RecurrenceFrequency.DAILY:
        return date < dateTimeFrom;
      case RecurrenceFrequency.WEEKLY:
        return (
          date < dateTimeFrom ||
          !value.selectedWeekDays?.includes(date.getDay())
        );
      case RecurrenceFrequency.MONTHLY:
        return (
          date < dateTimeFrom ||
          differenceInMonths(startOfMonth(date), startOfMonth(dateTimeFrom)) %
            value.frequencyInterval! !==
            0
        );
      case RecurrenceFrequency.YEARLY:
        return (
          date < dateTimeFrom ||
          !value.selectedMonths?.includes(date.getMonth()) ||
          date.getFullYear() % value.frequencyInterval! !== 0
        );
      default:
        return true;
    }
  };

  const isMonthlyFrequency =
    RecurrenceFrequency.MONTHLY === value.frequencyType;
  const isYearlyFrequency = RecurrenceFrequency.YEARLY === value.frequencyType;
  const isDailyFrequency = RecurrenceFrequency.DAILY === value.frequencyType;
  const isWeeklyFrequency = RecurrenceFrequency.WEEKLY === value.frequencyType;

  return (
    <>
      <FormField
        name={makeName('enabled')}
        labelId="calendar.dialog.recurrentEvent"
      >
        {({ field, label }) => (
          <AntForm.Item label={label}>
            <BasicSwitch
              {...field}
              disabled={!some([dateFrom, dateTo], v => !!v) || field.disabled}
            />
          </AntForm.Item>
        )}
      </FormField>

      {value.enabled && (
        <div>
          <div className={style.RecurrentEventContainer}>
            <FormField
              name={makeName('frequencyType')}
              labelId="calendar.dialog.frequencyType"
            >
              {({ field }) => (
                <AntForm.Item>
                  <div className={style.EventShouldRepeatTextContainer}>
                    <FormattedMessage id="calendar.dialog.repeated" />
                    <BasicSelect
                      options={RECURRENCE_FREQUENCY_OPTIONS}
                      bordered={false}
                      {...field}
                      onSelect={(newValue: RecurrenceFrequency) => {
                        if (newValue === RecurrenceFrequency.DAILY) {
                          setFieldValue(
                            makeName('selectedWeekDays'),
                            range(0, NUM_WEEKDAYS)
                          );
                        } else if (newValue === RecurrenceFrequency.WEEKLY) {
                          setFieldValue(makeName('selectedWeekDays'), [
                            weekDay,
                          ]);
                        } else if (newValue === RecurrenceFrequency.MONTHLY) {
                          setFieldValue(makeName('selectedMonthDay'), monthDay);
                        } else if (newValue === RecurrenceFrequency.YEARLY) {
                          setFieldValue(makeName('selectedMonths'), [
                            dateFrom?.getMonth(),
                          ]);
                        }
                      }}
                      className={style.FrequencyOptionsSelect}
                    />
                    {!values.allDay && (
                      <>
                        <FormattedMessage id="calendar.dialog.from" />
                        <Typography>
                          <FormattedTime value={dateFrom} second={undefined} />
                        </Typography>
                        <FormattedMessage id="calendar.dialog.to" />
                        <Typography>
                          <FormattedTime value={dateTo} second={undefined} />
                        </Typography>
                      </>
                    )}
                  </div>
                </AntForm.Item>
              )}
            </FormField>
            {!isDailyFrequency && (
              <FormField
                name={makeName('frequencyInterval')}
                labelId="calendar.dialog.frequencyInterval"
              >
                {({ field }) => (
                  <AntForm.Item>
                    <div className={style.FrequencyTypeContainer}>
                      <FormattedMessage id="calendar.dialog.every" />
                      <NumberInput
                        className={style.NumberInput}
                        min={1}
                        bordered={false}
                        {...field}
                      />
                      <Typography>
                        <FormattedMessage id={intervalLabel} />
                      </Typography>
                    </div>
                  </AntForm.Item>
                )}
              </FormField>
            )}

            {isMonthlyFrequency && (
              <MonthlyFrequency
                makeName={makeName}
                monthlyFrequencyOptions={monthlyFrequencyOptions}
              />
            )}
          </div>
          {(isWeeklyFrequency || isDailyFrequency) && (
            <DailyOrWeeklyFrequency
              makeName={makeName}
              weeklyValidation={weeklyValidation}
              isDailyFrequency={isDailyFrequency}
            />
          )}
          {isYearlyFrequency && (
            <YearlyFrequency
              makeName={makeName}
              yearlyValidation={yearlyValidation}
              dateFrom={dateFrom}
            />
          )}
          <FormField
            name={makeName('isForever')}
            labelId="calendar.dialog.forever"
          >
            {({ field, label }) => (
              <AntForm.Item label={label} className="FullWidth">
                <BasicSwitch
                  {...field}
                  disabled={
                    (isEmpty(value.selectedWeekDays) &&
                      value.frequencyType! === RecurrenceFrequency.WEEKLY) ||
                    field.disabled
                  }
                />
              </AntForm.Item>
            )}
          </FormField>
          {!value.isForever && (
            <div className="FlexCol">
              <FormField name={makeName('endType')}>
                {({ field }) => (
                  <AntForm.Item>
                    <RadioGroup
                      vertical
                      options={[
                        {
                          label: (
                            <div className={style.NumberOfOccurencesContainer}>
                              <FormattedMessage id="calendar.dialog.numberOfOccurances" />
                              <FormField name={makeName('count')}>
                                {({ field: subField }) => (
                                  <NumberInput
                                    className={style.NumberInput}
                                    min={1}
                                    bordered
                                    {...subField}
                                    disabled={
                                      value.endType ===
                                        RecurrenceEndType.UNTIL ||
                                      subField.disabled
                                    }
                                  />
                                )}
                              </FormField>
                            </div>
                          ),
                          value: RecurrenceEndType.COUNT,
                        },
                        {
                          label: (
                            <div className={style.LastOccurenceData}>
                              <FormattedMessage id="calendar.dialog.lastOccurenceDate" />
                              <FormField
                                name={makeName('lastOccurenceDate')}
                                validate={validateEndType}
                              >
                                {({ field: subField }) =>
                                  isMonthlyFrequency || isYearlyFrequency ? (
                                    <MonthPicker
                                      disabledDate={date =>
                                        isInappropriateDate(date)
                                      }
                                      {...subField}
                                      disabled={
                                        value.endType ===
                                        RecurrenceEndType.COUNT
                                      }
                                    />
                                  ) : (
                                    <DatePicker
                                      disabledDate={date =>
                                        isInappropriateDate(date)
                                      }
                                      {...subField}
                                      disabled={
                                        value.endType ===
                                        RecurrenceEndType.COUNT
                                      }
                                    />
                                  )
                                }
                              </FormField>
                            </div>
                          ),
                          value: RecurrenceEndType.UNTIL,
                        },
                      ]}
                      {...field}
                    />
                  </AntForm.Item>
                )}
              </FormField>
            </div>
          )}
        </div>
      )}
    </>
  );
}

const DailyOrWeeklyFrequency = ({
  makeName,
  weeklyValidation,
  isDailyFrequency,
}) => {
  const { setFieldValue } = useFormikContext();
  const weekdays = useLocaleSortedWeekdays();

  return (
    <FormField name={makeName('selectedWeekDays')} validate={weeklyValidation}>
      {({ field }) => (
        <AntForm.Item>
          <MultipleItemPicker
            options={weekdays}
            renderOption={weekday => (
              <FormattedWeekday weekday={weekday} type="narrow" />
            )}
            buttonClassName={style.SelectedDays}
            onOptionClick={() => {
              if (isDailyFrequency) {
                setFieldValue(
                  makeName('frequencyType'),
                  RecurrenceFrequency.WEEKLY
                );
              }
            }}
            {...field}
          />
        </AntForm.Item>
      )}
    </FormField>
  );
};

const MonthlyFrequency = ({ makeName, monthlyFrequencyOptions }) => (
  <FormField name={makeName('selectedMonthDay')}>
    {({ field }) => (
      <AntForm.Item>
        <RadioGroup
          options={monthlyFrequencyOptions}
          className="FlexCol"
          {...field}
          disabled={field.disabled}
        />
      </AntForm.Item>
    )}
  </FormField>
);

const YearlyFrequency = ({ makeName, yearlyValidation, dateFrom }) => (
  <FormField name={makeName('selectedMonths')} validate={yearlyValidation}>
    {({ field }) => (
      <AntForm.Item>
        <MultipleItemPicker
          options={range(0, NUM_MONTHS)}
          colSpan={4}
          renderOption={month => <FormattedMonth month={month} type="short" />}
          disabledValues={[dateFrom?.getMonth()]}
          buttonClassName={style.SelectedMonths}
          {...field}
        />
      </AntForm.Item>
    )}
  </FormField>
);

function togglePickerValue(arr, value) {
  return arr?.includes(value)
    ? arr.filter(v => v !== value)
    : [...(arr || []), value];
}

type MultipleItemPickerProps = {
  name: string;
  options: string[] | number[];
  value: string;
  renderOption: (val: any) => React.ReactNode;
  disabled?: boolean;
  buttonClassName?: string;
  onOptionClick?: () => void;
  disabledValues?: any[];
  colSpan?: number;
};

function MultipleItemPicker({
  name,
  options,
  disabled,
  value,
  buttonClassName,
  renderOption,
  onOptionClick,
  colSpan = 3,
}: MultipleItemPickerProps) {
  const { setFieldValue } = useFormikContext();
  return (
    <AntForm.Item className="Flex">
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {options.map(option => (
          <Col span={colSpan} key={option}>
            <Button
              onClick={() => {
                setFieldValue(name, togglePickerValue(value, option));
                onOptionClick?.();
              }}
              className={buttonClassName}
              type={value?.includes(option) ? 'primary' : 'default'}
              disabled={disabled}
            >
              {renderOption(option)}
            </Button>
          </Col>
        ))}
      </Row>
    </AntForm.Item>
  );
}
