import {
  ApolloError,
  ServerError,
  ServerParseError,
  isApolloError,
} from '@apollo/client';
import { NetworkError } from '@apollo/client/errors';
import { GraphQLError } from 'graphql';
import { find } from 'lodash-es';
import { IntlShape } from 'react-intl';

type ErrorFormatOptions = {
  intl?: IntlShape;
};
type ExtError = Error & {
  code?: string;
};

interface Extensions {
  extensions: ExtError;
}

interface Result {
  result?: {
    errors: [Extensions];
  };
}

const ErrorCodes = {
  APPLICATION_ERROR: 'APPLICATION_ERROR',
};

type RichNetworkError = (ServerError | ServerParseError) & Result;
function isRichNetworkError(e: NetworkError): e is RichNetworkError {
  return (e as any).response && (e as any).statusCode;
}

function formatNetworkError(e: RichNetworkError, { intl }: ErrorFormatOptions) {
  if (e.result?.errors?.[0].extensions?.code && intl) {
    return intl.formatMessage({
      id: e.result.errors[0].extensions.code,
      defaultMessage: intl.formatMessage({
        id: 'error.network',
      }),
    });
  }
  if (intl && e.response) {
    return `${intl.formatMessage({ id: 'error.network' })} ${e.statusCode}: ${
      e.response.statusText
    }`;
  }
  return e.message;
}

export function extractNetworkErrorStatusCode(e?: NetworkError) {
  if (e && isRichNetworkError(e)) {
    return e.statusCode;
  }
  return null;
}

function formatGraphqlError(e: GraphQLError, { intl }: ErrorFormatOptions) {
  if (e.extensions?.code && intl) {
    return intl.formatMessage({
      id: e.extensions.code as string,
      defaultMessage: e.message,
    });
  }
  return e.message;
}

function formatApolloError(e: ApolloError, { intl }: ErrorFormatOptions) {
  if (e.networkError) {
    if (isRichNetworkError(e.networkError)) {
      return formatNetworkError(e.networkError, { intl });
    } else {
      return formatStandardError(e.networkError, { intl });
    }
  }
  if (e.graphQLErrors) {
    return e.graphQLErrors.map(subError =>
      formatGraphqlError(subError, { intl })
    );
  }
  if (e.clientErrors) {
    return e.clientErrors
      .map(subError => formatStandardError(subError, { intl }))
      .join(', ');
  }
}

function formatStandardError(e: ExtError, { intl }: ErrorFormatOptions) {
  if (e.code === ErrorCodes.APPLICATION_ERROR) {
    // application errors are not translated
    return e.message;
  }
  if (intl && e.code) {
    return intl.formatMessage({ id: e.code, defaultMessage: e.message });
  }
  return e.message;
}

export function formatError(e: Error, { intl }: ErrorFormatOptions = {}) {
  if (isApolloError(e)) {
    return formatApolloError(e, { intl });
  }
  if (e instanceof Error) {
    return formatStandardError(e, { intl });
  }
  return 'Error';
}

export function extractErrorCode(e: Error) {
  if (isApolloError(e)) {
    if (e.graphQLErrors) {
      return find(e.graphQLErrors.map(subError => subError.extensions.code));
    }
    return undefined;
  }
  if (e instanceof Error) {
    return (e as any)?.code;
  }
  return undefined;
}
