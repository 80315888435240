import { Form as AntForm, Button, Input } from 'antd';
import { Form, Formik } from 'formik';
import { formatError } from 'graphql';
import { some, stubString } from 'lodash-es';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router';

import RouteDefinitions from '../../../app/routes';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../common/utils/messageUtils';
import { UploadFunction } from '../../../components/forms/FileUpload';
import FormField from '../../../components/forms/FormField';
import FormFieldArray from '../../../components/forms/FormFieldArray';
import FormItem from '../../../components/forms/FormItem';
import { ImageUpload } from '../../../components/forms/ImageUpload';
import { AccountSelect } from '../../../components/forms/fetchingSelects';
import { useLocalizedYup } from '../../../config/intl/LocaleProvider';
import { MessagingContact } from '../../../types/graphqlGenerated';
import { usePublicFileUpload } from '../../files/filesGraphql';
import { useContactsNavigation } from '../contactsNavigation';
import style from './ContactFormBase.module.less';
import { ContactFormValues } from './contactEditingTypes';

type ContactFormBaseProps = {
  initialValues: ContactFormValues;
  save: (values: ContactFormValues) => Promise<Pick<MessagingContact, 'id'>>;
  saving: boolean;
};

export function ContactFormBase({
  initialValues,
  save,
  saving,
}: ContactFormBaseProps) {
  const yup = useLocalizedYup();
  const { navigate } = useContactsNavigation();
  const { executeUpload } = usePublicFileUpload();
  const uploadFile = useCallback<UploadFunction>(
    async file => {
      try {
        const { result } = executeUpload(file.originFileObj!);
        const src = await result;
        return { contentId: '', id: 0, src };
      } catch (e) {
        showErrorMessage(formatError(e));
        throw e;
      }
    },
    [executeUpload]
  );

  const onSubmit = async (values: ContactFormValues) => {
    try {
      const contact = await save(values);
      showSuccessMessage(<FormattedMessage id="contacts.save.success" />);
      navigate(
        generatePath(RouteDefinitions.contactDetail, {
          contactId: `${contact.id}`,
        })
      );
    } catch (e) {
      showErrorMessage(<FormattedMessage id="contacts.save.error" />);
    }
  };

  const validationSchema = yup.object().shape({
    givenName: yup.string(),
    middleName: yup.string(),
    surname: yup.string(),
    emails: yup.array().of(yup.string().email()),
    phoneNumbers: yup.array().of(yup.string().trim()),
    whatsappNumbers: yup.array().of(yup.string().trim()),
    pictureUrl: yup.string(),
    messagingAccountId: yup.number(),
  });

  return (
    <div className={style.Form}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values }) => (
          <Form>
            <AntForm
              component={false}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >
              <FormField
                name="messagingAccountId"
                labelId="contacts.under-my-account"
              >
                {({ field, label }) => (
                  <AntForm.Item label={label}>
                    <AccountSelect
                      bordered
                      defaultValue={
                        values.messagingAccount && {
                          label: `${values.messagingAccount?.accountType} - ${values.messagingAccount?.platformId}`,
                          value: values.messagingAccount?.id,
                        }
                      }
                      showSearch={false}
                      {...field}
                    />
                  </AntForm.Item>
                )}
              </FormField>
              <FormItem labelId="contacts.givenName">
                <FormField name="givenName">
                  {({ field }) => <Input {...field} />}
                </FormField>
              </FormItem>
              <FormItem labelId="contacts.middleName">
                <FormField name="middleName">
                  {({ field }) => <Input {...field} />}
                </FormField>
              </FormItem>
              <FormItem labelId="contacts.surname">
                <FormField name="surname">
                  {({ field }) => <Input {...field} />}
                </FormField>
              </FormItem>
              <FormItem
                labelId="contacts.emails"
                translationValues={{ count: 2 }}
              >
                <FormFieldArray
                  name="emails"
                  getEmpty={stubString}
                  renderOne={({ field }) => <Input {...field} />}
                />
              </FormItem>
              <FormItem
                labelId="contacts.phoneNumbers"
                translationValues={{ count: 2 }}
              >
                <FormFieldArray
                  name="phoneNumbers"
                  getEmpty={stubString}
                  renderOne={({ field }) => <Input {...field} />}
                />
              </FormItem>
              <FormItem
                labelId="contacts.whatsappNumbers"
                translationValues={{
                  count: values.whatsappNumbers.length,
                }}
                help={<FormattedMessage id="contacts.whatsappNumbers.info" />}
              >
                <FormFieldArray
                  name="whatsappNumbers"
                  getEmpty={stubString}
                  renderOne={({ field }) => <Input {...field} />}
                />
              </FormItem>

              <FormItem labelId="contacts.profilePicture">
                <FormField name="picture">
                  {({ field }) => (
                    <ImageUpload {...field} upload={uploadFile} />
                  )}
                </FormField>
              </FormItem>
              <FormItem label=" " colon={false}>
                <Button
                  htmlType="submit"
                  type="primary"
                  disabled={
                    some(values.picture, p => p.status !== 'done') || saving
                  }
                  loading={saving}
                >
                  <FormattedMessage id="labels.submit" />
                </Button>
              </FormItem>
            </AntForm>
          </Form>
        )}
      </Formik>
    </div>
  );
}
