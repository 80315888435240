import { gql, useLazyQuery } from '@apollo/client';

import {
  createUseMutation,
  createUseQuery,
} from '../../common/utils/graphqlUtils';
import {
  GetEmailSignatureQuery,
  GetEmailSignatureQueryVariables,
  SetEmailSignatureMutation,
  SetEmailSignatureMutationVariables,
} from '../../types/graphqlGenerated';

const EMAIL_SIGNATURES_QUERY = gql`
  query GetEmailSignature($messagingAccountId: Int!) {
    getEmailSignature(messagingAccountId: $messagingAccountId) {
      body
      active
    }
  }
`;

export const useEmailSignatureQuery = createUseQuery<
  GetEmailSignatureQuery,
  GetEmailSignatureQueryVariables,
  'signature',
  GetEmailSignatureQuery['getEmailSignature']
>(EMAIL_SIGNATURES_QUERY, {
  extractResult: resp => resp.getEmailSignature,
  resultName: 'signature',
});

export function useEmailSignatureLazyQuery(
  variables: GetEmailSignatureQueryVariables
) {
  const [load] = useLazyQuery(EMAIL_SIGNATURES_QUERY, { variables });
  async function loadSignature() {
    const data = await load();
    const signature = data.data?.getEmailSignature;
    return signature as GetEmailSignatureQuery['getEmailSignature'];
  }
  return [loadSignature];
}

const SET_EMAIL_SIGNATURE_MUTATION = gql`
  mutation SetEmailSignature(
    $messagingAccountId: Int!
    $input: EmailSignatureInput!
  ) {
    setEmailSignature(messagingAccountId: $messagingAccountId, input: $input)
  }
`;

export const useSetEmailSignatureMutation = createUseMutation<
  SetEmailSignatureMutation,
  SetEmailSignatureMutationVariables,
  SetEmailSignatureMutation['setEmailSignature']
>(SET_EMAIL_SIGNATURE_MUTATION, {
  extractResult: resp => resp.setEmailSignature,
  refetchQueries: [EMAIL_SIGNATURES_QUERY],
  onCompleted: (_1, _2, client) => {
    client.refetchQueries({ include: ['GetEmailSignature'] });
  },
});
