import { Affix, AffixProps } from 'antd';
import { useRef } from 'react';

import { getScrollYParent } from '../common/utils/scrollUtils';

type LocalAffixProps = Omit<AffixProps, 'target' | 'prefixCls'>;

export default function LocalAffix({
  className,
  children,
  ...rest
}: LocalAffixProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={containerRef}>
      <Affix {...rest} target={() => getScrollYParent(containerRef.current!)}>
        <div className={className}>{children}</div>
      </Affix>
    </div>
  );
}
