import { ApolloClient, gql } from '@apollo/client';
import { fromPairs, sortBy } from 'lodash-es';

import {
  createUseMutation,
  createUseQuery,
} from '../../../common/utils/graphqlUtils';
import {
  CreateNamedMessagingThreadFilterMutation,
  CreateNamedMessagingThreadFilterMutationVariables,
  DeleteNamedMessagingThreadFilterMutation,
  DeleteNamedMessagingThreadFilterMutationVariables,
  GetNamedMessagingThreadFilterQuery,
  GetNamedMessagingThreadFilterQueryVariables,
  GetNamedMessagingThreadFiltersQuery,
  GetNamedMessagingThreadFiltersQueryVariables,
  UpdateNamedMessagingThreadFilterMutation,
  UpdateNamedMessagingThreadFilterMutationVariables,
} from '../../../types/graphqlGenerated';

const NamedFilterFragment = gql`
  fragment NamedMessagingThreadsFilterFullFragment on NamedMessagingThreadsFilter {
    id
    name
    content {
      messagingAccount {
        idEq
        idIn
      }
      folder {
        idEq
        idIn
        typeEq
        typeIn
      }
      label {
        idEq
        idIn
      }
      unread
      lastMessageOutgoing
      date {
        from
        to
      }
      participant {
        refEq
        refIn
      }
      searchTerm
    }
  }
`;

const NAMED_FILTERS_QUERY = gql`
  ${NamedFilterFragment}
  query GetNamedMessagingThreadFilters {
    getNamedMessagingThreadFilters {
      ...NamedMessagingThreadsFilterFullFragment
    }
  }
`;

export const useNamedFiltersQuery = createUseQuery<
  GetNamedMessagingThreadFiltersQuery,
  GetNamedMessagingThreadFiltersQueryVariables,
  'filters',
  GetNamedMessagingThreadFiltersQuery['getNamedMessagingThreadFilters']
>(NAMED_FILTERS_QUERY, {
  extractResult: resp => resp.getNamedMessagingThreadFilters,
  resultName: 'filters',
});

const NAMED_FILTER_QUERY = gql`
  ${NamedFilterFragment}
  query GetNamedMessagingThreadFilter($id: Int!) {
    getNamedMessagingThreadFilter(id: $id) {
      ...NamedMessagingThreadsFilterFullFragment
    }
  }
`;

export const useNamedFilterQuery = createUseQuery<
  GetNamedMessagingThreadFilterQuery,
  GetNamedMessagingThreadFilterQueryVariables,
  'filter',
  GetNamedMessagingThreadFilterQuery['getNamedMessagingThreadFilter']
>(NAMED_FILTER_QUERY, {
  extractResult: resp => resp.getNamedMessagingThreadFilter,
  resultName: 'filter',
});

const CREATE_NAMED_FILTER_MUTATION = gql`
  mutation CreateNamedMessagingThreadFilter(
    $input: NamedMessagingThreadsFilterInput!
  ) {
    createNamedMessagingThreadFilter(input: $input) {
      id
      name
    }
  }
`;

export const useCreateNamedFilterMutation = createUseMutation<
  CreateNamedMessagingThreadFilterMutation,
  CreateNamedMessagingThreadFilterMutationVariables,
  CreateNamedMessagingThreadFilterMutation['createNamedMessagingThreadFilter']
>(CREATE_NAMED_FILTER_MUTATION, {
  extractResult: resp => resp.createNamedMessagingThreadFilter,
  refetchQueries: ['GetNamedMessagingThreadFilters'],
});

const UPDATE_NAMED_FILTER_MUTATION = gql`
  mutation UpdateNamedMessagingThreadFilter(
    $id: Int!
    $input: NamedMessagingThreadsFilterInput!
  ) {
    updateNamedMessagingThreadFilter(id: $id, input: $input) {
      id
      name
    }
  }
`;

export const useUpdateNamedFilterMutation = createUseMutation<
  UpdateNamedMessagingThreadFilterMutation,
  UpdateNamedMessagingThreadFilterMutationVariables,
  UpdateNamedMessagingThreadFilterMutation['updateNamedMessagingThreadFilter']
>(UPDATE_NAMED_FILTER_MUTATION, {
  extractResult: resp => resp.updateNamedMessagingThreadFilter,
  refetchQueries: ['GetNamedMessagingThreadFilters'],
});

const DELETE_NAMED_FILTER_MUTATION = gql`
  mutation DeleteNamedMessagingThreadFilter($id: Int!) {
    deleteNamedMessagingThreadFilter(id: $id)
  }
`;

export const useDeleteNamedFilterMutation = createUseMutation<
  DeleteNamedMessagingThreadFilterMutation,
  DeleteNamedMessagingThreadFilterMutationVariables,
  DeleteNamedMessagingThreadFilterMutation['deleteNamedMessagingThreadFilter']
>(DELETE_NAMED_FILTER_MUTATION, {
  extractResult: resp => resp.deleteNamedMessagingThreadFilter,
  refetchQueries: ['GetNamedMessagingThreadFilters'],
});

export function readNamedThreadsFilters(client: ApolloClient<any>) {
  const result = client.readQuery<GetNamedMessagingThreadFiltersQuery>({
    query: NAMED_FILTERS_QUERY,
  });
  return result?.getNamedMessagingThreadFilters || [];
}

export function writeNamedThreadsFilters(
  client: ApolloClient<any>,
  order?: number[]
) {
  if (!order) {
    return null;
  }

  const data = readNamedThreadsFilters(client);
  const idToIndex = fromPairs(order.map((val, i) => [val, i]));
  const sortedData = sortBy(data, f => idToIndex[f.id] ?? order.length);

  client.writeQuery<GetNamedMessagingThreadFiltersQuery>({
    query: NAMED_FILTERS_QUERY,
    data: { getNamedMessagingThreadFilters: sortedData },
  });
}
