import { Button, Tooltip } from 'antd';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import LocalAffix from './LocalAffix';
import styles from './SidebarControls.module.less';

type TabPaneControlsProps = {
  children: ReactNode;
};

export function SidebarControlButton({ tooltipId, ...buttonProps }) {
  return (
    <Tooltip title={<FormattedMessage id={tooltipId} />}>
      <Button shape="circle" {...buttonProps} />
    </Tooltip>
  );
}

export default function SidebarControls({ children }: TabPaneControlsProps) {
  return (
    <LocalAffix offsetBottom={0} className={styles.Affix}>
      <div className={styles.Container}>{children}</div>
    </LocalAffix>
  );
}
