import { Tooltip } from 'antd';

import { CircleButton } from '../../../components/buttons';

export function ToolbarButton({ onClick, tooltip, icon }) {
  return (
    <Tooltip title={tooltip}>
      <CircleButton icon={icon} onClick={onClick} />
    </Tooltip>
  );
}
