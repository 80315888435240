import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  FileExcelOutlined,
  FileGifOutlined,
  FileImageOutlined,
  FileJpgOutlined,
  FileOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileWordOutlined,
  FileZipOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import classNames from 'classnames';
import { isEmpty, isFunction, startsWith } from 'lodash-es';
import { useState } from 'react';

import { formatError } from '../common/utils/errorUtils';
import styles from './AttachmentButton.module.less';

export function FileIcon({ contentType }) {
  if (contentType === 'image/jpeg') {
    return <FileJpgOutlined />;
  }
  if (contentType === 'image/gif') {
    return <FileGifOutlined />;
  }
  if (startsWith(contentType, 'image/')) {
    return <FileImageOutlined />;
  }
  if (/excel|xml|spreadsheet/.test(contentType)) {
    return <FileExcelOutlined />;
  }
  if (/word|doc/.test(contentType)) {
    return <FileWordOutlined />;
  }
  if (/powerpoint|ppt|presentation/.test(contentType)) {
    return <FilePptOutlined />;
  }
  if (/pdf/.test(contentType)) {
    return <FilePdfOutlined />;
  }
  if (/zip/.test(contentType)) {
    return <FileZipOutlined />;
  }
  return <FileOutlined />;
}

export type FileInfo = {
  key: any;
  id?: number;
  contentType?: string | null;
  filename?: string | null;
  contentId?: string | null;
  size?: number | null;
  downloadable?: boolean;
  uploading?: boolean;
  removable?: boolean;
  error?: Error;
};

type FileActionFunction = (file: FileInfo) => Promise<void>;

function AttachmentButtonIcon({ file }: { file: FileInfo }) {
  if (file.uploading) {
    return <UploadOutlined className="Animation--Blinking" />;
  }
  if (file.error) {
    return (
      <Tooltip title={formatError(file.error)}>
        <ExclamationCircleOutlined className={styles['Button__ErrorIcon']} />
      </Tooltip>
    );
  }
  return <FileIcon contentType={file.contentType} />;
}

type AttachmentButtonProps = {
  file: FileInfo;
  download?: FileActionFunction;
  remove?: FileActionFunction;
};

function AttachmentButton({ file, download, remove }: AttachmentButtonProps) {
  const [loading, setLoading] = useState(false);
  const initDownload = async () => {
    setLoading(true);
    try {
      await download!(file);
    } finally {
      setLoading(false);
    }
  };
  const initRemove = async () => {
    setLoading(true);
    try {
      await remove!(file);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      className={classNames(
        styles.Button,
        !(file.downloadable && isFunction(download)) &&
          styles['Button--NoDownload']
      )}
      icon={<AttachmentButtonIcon file={file} />}
      loading={loading}
      disabled={loading}
      onClick={
        file.downloadable && isFunction(download)
          ? () => initDownload()
          : undefined
      }
    >
      {file.filename}
      {file.removable && isFunction(remove) && (
        <CloseCircleOutlined
          className={classNames('Clickable', styles['Button__DeleteIcon'])}
          onClick={e => {
            e.stopPropagation();
            initRemove();
          }}
        />
      )}
    </Button>
  );
}

type AttachmentsListProps = {
  files: FileInfo[];
  download?: FileActionFunction;
  remove?: FileActionFunction;
};

export function AttachmentsList({
  files,
  download,
  remove,
}: AttachmentsListProps) {
  return !isEmpty(files) ? (
    <div className={styles.Container}>
      {files.map(file => (
        <AttachmentButton
          key={file.key}
          file={file}
          download={download}
          remove={remove}
        />
      ))}
    </div>
  ) : (
    <></>
  );
}
