import classNames from 'classnames';
import {
  DragEventHandler,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { DragStart, Draggable, Droppable } from 'react-beautiful-dnd';

import { useDragAndDropStateContext } from './RichDragAndDropContextProvider';
import { DragAndDropItemType } from './dragAndDropConstants';

type RenderPlaceholder = (info: { id: string | number }) => ReactNode;

const DragSourceContext = createContext({
  itemType: null as DragAndDropItemType | null,
  renderPlaceholder: (() => null) as RenderPlaceholder,
});

type DragSourceContainerProps = {
  itemType: DragAndDropItemType;
  children: ReactNode;
  renderPlaceholder: RenderPlaceholder;
  prepareData?: (draggableId: string) => any;
};

export function DragSourceContainer({
  itemType,
  children,
  renderPlaceholder,
  prepareData,
}: DragSourceContainerProps) {
  const { registerStartHandler, unregisterStartHandler } =
    useDragAndDropStateContext();
  const startHandler = useCallback(
    (event: DragStart) => prepareData?.(event.draggableId),
    [prepareData]
  );
  useEffect(() => {
    registerStartHandler(itemType, startHandler);
    return () => unregisterStartHandler(itemType);
  }, [startHandler, itemType, registerStartHandler, unregisterStartHandler]);

  return (
    <DragSourceContext.Provider value={{ itemType, renderPlaceholder }}>
      <Droppable droppableId={itemType} isDropDisabled>
        {(droppableProvided, droppableSnapshot) => (
          <div
            ref={droppableProvided.innerRef}
            {...droppableProvided.droppableProps}
            className={classNames(
              droppableSnapshot.draggingFromThisWith &&
                'DragSource--DraggingFrom'
            )}
          >
            {children}
            <div style={{ display: 'none' }}>
              {droppableProvided.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    </DragSourceContext.Provider>
  );
}

type DragSourceProps = {
  id: string | number;
  index: number;
  children: ReactNode;
};

export function DragSource({ id, index, children }: DragSourceProps) {
  const { willDrag } = useDragAndDropStateContext();
  const { itemType, renderPlaceholder } = useContext(DragSourceContext);
  const [startCoords, setStartCoords] = useState<
    { x: number; y: number } | undefined
  >();

  const drId = `${itemType}:${id}`;

  return (
    <Draggable draggableId={drId} index={index}>
      {(
        {
          innerRef,
          draggableProps: { style, ...draggableProps },
          dragHandleProps,
        },
        draggableSnapshot
      ) => {
        const isPreparingDrag = willDrag === `${drId}`;

        const onMouseDown: DragEventHandler<any> = e => {
          setStartCoords({ x: e.clientX, y: e.clientY });
        };

        if (isPreparingDrag || draggableSnapshot.isDragging) {
          return (
            <>
              <div
                style={{
                  // This is to prevent events in case of going back to the source
                  pointerEvents: 'none',
                }}
              >
                {children}
              </div>
              <div
                ref={innerRef}
                {...draggableProps}
                {...dragHandleProps}
                style={
                  isPreparingDrag
                    ? {
                        position: 'fixed',
                        display: 'inline-block',
                        width: 'auto',
                        height: 'auto',
                        top: startCoords?.y,
                        left: startCoords?.x,
                        transform: 'translate(-50%, -50%)',
                      }
                    : style
                }
              >
                {renderPlaceholder({ id })}
              </div>
            </>
          );
        }

        return (
          <div
            ref={innerRef}
            {...draggableProps}
            {...dragHandleProps}
            onMouseDown={onMouseDown}
          >
            {children}
          </div>
        );
      }}
    </Draggable>
  );
}
