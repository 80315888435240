import { ReactNode } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import LoginGuard from '../modules/auth/guards/LoginGuard';
import NoLoginGuard from '../modules/auth/guards/NoLoginGuard';
import { CalendarEventDetailPage } from '../pages/CalendarEventDetailPage';
import CalendarPage from '../pages/CalendarPage';
import { ConnectFailPage } from '../pages/ConnectFailPage';
import { ConnectSuccessPage } from '../pages/ConnectSuccessPage';
import ContactDetailPage from '../pages/ContactDetailPage';
import ContactsListPage from '../pages/ContactsListPage';
import CreateContactPage from '../pages/CreateContactPage';
import { DocsPage } from '../pages/DocsPage';
import EditContactPage from '../pages/EditContactPage';
import ForgottenPasswordPage from '../pages/ForgottenPasswordPage';
import InitialAuthPage, { InitialAuthTab } from '../pages/InitialAuthPage';
import NotFoundPage from '../pages/NotFoundPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import SendMessagePage from '../pages/SendMessagePage';
import SilentInitialAuthPage from '../pages/SilentInitialAuthPage';
import TermsAndConditionsPage from '../pages/TermsAndConditionsPage';
import {
  FilterViewThreadsListPage,
  FolderViewThreadsListPage,
} from '../pages/ThreadsListPage';
import RouteDefinitions, { DEFAULT_LOGGED_ROUTE } from './routes';

export default function AppRoutes() {
  return (
    <Routes>
      <Route
        path={RouteDefinitions.login}
        element={
          <NoLoginGuard>
            <InitialAuthPage tab={InitialAuthTab.LOGIN} />
          </NoLoginGuard>
        }
      />
      <Route
        path={RouteDefinitions.forgotPassword}
        element={
          <NoLoginGuard>
            <ForgottenPasswordPage />
          </NoLoginGuard>
        }
      />
      <Route
        path={RouteDefinitions.signup}
        element={
          <NoLoginGuard>
            <InitialAuthPage tab={InitialAuthTab.SIGNUP} />
          </NoLoginGuard>
        }
      />
      <Route path={RouteDefinitions.docs} element={<DocsPage />} />
      <Route
        path={RouteDefinitions.silentSignup}
        element={
          <NoLoginGuard>
            <SilentInitialAuthPage tab={InitialAuthTab.SIGNUP} />
          </NoLoginGuard>
        }
      />
      <Route
        path={RouteDefinitions.terms}
        element={<TermsAndConditionsPage />}
      />
      <Route
        path={RouteDefinitions.privacyPolicy}
        element={<PrivacyPolicyPage />}
      />
      <Route
        path={RouteDefinitions.root}
        element={<Navigate to={DEFAULT_LOGGED_ROUTE} />}
      />
      <Route
        path={`${RouteDefinitions.messagesFolderView}/*`}
        element={
          <LoginGuard>
            <FolderViewThreadsListPage />
          </LoginGuard>
        }
      />
      <Route
        path={`${RouteDefinitions.messagesFilterView}/*`}
        element={
          <LoginGuard>
            <FilterViewThreadsListPage />
          </LoginGuard>
        }
      />
      <Route
        path={RouteDefinitions.sendMessage}
        element={
          <LoginGuard>
            <SendMessagePage />
          </LoginGuard>
        }
      />
      <Route
        path={RouteDefinitions.calendar}
        element={
          <LoginGuard>
            <CalendarPage />
          </LoginGuard>
        }
      />
      <Route
        path={RouteDefinitions.calendarEventDetail}
        element={
          <LoginGuard>
            <CalendarEventDetailPage />
          </LoginGuard>
        }
      />
      <Route
        path={RouteDefinitions.contacts}
        element={
          <LoginGuard>
            <ContactsListPage />
          </LoginGuard>
        }
      />
      <Route
        path={RouteDefinitions.contactDetail}
        element={
          <LoginGuard>
            <ContactDetailPage />
          </LoginGuard>
        }
      />
      <Route
        path={RouteDefinitions.createContact}
        element={
          <LoginGuard>
            <CreateContactPage />
          </LoginGuard>
        }
      />
      <Route
        path={RouteDefinitions.editContact}
        element={
          <LoginGuard>
            <EditContactPage />
          </LoginGuard>
        }
      />
      <Route
        path={RouteDefinitions.connectSuccess}
        element={
          <LoginGuard>
            <ConnectSuccessPage
              infoTextId="connectAccount.connectProcessInfo"
              showLinkAnotherAccount
            />
          </LoginGuard>
        }
      />
      <Route
        path={RouteDefinitions.waConnectSuccess}
        element={
          <LoginGuard>
            <ConnectSuccessPage
              infoTextId="connectAccount.connectProcessInfoWa"
              infoTextValues={{
                link1: (chunks: ReactNode[]) => (
                  <a
                    href="https://business.facebook.com/billing_hub/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {chunks}
                  </a>
                ),
                link2: (chunks: ReactNode[]) => (
                  <a
                    href="https://developers.facebook.com/docs/whatsapp/pricing/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {chunks}
                  </a>
                ),
              }}
            />
          </LoginGuard>
        }
      />
      <Route
        path={RouteDefinitions.connectFail}
        element={
          <LoginGuard>
            <ConnectFailPage />
          </LoginGuard>
        }
      />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
