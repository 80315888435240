import { ContactsList } from '../modules/contacts/contactsList/ContactsList';
import { useContactsNavigation } from '../modules/contacts/contactsNavigation';
import { SearchBarForContacts } from '../modules/contacts/filters/SearchBarForContacts';
import { ContactsAppTemplate } from '../modules/contacts/layout/ContactsAppTemplate';

export default function ContactsListPage() {
  const { searchTerm, navigate } = useContactsNavigation();
  const setSearchTerm = (val: string) =>
    navigate(undefined, { searchTerm: val });
  return (
    <ContactsAppTemplate
      subHeaderContent={
        <SearchBarForContacts
          searchTerm={searchTerm ?? ''}
          onSearch={val => setSearchTerm(val)}
        />
      }
    >
      <ContactsList searchTerm={searchTerm} />
    </ContactsAppTemplate>
  );
}
