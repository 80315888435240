import { Tooltip, TooltipProps } from 'antd';
import { FormattedMessage } from 'react-intl';

import { TranslationId } from '../types/appTypes';

type OptionalTooltipProps = Omit<TooltipProps, 'title'> & {
  title?: TooltipProps['title'];
  enabled: boolean;
  titleId?: TranslationId;
};

export default function OptionalTooltip({
  title: titleOuter,
  titleId,
  enabled,
  children,
  ...rest
}: OptionalTooltipProps) {
  const title = titleId ? <FormattedMessage id={titleId} /> : titleOuter;
  return title && enabled ? (
    <Tooltip title={title} {...rest}>
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  );
}
