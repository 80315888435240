import { isNumber, toNumber } from 'lodash-es';
import { useParams } from 'react-router';

import RouteDefinitions from '../app/routes';
import { EditContactForm } from '../modules/contacts/contactEditing/EditContactForm';
import { ContactsAppTemplate } from '../modules/contacts/layout/ContactsAppTemplate';
import { UseParams } from '../types/libHelperTypes';

export default function EditContactPage() {
  const { contactId } = useParams<UseParams<RouteDefinitions.editContact>>();
  const id = toNumber(contactId);
  if (!isNumber(id)) {
    return null;
  }

  return (
    <ContactsAppTemplate>
      <EditContactForm id={id} />
    </ContactsAppTemplate>
  );
}
