import { Modal } from 'antd';
import { addHours, startOfHour } from 'date-fns/esm';
import { isBoolean } from 'lodash-es';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { showSuccessMessage } from '../../../common/utils/messageUtils';
import { PrimaryButton } from '../../../components/buttons';
import { mapFormToGraphqlCalendarEvent } from '../calendarEventCommon/calendarEventConversions';
import { CalendarEventFormValues } from '../calendarEventCommon/calendarEventTypes';
import { useCalendarsQuery, useCreateCalendarEvent } from '../calendarGraphql';
import {
  CalendarEventFormBase,
  CalendarEventFormBaseProps,
} from './CalendarEventFormBase';
import { RECURRENCE_EMPTY_INITIAL_VALUES } from './recurrenceConstants';

const EMPTY_INITIAL_VALUES: CalendarEventFormValues = {
  title: '',
  calendarId: undefined,
  sendInvitations: true,
  allDay: false,
  when: undefined,
  participants: [],
  description: '',
  recurrence: RECURRENCE_EMPTY_INITIAL_VALUES,
};

type CreateCalendarEventButtonProps = {
  labelId?: string;
};

export const CreateCalendarEventButton = ({
  labelId = 'calendar.createNewEvent',
}: CreateCalendarEventButtonProps) => {
  const [visible, setVisible] = useState(false);

  const now = new Date();
  const start = addHours(startOfHour(now), 1);
  const end = addHours(start, 1);

  return (
    <>
      <PrimaryButton
        onClick={() => {
          setVisible(true);
        }}
        labelId={labelId}
      />
      <CreateCalendarEventModal
        labelId={labelId}
        visible={visible}
        setVisible={setVisible}
        dateRange={[start, end]}
      />
    </>
  );
};

type CreateCalendarEventModalProps = CreateCalendarEventButtonProps & {
  visible: boolean;
  setVisible: (val: boolean) => void;
  dateRange?: [Date, Date];
  allDay?: boolean;
};

export const CreateCalendarEventModal = ({
  visible,
  setVisible,
  dateRange,
  allDay,
}: CreateCalendarEventModalProps) => {
  const { calendars = [] } = useCalendarsQuery();
  const firstSelectedCalendar = calendars.find(c => c.selected);

  const onCreateEventSuccess = () => {
    showSuccessMessage(
      <FormattedMessage id="calendar.dialog.result.success" />
    );
    setVisible(false);
  };

  const [createEvent, { loading: submitting }] = useCreateCalendarEvent({
    onCompleted: onCreateEventSuccess,
  });

  const performMutation: CalendarEventFormBaseProps['performMutation'] =
    async values => {
      const accountId = calendars.find(c => c.id === values.calendarId)!.account
        .id;
      await createEvent({
        accountId,
        input: mapFormToGraphqlCalendarEvent(values),
        notifyParticipants: values.sendInvitations,
      });
    };

  return (
    <Modal
      title={<FormattedMessage id="calendar.dialog.title" />}
      visible={visible}
      footer={null}
      onCancel={() => {
        setVisible(false);
      }}
      destroyOnClose
      confirmLoading={submitting}
    >
      <CalendarEventFormBase
        initialValues={{
          ...EMPTY_INITIAL_VALUES,
          ...(isBoolean(allDay) ? { allDay } : {}),
          ...(dateRange ? { when: dateRange } : {}),
          calendarId: firstSelectedCalendar?.id,
        }}
        performMutation={performMutation}
        showCalendarSelect={true}
        renderButtons={({ isSubmitting }) => (
          <PrimaryButton
            htmlType="submit"
            labelId="calendar.dialog.create"
            loading={isSubmitting}
            disabled={isSubmitting}
          />
        )}
      />
    </Modal>
  );
};
