export function getScrollYParent(element: HTMLElement) {
  let parent: HTMLElement | null = element;
  while ((parent = parent.parentElement)) {
    const cs = getComputedStyle(parent, null);
    const overflow =
      cs.getPropertyValue('overflow') + cs.getPropertyValue('overflow-y');
    const isScrollable = /(auto|scroll)/.test(overflow);
    if (isScrollable) {
      return parent;
    }
  }

  return null;
}
