import { Typography } from 'antd';

import NonLoggedPageTemplate from '../modules/messagesApp/layout/NonLoggedPageTemplate';
const { Title, Text, Paragraph } = Typography;

export default function PrivacyPolicyPage() {
  return (
    <NonLoggedPageTemplate contentWidth="75vw">
      <Typography>
        <Title level={5}>Datenschutzerklärung</Title>
        <Paragraph>
          Bitte lesen Sie die folgende Erklärung, um mehr über unsere
          Informationsbeschaffungs- und -verbreitungspraktiken zu erfahren.
        </Paragraph>
        <Title level={5}>UMFANG:</Title>
        <Paragraph>
          DATA 01- Vifzack (nachfolgend „das Unternehmen“, „wir“, „unser“,
          „uns“) bietet unter anderem eine Reihe von
          Messaging-Software-as-a-Service-Lösungen über eine einzige Plattform
          an, um seinen Kunden die Verwaltung der Kommunikation mit ihren
          Nutzern unter dem Namen und Stil von „Vifzack - ein Produkt von DATA
          01“ (nachfolgend „Lösung“) zu ermöglichen, um den Benutzersupport
          durch schnellere Reaktionen und effizientere interne Prozesse zu
          verbessern. Die Lösung verpflichtet sich, Ihre Privatsphäre zu
          schützen und zu respektieren. Diese Richtlinie
          („Datenschutzerklärung“) zusammen mit den Allgemeinen
          Geschäftsbedingungen, verfügbar unter{' '}
          <Text style={{ color: 'red' }}>[https .ai/privacy-policy],</Text>
        </Paragraph>
        <Paragraph>
          und allen anderen als Teil der Lösung aufgeführten Richtlinien,
          skizziert die Datenschutzpraktiken der Lösung hinsichtlich der
          Erhebung, Nutzung und Sicherung Ihrer Informationen durch die Lösung
          und die darauf angebotenen Dienste. Durch die Nutzung, Registrierung
          oder Anmeldung für die Lösung akzeptieren Sie die Bedingungen dieser
          Datenschutzerklärung. Wenn Sie keine dieser Bedingungen akzeptieren,
          wird Ihre Nutzung der Lösung eingeschränkt. Ihr Klicken auf die
          Schaltfläche „Zustimmen“ und die Übermittlung personenbezogener Daten
          oder, wenn Sie minderjährig sind, die Übermittlung Ihrer
          personenbezogenen Daten entweder durch Ihre Eltern (oder Ihren
          gesetzlichen Vormund) oder durch Sie mit der Erlaubnis Ihrer Eltern
          (oder Ihres gesetzlichen Vormunds) an die Lösung gilt als Ihre
          Zustimmung zu dieser Datenschutzerklärung und Ihr Einverständnis, von
          uns kontaktiert zu werden, um Sie über Produkte und/oder
          Dienstleistungen und/oder zukünftige Vorteile zu informieren, die von
          uns oder einem unserer verbundenen Unternehmen oder anderen Subdomains
          angeboten werden. Diese Datenschutzerklärung ist ein elektronisches
          Dokument im Sinne des Information Technology Act, 2000 (in der jeweils
          gültigen Fassung) („IT-Gesetz“) und der dazugehörigen Vorschriften und
          wird unter anderem gemäß den Bestimmungen der Regel 3 (1) der
          Information Technology (Intermediaries Guidelines) Rules, 2011,
          veröffentlicht, die die Veröffentlichung von Regeln und Vorschriften,
          der Datenschutzerklärung und der Nutzungsbedingungen für den Zugriff
          auf oder die Nutzung der Lösung vorschreiben. Diese
          Datenschutzerklärung ist ein elektronisches Dokument, das von einem
          Computersystem erstellt wurde und keine physischen oder digitalen
          Unterschriften erfordert.
        </Paragraph>
        <Title level={5}>ÄNDERUNGEN DER DATENSCHUTZRICHTLINIE:</Title>
        <Paragraph>
          Wir behandeln Ihre Informationen in Übereinstimmung mit den geltenden
          Gesetzen und Vorschriften Indiens. Diese Datenschutzrichtlinie
          unterliegt Änderungen aufgrund von geschäftlichen, rechtlichen oder
          regulatorischen Anforderungen und wird von Zeit zu Zeit online
          aktualisiert. Wir werden alle Anstrengungen unternehmen, um Sie über
          wesentliche Änderungen dieser Datenschutzrichtlinie zu informieren. Es
          wird empfohlen, diese Seite regelmäßig zu besuchen, um die
          Datenschutzrichtlinie und etwaige Änderungen daran zu überprüfen. Ihre
          fortgesetzte Teilnahme an der Lösung nach Änderungen dieser
          Datenschutzrichtlinie gilt als Ihre Zustimmung zu diesen Änderungen.
        </Paragraph>
        <Title level={5}>PERSÖNLICHE INFORMATIONEN, DIE WIR SAMMELN:</Title>
        <Paragraph>
          „Persönliche Informationen“ sind definiert als Informationen, die
          verwendet werden können, um Sie zu identifizieren, und können Details
          wie Ihren Namen, Alter, Geschlecht, Kontaktdaten,
          eingehende/ausgehende Nachrichten, Geschäftsinformationen, Produkte
          und Dienstleistungen, an denen Sie interessiert sind oder über die Sie
          weitere Informationen benötigen, umfassen. Soweit es sich um sensible
          persönliche Informationen handelt, tragen sie die Bedeutung, die von
          den geltenden Gesetzen von Zeit zu Zeit definiert wird.
        </Paragraph>
        <Paragraph>
          Wir sammeln, verwenden und teilen Ihre persönlichen Informationen wie
          folgt:
        </Paragraph>
        <Title level={5}>Sammlung:</Title>
        <Paragraph>
          Wir können Ihre persönlichen Informationen sammeln, wann immer es
          relevant ist, um Ihnen die Dienstleistungen zu erbringen und/oder jede
          Transaktion abzuschließen oder jedes Produkt bereitzustellen, das Sie
          unter der Lösung angefordert oder autorisiert haben. Sie stimmen auch
          der Sammlung bestimmter persönlicher Informationen im Zuge Ihrer
          Bewerbung für die Produkte und/oder Dienstleistungen im Zusammenhang
          mit der Lösung zu.
        </Paragraph>
        <Title level={5}>Nutzung:</Title>
        <Paragraph>
          Wir benötigen diese persönlichen Informationen entweder (a) um Ihre
          Anfrage zur Nutzung der Lösung zu validieren und zu bearbeiten; oder
          (b) um dem Unternehmen und/oder seinen verbundenen Unternehmen zu
          helfen, Ihre Bestellungen und/oder Transaktionen zu bearbeiten und zu
          erfüllen, falls vorhanden; oder (c) um Details zu verbündeten
          Produkten und/oder Dienstleistungen und/oder Transaktionen an Dritte
          zu kommunizieren; oder (d) um die Qualität der Lösung zu verbessern;
          oder (e) um Ihnen zu helfen, die für Ihre Bedürfnisse am besten
          geeigneten Dienstleistungen/Produkte zu bestimmen; oder (f) um unsere
          internen Geschäftsvorgänge zu erleichtern, einschließlich der
          Erfüllung gesetzlicher und regulatorischer Anforderungen; oder (g) um
          Ihnen Empfehlungen zu Dienstleistungen/Produkten zu geben, an denen
          Sie möglicherweise interessiert sind, basierend auf Ihrer Teilnahme an
          der Lösung; oder (h) um Ihnen Marketingkommunikationen und Werbung zu
          bieten, die für Sie von Interesse sein könnten; oder (i) um
          Streitigkeiten zu lösen oder Probleme zu beheben; oder (j) um uns vor
          Fehlern, Betrug und anderen kriminellen Aktivitäten zu schützen; oder
          (h) um unsere Geschäftsbedingungen durchzusetzen; oder (i) wie Ihnen
          zum Zeitpunkt der Sammlung beschrieben.
        </Paragraph>
        <Title level={5}>Teilen:</Title>
        <Paragraph>
          Wir können Ihre persönlichen Informationen unseren verbundenen
          Unternehmen offenlegen, wenn dies notwendig ist (a) um die
          Dienstleistungen in unserem Auftrag oder in Ihrem Namen in Bezug auf
          die Lösung zu erbringen; oder (b) um Werbung und Werbedienstleistungen
          anzuzeigen; oder (c) um Suchergebnisse und Links (einschließlich
          bezahlter Listen und Links) bereitzustellen; oder (d) um effizienten
          Kundendienst zu leisten. Bitte beachten Sie, dass unsere Unternehmen
          und verbundenen Unternehmen Sie aufgrund eines solchen Teilens
          möglicherweise vermarkten. Wenn Sie sich gegen ein solches Teilen
          entscheiden möchten, senden Sie uns bitte eine E-Mail an{' '}
          <Text style={{ color: 'red' }}>info@vifzack.net</Text>. Wir können
          Ihre persönlichen Informationen an Dritte weitergeben. Diese
          Offenlegung kann erforderlich sein, um Ihnen und solchen Dritten
          Dienstleistungen unter der Lösung zu erbringen oder um Ihr Erlebnis zu
          verbessern oder um unsere gesetzlichen Verpflichtungen zu erfüllen.
          Wir können Ihre persönlichen Informationen auch mit externen
          Organisationen oder Einzelpersonen teilen, wenn wir der Ansicht sind,
          dass ein solcher Zugriff, eine solche Nutzung, Speicherung oder
          Offenlegung der persönlichen Informationen vernünftigerweise notwendig
          ist:
        </Paragraph>
        <Paragraph>
          (a) um geltende Gesetze, Vorschriften, rechtliche Verfahren oder
          durchsetzbare behördliche Anfragen zu erfüllen;
        </Paragraph>
        <Paragraph>
          (b) um Betrug, Sicherheits- oder technische Probleme zu erkennen, zu
          verhindern oder anderweitig zu beheben;
        </Paragraph>
        <Paragraph>
          (c) um Rechte, Eigentum oder Sicherheit unserer Kunden oder der
          Öffentlichkeit zu schützen, wie es nach geltendem Recht in Indien
          erforderlich oder zulässig ist.
        </Paragraph>
        <Title level={5}>
          NICHT-PERSONENBEZOGENE INFORMATIONEN, DIE WIR SAMMELN:
        </Title>
        <Paragraph>
          Nicht-personenbezogene Informationen sind definiert als Informationen,
          die Sie nicht persönlich identifizieren und einzigartige System- oder
          Hardwarekennungen, System- oder Anwendungssoftware und
          Peripheriegeräte umfassen können. Alle nicht-personenbezogenen
          Informationen, die mit personenbezogenen Informationen kombiniert
          werden, werden als personenbezogene Informationen behandelt. Die
          folgende Art und Weise der Sammlung, Nutzung und Weitergabe von
          nicht-personenbezogenen Informationen:
        </Paragraph>
        <Title level={5}>Sammlung:</Title>
        <Paragraph>
          Sie stimmen zu, dass die Lösung diagnostische, technische,
          nutzungsbezogene Informationen zu den unten beschriebenen
          Nutzungszwecken oder wie Ihnen zum Zeitpunkt der Sammlung mitgeteilt,
          sammeln darf. Diese Informationen werden nach der Sammlung
          anonymisiert, sodass sie Sie nicht persönlich identifizieren.
        </Paragraph>
        <Title level={5}>Nutzung:</Title>
        <Paragraph>
          Die nicht-personenbezogenen Informationen werden regelmäßig gesammelt,
          um die Lösung und die darin enthaltenen Dienstleistungen
          bereitzustellen und zu verbessern, die Bereitstellung von
          Software-Updates, Produktsupport und andere Dienstleistungen für Sie
          zu erleichtern (falls vorhanden) und die Einhaltung der Bedingungen
          dieser Datenschutzrichtlinie zu überprüfen. Da nicht-personenbezogene
          Informationen Sie nicht persönlich identifizieren, können wir diese
          Informationen nach unserem Ermessen gemäß den geltenden Gesetzen
          verwenden und offenlegen.
        </Paragraph>
        <Title level={5}>Teilen:</Title>
        <Paragraph>
          Um den Partnern der Lösung und Drittanbietern zu ermöglichen, ihre
          Software, Hardware und Dienstleistungen, die für die Nutzung mit der
          Lösung entwickelt wurden, zu verbessern, können wir auch einem solchen
          Partner oder Drittanbietern eine Teilmenge der diagnostischen
          Informationen bereitstellen, die für die Software, Hardware und/oder
          Dienstleistungen dieses Partners oder Drittanbieters relevant sind,
          solange die diagnostischen Informationen in einer Form vorliegen, die
          Sie nicht persönlich identifizieren.
        </Paragraph>
        <Title level={5}>Cloud-API:</Title>
        <Paragraph>
          Die WhatsApp-Infrastruktur bezieht sich auf die Bereitstellung des
          WhatsApp Enterprise Clients, der die Kommunikation zwischen Benutzern
          und der Interakt-Plattform unterstützt.
        </Paragraph>
        <Paragraph>
          Der Kunde bestätigt und stimmt zu, dass alle von ihm im Zusammenhang
          mit der WhatsApp-Nachrichtenausführung geteilten Daten nun in der
          Cloud-API ("Datenaufenthaltsort"), einem Meta-Produkt, gespeichert
          werden.
        </Paragraph>
        <Paragraph>
          Es wird weiter verstanden und akzeptiert, dass alle zuvor über die
          WhatsApp-Infrastruktur geleiteten und gespeicherten Kundendaten, d.h.
          zum Zwecke der Nachrichten-/Benachrichtigungsausführung auf WhatsApp,
          künftig zu den derzeit in Nordamerika und der Europäischen Union
          befindlichen Datenzentren über den Cloud-API-Dienst von Meta migriert
          werden.
        </Paragraph>
        <Paragraph>
          Für die Bereitstellung von Dienstleistungen im Rahmen dieser
          Vereinbarung erkennt der Kunde an, dass die Datenlokalisierung für die
          WhatsApp-Infrastruktur von der Gesellschaft für keine der im Zuge der
          Bereitstellung des WhatsApp-Messaging erhaltenen oder geteilten Daten
          angeboten wird.
        </Paragraph>
        <Title level={5}>STANDORTDIENSTE:</Title>
        <Paragraph>
          Die Lösung, ihre Lizenznehmer und Agenten können regelmäßig auf Ihre
          Teilnahme an der Lösung und die Nutzung der darin enthaltenen Lösung
          und andere Informationen zu Ihrem Standort über Bluetooth- und
          Wi-Fi-Signale und andere Technologien und Daten zugreifen, diese
          sammeln und analysieren, um die Dienstleistungen der Lösung zu
          erbringen und das Design, die Funktionalität, die Leistung und den
          Inhalt der Lösung zu verbessern. Um Standortinformationen zu sammeln,
          müssen die Standortdienste auf Ihrem Gerät aktiviert sein. Daher kann
          das Gerät in einigen Fällen verlangen, dass Sie die Standortdienste
          aktivieren. Mit Ausnahme der Fälle, in denen von Ihnen angeforderte
          spezifische Dienstleistungen eine persönliche Identifizierung
          erfordern oder beinhalten, sammelt die Lösung Standortdaten anonym und
          in einer Form, die Sie nicht persönlich identifiziert. Durch die
          Nutzung der Lösung stimmen Sie der Übertragung, Sammlung, Speicherung,
          Verarbeitung und Nutzung Ihrer Standortdaten und Abfragen zu, um
          solche Dienstleistungen der Lösung bereitzustellen und zu verbessern.
        </Paragraph>
        <Title level={5}>COOKIES:</Title>
        <Paragraph>
          Wir platzieren sowohl permanente als auch temporäre Cookies auf Ihrem
          Gerät im Zusammenhang mit der Lösung. Informationen, die durch Cookies
          und andere Technologien gesammelt werden, werden als
          nicht-personenbezogene Informationen behandelt. Soweit IP-Adressen
          oder ähnliche Kennungen jedoch nach lokalem Recht als personenbezogene
          Informationen betrachtet werden, behandeln wir alle Informationen, die
          mit solchen Kennungen verknüpft sind, als personenbezogene
          Informationen. Ebenso behandeln wir alle nicht-personenbezogenen
          Informationen, die mit personenbezogenen Informationen kombiniert
          werden, als personenbezogene Informationen.
        </Paragraph>
        <Title level={5}>WEITERE INFORMATIONEN, DIE DIE LÖSUNG SAMMELT:</Title>
        <Paragraph>
          Die Lösung kann auch andere Informationen über Sie auf die in der
          Lösung beschriebenen Weise oder mit Ihrer Zustimmung sammeln. Sie
          können sich dafür entscheiden, uns bestimmte Arten von Informationen
          nicht zur Verfügung zu stellen, aber dies kann Ihre Fähigkeit
          beeinträchtigen, bestimmte Dienstleistungen zu nutzen.
        </Paragraph>
        <Paragraph>
          DIENSTE DRITTER, ANWENDUNGEN DRITTER, WEBSITES UND DIENSTE:
        </Paragraph>
        <Paragraph>
          Dienste Dritter bezeichnen die von der Firma unter der Lösung zur
          Verfügung gestellten Dienste Dritter, einschließlich, aber nicht
          beschränkt auf WhatsApp-Geschäftsplattformen. Der Begriff
          „Drittanbieter“ bezeichnet den Anbieter solcher Dienste Dritter. Die
          Lösung kann Links zu anderen Websites/Anwendungen oder deren
          Unterdomänen oder Websites/Anwendungen von Drittanbietern enthalten
          oder Anzeigen solcher Drittanbieter und andere Inhalte anzeigen, die
          auf Dienste Dritter verweisen. Solche Dienste Dritter unterliegen
          ihren jeweiligen Datenschutzrichtlinien, die außerhalb unserer
          Kontrolle liegen. Sobald Sie unsere Server verlassen, wird die Nutzung
          der von Ihnen bereitgestellten Informationen durch die
          Datenschutzrichtlinie der Betreiber der von Ihnen besuchten Dienste
          Dritter geregelt und solche Richtlinien können von dieser
          Datenschutzrichtlinie abweichen. Wenn Sie die Datenschutzrichtlinie
          eines Drittanbieters nicht finden können, müssen Sie die entsprechende
          Website/Anwendung dieses Drittanbieters direkt für weitere
          Informationen kontaktieren. Wir sind nicht verantwortlich für die
          Datenschutzpraktiken oder den Inhalt solcher Dienste Dritter. Wir
          haften nicht für die von den Drittanbietern auf der Lösung erbrachten
          Dienstleistungen. Sie übernehmen alle Risiken, die sich aus oder im
          Zusammenhang mit Transaktionen ergeben, die Sie mit solchen
          Drittanbietern auf der Lösung durchführen, und Sie stimmen zu und
          erkennen an, dass das Unternehmen nicht verantwortlich oder haftbar
          für Verluste ist, die aus Ihrer Nutzung solcher Dienste Dritter
          entstehen. Wir können Drittanbieter-Werbeunternehmen verwenden, um
          Anzeigen zu schalten, wenn Sie die Lösung besuchen. Bitte beachten
          Sie, dass alle Dienste Dritter, die Sie im Rahmen der Lösung nutzen,
          möglicherweise auch Informationen über Sie und Ihre Nutzung sammeln,
          verwenden und teilen. Wir können nicht kontrollieren, wie diese
          Drittanbieter Informationen sammeln, verwenden, teilen oder sichern.
          Diese Drittanbieter, die Ihre persönlichen und/oder nutzungsbezogenen
          Informationen sammeln, verarbeiten, teilen, verwenden, aufbewahren
          oder mit denen solche Informationen gemäß vertraglichen
          Verpflichtungen geteilt werden, sind ausschließlich für diese Nutzung
          und Haftung verantwortlich.
        </Paragraph>
        <Paragraph>ZUGANG, KORREKTUR UND LÖSCHUNG:</Paragraph>
        <Paragraph>
          Wir bemühen uns, unsere Aufzeichnungen mit den neuesten Informationen
          auf dem neuesten Stand und korrekt zu halten. Es ist sicherzustellen,
          dass die von Zeit zu Zeit bereitgestellten Informationen oder Daten
          korrekt, aktuell und aktualisiert sind und dass Sie alle Rechte,
          Genehmigungen und Einwilligungen haben, solche Informationen oder
          Daten bereitzustellen. Bitte beachten Sie, dass das Löschen bestimmter
          Informationen oder der Widerruf der Einwilligung zur Stornierung Ihres
          Zugangs zur Lösung oder zu bestimmten Funktionen und Diensten der
          Lösung führen kann. Darüber hinaus können wir Ihre Anfrage zur
          Korrektur, Aktualisierung oder Löschung möglicherweise nicht
          bearbeiten, falls diese nicht durch gültige Dokumente unterstützt wird
          oder die Datenaufbewahrung gemäß geltendem Recht oder Anfragen von
          Strafverfolgungsbehörden oder im Rahmen von Gerichtsverfahren
          erforderlich ist oder es extrem schwierig ist, sie umzusetzen (z. B.
          Anfragen im Zusammenhang mit Sicherungskopien oder wenn ein neues
          System erforderlich ist, um die Anfrage zu bearbeiten oder eine
          technische Neugestaltung erforderlich ist) oder die Privatsphäre
          anderer Benutzer gefährdet.
        </Paragraph>
        <Paragraph>VERARBEITUNG DER INFORMATIONEN</Paragraph>
        <Paragraph>
          Wir sowie unsere verbundenen Unternehmen oder Drittanbieter, mit denen
          wir vertragliche Vereinbarungen getroffen haben, können auch alle von
          Ihnen gesammelten persönlichen und/oder nutzungsbezogenen
          Informationen für legitime kommerzielle Zwecke verarbeiten,
          einschließlich der Bereitstellung der oben genannten Dienste für die
          Lösung, sofern Sie nicht ausdrücklich widersprechen. Dies kann Ihre
          Fähigkeit beeinträchtigen, die Lösung in Anspruch zu nehmen und/oder
          zu nutzen.
        </Paragraph>
        <Paragraph>HAFTUNGSAUSSCHLUSS</Paragraph>
        <Paragraph>
          Ungeachtet anderslautender Bestimmungen in dieser
          Datenschutzrichtlinie haften wir nicht für indirekte, zufällige,
          Folge-, Sonder- oder exemplarische Schäden, die durch Datenverlust
          oder Nutzungsausfall entstehen, im Zusammenhang mit Ihrem Zugriff auf
          oder Ihrer Nutzung oder der Unfähigkeit, auf die Lösung zuzugreifen
          oder sie zu nutzen, einschließlich, aber nicht beschränkt auf alle
          Unterdomänen oder Dienste Dritter.
        </Paragraph>
        <Paragraph>FRAGEN UND BESCHWERDEN:</Paragraph>
        <Paragraph>
          Wir sind bestrebt, Ihre von uns gesammelten und verarbeiteten
          persönlichen Informationen zu schützen und freuen uns auf Ihre weitere
          Unterstützung. Im Falle von Feedback oder Bedenken bezüglich des
          Schutzes Ihrer persönlichen Informationen oder bei
          datenschutzbezogenem Feedback oder Bedenken können Sie den
          Datenschutzbeauftragten Manfred Walcher unter{' '}
          <Text style={{ color: 'red' }}>
            Info@vifzack.net oder unter Hello@vifzack.net für
          </Text>{' '}
          weitere Klarstellungen kontaktieren.
        </Paragraph>
      </Typography>
    </NonLoggedPageTemplate>
  );
}
