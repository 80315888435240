import {
  CaretDownFilled,
  CheckCircleFilled,
  ClockCircleFilled,
  CloseCircleFilled,
  CloseCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  QuestionCircleFilled,
} from '@ant-design/icons';
import { Button, Dropdown, Menu, Tooltip, Typography } from 'antd';
import DOMPurify from 'dompurify';
import { isEmpty, toNumber } from 'lodash-es';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { generatePath, useNavigate } from 'react-router';

import RouteDefinitions from '../../../app/routes';
import {
  showConfirm,
  showDebouncedErrorMessage,
  showSuccessMessage,
} from '../../../common/utils/messageUtils';
import { DateLabel } from '../../../components/DateLabel';
import { ErrorAlert } from '../../../components/ErrorAlert';
import { OverlaySpinner } from '../../../components/OverlaySpinner';
import { Whitespace } from '../../../components/Whitespace';
import { CircleButton } from '../../../components/buttons';
import {
  CalendarEvent,
  CalendarEventParticipant,
  CalendarEventParticipantStatus,
  CalendarRecurrenceEditScope,
} from '../../../types/graphqlGenerated';
import { parseGraphqlWhen } from '../calendarEventCommon/calendarEventConversions';
import { FormattedRRule } from '../calendarEventCommon/rruleElements';
import { CalendarEventEditForm } from '../calendarEventEditing/CalendarEventEditForm';
import { RecurrenceEditScopeDialog } from '../calendarEventEditing/RecurrenceEditScopeDialog';
import {
  useCalendarEventDetailQuery,
  useDeleteCalendarEventMutation,
  useSendRSVP,
} from '../calendarGraphql';
import style from './CalendarEventDetailContent.module.less';

const { Title, Text } = Typography;

type TimeLabelProps = {
  date: Date;
  allDay?: boolean;
  timeLabelId: string;
};

function EventDateTimeLabelsByWhenType({ event }: { event: CalendarEvent }) {
  const { start, end, allDay } = parseGraphqlWhen(event);

  return (
    <div>
      <EventDateTimeLabel
        timeLabelId="eventDetail.dateFrom"
        date={start}
        allDay={allDay}
      />
      <EventDateTimeLabel
        timeLabelId="eventDetail.dateTo"
        date={end}
        allDay={allDay}
      />
    </div>
  );
}

function EventDateTimeLabel({ timeLabelId, date, allDay }: TimeLabelProps) {
  return (
    <div>
      <FormattedMessage id={timeLabelId} />:
      <Whitespace />
      <DateLabel date={date} showRelativeTime={false} allDay={allDay} />
    </div>
  );
}
function renderMenu(participants: CalendarEventParticipant[]) {
  return (
    <Menu
      items={participants.map(participant => ({
        key: participant.email,
        label: (
          <div className={style.ParticipantWrapper}>
            <RSVPIcon
              status={
                participant.status ?? CalendarEventParticipantStatus.Noreply
              }
            />
            {participant.name || participant.email}
          </div>
        ),
      }))}
    />
  );
}

enum RSVPIconSize {
  small = '16px',
  medium = '24px',
  big = '32px',
}

function RSVPIcon({
  status,
  size = RSVPIconSize.small,
}: {
  status?: CalendarEventParticipantStatus | null;
  size?: RSVPIconSize;
}) {
  switch (status) {
    case CalendarEventParticipantStatus.Maybe:
      return (
        <Tooltip
          title={
            <FormattedMessage id="calendar.dialog.participantStatus.maybe" />
          }
        >
          <QuestionCircleFilled
            className={style.RSVPMaybeIcon}
            style={{ fontSize: size }}
          />
        </Tooltip>
      );
    case CalendarEventParticipantStatus.Yes:
      return (
        <Tooltip
          title={
            <FormattedMessage id="calendar.dialog.participantStatus.yes" />
          }
        >
          <CheckCircleFilled
            className={style.RSVPYesIcon}
            style={{ fontSize: size }}
          />
        </Tooltip>
      );
    case CalendarEventParticipantStatus.No:
      return (
        <Tooltip
          title={<FormattedMessage id="calendar.dialog.participantStatus.no" />}
        >
          <CloseCircleFilled
            className={style.RSVPNoIcon}
            style={{ fontSize: size }}
          />
        </Tooltip>
      );
    default:
      return (
        <Tooltip
          title={
            <FormattedMessage id="calendar.dialog.participantStatus.noResponse" />
          }
        >
          <ClockCircleFilled
            className={style.RSVPWaitingForResponseIcon}
            style={{ fontSize: size }}
          />
        </Tooltip>
      );
  }
}

function Participant({
  name,
  status,
}: {
  name: string;
  status?: CalendarEventParticipantStatus | null;
}) {
  return (
    <div className={style.ParticipantWrapper}>
      <RSVPIcon status={status} /> {name}
    </div>
  );
}

type CalendarEventDetailContentProps = {
  eventId: string;
  calendarId: number;
};

export default function CalendarEventDetailContent({
  eventId,
  calendarId,
}: CalendarEventDetailContentProps) {
  const [editMode, setEditMode] = useState<boolean>(false);
  const { event, loading, error } = useCalendarEventDetailQuery({
    eventId,
    calendarId,
  });

  const navigate = useNavigate();
  const [deleteCalendarEvent] = useDeleteCalendarEventMutation({
    onCompleted: () => {
      showSuccessMessage(
        <FormattedMessage id="eventDetail.deleteModal.success" />
      );
      navigate(RouteDefinitions.calendar);
    },
  });

  const visibleParticipants = event?.participants?.slice(0, 5) || [];
  const dropdownParticipants = event?.participants?.slice(5);
  const [sendRSVP, { loading: submitting }] = useSendRSVP();

  const submitRSVPResponse = async (
    newRSVP: CalendarEventParticipantStatus
  ) => {
    try {
      await sendRSVP({
        input: {
          calendarId: toNumber(calendarId),
          eventId,
          newRSVP,
        },
      });

      showSuccessMessage(
        <FormattedMessage id="calendar.dialog.participantStatus.updated" />
      );
    } catch {
      showDebouncedErrorMessage(
        <FormattedMessage id="calendar.dialog.participantStatus.updateError" />
      );
    }
  };

  const [recurrenceEditStateDialogOpen, setRecurrenceEditStateDialogOpen] =
    useState(false);
  const showDeleteConfirm = (
    recurrenceEditScope?: CalendarRecurrenceEditScope
  ) => {
    showConfirm({
      title: <FormattedMessage id="eventDetail.deleteModal.title" />,
      content: (
        <FormattedMessage
          id={
            !recurrenceEditScope ||
            recurrenceEditScope === CalendarRecurrenceEditScope.One
              ? 'eventDetail.deleteModal.content.singular'
              : 'eventDetail.deleteModal.content.plural'
          }
        />
      ),
      okText: <FormattedMessage id="eventDetail.deleteModal.ok" />,
      cancelText: <FormattedMessage id="eventDetail.deleteModal.cancel" />,
      onOk: async () => {
        await deleteCalendarEvent({
          eventId,
          calendarId,
          scope: recurrenceEditScope,
        });
      },
    });
  };

  if (loading) {
    return <OverlaySpinner />;
  }
  if (error) {
    return <ErrorAlert error={error} />;
  }
  if (!event) {
    return null;
  }

  return (
    <div className={style.Container}>
      <div className={style.InnerContainer}>
        <div className={style.EventInfoColumn}>
          {editMode ? (
            <CalendarEventEditForm
              eventId={eventId}
              calendarId={toNumber(calendarId)}
              event={event}
              onCompleted={({ id }) => {
                setEditMode(false);
                // Id may change, so we will go to the new detail
                navigate(
                  generatePath(RouteDefinitions.calendarEventDetail, {
                    calendarId: `${calendarId}`,
                    eventId: id,
                  }),
                  { replace: true }
                );
              }}
            />
          ) : (
            <>
              <Title level={1}>{event?.title}</Title>
              <EventDateTimeLabelsByWhenType event={event as CalendarEvent} />
              {event.masterEvent?.recurrence && (
                <div>
                  <FormattedRRule value={event.masterEvent.recurrence.rrule} />
                </div>
              )}
              <Title level={5} className={style.ParticipantsWrapper}>
                <FormattedMessage id="filterEditor.participants" />:
                <div>
                  {visibleParticipants.map(participant => (
                    <Participant
                      name={participant.name || participant.email}
                      status={participant.status}
                      key={participant.email}
                    />
                  ))}
                </div>
                <div className={style.RSVPIcons}>
                  <Button
                    onClick={() => {
                      submitRSVPResponse(CalendarEventParticipantStatus.Yes);
                    }}
                    disabled={submitting}
                  >
                    <RSVPIcon
                      status={CalendarEventParticipantStatus.Yes}
                      size={RSVPIconSize.medium}
                    />
                  </Button>
                  <Button
                    onClick={() => {
                      submitRSVPResponse(CalendarEventParticipantStatus.No);
                    }}
                    disabled={submitting}
                  >
                    <RSVPIcon
                      status={CalendarEventParticipantStatus.No}
                      size={RSVPIconSize.medium}
                    />
                  </Button>
                  <Button
                    onClick={() => {
                      submitRSVPResponse(CalendarEventParticipantStatus.Maybe);
                    }}
                    disabled={submitting}
                  >
                    <RSVPIcon
                      status={CalendarEventParticipantStatus.Maybe}
                      size={RSVPIconSize.medium}
                    />
                  </Button>
                </div>
                <div>
                  {!isEmpty(dropdownParticipants) && (
                    <Dropdown
                      overlay={renderMenu(
                        (dropdownParticipants as CalendarEventParticipant[]) ??
                          []
                      )}
                    >
                      <CaretDownFilled />
                    </Dropdown>
                  )}
                </div>
              </Title>
              <Title level={5}>
                <FormattedMessage id="eventDetail.description" />
              </Title>
              <div className={style.DescriptionWrapper}>
                <Text>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(event?.description || ''),
                    }}
                  />
                </Text>
              </div>
            </>
          )}
        </div>
        <span className={style.IconsWrapper}>
          <CircleButton
            icon={editMode ? <CloseCircleOutlined /> : <EditOutlined />}
            onClick={() => setEditMode(currentEditMode => !currentEditMode)}
          />
          <CircleButton
            icon={<DeleteOutlined />}
            onClick={() =>
              event.masterEvent
                ? setRecurrenceEditStateDialogOpen(true)
                : showDeleteConfirm()
            }
          />
          <RecurrenceEditScopeDialog
            visible={recurrenceEditStateDialogOpen}
            onClose={() => setRecurrenceEditStateDialogOpen(false)}
            titleId="calendar.dialog.deleteRecurrence.editScope.title"
            onConfirm={async val => {
              showDeleteConfirm(val);
            }}
          />
        </span>
      </div>
    </div>
  );
}
