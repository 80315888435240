import { repeat } from 'lodash-es';
import { MessageDescriptor, useIntl } from 'react-intl';

import { TranslationId } from '../../types/appTypes';

export function useFormattedMessage(
  id?: TranslationId,
  options?: Omit<MessageDescriptor, 'id'>
) {
  const intl = useIntl();
  return intl.formatMessage({ id, ...options });
}

export function useDateTimeFormatter() {
  const intl = useIntl();
  return (date: Date) => `${intl.formatDate(date)} ${intl.formatTime(date)}`;
}

function datePartsToFormat(parts: Intl.DateTimeFormatPart[]) {
  return parts
    .map(part => {
      if (part.type === 'literal') {
        return part.value;
      }
      if (part.type === 'day') {
        return repeat('D', part.value.length);
      }
      if (part.type === 'month') {
        return repeat('M', part.value.length);
      }
      if (part.type === 'year') {
        return repeat('Y', part.value.length);
      }
      if (part.type === 'hour') {
        return repeat('H', part.value.length);
      }
      if (part.type === 'minute') {
        return repeat('m', part.value.length);
      }
      if (part.type === 'second') {
        return repeat('s', part.value.length);
      }
      return '';
    })
    .join('');
}

export function useIntlFormatDescriptors() {
  const intl = useIntl();

  const dateFormat = datePartsToFormat(intl.formatDateToParts(new Date()));
  const timeFormat = datePartsToFormat(intl.formatTimeToParts(new Date()));
  const dateTimeFormat = `${dateFormat} ${timeFormat}`;

  return { dateFormat, timeFormat, dateTimeFormat };
}
