import { ReactNode } from 'react';

import RichDragAndDropContextProvider from '../../common/dragAndDrop/RichDragAndDropContextProvider';
import { BaseLayout } from '../../layout/BaseLayout';
import { MessagesAppSidebar } from './MessagesAppSidebar';

type MessagesAppTemplateProps = {
  activeFolderKey?: string;
  activeFilterId?: number;
  children?: ReactNode;
  subHeaderContent?: ReactNode;
};

export const MessagesAppTemplate = ({
  activeFolderKey,
  activeFilterId,
  children,
  subHeaderContent,
}: MessagesAppTemplateProps) => (
  <RichDragAndDropContextProvider>
    <BaseLayout
      subHeaderContent={subHeaderContent}
      sidebar={
        <MessagesAppSidebar
          activeFolderKey={activeFolderKey}
          activeFilterId={activeFilterId}
        />
      }
      content={children}
    />
  </RichDragAndDropContextProvider>
);
