import {
  AuthenticationDetails,
  ClientMetadata,
  CodeDeliveryDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoUserPool,
  CognitoUserSession,
  ISignUpResult,
  UserData,
} from 'amazon-cognito-identity-js';

import { remoteLog } from '../../common/utils/logUtils';
import { config } from '../../config/config';

export type AuthService = ReturnType<typeof setupCognitoAuthService>;

function toPromise(self, method) {
  return async (...args) =>
    new Promise((resolve, reject) => {
      method.call(self, ...args, (err, res) => {
        if (err) {
          remoteLog(
            `Cognito ${method.name} error`,
            {
              errorMessage: err?.message,
            },
            'warning'
          );
          reject(err);
        } else {
          resolve(res);
        }
      });
    });
}

export function setupCognitoAuthService() {
  const userPool: CognitoUserPool = new CognitoUserPool({
    UserPoolId: config.cognitoUserPoolId,
    ClientId: config.cognitoClientId,
  });

  function getCurrentUser(): CognitoUser | undefined {
    return userPool.getCurrentUser() ?? undefined;
  }

  async function getUserData(user?: CognitoUser) {
    if (!user) {
      return null;
    }
    return toPromise(user, user.getUserData)() as Promise<UserData | null>;
  }

  async function signUp(email: string, password: string) {
    const attributeList = [
      new CognitoUserAttribute({
        Name: 'email',
        Value: email,
      }),
    ];
    return toPromise(userPool, userPool.signUp)(
      email,
      password,
      attributeList,
      []
    ) as Promise<ISignUpResult>;
  }

  async function confirmRegistration(user: CognitoUser, code: string) {
    return toPromise(user, user.confirmRegistration)(
      code,
      true
    ) as Promise<ClientMetadata>;
  }

  async function login(email: string, password: string) {
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });
    const user: CognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });
    user.setAuthenticationFlowType('USER_PASSWORD_AUTH');

    return new Promise<{ user: CognitoUser; session: CognitoUserSession }>(
      function (resolve, reject) {
        user.authenticateUser(authenticationDetails, {
          onSuccess: session => {
            resolve({ session, user });
          },
          onFailure: err => {
            reject(err);
          },
        });
      }
    );
  }

  async function resetPassword(email: string) {
    const user: CognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });
    user.setAuthenticationFlowType('USER_PASSWORD_RESET');

    return new Promise<{ deliveryDetails: CodeDeliveryDetails }>(function (
      resolve,
      reject
    ) {
      user.forgotPassword({
        onSuccess: deliveryDetails => {
          resolve({ deliveryDetails });
        },
        onFailure: err => {
          reject(err);
        },
      });
    });
  }

  async function resendConfirmationCode(email: string) {
    const user: CognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });
    user.setAuthenticationFlowType('USER_RESET_PASSWORD_CONFIRMATION_CODE');

    return new Promise<{ deliveryDetails: CodeDeliveryDetails }>(function (
      resolve,
      reject
    ) {
      user.resendConfirmationCode((err, deliveryDetails) => {
        if (err) {
          reject(err);
        } else {
          resolve(deliveryDetails);
        }
      });
    });
  }

  async function confirmNewPassword(
    email: string,
    confirmationCode: string,
    newPassword: string
  ) {
    const user: CognitoUser = new CognitoUser({
      Username: email,
      Pool: userPool,
    });
    user.setAuthenticationFlowType('USER_CONFIRM_NEW_PASSWORD');

    return new Promise<{ success: string }>(function (resolve, reject) {
      user.confirmPassword(confirmationCode, newPassword, {
        onSuccess: success => {
          resolve({ success });
        },
        onFailure: err => {
          reject(err);
        },
      });
    });
  }

  return {
    getCurrentUser,
    getUserSession: getFreshCognitoUserSession,
    getUserData,
    signUp,
    confirmRegistration,
    resetPassword,
    resendConfirmationCode,
    confirmNewPassword,
    login,
  };
}

export async function getFreshCognitoUserSession(user?: CognitoUser) {
  if (!user) {
    return null;
  }
  return toPromise(
    user,
    user.getSession
  )() as Promise<CognitoUserSession | null>;
}
