import { range } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import { NUM_WEEKDAYS } from '../../../components/dateFormats';
import {
  RecurrenceEndType,
  RecurrenceFormValues,
  RecurrenceFrequency,
} from '../calendarEventCommon/calendarEventTypes';

export const RRULE_DAYS = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];

export const RECURRENCE_FREQUENCY_OPTIONS = [
  {
    label: <FormattedMessage id="calendar.dialog.daily" />,
    value: RecurrenceFrequency.DAILY,
  },
  {
    label: <FormattedMessage id="calendar.dialog.weekly" />,
    value: RecurrenceFrequency.WEEKLY,
  },
  {
    label: <FormattedMessage id="calendar.dialog.monthly" />,
    value: RecurrenceFrequency.MONTHLY,
  },
  {
    label: <FormattedMessage id="calendar.dialog.yearly" />,
    value: RecurrenceFrequency.YEARLY,
  },
];

export const RECURRENCE_EMPTY_INITIAL_VALUES: RecurrenceFormValues = {
  enabled: false,
  frequencyInterval: 1,
  frequencyType: RecurrenceFrequency.DAILY,
  isForever: true,
  count: 3,
  endType: RecurrenceEndType.COUNT,
  lastOccurenceDate: undefined,
  selectedWeekDays: range(0, NUM_WEEKDAYS),
  selectedMonthDay: undefined,
  selectedMonths: undefined,
};
