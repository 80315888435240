import {
  format,
  parse,
  setDate,
  setHours,
  setMinutes,
  setMonth,
  setSeconds,
  setYear,
  startOfDay,
} from 'date-fns';
import { isNil, isNumber } from 'lodash-es';

export const toUnixTs = (date?: Date) =>
  date && Math.floor(date.getTime() / 1000);
export const fromUnixTs = (time?: number) =>
  isNil(time) ? undefined : new Date(time * 1000);

const utcToLocal = (date: Date) =>
  new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  );

const localToUtc = (date: Date) =>
  new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    )
  );

/**
 * Formats the date in its UTC form
 */
export function formatUTC(date: Date, formatStr: string) {
  return format(utcToLocal(date), formatStr);
}

type DateFields = {
  year?: number;
  month?: number;
  date?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
};

const DATE_FIELDS_MAPPING: [
  keyof DateFields,
  (date: Date, val: number) => Date
][] = [
  ['year', setYear],
  ['month', setMonth],
  ['date', setDate],
  ['hours', setHours],
  ['minutes', setMinutes],
  ['seconds', setSeconds],
];

/**
 * Sets multiple date fields at once
 */
export function setDateFields(date: Date, fields: DateFields) {
  return DATE_FIELDS_MAPPING.reduce(
    (prevDate, [key, setter]) =>
      isNumber(fields[key]) ? setter(prevDate, fields[key]) : prevDate,
    date
  );
}

const DATE_FORMAT = 'yyyy-MM-dd';

export const parseDate = (val: string) =>
  parse(val, DATE_FORMAT, startOfDay(new Date()));
export const parseDateTime = (val: string) => new Date(val);
export const formatDate = (val: Date) => format(val, DATE_FORMAT);

const RRULE_DATETIME_FORMAT = "yyyyMMdd'T'HHmmss'Z'";
const RRULE_DATE_FORMAT = 'yyyyMMdd';

export const parseRruleDate = (val: string) =>
  localToUtc(parse(val, RRULE_DATE_FORMAT, startOfDay(new Date())));

export const parseRruleDateTime = (val: string) =>
  localToUtc(parse(val, RRULE_DATETIME_FORMAT, startOfDay(new Date())));

type ParseRruleDateOrDateTimeResult = {
  type: 'date' | 'datetime';
  value: Date;
};

export function parseRruleDateOrDateTime(
  val: string
): ParseRruleDateOrDateTimeResult {
  return val.includes('T')
    ? { type: 'datetime', value: parseRruleDateTime(val) }
    : { type: 'date', value: parseRruleDate(val) };
}

export const formatRruleDate = (val: Date) => formatUTC(val, RRULE_DATE_FORMAT);
export const formatRruleDateTime = (val: Date) =>
  formatUTC(val, RRULE_DATETIME_FORMAT);
