import classNames from 'classnames';
import { noop } from 'lodash-es';
import { ForwardedRef, ReactNode, forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useNodeInnerText } from '../common/utils/stringUtils';
import styles from './VerticalMenu.module.less';

type VerticalMenuProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'role'
>;

export const VerticalMenu = forwardRef(function VerticalMenuInner(
  { className, children, ...rest }: VerticalMenuProps,
  ref
) {
  return (
    <div
      ref={ref as ForwardedRef<HTMLDivElement>}
      role="menu"
      className={classNames(styles.Container, className)}
      {...rest}
    >
      {children}
    </div>
  );
});

type VerticalMenuItemProps = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'role' | 'onClick'
> & {
  active?: boolean;
  to?: string;
  leftContent?: ReactNode;
  rightContent?: ReactNode;
};

const STOP_PROPAGATION = (e: { stopPropagation: () => void }) =>
  e.stopPropagation();

export const VerticalMenuItem = forwardRef(function VerticalMenuItemInner(
  {
    active = false,
    to,
    children,
    leftContent,
    rightContent,
    className,
    ...rest
  }: VerticalMenuItemProps,
  ref
) {
  const navigate = useNavigate();
  const { ref: textRef, text } = useNodeInnerText<HTMLDivElement>();

  return (
    <div
      ref={ref as ForwardedRef<HTMLDivElement>}
      className={classNames(
        styles.Item,
        active && styles['Item--Active'],
        to && styles['Item--HasLink'],
        className
      )}
      role="menuitem"
      onClick={to ? () => navigate(to) : noop}
      {...rest}
    >
      {leftContent && (
        <div className={styles['Item__LeftContent']} onClick={STOP_PROPAGATION}>
          {leftContent}
        </div>
      )}
      <div ref={textRef} className={styles['Item__Content']} title={text}>
        {children}
      </div>
      {rightContent && (
        <div
          className={styles['Item__RightContent']}
          onClick={STOP_PROPAGATION}
        >
          {rightContent}
        </div>
      )}
    </div>
  );
});
