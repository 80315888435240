import { Record } from 'immutable';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Action, bindActionCreators } from 'redux';

import { localStorageAdapter } from '../../common/utils/localStorageUtils';
import { Locale } from '../../config/intl/intl';
import { LOGOUT } from '../auth/authReducer';

type SettingsState = typeof INITIAL_STATE;
type PartialState = { settings: SettingsState };

export type PageSize = 20 | 50 | 100;

type InitialStateType = {
  locale: Locale;
  pageSize: PageSize;
};

function loadInitialSettings(): InitialStateType {
  const settings = localStorageAdapter.getItem('Settings');
  return {
    locale: settings?.locale || Locale.EnGb,
    pageSize: settings?.pageSize || 20,
  };
}

function saveSettings(settings: SettingsState) {
  localStorageAdapter.setItem('Settings', settings);
}

const InitialState = Record<InitialStateType>(loadInitialSettings());
const INITIAL_STATE = InitialState();

const CHANGE_LOCALE = 'SETTINGS/CHANGE_LOCALE';
const CHANGE_PAGE_SIZE = 'SETTINGS/CHANGE_PAGE_SIZE';

function settingsReducerInner(
  state: SettingsState = INITIAL_STATE,
  action: Action
) {
  switch (action.type) {
    case CHANGE_LOCALE: {
      const typedAction = action as SetLocaleAction;
      return state.set('locale', typedAction.locale);
    }
    case CHANGE_PAGE_SIZE: {
      const typedAction = action as SetPageSizeAction;
      return state.set('pageSize', typedAction.pageSize);
    }
    case LOGOUT: {
      return state.clear();
    }
    default:
      return state;
  }
}

export function settingsReducer(
  state: SettingsState = INITIAL_STATE,
  action: Action
) {
  const newState = settingsReducerInner(state, action);
  saveSettings(newState);
  return newState;
}

export const getLocale = (state: PartialState) => state.settings.locale;
export const getPageSize = (state: PartialState) => state.settings.pageSize;

type SetLocaleAction = Action & Pick<InitialStateType, 'locale'>;
function setLocale(locale: Locale): SetLocaleAction {
  return { type: CHANGE_LOCALE, locale };
}

type SetPageSizeAction = Action & Pick<InitialStateType, 'pageSize'>;
function setPageSize(pageSize: PageSize): SetPageSizeAction {
  return { type: CHANGE_PAGE_SIZE, pageSize };
}

export function useSettingsActions() {
  const dispatch = useDispatch();
  return useMemo(
    () => ({
      setLocale: bindActionCreators(setLocale, dispatch),
      setPageSize: bindActionCreators(setPageSize, dispatch),
    }),
    [dispatch]
  );
}
