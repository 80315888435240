import { MenuOutlined } from '@ant-design/icons';
import { Button, Layout } from 'antd';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { useResponsiveQueries } from '../../common/utils/layoutUtils';
import { theme } from '../../styles/theme';
import { UserFeedbackButton } from '../issues/UserFeedbackPopup';
import style from './BaseLayout.module.less';
import { DesktopHeader } from './header/DesktopHeader';
import { MainHeader } from './header/MobileAndTabletHeader';
import { getSidebarCollapse, useLayoutActions } from './layoutReducer';

interface Props {
  sidebar: ReactNode;
  content: ReactNode;
  subHeaderContent?: ReactNode;
}

export function BaseLayout({ sidebar, content, subHeaderContent }: Props) {
  const isSidebarCollapsed = useSelector(getSidebarCollapse) as boolean;

  const media = useResponsiveQueries();
  const isSmOrMd = media.sm || media.md;
  const isMdOrLg = media.md || media.lg;

  const { setSidebarCollapse } = useLayoutActions();

  return (
    <Layout className={style.Layout}>
      {isMdOrLg && (
        <>
          <Layout.Header className={isSidebarCollapsed ? style.Header : ''}>
            <DesktopHeader />
          </Layout.Header>
          {subHeaderContent && (
            <Layout.Header className={style.SubHeader}>
              {subHeaderContent}
            </Layout.Header>
          )}
        </>
      )}
      <Layout className={style.MainLayout}>
        <Layout.Sider
          collapsible={isSmOrMd}
          collapsed={isSmOrMd && isSidebarCollapsed}
          collapsedWidth={0}
          width={theme['@app-siderbar-width']}
          zeroWidthTriggerStyle={{ top: 10, right: 10 }}
          trigger={
            <Button type="text" size="large" className={style.Sider__Trigger}>
              <MenuOutlined />
            </Button>
          }
          onCollapse={val => setSidebarCollapse(val)}
          className={style.Sider}
        >
          {sidebar}
          <></>
        </Layout.Sider>
        <Layout.Content
          onClick={() => {
            if (!isSidebarCollapsed && isSmOrMd) {
              setSidebarCollapse(true);
            }
          }}
        >
          <div className={style['Content__Inner']}>
            {media.sm && (
              <>
                <MainHeader />
                {subHeaderContent && (
                  <Layout.Header className={style.SubHeader}>
                    {subHeaderContent}
                  </Layout.Header>
                )}
              </>
            )}
            {content}
          </div>
        </Layout.Content>
        <UserFeedbackButton />
      </Layout>
    </Layout>
  );
}
