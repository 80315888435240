import { ConfigProvider as AntdProvider } from 'antd';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import * as yup from 'yup';

import { getLocale } from '../../modules/settings/settingsReducer';
import { getLocaleData } from './intl';

const LocalizedYupContext = createContext(yup);

export function useLocalizedYup() {
  return useContext(LocalizedYupContext);
}

interface Props {
  children: ReactNode;
  skipYupInit?: boolean;
}

export default function LocaleProvider(props: Props) {
  const [yupCounter, setYupCounter] = useState(0);
  const localeName = useSelector(getLocale);
  const { locale, messages, antLocale, yupLocale } = getLocaleData(localeName);
  useEffect(() => {
    if (!props.skipYupInit) {
      yup.setLocale(yupLocale);
      setYupCounter(i => i + 1);
    }
  }, [props.skipYupInit, yupLocale]);

  return (
    <IntlProvider locale={locale} messages={messages}>
      <AntdProvider locale={antLocale}>
        <LocalizedYupContext.Provider value={yup} key={yupCounter}>
          {props.children}
        </LocalizedYupContext.Provider>
      </AntdProvider>
    </IntlProvider>
  );
}
