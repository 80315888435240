import { FlagOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { values } from 'lodash-es';
import { useSelector } from 'react-redux';

import { Locale, getLocaleData } from '../../../config/intl/intl';
import { getLocale, useSettingsActions } from '../../settings/settingsReducer';

const LOCALE_OPTIONS = values(Locale).map(l => ({
  value: l,
  label: getLocaleData(l).label,
}));
export function LocaleSelector() {
  const locale = useSelector(getLocale);
  const { setLocale } = useSettingsActions();

  return (
    <Select
      suffixIcon={<FlagOutlined />}
      options={LOCALE_OPTIONS}
      value={locale}
      onChange={val => setLocale(val)}
    />
  );
}
