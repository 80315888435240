import { RedoOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  MessagingAccount,
  MessagingAccountType,
} from '../../../types/graphqlGenerated';
import { DynamicMenuItem } from '../../common/dynamicMenu/dynamicMenuTypes';
import { ConnectEmailAccountForm } from '../connectAccount/ConnectEmailAccountForm';

export function useReconnectAccountMenuItem({
  account,
}: {
  account: MessagingAccount;
}): DynamicMenuItem {
  const [modalOpen, setModalOpen] = useState(false);

  return {
    key: 'reconnectAccount',
    visible: account.accountType === MessagingAccountType.Email,
    icon: <RedoOutlined />,
    label: <FormattedMessage id="connectedAccounts.reconnectAccount" />,
    onClick: () => setModalOpen(true),
    additionalElements: (
      <Modal
        visible={modalOpen}
        onCancel={() => setModalOpen(false)}
        width="600px"
        destroyOnClose
        footer={null}
      >
        <ConnectEmailAccountForm
          onClose={() => setModalOpen(false)}
          termsAcceptanceTimestamp={new Date()}
        />
      </Modal>
    ),
  };
}
