import './styles/main.less';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { loadPolyfills } from './polyfills';

loadPolyfills().then(() =>
  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
  )
);
