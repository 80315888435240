import {
  BugFilled as BugIcon,
  BulbFilled as BulbIcon,
  CameraOutlined as CameraIcon,
  DeleteOutlined,
  ExclamationCircleFilled as ExclamationIcon,
} from '@ant-design/icons';
import {
  Form as AntForm,
  Button,
  Col,
  Input,
  Row,
  Space,
  Typography,
} from 'antd';
import classNames from 'classnames';
import { Form, FormikContextType, useFormik } from 'formik';
import html2canvas from 'html2canvas';
import { compact, toPairs } from 'lodash-es';
import { useState } from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { showSuccessMessage } from '../../common/utils/messageUtils';
import { requestAnimationFramePromise } from '../../common/utils/promisfiedNative';
import { SimpleFormModal } from '../../components/SimpleFormModal';
import { CircleButton, PrimaryButton } from '../../components/buttons';
import FormField from '../../components/forms/FormField';
import { BasicSwitch } from '../../components/forms/booleanFields';
import { useLocalizedYup } from '../../config/intl/LocaleProvider';
import { UserFeedbackType } from '../../types/graphqlGenerated';
import { getUser } from '../auth/authReducer';
import { useIssuesFileUpload } from '../files/filesGraphql';
import styles from './UserFeedbackPopup.module.less';
import { useCreateUserFeedback } from './issuesGraphql';
import { getDebugEnvInfo } from './issuesUtils';

type UserFeedbackFormValues = {
  type?: UserFeedbackType;
  title: string;
  description: string;
  screenshotFilePath?: string;
  allowScreenSharing: boolean;
};

const INITIAL_VALUES: UserFeedbackFormValues = {
  type: undefined,
  title: '',
  description: '',
  screenshotFilePath: undefined,
  allowScreenSharing: false,
};

type TypeSelectProps = {
  onSelect: (type: UserFeedbackType) => void;
};

function TypeSelect({ onSelect }: TypeSelectProps) {
  return (
    <Row gutter={16}>
      <Col
        className={classNames(styles.IconContainer, styles.BugIconContainer)}
        span={12}
        onClick={() => onSelect(UserFeedbackType.Bug)}
      >
        <BugIcon className={classNames(styles.OptionIcons, styles.Icon)} />
        <FormattedMessage id="issues.dialog.bug" />
      </Col>
      <Col
        className={classNames(styles.IconContainer, styles.BulbIconContainer)}
        span={12}
        onClick={() => onSelect(UserFeedbackType.Idea)}
      >
        <BulbIcon className={classNames(styles.OptionIcons, styles.Icon)} />
        <FormattedMessage id="issues.dialog.idea" />
      </Col>
    </Row>
  );
}

type UserFeedbackFormProps = {
  formik: FormikContextType<UserFeedbackFormValues>;
  sending: boolean;
};

function UserFeedbackForm({ formik, sending }: UserFeedbackFormProps) {
  const { executeUpload, uploading } = useIssuesFileUpload();

  const captureScreenshot = async () => {
    document.body.classList.add('ScreenshotInProgress');
    await requestAnimationFramePromise();
    try {
      const canvas = await html2canvas(document.body);
      const file = await new Promise<File>((resolve, reject) =>
        canvas.toBlob(
          blob =>
            blob
              ? resolve(
                  new File([blob], 'screenshot.png', { type: 'image/png' })
                )
              : reject(),
          'image/png'
        )
      );
      const { result } = executeUpload(file);
      const filePath = await result;
      formik.setFieldValue('screenshotFilePath', filePath);
    } finally {
      document.body.classList.remove('ScreenshotInProgress');
    }
  };

  return (
    <Form>
      <Space
        direction="vertical"
        size={25}
        className={styles.NoMarginFormItems}
      >
        <FormField
          name="title"
          placeholderId="userFeedbackPopup.bug.title"
          required
        >
          {({ field }) => <Input {...field} size="large" />}
        </FormField>

        <FormField
          name="description"
          placeholderId="userFeedbackPopup.description"
          required
        >
          {({ field }) => (
            <TextareaAutosize {...field} className="ant-input" rows={3} />
          )}
        </FormField>

        <FormField
          name="allowScreenSharing"
          labelId="userFeedbackPopup.allowScreenSharing"
        >
          {({ field, label }) => (
            <AntForm.Item label={label}>
              <BasicSwitch {...field} />
            </AntForm.Item>
          )}
        </FormField>

        <div className={classNames(styles.TakeScreenshotButton, 'Flex')}>
          <Button
            icon={<CameraIcon />}
            type="primary"
            size="large"
            onClick={() => captureScreenshot()}
            loading={uploading}
            disabled={!formik.values.allowScreenSharing || uploading}
          >
            <span>
              <FormattedMessage id="userFeedbackPopup.takeScreenshot" />
            </span>
          </Button>

          {!!formik.values.screenshotFilePath && (
            <>
              <Typography className={styles.ScreenshotTakenText}>
                <FormattedMessage id="userFeedbackPopup.screenshotTaken" />
              </Typography>
              <CircleButton
                icon={<DeleteOutlined />}
                onClick={() =>
                  formik.setFieldValue('screenshotFilePath', undefined)
                }
              />
            </>
          )}
        </div>

        <PrimaryButton
          labelId="userFeedbackPopup.submit"
          onClick={() => formik.submitForm()}
          loading={sending}
          disabled={uploading}
        />
      </Space>
    </Form>
  );
}

type UseFormDefinitionOpts = {
  onClose: () => void;
};

function useFormDefinition({ onClose }: UseFormDefinitionOpts) {
  const [createUserFeedback, { loading: sending }] = useCreateUserFeedback();
  const user = useSelector(getUser);

  const onSubmit = async (values: UserFeedbackFormValues) => {
    const description = [
      `ENVIRONMENT:\n${toPairs(getDebugEnvInfo())
        .map(([key, val]) => `* ${key}: ${val}`)
        .join('\n')}`,
      ['USER:', `Email: ${user?.getUsername()}`].join('\n'),
      values.description && `DESCRIPTION:\n\n${values.description}`,
    ].join('\n\n');

    await createUserFeedback({
      input: {
        title: values.title,
        description,
        type: values.type!,
        screenshotFilePath: values.allowScreenSharing
          ? values.screenshotFilePath
          : undefined,
      },
    });

    onClose();
    formik.resetForm();
    showSuccessMessage(
      <FormattedMessage id="userFeedbackPopup.result.success" />
    );
  };

  const yup = useLocalizedYup();

  const validationSchema = yup.object().shape({
    type: yup.string().required(),
    title: yup.string().required(),
    description: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit,
    validationSchema,
  });

  return { formik, validationSchema, onSubmit, sending };
}

type UserFeedbackModalProps = {
  visible: boolean;
  onClose: () => void;
};

export function UserFeedbackModal({
  visible,
  onClose,
}: UserFeedbackModalProps) {
  const { formik, sending } = useFormDefinition({ onClose });

  const handleCancel = () => {
    onClose();
    formik.resetForm();
  };

  const handleGoBack = () => {
    formik.resetForm();
  };

  return (
    <SimpleFormModal
      visible={visible}
      destroyOnClose
      onClose={handleCancel}
      formik={formik}
      titleId="userFeedbackPopup.title"
      wrapClassName="Screenshot--Hide"
      mask={false}
      footer={compact([
        formik.values.type && (
          <Button key="back" onClick={handleGoBack}>
            <FormattedMessage id="userFeedbackPopup.back" />
          </Button>
        ),
        <Button key="cancel" onClick={handleCancel}>
          <FormattedMessage id="userFeedbackPopup.cancel" />
        </Button>,
      ])}
      maskClosable={false}
    >
      {!formik.values.type ? (
        <TypeSelect onSelect={type => formik.setFieldValue('type', type)} />
      ) : (
        <UserFeedbackForm formik={formik} sending={sending} />
      )}
    </SimpleFormModal>
  );
}

export function UserFeedbackButton() {
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <>
      <div className={styles.ButtonContainer}>
        <ExclamationIcon
          className={classNames(
            styles.Button__ErrorIcon,
            styles.Transition,
            styles.Scale
          )}
          width={300}
          height={300}
          onClick={() => setModalVisible(true)}
        />
      </div>
      <UserFeedbackModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
      />
    </>
  );
}
