import { ArrowLeftOutlined } from '@ant-design/icons';
import { generatePath, useNavigate } from 'react-router';

import RouteDefinitions from '../../../app/routes';
import { CircleButton } from '../../../components/buttons';
import { AppSidebar } from '../../layout/AppSidebar';
import style from './CalendarEventDetailSidebar.module.less';

export default function CalendarEventDetailSidebar() {
  const navigate = useNavigate();
  return (
    <AppSidebar>
      <div className={style.SidebarContentContainer}>
        <CircleButton
          onClick={() => navigate(generatePath(RouteDefinitions.calendar))} // discuss, save view (day/week/month) to redux?
          icon={<ArrowLeftOutlined />}
        />
      </div>
    </AppSidebar>
  );
}
