import { ApolloProvider } from '@apollo/client';
import { useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import AppRoutes from './app/AppRoutes';
import { apolloClient, store } from './app/appSingletons';
import AnalyticsProvider from './components/AnalyticsProvider';
import ErrorBoundary from './components/ErrorBoundary';
import LocaleProvider from './config/intl/LocaleProvider';
import AuthInitializer from './modules/auth/AuthInitializer';
import { AuthServiceProvider } from './modules/auth/authService';
import { setupCognitoAuthService } from './modules/auth/cognitoAuthService';
import { BackgroundOperationsProvider } from './modules/common/backgroundOperations/backgroundOperations';

function App() {
  useEffect(() => {
    window.fbAsyncInit = function () {
      FB.init({
        appId: process.env.REACT_APP_FB_APP_ID,
        xfbml: true,
        cookie: true,
        version: 'v19.0',
      });
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode?.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, []);
  return (
    <ErrorBoundary>
      <ReduxProvider store={store}>
        <LocaleProvider>
          <AuthServiceProvider setup={setupCognitoAuthService}>
            <AuthInitializer />
            <ApolloProvider client={apolloClient}>
              <BackgroundOperationsProvider>
                <BrowserRouter>
                  <AnalyticsProvider>
                    <AppRoutes />
                  </AnalyticsProvider>
                </BrowserRouter>
              </BackgroundOperationsProvider>
            </ApolloProvider>
          </AuthServiceProvider>
        </LocaleProvider>
      </ReduxProvider>
    </ErrorBoundary>
  );
}

export default App;
