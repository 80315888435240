import { ArrowLeftOutlined } from '@ant-design/icons';

import RouteDefinitions from '../../../app/routes';
import { OverlaySpinner } from '../../../components/OverlaySpinner';
import { CircleButton } from '../../../components/buttons';
import { MessagingContact } from '../../../types/graphqlGenerated';
import {
  mapFormToGraphqlContact,
  mapGraphqlToFormContact,
} from '../contactConversions';
import { useContactQuery, useUpdateContactMutation } from '../contactsGraphql';
import { useContactsNavigation } from '../contactsNavigation';
import commonStyle from '../layout/ContactsAppCommon.module.less';
import { ContactFormBase } from './ContactFormBase';

type Props = {
  id: number;
};

export function EditContactForm({ id }: Props) {
  const { contact, loading } = useContactQuery({ id });

  const { navigate } = useContactsNavigation();
  const [executeUpdate, { loading: saving }] = useUpdateContactMutation();

  if (loading) {
    return <OverlaySpinner />;
  }

  return (
    <>
      <div className={commonStyle.Toolbar}>
        <div className="FlexRow FlexRow--SpaceSm">
          <CircleButton
            icon={<ArrowLeftOutlined />}
            onClick={() => navigate(RouteDefinitions.contacts)}
          />
        </div>
      </div>
      <div className={commonStyle.Content}>
        <ContactFormBase
          initialValues={mapGraphqlToFormContact(contact as MessagingContact)}
          save={async values => {
            const modifiedContact = await executeUpdate({
              id,
              input: mapFormToGraphqlContact(values),
            });
            return modifiedContact!;
          }}
          saving={saving}
        />
      </div>
    </>
  );
}
