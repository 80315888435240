import { UserOutlined } from '@ant-design/icons';
import { Avatar, Col, Row } from 'antd';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { useResponsiveQueries } from '../../../common/utils/layoutUtils';
import {
  AttachmentsList,
  FileInfo,
} from '../../../components/AttachmentButton';
import { DateLabel } from '../../../components/DateLabel';
import { Whitespace } from '../../../components/Whitespace';
import { TranslationId } from '../../../types/appTypes';
import {
  MessagingMessageFullFragmentFragment,
  MessagingParticipant,
  MessagingThreadDetailFragmentFragment,
} from '../../../types/graphqlGenerated';
import { ParticipantsLabel } from '../../contacts/participantFormats';
import { useCreateFileDownloadLinkMutation } from '../../files/filesGraphql';
import { MessageComposerReactionMessageType } from '../sendMessage/messageComposer/messageComposerTypes';
import { ActionButtons } from './ActionButtons';
import styles from './EmailMessageItem.module.less';
import { HtmlMessageBody } from './HtmlMessageBody';

type Thread = MessagingThreadDetailFragmentFragment;
type Message = MessagingMessageFullFragmentFragment;

type HeaderLabelProps = {
  labelId: TranslationId;
  className?: string;
  children: ReactNode;
};

function HeaderLabel({ labelId, className, children }: HeaderLabelProps) {
  return children ? (
    <div className={styles.LabelRow}>
      <span className={styles.Label}>
        <FormattedMessage id={labelId} />:
        <Whitespace />
      </span>
      <span className={classNames(styles.LabelValue, className)}>
        {children}
      </span>
    </div>
  ) : null;
}

type ParticipantsListWithLabelProps = {
  labelId: TranslationId;
  participants: MessagingParticipant[];
  me?: string;
};

function ParticipantsListWithLabel({
  labelId,
  participants,
  me,
}: ParticipantsListWithLabelProps) {
  return isEmpty(participants) ? null : (
    <HeaderLabel labelId={labelId}>
      <ParticipantsLabel participants={participants} me={me} />
    </HeaderLabel>
  );
}

type ParticipantsByTypeLabelsProps = {
  thread: Thread;
  message: Message;
};

function ParticipantsByTypeLabels({
  thread,
  message,
}: ParticipantsByTypeLabelsProps) {
  const { messagingAccount } = thread;
  const { from, to, cc, bcc, replyTo } = message;
  return (
    <div className={styles.ParticipantsWrapper}>
      <ParticipantsListWithLabel
        labelId="threadDetail.from"
        participants={from}
        me={messagingAccount.platformId || ''}
      />
      <ParticipantsListWithLabel
        labelId="threadDetail.to"
        participants={to}
        me={messagingAccount.platformId || ''}
      />
      <ParticipantsListWithLabel
        labelId="email.cc"
        participants={cc}
        me={messagingAccount.platformId || ''}
      />
      <ParticipantsListWithLabel
        labelId="email.bcc"
        participants={bcc}
        me={messagingAccount.platformId || ''}
      />
      <ParticipantsListWithLabel
        labelId="email.replyTo"
        participants={replyTo}
        me={messagingAccount.platformId || ''}
      />
    </div>
  );
}

type EmailMessageItemProps = {
  thread: Thread;
  message: Message;
  inlineComposerOpen?: MessageComposerReactionMessageType;
  setInlineComposerOpen: (mode: MessageComposerReactionMessageType) => void;
};

export function EmailMessageItem({
  thread,
  message,
  inlineComposerOpen,
  setInlineComposerOpen,
}: EmailMessageItemProps) {
  const [downloadFileMutate] = useCreateFileDownloadLinkMutation({
    onCompleted: res => {
      const url = res;
      if (url) {
        window.open(url, '__blank');
      }
    },
  });
  const downloadFile = async (file: FileInfo) => {
    await downloadFileMutate({ id: file.id!, triggerDownload: true });
  };

  const { id, subject, date: rawDate, body, files } = message;
  const date = rawDate && new Date(rawDate);

  const { sm: isSmallScreen, md: isMediumScreen } = useResponsiveQueries();

  return (
    <div className={styles.Container}>
      {isSmallScreen || isMediumScreen ? (
        <Row className={styles.Header} key={id} gutter={8}>
          <Col>
            <Avatar size={38} icon={<UserOutlined />} />
          </Col>
          <Col className="Flex--1">
            <ParticipantsByTypeLabels thread={thread} message={message} />
            {date && (
              <div>
                <DateLabel date={date} />
              </div>
            )}
          </Col>
        </Row>
      ) : (
        <Row
          align="middle"
          justify="space-between"
          className={styles.Header}
          key={id}
        >
          <Col className={styles.Left}>
            <Row align="middle" gutter={20}>
              <Col>
                <Avatar size={38} icon={<UserOutlined />} />
              </Col>
              <Col className="Flex--1">
                <HeaderLabel
                  labelId="threadDetail.subject"
                  className={styles.Subject}
                >
                  {subject}
                </HeaderLabel>
                <ParticipantsByTypeLabels thread={thread} message={message} />
              </Col>
            </Row>
          </Col>
          <Col>
            <Row align="middle">
              {date && (
                <Col>
                  <div className={styles.DateLabel}>
                    <DateLabel date={date} />
                  </div>
                </Col>
              )}
              <Col>
                <div className={styles.Actions}>
                  <ActionButtons
                    setInlineComposerOpen={setInlineComposerOpen}
                    withCaptions={false}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <AttachmentsList
        files={files.map(f => ({ ...f, key: f.id, downloadable: true }))}
        download={downloadFile}
      />
      <div className={styles.BodyWrapper}>
        <HtmlMessageBody id={id} files={files} body={body || ''} />
      </div>
      {!inlineComposerOpen && (
        <ActionButtons
          setInlineComposerOpen={setInlineComposerOpen}
          withCaptions={true}
        />
      )}
    </div>
  );
}
