import { Col, Row } from 'antd';
import { identity } from 'lodash-es';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  MessagingAccountType,
  MessagingFolderType,
  MessagingParticipant,
  MessagingThread,
} from '../../types/graphqlGenerated';
import { ParticipantsLabel } from '../contacts/participantFormats';

type InnerListProps = {
  participants: MessagingParticipant[];
  prefix?: ReactNode;
  allowExpand: boolean;
  me?: string;
  showFull: boolean;
};

function InnerList({
  participants,
  prefix,
  allowExpand,
  me,
  showFull,
}: InnerListProps) {
  if (prefix) {
    return (
      <Row align="top" gutter={5} wrap={false}>
        <Col>{prefix}</Col>
        <Col className="Flex--1" style={{ minWidth: 0 }}>
          <ParticipantsLabel
            participants={participants}
            allowExpand={allowExpand}
            showFull={showFull}
            me={me}
          />
        </Col>
      </Row>
    );
  }

  return (
    <ParticipantsLabel
      participants={participants}
      allowExpand={allowExpand}
      me={me}
    />
  );
}

type ThreadParticipantsListProps = {
  thread: Pick<
    MessagingThread,
    'from' | 'to' | 'participants' | 'subject' | 'messagingAccount'
  > & {
    messagingAccount: Pick<MessagingThread['messagingAccount'], 'platformId'>;
  };
  activeFolderType?: MessagingFolderType;
  allowExpand?: boolean;
  modifyPrefix?: (node: ReactNode) => ReactNode;
  isSubject?: boolean;
  showFull: boolean;
};

export function ThreadParticipantsList({
  thread,
  activeFolderType,
  allowExpand = false,
  modifyPrefix = identity,
  showFull,
}: ThreadParticipantsListProps) {
  if (
    !activeFolderType ||
    thread.messagingAccount.accountType === MessagingAccountType.Whatsapp
  ) {
    return (
      <InnerList
        participants={thread.participants}
        prefix={modifyPrefix(
          <>
            <FormattedMessage id="message.participants" />:
          </>
        )}
        allowExpand={allowExpand}
        me={thread.messagingAccount.platformId || undefined}
        showFull={showFull}
      />
    );
  }

  if (activeFolderType === MessagingFolderType.Sent) {
    return (
      <InnerList
        participants={thread.to}
        prefix={modifyPrefix(
          <>
            <FormattedMessage id="message.to" />:
          </>
        )}
        allowExpand={allowExpand}
        me={thread.messagingAccount.platformId || undefined}
        showFull={showFull}
      />
    );
  }

  return (
    <InnerList
      participants={thread.from}
      prefix={modifyPrefix(
        <>
          <FormattedMessage id="message.from" />:
        </>
      )}
      allowExpand={allowExpand}
      me={thread.messagingAccount.platformId || undefined}
      showFull={showFull}
    />
  );
}
