import { gql } from '@apollo/client';

import { createUseQuery } from '../../common/utils/graphqlUtils';
import {
  GetTemplateMessagesQuery,
  GetTemplateMessagesQueryVariables,
} from '../../types/graphqlGenerated';

export const TemplateFieldsFragment = gql`
  fragment TemplateFieldsFragment on TemplatesFields {
    id
    name
    category
    language
    components {
      type
      text
      format
      example
      buttons {
        type
        text
        example
        url
      }
    }
  }
`;

const TEMPLATES_QUERY = gql`
  ${TemplateFieldsFragment}
  query GetTemplateMessages($input: GetMessageTemplatesInput!) {
    getTemplateMessages(input: $input) {
      data {
        ...TemplateFieldsFragment
      }
    }
  }
`;

export const useGetTemplateMessages = createUseQuery<
  GetTemplateMessagesQuery,
  GetTemplateMessagesQueryVariables,
  'templates',
  GetTemplateMessagesQuery['getTemplateMessages']
>(TEMPLATES_QUERY, {
  extractResult: resp => resp.getTemplateMessages,
  resultName: 'templates',
  opts: {
    fetchPolicy: 'cache-and-network',
  },
});
