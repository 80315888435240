import { deburr, isEmpty } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';

export function useNodeInnerText<EL extends HTMLElement>() {
  const ref = useRef<EL>(null);
  const [text, setText] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (ref.current) {
      setText(ref.current.innerText);
    }
  });

  return { ref, text };
}

export function normalizeSearchString(searchTerm: string) {
  return deburr(searchTerm.toLowerCase());
}

export const replacePlaceholders = (
  originalText: string,
  replacements: string[]
): string => {
  if (isEmpty(replacements)) {
    return originalText;
  }
  const regex = new RegExp(/\{\{(\d+)\}\}/g);
  return originalText.replace(regex, (match: string, index: string) => {
    const replacementIndex = parseInt(index) - 1; // Array index starts from 0
    return replacements[replacementIndex] || match; // Replace with corresponding string or keep the placeholder
  });
};
