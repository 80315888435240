import {
  // PlusOutlined,
  // SearchOutlined,
  WarningOutlined,
} from '@ant-design/icons';
// import { Divider, Input, Space, Switch } from 'antd';
import { isString } from 'lodash-es';
import { useCallback } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { FormattedMessage } from 'react-intl';

// import { useFormattedMessage } from '../../../common/utils/intlUtils';
import { showConfirm } from '../../../common/utils/messageUtils';
import { ErrorAlert } from '../../../components/ErrorAlert';
import { OverlaySpinner } from '../../../components/OverlaySpinner';
// import SidebarControls, {
//   SidebarControlButton,
// } from '../../../components/SidebarControls';
import { VerticalMenu } from '../../../components/VerticalMenu';
import {
  // MessagingFolder,
  MessagingFolderType,
} from '../../../types/graphqlGenerated';
import {
  DropTarget,
  DropTargetContainer,
  DropTargetHandler,
} from '../../common/dragAndDrop/DropTarget';
import { DragAndDropItemType } from '../../common/dragAndDrop/dragAndDropConstants';
import { useMoveThreadsToSpecialFolderMutation } from '../threadsGraphql';
import { useFoldersQuery, useFoldersStatsQuery } from './foldersGraphql';
import { SPECIAL_FOLDERS, getFolderIcon } from './messagingFolderConstants';
import { SpecialFolderMenuItem } from './messagingFolderItems';

type MessagingFoldersProps = {
  activeFolderKey?: string;
};
/* TURNED-OFF FOLDERS */
// function extractItemFromDrop(
//   res: Partial<DropResult>,
//   dndIndicesMap
// ): MessagingFolderType | undefined {
//   const { combine, destination } = res;
//
//   if (combine) {
//     const items = values(dndIndicesMap);
//     const typeOrId = combine.draggableId.split(':')[1];
//     return items.find(it =>
//       isString(it) ? it === typeOrId : `${it.id}` === typeOrId
//     );
//   } else if (destination) {
//     return dndIndicesMap[destination.index];
//   }
// }

function extractItemFromDrop(
  res: Partial<DropResult>
): MessagingFolderType | undefined {
  const { combine, destination } = res;

  if (combine) {
    const typeOrId = combine.draggableId.split(':')[1];
    return SPECIAL_FOLDERS.find(it => it === typeOrId);
  } else if (destination) {
    return SPECIAL_FOLDERS[destination.index];
  }
}
/* TURNED-OFF FOLDERS */
// function makeDNDIndicesMaps(treeData) {
//   const indexToFolder = {};
//   const folderToIndex = {};
//   SPECIAL_FOLDERS.forEach((f, idx) => {
//     indexToFolder[idx] = f;
//     folderToIndex[f['id'] || f] = idx;
//   });
//   treeToArrayDepthFirst(treeData).forEach((f, idx) => {
//     const index = SPECIAL_FOLDERS.length + idx;
//     indexToFolder[index] = f;
//     folderToIndex[f.id] = index;
//   });
//   return { indexToFolder, folderToIndex };
// }
//
// type FoldersSearchProps = {
//   value: string;
//   setValue: (val: string) => void;
// };
//
// function FoldersSearch({ value, setValue }: FoldersSearchProps) {
//   return (
//     <Input
//       placeholder={useFormattedMessage('messagesSidebar.folders.search')}
//       value={value}
//       onChange={e => setValue(e.target.value)}
//       allowClear
//       prefix={<SearchOutlined />}
//     />
//   );
// }

export function MessagingFolders({ activeFolderKey }: MessagingFoldersProps) {
  const { loading, error, folders } = useFoldersQuery();
  const { foldersStats } = useFoldersStatsQuery();
  /* TURNED-OFF FOLDERS */
  // const [addingActive, setAddingActive] = useState(false);
  // const [searchedFolderName, setSearchedFolderName] = useState<string>('');
  // const [showHiddenFolders, setShowHiddenFolders] = useState(false);

  const [moveToSpecialFolder] = useMoveThreadsToSpecialFolderMutation();
  /* TURNED-OFF FOLDERS */
  // const [folderTreeData, dndMaps] = useMemo(() => {
  //   const nonSpecialFolders = folders?.filter(f => !f.type) ?? [];
  //   const folderTreeDataInner = parseFolderTreeStructure(
  //     nonSpecialFolders as MessagingFolder[],
  //     showHiddenFolders,
  //     searchedFolderName
  //   );
  //   // https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/api/draggable.md#required-props
  //   const dndMapsInner = makeDNDIndicesMaps(folderTreeDataInner);
  //   return [folderTreeDataInner, dndMapsInner];
  // }, [folders, searchedFolderName, showHiddenFolders]);
  /* TURNED-OFF FOLDERS */
  // const handleDrop = useCallback<DropTargetHandler>(
  //   (res, { filter, onSuccess }) => {
  //     const item = extractItemFromDrop(res, dndMaps.indexToFolder);
  //     if (item && item !== MessagingFolderType.All) {
  //       showConfirm({
  //         title: <FormattedMessage id="moveThreads.modalTitle" />,
  //         content: <FormattedMessage id="moveThreads.modalContent" />,
  //         onOk: async () => {
  //           if (isString(item)) {
  //             await moveToSpecialFolder({ filter, folderType: item });
  //           } else {
  //             await moveToFolder({ filter, folderId: item['id'] });
  //           }
  //           onSuccess?.();
  //         },
  //       });
  //     }
  //   },
  //   [dndMaps, moveToFolder, moveToSpecialFolder]
  // );

  const handleDrop = useCallback<DropTargetHandler>(
    (res, { filter, onSuccess }) => {
      const item = extractItemFromDrop(res);
      if (item && item !== MessagingFolderType.All) {
        showConfirm({
          title: <FormattedMessage id="moveThreads.modalTitle" />,
          content: <FormattedMessage id="moveThreads.modalContent" />,
          onOk: async () => {
            if (isString(item)) {
              await moveToSpecialFolder({ filter, folderType: item });
            }
            onSuccess?.();
          },
        });
      }
    },
    [moveToSpecialFolder]
  );

  if (error) {
    return <ErrorAlert error={error} />;
  }
  if (loading) {
    return <OverlaySpinner />;
  }

  if (!folders) {
    return null;
  }

  return (
    <>
      <DropTargetContainer
        itemType={DragAndDropItemType.Folder}
        acceptItemTypes={[DragAndDropItemType.Thread]}
        handleDrop={handleDrop}
      >
        <VerticalMenu>
          {SPECIAL_FOLDERS.map((ft, i) => (
            <DropTarget
              key={ft}
              id={ft}
              index={i}
              disabled={ft === MessagingFolderType.All}
              disabledTooltip={
                <div className="FlexRow FlexRow--SpaceSm">
                  <WarningOutlined />
                  <FormattedMessage id="moveThreads.disabledFolder" />
                </div>
              }
            >
              <SpecialFolderMenuItem
                icon={getFolderIcon(ft)}
                folderType={ft}
                folders={folders}
                foldersStats={foldersStats}
                activeFolderKey={activeFolderKey}
              />
            </DropTarget>
          ))}
          {/* TURNED-OFF FOLDERS */}
          {/*{(!isEmpty(folderTreeData) || searchedFolderName) && (*/}
          {/*  <>*/}
          {/*    <Divider type="horizontal" key="div2" />*/}
          {/*    <Space direction="vertical" size={5}>*/}
          {/*      <FoldersSearch*/}
          {/*        value={searchedFolderName}*/}
          {/*        setValue={setSearchedFolderName}*/}
          {/*      />*/}
          {/*      <FoldersTree*/}
          {/*        foldersStats={foldersStats}*/}
          {/*        dndFolderToIndexMap={dndMaps.folderToIndex}*/}
          {/*        treeData={folderTreeData}*/}
          {/*      />*/}
          {/*    </Space>*/}
          {/*  </>*/}
          {/*)}*/}
          {/*{addingActive && (*/}
          {/*  <MessagingFoldersInlineAddForm*/}
          {/*    stopAdding={() => setAddingActive(false)}*/}
          {/*  />*/}
          {/*)}*/}
        </VerticalMenu>
      </DropTargetContainer>
      {/* TURNED-OFF FOLDERS */}
      {/*{!addingActive && (*/}
      {/*  <SidebarControls>*/}
      {/*    <SidebarControlButton*/}
      {/*      tooltipId="messagesSidebar.folders.add"*/}
      {/*      icon={<PlusOutlined />}*/}
      {/*      onClick={() => setAddingActive(true)}*/}
      {/*    />*/}
      {/*  </SidebarControls>*/}
      {/*)}*/}
      {/*<div className="Flex" style={{ gap: 10 }}>*/}
      {/*  <FormattedMessage id="messagesSidebar.folders.showHiddenFolders" />*/}
      {/*  <Switch*/}
      {/*    onChange={() => setShowHiddenFolders(prev => !prev)}*/}
      {/*    checked={showHiddenFolders}*/}
      {/*  />*/}
      {/*</div>*/}
    </>
  );
}
