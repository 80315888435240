import { Space } from 'antd';
import classNames from 'classnames';
import { uniq } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import Version from '../../components/Version';
import { PROJECT_BEGINNING_YEAR } from '../../config/constants';
import style from './Footer.module.less';

export const SimpleFooterClasses = {
  '--Bottom': style['SimpleFooter--Bottom'],
};

interface Props {
  className?: string;
}

export function SimpleFooter(props: Props) {
  const year = new Date().getFullYear();
  const range = [PROJECT_BEGINNING_YEAR, year];
  const rangeStr = uniq(range).join(' - ');
  return (
    <div className={classNames(style.SimpleFooter, props.className)}>
      <Space direction="horizontal" size={10} split="|">
        <span>
          Copyright ©{rangeStr} <FormattedMessage id="brand.name" />
        </span>
        <Version />
        {/*<Link TODO TURN ON IN PROD*/}
        {/*  to={RouteDefinitions.docs}*/}
        {/*  target="_blank"*/}
        {/*  rel="noopener noreferrer"*/}
        {/*>*/}
        {/*  <FormattedMessage id="base.docs" />*/}
        {/*</Link>*/}
      </Space>
    </div>
  );
}
