import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { DEFAULT_NON_LOGGED_ROUTE } from '../../../app/routes';
import LoadingPage from '../../../pages/LoadingPage';
import { getLoggedIn, getUserSessionLoading } from '../authReducer';

interface Props {
  children: ReactNode;
}

export default function LoginGuard(props: Props) {
  const loggedIn = useSelector(getLoggedIn);
  const userSessionLoading = useSelector(getUserSessionLoading);

  if (userSessionLoading) {
    return <LoadingPage titleId="loading.authenticating" />;
  }

  if (!loggedIn) {
    return <Navigate to={DEFAULT_NON_LOGGED_ROUTE} />;
  }

  return <>{props.children}</>;
}
