import {
  compact,
  isArray,
  isEmpty,
  isNil,
  isNumber,
  isString,
  pickBy,
  sortBy,
} from 'lodash-es';
import { ByWeekday, RRule } from 'rrule';
import { formatRruleDateTime } from 'vifzack-common-utils';

import { CalendarEventFullFragmentFragment } from '../../../types/graphqlGenerated';
import {
  RECURRENCE_EMPTY_INITIAL_VALUES,
  RRULE_DAYS,
} from '../calendarEventEditing/recurrenceConstants';
import {
  RecurrenceEndType,
  RecurrenceFormValues,
  RecurrenceFrequency,
} from './calendarEventTypes';

function prepareRRule(values: RecurrenceFormValues) {
  const frequency = values.frequencyType!;
  const byDayRaw =
    !isEmpty(values.selectedWeekDays) &&
    values.frequencyType! === RecurrenceFrequency.WEEKLY
      ? values.selectedWeekDays
      : undefined;
  const byDay = byDayRaw?.map(day => (isNaN(day) ? day : RRULE_DAYS[day]));
  const byMonth =
    !isEmpty(values.selectedMonths) &&
    values.frequencyType! === RecurrenceFrequency.YEARLY
      ? values.selectedMonths
      : undefined;
  const interval = values.frequencyInterval;
  const count =
    !values.isForever && values.endType === 'count' ? values.count : undefined;
  const until =
    !values.isForever && values.endType === 'until'
      ? values.lastOccurenceDate
      : undefined;
  const byMonthDay =
    isNumber(values.selectedMonthDay) &&
    values.frequencyType! === RecurrenceFrequency.MONTHLY
      ? [values.selectedMonthDay]
      : undefined;

  return {
    frequency,
    byDayRaw,
    byDay,
    byMonth,
    interval,
    count,
    until,
    byMonthDay,
  };
}

export function buildRRule(values: RecurrenceFormValues) {
  const {
    frequency,
    byDayRaw,
    byDay,
    byMonth,
    interval,
    count,
    until,
    byMonthDay,
  } = prepareRRule(values);

  const parts = compact([
    frequency && `FREQ=${frequency}`,
    byDayRaw && `BYDAY=${byDay!.join(',')}`,
    byMonth && `BYMONTH=${sortBy(byMonth.map(val => val + 1)).join(',')}`,
    byMonthDay && `BYMONTHDAY=${byMonthDay.join(',')}`,
    isNumber(count) && `COUNT=${count}`,
    isNumber(interval) && `INTERVAL=${interval}`,
    until && `UNTIL=${formatRruleDateTime(until)}`,
  ]);

  return `RRULE:${parts.join(';')}`;
}

export const arrayToRrule = (rrule: string[]) =>
  RRule.fromString(
    rrule
      // EXDATE is not fully supported by the library and is not shown in form anyway
      .filter(str => !str.includes('EXDATE'))
      .join('\n')
  );

function parseRRuleRaw(rrule: string[]) {
  const rruleInstance = arrayToRrule(rrule);

  let endType: RecurrenceFormValues['endType'];
  if (isNumber(rruleInstance.origOptions.count)) {
    endType = RecurrenceEndType.COUNT;
  }
  if (rruleInstance.origOptions.until) {
    endType = RecurrenceEndType.UNTIL;
  }

  let frequencyType: RecurrenceFormValues['frequencyType'] =
    RecurrenceFrequency.DAILY;
  if (rruleInstance.origOptions.bymonth) {
    frequencyType = RecurrenceFrequency.YEARLY;
  } else if (rruleInstance.origOptions.bymonthday) {
    frequencyType = RecurrenceFrequency.MONTHLY;
  } else if (rruleInstance.origOptions.byweekday) {
    frequencyType = RecurrenceFrequency.WEEKLY;
  }

  let selectedMonthDay: RecurrenceFormValues['selectedMonthDay'];
  if (isNumber(rruleInstance.origOptions.bymonthday)) {
    selectedMonthDay = rruleInstance.origOptions.bymonthday;
  } else if (isArray(rruleInstance.origOptions.bymonthday)) {
    selectedMonthDay = rruleInstance.origOptions.bymonthday[0];
  }

  let byweekdayArray: ByWeekday[] | undefined = undefined;
  if (isArray(rruleInstance.origOptions.byweekday)) {
    byweekdayArray = rruleInstance.origOptions.byweekday;
  } else if (rruleInstance.origOptions.byweekday) {
    byweekdayArray = [rruleInstance.origOptions.byweekday];
  }

  let selectedMonths: number[] | undefined = undefined;
  if (isArray(rruleInstance.origOptions.bymonthday)) {
    selectedMonths = rruleInstance.origOptions.bymonthday;
  } else if (rruleInstance.origOptions.bymonthday) {
    selectedMonths = [rruleInstance.origOptions.bymonthday];
  }

  return {
    rruleInstance,
    endType,
    frequencyType,
    selectedMonthDay,
    byweekdayArray,
    selectedMonths,
  };
}

export function parseRRule(
  masterEvent?: CalendarEventFullFragmentFragment['masterEvent']
): RecurrenceFormValues {
  if (!masterEvent?.recurrence?.rrule) {
    return RECURRENCE_EMPTY_INITIAL_VALUES;
  }

  const {
    rruleInstance,
    endType,
    frequencyType,
    selectedMonthDay,
    byweekdayArray,
    selectedMonths,
  } = parseRRuleRaw(masterEvent.recurrence.rrule);

  const source: RecurrenceFormValues = {
    enabled: true,
    frequencyInterval: rruleInstance.origOptions.interval,
    frequencyType,
    isForever: !endType,
    count: rruleInstance.origOptions.count || undefined,
    endType,
    lastOccurenceDate: rruleInstance.origOptions.until || undefined,
    selectedWeekDays: byweekdayArray?.map(w => {
      if (isNumber(w)) {
        return w;
      }
      if (isString(w)) {
        return RRULE_DAYS.indexOf(w);
      }
      return w.getJsWeekday();
    }),
    selectedMonthDay,
    selectedMonths,
  };

  return {
    ...RECURRENCE_EMPTY_INITIAL_VALUES,
    ...pickBy(source, v => !isNil(v)),
  };
}
