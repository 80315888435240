import {
  MessagingContact,
  MessagingContactInput,
} from '../../types/graphqlGenerated';
import { ContactFormValues } from './contactEditing/contactEditingTypes';

const mapPhoneNumberType = (phoneNumbers: string[]) =>
  phoneNumbers.filter(w => w).map(num => num.replace(/\s+/g, ''));

export function mapFormToGraphqlContact(
  values: ContactFormValues
): MessagingContactInput {
  return {
    givenName: values.givenName,
    middleName: values.middleName,
    surname: values.surname,
    emails: values.emails.filter(e => e),
    phoneNumbers: mapPhoneNumberType(values.phoneNumbers),
    pictureUrl: values.picture?.[0]?.response?.src,
    whatsappNumbers: mapPhoneNumberType(values.whatsappNumbers),
    messagingAccountId: values.messagingAccountId,
  };
}

export function mapGraphqlToFormContact(
  input: MessagingContact
): ContactFormValues {
  return {
    givenName: input.givenName ?? undefined,
    middleName: input.middleName ?? undefined,
    surname: input.surname ?? undefined,
    emails: input.emails,
    phoneNumbers: input.phoneNumbers,
    picture: input.pictureUrl
      ? [
          {
            name: input.pictureUrl.split(/\//g).pop()!,
            uid: input.pictureUrl,
            response: { src: input.pictureUrl, id: 0, contentId: '' },
            status: 'done',
          },
        ]
      : [],
    whatsappNumbers: input.whatsappAccounts.map(w => w.phoneNumber),
    messagingAccount: input.messagingAccount,
  };
}
