import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';

import { FailureIconLarge } from '../components/icons';
import { ConnectAccountButton } from '../modules/accounts/ConnectAccountButton';
import { ConnectResult } from '../modules/accounts/connectAccount/ConnectResult';
import { MessagesAppTemplate } from '../modules/messagesApp/layout/MessagesAppTemplate';

export const ConnectFailPage = () => (
  <MessagesAppTemplate>
    <ConnectResult>
      <FailureIconLarge />
      <Typography.Title level={2}>
        <FormattedMessage id="connectAccount.failureMessage" />
      </Typography.Title>
      <ConnectAccountButton labelId="connectAccount.button.tryAgain" />
    </ConnectResult>
  </MessagesAppTemplate>
);
