import { LogoutOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { useResponsiveQueries } from '../../common/utils/layoutUtils';
import { LogoSmall } from '../../components/Logo';
import Version from '../../components/Version';
import { useAuthActions } from '../auth/authReducer';
import style from './AppSidebar.module.less';
import { NavigationMenu } from './header/DesktopHeader';
import { LocaleSelector } from './header/LocaleSelector';
import { ToolbarButton } from './header/ToolbarButton';
import { useLayoutActions } from './layoutReducer';

type AppSidebarProps = {
  children: ReactNode;
};

export function AppSidebar({ children }: AppSidebarProps) {
  const { logoutUser } = useAuthActions();
  const { setSidebarCollapse } = useLayoutActions();
  const { sm: isSmallScreen, md: isMediumScreen } = useResponsiveQueries();

  return isSmallScreen || isMediumScreen ? (
    <div className={style.SiderInner}>
      {isSmallScreen && (
        <>
          <LogoSmall />
          <NavigationMenu
            theme="light"
            mode="inline"
            className={style.Menu}
            onClick={() => {
              setSidebarCollapse(true);
            }}
          />
        </>
      )}
      <div className={style.Container}>
        {children}
        <Space direction="vertical" size={30} align="center">
          <LocaleSelector />
          <Version />
          <ToolbarButton
            tooltip={<FormattedMessage id="labels.logout" />}
            icon={<LogoutOutlined />}
            onClick={() => logoutUser()}
          />
        </Space>
      </div>
    </div>
  ) : (
    <div className={style.Container}>{children}</div>
  );
}
