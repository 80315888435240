import { ArrowLeftOutlined } from '@ant-design/icons';

import RouteDefinitions from '../../../app/routes';
import { CircleButton } from '../../../components/buttons';
import { mapFormToGraphqlContact } from '../contactConversions';
import { useCreateContactMutation } from '../contactsGraphql';
import { useContactsNavigation } from '../contactsNavigation';
import commonStyle from '../layout/ContactsAppCommon.module.less';
import { ContactFormBase } from './ContactFormBase';
import { ContactFormValues } from './contactEditingTypes';

const INITIAL_VALUES: ContactFormValues = {
  givenName: '',
  middleName: '',
  surname: '',
  emails: [],
  phoneNumbers: [],
  whatsappNumbers: [],
  picture: [],
};

export function CreateContactForm() {
  const { navigate } = useContactsNavigation();
  const [executeCreate, { loading: saving }] = useCreateContactMutation();

  return (
    <>
      <div className={commonStyle.Toolbar}>
        <div className="FlexRow FlexRow--SpaceSm">
          <CircleButton
            icon={<ArrowLeftOutlined />}
            onClick={() => navigate(RouteDefinitions.contacts)}
          />
        </div>
      </div>
      <div className={commonStyle.Content}>
        <ContactFormBase
          initialValues={INITIAL_VALUES}
          save={async values => {
            const contact = await executeCreate({
              input: mapFormToGraphqlContact(values),
            });
            return contact!;
          }}
          saving={saving}
        />
      </div>
    </>
  );
}
