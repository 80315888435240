import googleAnalytics from '@analytics/google-analytics';
import Analytics, { AnalyticsInstance } from 'analytics';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router';

import { config } from '../config/config';

const AnalyticsContext = createContext<{
  analytics: AnalyticsInstance | undefined;
}>({ analytics: undefined });

export const useAnalytics = () => useContext(AnalyticsContext).analytics;

type AnalyticsProviderProps = {
  children: ReactNode;
};

export default function AnalyticsProvider({
  children,
}: AnalyticsProviderProps) {
  const [analytics, setAnalytics] = useState<AnalyticsInstance>();
  useEffect(() => {
    if (config.googleAnalyticsMeasurementId) {
      setAnalytics(
        Analytics({
          app: 'Vifzack',
          plugins: [
            googleAnalytics({
              measurementIds: [config.googleAnalyticsMeasurementId],
            }),
          ],
        })
      );
    }
  }, []);
  const { pathname } = useLocation();
  useEffect(() => {
    analytics?.page();
  }, [analytics, pathname]);

  return (
    <AnalyticsContext.Provider value={{ analytics }}>
      {children}
    </AnalyticsContext.Provider>
  );
}
