import { DeleteOutlined } from '@ant-design/icons';
import { Alert, Checkbox, Space } from 'antd';
import { useFormik } from 'formik';
import { isNumber } from 'lodash-es';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../common/utils/messageUtils';
import { SimpleFormModal } from '../../../components/SimpleFormModal';
import { Whitespace } from '../../../components/Whitespace';
import FormField from '../../../components/forms/FormField';
import { useLocalizedYup } from '../../../config/intl/LocaleProvider';
import { MessagingAccount } from '../../../types/graphqlGenerated';
import { DynamicMenuItem } from '../../common/dynamicMenu/dynamicMenuTypes';
import { MessagingAccountLabel } from '../accountFormats';
import { useDisconnectAccountMutation } from '../accountsGraphql';
import styles from './AccountsListItem.module.less';

const onError = () =>
  showErrorMessage(
    <FormattedMessage id="connectedAccounts.disconnect.error" />
  );

export function useDisconnectAccountMenuItem({
  account,
}: {
  account: MessagingAccount;
}): DynamicMenuItem {
  const [disconnectingAccount, setDisconnectingAccount] = useState<
    number | undefined
  >(undefined);
  const [disconnect] = useDisconnectAccountMutation({
    onOperationSuccess: () =>
      showSuccessMessage(
        <FormattedMessage id="connectedAccounts.disconnect.success" />
      ),
    onOperationError: onError,
    onError,
  });
  const yup = useLocalizedYup();
  const formik = useFormik({
    initialValues: { acknowledge: false },
    onSubmit: () => disconnect({ id: disconnectingAccount! }),
    validationSchema: yup.object().shape({
      acknowledge: yup.boolean().isTrue(),
    }),
  });

  return {
    key: 'disconnect',
    visible: true,
    icon: <DeleteOutlined className={styles['Icon--Neutral']} />,
    label: <FormattedMessage id="connectedAccounts.disconnect" />,
    onClick: () => setDisconnectingAccount(account.id),
    additionalElements: (
      <SimpleFormModal
        visible={isNumber(disconnectingAccount)}
        onClose={() => setDisconnectingAccount(undefined)}
        titleId="connectedAccounts.disconnect.modalTitle"
        formik={formik}
        destroyOnClose
      >
        <Space direction="vertical" size={20}>
          <Alert
            type="warning"
            message={
              <Space direction="vertical" size={10}>
                <div>
                  <FormattedMessage id="connectedAccounts.disconnect.modalContent" />
                </div>
                <div className="FlexRow FlexRow--SpaceSm">
                  <span>
                    <FormattedMessage id="connectedAccounts.disconnect.account" />
                    :<Whitespace />
                  </span>
                  <MessagingAccountLabel account={account} />
                </div>
              </Space>
            }
          />
          <FormField name="acknowledge">
            {({ field: { value: checked, ...fieldRest } }) => (
              <div>
                <Checkbox {...fieldRest} checked={checked}>
                  <FormattedMessage id="connectedAccounts.disconnect.acknowledge" />
                </Checkbox>
              </div>
            )}
          </FormField>
        </Space>
      </SimpleFormModal>
    ),
  };
}
