import { generatePath } from 'react-router';

import { MessagingFolderType } from '../types/graphqlGenerated';

enum RouteDefinitions {
  root = '/',
  login = '/login',
  signup = '/signup',
  docs = '/docs',
  silentSignup = '/silent-signup',
  terms = '/terms',
  privacyPolicy = '/privacy',
  calendar = '/calendar',
  calendarEventDetail = '/calendar/:calendarId/event/:eventId',
  contacts = '/contacts',
  contactDetail = '/contacts/:contactId',
  createContact = '/contacts/create',
  editContact = '/contacts/:contactId/edit',
  forgotPassword = '/forgot-password',
  messagesFilterView = '/messages/filter/:filterId',
  messagesFolderView = '/messages/folder/:folderTypeOrId',
  threadDetailRelative = 'thread/:threadId',
  sendMessage = '/send-message',
  connectSuccess = '/messaging-account/connect-success',
  waConnectSuccess = '/messaging-account/whatsapp-connect-success',
  connectFail = '/messaging-account/connect-fail',
}

export const messagesFolderViewInboxRoute = generatePath(
  RouteDefinitions.messagesFolderView,
  {
    folderTypeOrId: MessagingFolderType.Inbox,
  }
);

export const DEFAULT_LOGGED_ROUTE = messagesFolderViewInboxRoute;

export const DEFAULT_NON_LOGGED_ROUTE = RouteDefinitions.login;

export const getMessageListRoute = (folderTypeOrId: string | number) =>
  generatePath(RouteDefinitions.messagesFolderView, {
    folderTypeOrId: `${folderTypeOrId}`,
  });

export default RouteDefinitions;
