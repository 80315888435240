import { CognitoUser } from 'amazon-cognito-identity-js';
import { Result } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { AutoRedirect, AutoRedirectText } from '../../components/Redirect';
import SignupInfoForm from './SignupInfoForm';
import SignupVerifyForm from './SignupVerifyForm';

export default function SignupSteps() {
  const [user, setUser] = useState<CognitoUser>();
  const onInfoSaved = (newUser: CognitoUser) => {
    requestAnimationFrame(() => setUser(newUser));
  };

  const [done, setDone] = useState<boolean>();
  const onVerified = () => {
    setDone(true);
  };

  if (!user) {
    return <SignupInfoForm onSuccess={onInfoSaved} />;
  }

  if (!done) {
    return <SignupVerifyForm user={user} onSuccess={onVerified} />;
  }

  return (
    <>
      <AutoRedirect />
      <Result
        status="success"
        title={<FormattedMessage id="initialAuth.signup.success.title" />}
        subTitle={<AutoRedirectText />}
      />
    </>
  );
}
