import { gql } from '@apollo/client';

import { createUseQuery } from '../../../common/utils/graphqlUtils';
import {
  GetMessagingFoldersQuery,
  GetMessagingFoldersQueryVariables,
  GetMessagingFoldersStatsQuery,
  GetMessagingFoldersStatsQueryVariables,
} from '../../../types/graphqlGenerated';
import { AccountFullFragment } from '../../accounts/accountsGraphql';
/* TURNED-OFF FOLDERS */
/*
add to query FOLDERS_QUERY
      parentNode {
        id
      }
      nextSibling {
        id
      }
 */

const FOLDERS_QUERY = gql`
  ${AccountFullFragment}
  query GetMessagingFolders {
    getMessagingFolders {
      id
      type
      displayName
      displayed
      system
      messagingAccount {
        ...MessagingAccountFullFragment
      }
    }
  }
`;

export const useFoldersQuery = createUseQuery<
  GetMessagingFoldersQuery,
  GetMessagingFoldersQueryVariables,
  'folders',
  GetMessagingFoldersQuery['getMessagingFolders']
>(FOLDERS_QUERY, {
  extractResult: resp => resp.getMessagingFolders,
  resultName: 'folders',
});

const FOLDERS_STATS_QUERY = gql`
  query GetMessagingFoldersStats {
    getMessagingFoldersStats {
      id
      notificationCount
    }
  }
`;

export const useFoldersStatsQuery = createUseQuery<
  GetMessagingFoldersStatsQuery,
  GetMessagingFoldersStatsQueryVariables,
  'foldersStats',
  GetMessagingFoldersStatsQuery['getMessagingFoldersStats']
>(FOLDERS_STATS_QUERY, {
  extractResult: resp => resp.getMessagingFoldersStats,
  resultName: 'foldersStats',
});
/* TURNED-OFF FOLDERS */
// const CREATE_FOLDER_MUTATION = gql`
//   mutation CreateMessagingFolder($input: MessagingFolderInput!) {
//     createMessagingFolder(input: $input) {
//       id
//       displayName
//     }
//   }
// `;
/* TURNED-OFF FOLDERS */
// export const useCreateFolderMutation = createUseMutation<
//   CreateMessagingFolderMutation,
//   CreateMessagingFolderMutationVariables,
//   CreateMessagingFolderMutation['createMessagingFolder']
// >(CREATE_FOLDER_MUTATION, {
//   extractResult: resp => resp.createMessagingFolder,
//   awaitRefetchQueries: true,
//   refetchQueries: ['GetMessagingFolders'],
// });
/* TURNED-OFF FOLDERS */
// const EDIT_FOLDER_MUTATION = gql`
//   mutation EditMessagingFolder($id: Int!, $input: MessagingFolderInput!) {
//     editMessagingFolder(id: $id, input: $input) {
//       id
//       displayName
//       displayed
//       nextSibling {
//         id
//       }
//       parentNode {
//         id
//       }
//     }
//   }
// `;

/**
 * Updates old folder hierarchy according to new folder data.
 * See MessagingFolderRepository.ts for more info how to manipulate tree structure
 */
/* TURNED-OFF FOLDERS */
// function makeFoldersUpdate(
//   oldFolders: MessagingFolder[],
//   updatedFolder: MessagingFolder
// ) {
//   const oldFolderVersion = oldFolders.find(f => f.id === updatedFolder.id)!;
//   const oldBacklinkNode = oldFolders.find(
//     f => !f.type && f.nextSibling?.id === updatedFolder.id
//   );
//   const newBacklinkNode = oldFolders.find(
//     f =>
//       !f.type &&
//       f.parentNode?.id === updatedFolder.parentNode?.id &&
//       f.nextSibling?.id === updatedFolder.nextSibling?.id
//   );
//   const isChangingPosition =
//     updatedFolder.parentNode?.id !== oldFolderVersion.parentNode?.id ||
//     updatedFolder.nextSibling?.id !== oldFolderVersion.nextSibling?.id;
//
//   return oldFolders.map(f => {
//     if (isChangingPosition) {
//       if (f.id === oldBacklinkNode?.id) {
//         return {
//           ...oldBacklinkNode,
//           nextSibling: oldFolderVersion.nextSibling && {
//             id: oldFolderVersion.nextSibling.id,
//           },
//         };
//       }
//       if (f.id === newBacklinkNode?.id) {
//         return {
//           ...newBacklinkNode,
//           nextSibling: { id: updatedFolder.id },
//         };
//       }
//     }
//     if (f.id === updatedFolder.id) {
//       return {
//         ...f,
//         ...updatedFolder,
//       };
//     }
//     return f;
//   });
// }
/* TURNED-OFF FOLDERS */
// export function useEditFolderMutation() {
//   const client = useApolloClient();
//   const [mutate, result] = useMutation<
//     EditMessagingFolderMutation,
//     EditMessagingFolderMutationVariables
//   >(EDIT_FOLDER_MUTATION);
//   async function editFolder(variables: EditMessagingFolderMutationVariables) {
//     const oldFolders = client.readQuery<GetMessagingFoldersQuery>({
//       query: FOLDERS_QUERY,
//     })!.getMessagingFolders as MessagingFolder[];
//     await mutate({
//       variables,
//       refetchQueries: ['GetMessagingFolders'],
//       optimisticResponse: {
//         editMessagingFolder: {
//           __typename: 'MessagingFolder',
//           id: variables.id,
//           displayed: variables.input.displayed ?? true,
//           displayName: variables.input.displayName,
//           nextSibling: variables.input.nextSiblingId
//             ? ({ id: variables.input.nextSiblingId } as MessagingFolder)
//             : null,
//           parentNode: variables.input.parentNodeId
//             ? ({ id: variables.input.parentNodeId } as MessagingFolder)
//             : null,
//         },
//       },
//       update: (proxy, newData) => {
//         const updatedFolder = newData.data!
//           .editMessagingFolder as MessagingFolder;
//         const newFolders = makeFoldersUpdate(oldFolders, updatedFolder);
//         proxy.writeQuery<GetMessagingFoldersQuery>({
//           query: FOLDERS_QUERY,
//           data: {
//             getMessagingFolders: newFolders,
//           },
//         });
//       },
//     });
//   }
//   return [editFolder, result] as [typeof editFolder, typeof result];
// }
//    /* TURNED-OFF FOLDERS */
// const DELETE_FOLDER_MUTATION = gql`
//   mutation DeleteMessagingFolder($id: Int!) {
//     deleteMessagingFolder(id: $id)
//   }
// `;
//    /* TURNED-OFF FOLDERS */
// export const useDeleteFolderMutation = createUseMutation<
//   DeleteMessagingFolderMutation,
//   DeleteMessagingFolderMutationVariables,
//   DeleteMessagingFolderMutation['deleteMessagingFolder']
// >(DELETE_FOLDER_MUTATION, {
//   extractResult: resp => resp.deleteMessagingFolder,
//   refetchQueries: ['GetMessagingFolders'],
//   awaitRefetchQueries: true,
// });
