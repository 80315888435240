import { CognitoUser } from 'amazon-cognito-identity-js';
import { Button, Divider, Input, Space } from 'antd';
import { Form, Formik } from 'formik';
import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { formatError } from '../../common/utils/errorUtils';
import { showErrorMessage } from '../../common/utils/messageUtils';
import { useCustomValidations } from '../../common/utils/validationUtils';
import FormField from '../../components/forms/FormField';
import { GoogleIcon } from '../../components/icons';
import { useLocalizedYup } from '../../config/intl/LocaleProvider';
import { useAuthService } from './authService';

interface PropsSignupInfoFormInner {
  isSubmitting?: boolean;
}

function SignupInfoFormInner(props: PropsSignupInfoFormInner) {
  return (
    <Form>
      <Space direction="vertical" size={25}>
        <FormField name="email" placeholderId="initialAuth.placeholder.email">
          {({ field }) => <Input {...field} size="large" />}
        </FormField>

        <FormField
          name="password"
          placeholderId="initialAuth.placeholder.password"
        >
          {({ field }) => <Input {...field} type="password" size="large" />}
        </FormField>

        <FormField
          name="passwordConfirmation"
          placeholderId="initialAuth.placeholder.passwordConfirmation"
        >
          {({ field }) => <Input {...field} type="password" size="large" />}
        </FormField>

        <Button
          className="FullWidth"
          type="primary"
          size="large"
          htmlType="submit"
          disabled={props.isSubmitting}
          loading={props.isSubmitting}
        >
          <FormattedMessage id="initialAuth.button.signup" />
        </Button>

        <Divider type="horizontal" className="ant-divider--NoSpace">
          <div className="Circle-28 Text--UpperCase">
            <FormattedMessage id="divider.or" />
          </div>
        </Divider>

        <Button
          className="FullWidth"
          icon={<GoogleIcon />}
          type="primary"
          size="large"
          disabled
        >
          <span>
            <FormattedMessage id="initialAuth.button.signup.google" />
          </span>
        </Button>
      </Space>
    </Form>
  );
}

interface PropsSignupInfoForm {
  onSuccess?: (user: CognitoUser) => void;
}

const INITIAL_VALUES = {
  email: '',
  password: '',
  passwordConfirmation: '',
};

export default function SignupInfoForm({ onSuccess }: PropsSignupInfoForm) {
  const intl = useIntl();
  const yup = useLocalizedYup();
  const authService = useAuthService();
  const { strongPasswordValidation } = useCustomValidations();

  const onSubmit = useCallback(
    async values => {
      try {
        const { user } = await authService.signUp(
          values.email,
          values.password
        );
        onSuccess?.(user);
      } catch (e) {
        showErrorMessage(formatError(e));
      }
    },
    [authService, onSuccess]
  );

  const validationSchema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required().test(strongPasswordValidation),
    passwordConfirmation: yup
      .string()
      .oneOf(
        [yup.ref('password')],
        intl.formatMessage({ id: 'validation.passwordsMustMatch' })
      ),
  });

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      component={SignupInfoFormInner}
    />
  );
}
