import { ReactNode } from 'react';

import { BaseLayout } from '../../layout/BaseLayout';
import { ContactsAppSidebar } from './ContactsAppSidebar';
import style from './ContactsAppTemplate.module.less';

type ContactsAppTemplateProps = {
  children?: ReactNode;
  subHeaderContent?: ReactNode;
};

export const ContactsAppTemplate = ({
  children,
  subHeaderContent,
}: ContactsAppTemplateProps) => (
  <BaseLayout
    subHeaderContent={subHeaderContent}
    sidebar={<ContactsAppSidebar />}
    content={<div className={style.Container}>{children}</div>}
  />
);
