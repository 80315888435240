import { useEffect } from 'react';

import { ErrorAlert } from '../../../components/ErrorAlert';
import { OverlaySpinner } from '../../../components/OverlaySpinner';
import {
  GetMessagingThreadQueryVariables,
  MessagingThreadsFilterInput,
} from '../../../types/graphqlGenerated';
import {
  useSetThreadsReadUnreadMutation,
  useThreadDetailQuery,
} from '../threadsGraphql';
import styles from './ThreadDetail.module.less';
import { ThreadHeader } from './ThreadHeader';
import {
  ScrollableThreadMessageList,
  SearchViewThreadMessageList,
} from './ThreadMessageList';
import { useThreadHighlight } from './helpers/threadUtils';

export function ThreadDetail({ threadId }) {
  const { searchMode, searchTerm } = useThreadHighlight();

  const variables: GetMessagingThreadQueryVariables = searchMode
    ? { id: threadId, subset: { searchTerm, around: 2 } }
    : { id: threadId };

  const opts: Parameters<typeof useThreadDetailQuery>[1] = searchMode
    ? { fetchPolicy: 'network-only' }
    : {};

  const { error, loading, thread } = useThreadDetailQuery(variables, opts);
  const [setReadProperty] = useSetThreadsReadUnreadMutation();

  useEffect(() => {
    const filter: MessagingThreadsFilterInput = {
      id: { eq: threadId },
    };

    setReadProperty({ filter, unread: false });
  }, [setReadProperty, threadId]);

  if (error) {
    return <ErrorAlert error={error} />;
  }
  if (loading && !thread) {
    return <OverlaySpinner />;
  }
  if (!thread) {
    return null;
  }

  return (
    <div className={styles.Container}>
      <ThreadHeader thread={thread} className="Print--Hide" />
      <div className={styles.Content}>
        {searchMode ? (
          <SearchViewThreadMessageList thread={thread} />
        ) : (
          <ScrollableThreadMessageList thread={thread} />
        )}
      </div>
    </div>
  );
}
