function removeElementFromDOM(elem: Element) {
  elem.parentNode?.removeChild(elem);
  return elem;
}

function removeElementsAfterSeparatorFromDOM(separator: Element) {
  const quoteChildren: [ChildNode?] = [];
  const parentNode = separator.parentNode!;

  function processElement(elem: ChildNode | null) {
    if (!elem) {
      return;
    }
    quoteChildren.push(elem);
    processElement(elem.nextSibling);
    parentNode.removeChild(elem);
  }
  processElement(separator);

  const quoteWrapper = document.createElement('div');
  // @ts-ignore: reason, ts does not know replaceChildren method
  quoteWrapper.replaceChildren(...quoteChildren);
  return quoteWrapper;
}

enum Client {
  Gmail = 'Gmail',
  GmailOld = 'GmailOld',
  Vifzack = 'Vifzack',
  Outlook = 'Outlook',
}

// Previous emails (quotes) in a message are identified by these heuristic rules
const QUOTE_SELECTORS = {
  [Client.Gmail]: 'div.gmail_quote_attribution',
  [Client.GmailOld]: 'div.gmail_quote',
  [Client.Vifzack]: 'div.quote',
  [Client.Outlook]: 'div#appendonsend',
};

export function parseMessageQuote(html) {
  // No need to sanitize since we are already passing sanitized content here
  const dom = document.createElement('body');
  dom.innerHTML = html;

  // find first element matching pattern
  const selector = Object.values(QUOTE_SELECTORS).join(',');
  const quoteMarker = dom.querySelector(selector);
  const match = Object.entries(QUOTE_SELECTORS).find(([, sel]) =>
    quoteMarker?.matches(sel)
  );
  const client = match?.[0] as Client | undefined;

  switch (client) {
    case Client.Gmail:
    case Client.Outlook:
      // Outlook/Gmail use separator element and everything below is considered quote
      const separatorQuote = removeElementsAfterSeparatorFromDOM(quoteMarker!);
      return {
        emailBody: dom.innerHTML,
        emailQuote: separatorQuote?.outerHTML,
      };
    case Client.GmailOld:
    case Client.Vifzack:
      // quote is surrounded by a wrapper element
      const wrapperQuote = removeElementFromDOM(quoteMarker!);
      return {
        emailBody: dom.innerHTML,
        emailQuote: wrapperQuote?.outerHTML,
      };
    default:
      // if no heuristic was found, don't parse quote
      return {
        emailBody: dom.innerHTML,
        emailQuote: null,
      };
  }
}
