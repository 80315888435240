import { RichTextEditorValue } from '../../../components/forms/RichEditor';

export enum RecurrenceEndType {
  COUNT = 'count',
  UNTIL = 'until',
}

export enum RecurrenceFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export type RecurrenceFormValues = {
  frequencyInterval?: number;
  frequencyType?: RecurrenceFrequency;
  isForever?: boolean;
  count?: number;
  endType?: RecurrenceEndType;
  lastOccurenceDate?: Date;
  selectedMonthDay?: number;
  selectedWeekDays?: number[];
  selectedMonths?: number[];
  enabled: boolean;
};

export type CalendarEventFormValues = {
  participants: string[];
  sendInvitations: boolean;
  title: string;
  description: RichTextEditorValue;
  calendarId?: number;
  allDay: boolean;
  when?: [Date, Date] | [Date];
  recurrence: RecurrenceFormValues;
};
