import { ColumnWidthOutlined } from '@ant-design/icons';
import { Form as AntForm, Button, Input, Tooltip } from 'antd';
import classNames from 'classnames';
import {
  Form,
  FormikContextType,
  FormikProvider,
  useFormik,
  useFormikContext,
} from 'formik';
import { isUndefined } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import { useIntlFormatDescriptors } from '../../../common/utils/intlUtils';
import { useCustomValidations } from '../../../common/utils/validationUtils';
import FormField from '../../../components/forms/FormField';
import {
  RichEditorMode,
  RichTextEditor,
} from '../../../components/forms/RichEditor';
import { BasicSwitch } from '../../../components/forms/booleanFields';
import { DatePicker, RangePicker } from '../../../components/forms/dateFields';
import {
  CalendarSelect,
  EmailParticipantSelect,
} from '../../../components/forms/fetchingSelects';
import { useLocalizedYup } from '../../../config/intl/LocaleProvider';
import { CalendarEventFormValues } from '../calendarEventCommon/calendarEventTypes';
import style from './CalendarEventFormBase.module.less';
import { RecurrenceField } from './RecurrenceField';

type CalendarEventFormProps = {
  showCalendarSelect: boolean;
};

const CalendarEventForm = ({ showCalendarSelect }: CalendarEventFormProps) => {
  const { dateTimeFormat, dateFormat } = useIntlFormatDescriptors();
  const { values } = useFormikContext<CalendarEventFormValues>();

  return (
    <AntForm
      component={false}
      layout="vertical"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
    >
      <FormField name="participants" labelId="calendar.participants">
        {({ field, label }) => (
          <AntForm.Item label={label} className="FullWidth">
            <EmailParticipantSelect
              {...field}
              bordered={true}
              mode="tags"
              tokenSeparators={[', ']}
            />
          </AntForm.Item>
        )}
      </FormField>
      <FormField name="allDay" labelId="calendar.allDay">
        {({ field, label }) => (
          <AntForm.Item label={label} required={true}>
            <BasicSwitch {...field} />
          </AntForm.Item>
        )}
      </FormField>
      <FormField name="when" labelId="calendar.dateAndTime">
        {({ field, label, form }) => {
          const format = values.allDay ? dateFormat : dateTimeFormat;
          const isRange = field.value?.length === 2 || isUndefined(field.value);

          return (
            <AntForm.Item label={label} required={true}>
              <div className="FlexRow FlexRow--SpaceSm">
                <div className="Flex--1">
                  {isRange ? (
                    <RangePicker
                      showTime={!values.allDay}
                      size="middle"
                      format={format}
                      {...field}
                      placeholder={undefined}
                      className="FullWidth"
                    />
                  ) : (
                    <DatePicker
                      showTime={!values.allDay}
                      size="middle"
                      format={format}
                      {...field}
                      className="FullWidth"
                      value={field.value[0]}
                      formatValue={dt => [dt]}
                    />
                  )}
                </div>
                <Tooltip
                  title={
                    <FormattedMessage
                      id={
                        isRange
                          ? 'calendar.disableRange'
                          : 'calendar.enableRange'
                      }
                    />
                  }
                >
                  <Button
                    shape="circle"
                    icon={<ColumnWidthOutlined />}
                    className={classNames(
                      style.RangeIcon,
                      isRange && style['RangeIcon--Enabled']
                    )}
                    onClick={() => {
                      if (isRange) {
                        form.setFieldValue(field.name, [field.value?.[0]]);
                      } else {
                        form.setFieldValue(field.name, [
                          field.value[0],
                          field.value[0],
                        ]);
                      }
                    }}
                  />
                </Tooltip>
              </div>
            </AntForm.Item>
          );
        }}
      </FormField>
      <FormField name="recurrence">
        {({ field }) => (
          <RecurrenceField
            dateFrom={values.when?.[0]}
            dateTo={values.when?.[1]}
            {...field}
          />
        )}
      </FormField>
      <FormField name="title" labelId="calendar.title">
        {({ field, label }) => (
          <AntForm.Item label={label} className="FullWidth" required={true}>
            <Input size="middle" {...field} />
          </AntForm.Item>
        )}
      </FormField>
      <FormField name="description" labelId="calendar.description">
        {({ field, label }) => (
          <AntForm.Item label={label} className="FullWidth">
            <RichTextEditor
              mode={RichEditorMode.SimpleNoImage}
              isInFlex
              bordered
              {...field}
            />
          </AntForm.Item>
        )}
      </FormField>
      <FormField name="sendInvitations" labelId="calendar.sendInvitations">
        {({ field, label }) => (
          <AntForm.Item label={label} className="FullWidth">
            <BasicSwitch {...field} />
          </AntForm.Item>
        )}
      </FormField>
      {showCalendarSelect && (
        <FormField
          name="calendarId"
          labelId="calendar.calendar"
          required={true}
        >
          {({ field, label }) => (
            <AntForm.Item label={label} className="FullWidth" required={true}>
              <CalendarSelect {...field} />
            </AntForm.Item>
          )}
        </FormField>
      )}
    </AntForm>
  );
};

export type CalendarEventFormBaseProps = {
  initialValues: CalendarEventFormValues;
  performMutation: (input: CalendarEventFormValues) => Promise<void>;
  showCalendarSelect?: boolean;
  renderButtons: (
    formik: FormikContextType<CalendarEventFormValues>
  ) => JSX.Element;
};

export function CalendarEventFormBase({
  initialValues,
  performMutation,
  showCalendarSelect = true,
  renderButtons,
}: CalendarEventFormBaseProps) {
  const yup = useLocalizedYup();
  const { emailListValidation } = useCustomValidations();

  const validationSchema = yup.object().shape({
    title: yup.string().required(),
    calendarId: yup.number().required(),
    when: yup.array().of(yup.date()).required(),
    participants: yup.array().test(emailListValidation),
  });

  const onSubmit = async (values: CalendarEventFormValues) => {
    await performMutation(values);
  };

  const formik = useFormik<CalendarEventFormValues>({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <FormikProvider value={formik}>
      <Form className={style.Container}>
        <CalendarEventForm showCalendarSelect={showCalendarSelect} />
        {renderButtons(formik)}
      </Form>
    </FormikProvider>
  );
}
