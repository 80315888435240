import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { Record } from 'immutable';
import { Dispatch, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Action, bindActionCreators } from 'redux';

import { ReduxContext } from '../../types/appTypes';

type AuthState = typeof INITIAL_STATE;
type PartialState = { auth: AuthState };

type InitialStateType = {
  user?: CognitoUser;
  session?: CognitoUserSession;
  sessionLoading: boolean;
};

const InitialState = Record<InitialStateType>({
  user: undefined,
  session: undefined,
  // needs to be `true` in initial state otherwise LoginGuard keeps redirecting on refresh
  sessionLoading: true,
});
const INITIAL_STATE = InitialState();

const LOGIN = 'AUTH/LOGIN';
export const LOGOUT = 'AUTH/LOGOUT';
const START_LOADING = 'AUTH/START_LOADING';
const STOP_LOADING = 'AUTH/STOP_LOADING';

export function authReducer(state: AuthState = INITIAL_STATE, action: Action) {
  switch (action.type) {
    case LOGIN:
      const typedAction = action as LoginUserAction;
      return state
        .set('user', typedAction.user)
        .set('session', typedAction.session);
    case LOGOUT:
      return state.clear().set('sessionLoading', false);
    case START_LOADING:
      return state.set('sessionLoading', true);
    case STOP_LOADING:
      return state.set('sessionLoading', false);
    default:
      return state;
  }
}

export const getLoggedIn = (state: PartialState) => !!state.auth.session;
export const getUser = (state: PartialState) => state.auth.user;
export const getUserSession = (state: PartialState) => state.auth.session;
export const getUserSessionLoading = (state: PartialState) =>
  state.auth.sessionLoading;

type LoginUserAction = Action & {
  user: CognitoUser;
  session: CognitoUserSession;
};
function loginUser(
  user: CognitoUser,
  session: CognitoUserSession
): LoginUserAction {
  return { type: LOGIN, user, session };
}

function loadSessionWrapper(
  retrieveUserAndSession: () => Promise<
    { user: CognitoUser; session: CognitoUserSession } | undefined
  >
) {
  return async (dispatch: Dispatch<unknown>) => {
    dispatch({ type: START_LOADING });
    try {
      const res = await retrieveUserAndSession();
      if (res) {
        dispatch(loginUser(res.user, res.session));
      }
    } finally {
      dispatch({ type: STOP_LOADING });
    }
  };
}

export function logoutUser() {
  return async (
    dispatch: Dispatch<unknown>,
    getState: () => PartialState,
    context: ReduxContext
  ) => {
    const user = getUser(getState());
    if (user) {
      // This operation is not critical, so we will ignore a potential error
      user.globalSignOut({
        onSuccess: msg => console.log(`Global sign out success: ${msg}`),
        onFailure: err => console.log(`Global sign out error: ${err.message}`),
      });
    }

    // This needs to be done before dispatch - otherwise it would be cleared after
    // rerender and all running queries would be stuck
    // `clearStore` is preferred to `resetStore` for logout scenario
    await context.apolloClient.clearStore();
    context.subscriptionClient.close(true);

    dispatch({ type: LOGOUT });
  };
}

export function useAuthActions() {
  const dispatch = useDispatch();
  return useMemo(
    () => ({
      loginUser: bindActionCreators(loginUser, dispatch),
      loadSessionWrapper: bindActionCreators(loadSessionWrapper, dispatch),
      logoutUser: bindActionCreators(logoutUser, dispatch),
    }),
    [dispatch]
  );
}
