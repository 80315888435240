import { Space } from 'antd';
import { ReactNode } from 'react';

import { LogoFull } from '../../../components/Logo';
import { SimpleFooter, SimpleFooterClasses } from '../../layout/Footer';
import style from './NonLoggedPageTemplate.module.less';

type NonLoggedPageTemplateProps = {
  children: ReactNode;
  contentWidth?: string;
};

export default function NonLoggedPageTemplate({
  children,
  contentWidth,
}: NonLoggedPageTemplateProps) {
  return (
    <div className={style.Container}>
      <div className={style.Content}>
        <div
          className={style.Card}
          style={contentWidth ? { width: contentWidth } : undefined}
        >
          <Space direction="vertical" size={20}>
            <LogoFull />
            {children}
          </Space>
          <SimpleFooter className={SimpleFooterClasses['--Bottom']} />
        </div>
      </div>
    </div>
  );
}
