import { Menu, MenuProps } from 'antd';
import { Fragment } from 'react';

import { DynamicMenuItem } from './dynamicMenuTypes';

/**
 * This is needed for Ant menu that is generated dynamically because it requires Menu items to be direct descendants
 * which limits extraction into elements
 *
 * This is a function instead of element for usage in Dropdown, which requires Menu
 */
export function renderDynamicMenu(items: DynamicMenuItem[], props?: MenuProps) {
  return (
    <Menu
      {...(props || {})}
      items={items
        .filter(item => item.visible)
        .map(item => ({
          key: item.key,
          icon: item.icon,
          onClick: item.onClick,
          label: item.label,
          title: item.tooltip,
          disabled: item.disabled,
        }))}
    />
  );
}

/**
 * This is needed for additional dialogs of menu items that should be rendered outside of menu
 */
export function renderDynamicMenuAdditionalElements(items: DynamicMenuItem[]) {
  return (
    <>
      {items.map(item => {
        if (!item.visible) {
          return null;
        }
        return <Fragment key={item.key}>{item.additionalElements}</Fragment>;
      })}
    </>
  );
}
