import { Alert } from 'antd';

import { formatError } from '../common/utils/errorUtils';

type Props = {
  error: Error;
};

export function ErrorAlert({ error }: Props) {
  return (
    <Alert
      message={`Unexpected error occured: ${formatError(error)}`}
      type="error"
    />
  );
}
