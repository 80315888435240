import { compact } from 'lodash-es';

import { FileUploadFile } from '../../../../../components/forms/FileUpload';
import {
  MessagingAccount,
  MessagingMessageInput,
} from '../../../../../types/graphqlGenerated';
import { MessageComposerReaction } from '../messageComposerTypes';

export type WhatsappMessageComposerFormValues = {
  to?: string;
  quoteMessage?: boolean;
  files?: FileUploadFile[];
  template?: {
    nameAndLanguage?: string;
    body?: { text: string }[];
    header?: { text: string };
    buttons?: { text: string }[];
  };
  messages?: { body: string; file?: FileUploadFile }[];
};

type UseInitialValues = {
  reaction?: MessageComposerReaction;
};

export function useWhatsappMessageComposerInitialValues({
  reaction,
}: UseInitialValues): WhatsappMessageComposerFormValues {
  if (reaction?.type === 'reply') {
    return {
      to: reaction.message.from[0].ref,
      quoteMessage: false,
      files: [],
      messages: [{ body: '' }],
    };
  }

  return {
    to: undefined,
    quoteMessage: false,
    files: [],
    messages: [{ body: '' }],
  };
}

type WhatsappFormToGraphQlInputOpts = {
  values: WhatsappMessageComposerFormValues;
  account: MessagingAccount;
  reaction?: MessageComposerReaction;
};

export async function whatsappFormToGraphQlInput({
  values,
  account,
  reaction,
}: WhatsappFormToGraphQlInputOpts): Promise<MessagingMessageInput[]> {
  return (values.messages || []).map(({ body, file }) => ({
    ...(reaction?.type === 'reply'
      ? {
          threadId: reaction.thread.id,
          replyToMessageId: values.quoteMessage
            ? reaction.message.id
            : undefined,
        }
      : {}),
    ...(reaction?.type === 'forward'
      ? {
          forwardMessageId: reaction.message.id,
        }
      : {}),
    fromMessagingAccountId: account.id,
    to: values.to ? [{ ref: values.to }] : [],
    subject: '',
    body,
    fileIds: compact([file?.response?.id]),
    ...(values?.template && { template: values.template }),
    // TODO map template fields body, header, template.name both in graphql, queues, api stack, nx stack
  }));
}
