import classNames from 'classnames';
import { isEmpty } from 'lodash-es';

import { ErrorAlert } from '../../../components/ErrorAlert';
import { OverlaySpinner } from '../../../components/OverlaySpinner';
import { useExtendedPagination } from '../../common/pagination/paginationUtils';
import { useContactsQuery } from '../contactsGraphql';
import style from './ContactsList.module.less';
import { ContactsListContent } from './ContactsListContent';
import { ContactsListToolbar } from './ContactsListToolbar';

type ContactsListProps = {
  searchTerm?: string;
};

export function ContactsList({ searchTerm }: ContactsListProps) {
  const { pageInfo, paginationInput, setIndex, setTotal } =
    useExtendedPagination();

  const { loading, error, contacts } = useContactsQuery(
    {
      filter: { searchTerm: isEmpty(searchTerm) ? null : searchTerm },
      pagination: paginationInput,
    },
    {
      onCompleted: ({ pagination }) => setTotal(pagination?.count ?? 0),
    }
  );

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <>
      <ContactsListToolbar pageInfo={pageInfo} onPageIndexChange={setIndex} />
      <div
        className={classNames(
          'Flex--1 Overflow--Auto',
          style.ListContentWrapper
        )}
      >
        <ContactsListContent items={contacts!} />
      </div>
      {/* We don't want to show this on reload */}
      {loading && !contacts && <OverlaySpinner />}
    </>
  );
}
