import {
  ArrowLeftOutlined,
  KeyOutlined,
  LockOutlined,
} from '@ant-design/icons';
import { Input, Space } from 'antd';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { DEFAULT_NON_LOGGED_ROUTE } from '../../app/routes';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../common/utils/messageUtils';
import { useCustomValidations } from '../../common/utils/validationUtils';
import { CircleButton, PrimaryButton } from '../../components/buttons';
import FormField from '../../components/forms/FormField';
import { useLocalizedYup } from '../../config/intl/LocaleProvider';
import { useAuthService } from '../../modules/auth/authService';
import { theme } from '../../styles/theme';

type ConfirmNewPasswordFormValues = {
  confirmationCode: string;
  newPassword: string;
  newPasswordConfirmation: string;
};

const INITIAL_VALUES: ConfirmNewPasswordFormValues = {
  confirmationCode: '',
  newPassword: '',
  newPasswordConfirmation: '',
};

type ConfirmNewPasswordFormProps = {
  email: string;
  goBack: () => void;
};

const ConfirmNewPasswordForm = ({
  email,
  goBack,
}: ConfirmNewPasswordFormProps) => {
  const authService = useAuthService();
  const navigate = useNavigate();

  const onSubmit = async (values: ConfirmNewPasswordFormValues) => {
    const { confirmationCode, newPassword } = values;

    try {
      await authService.confirmNewPassword(
        email,
        confirmationCode,
        newPassword
      );

      showSuccessMessage(
        <FormattedMessage id="forgotPassword.passwordUpdated" />
      );
      navigate(DEFAULT_NON_LOGGED_ROUTE);
    } catch (error) {
      showErrorMessage(<FormattedMessage id="forgotPassword.errorOccured" />);
    }
  };

  const yup = useLocalizedYup();

  const { strongPasswordValidation } = useCustomValidations();

  const intl = useIntl();

  const validationSchema = yup.object().shape({
    newPassword: yup.string().required().test(strongPasswordValidation),
    newPasswordConfirmation: yup
      .string()
      .required()
      .oneOf(
        [yup.ref('newPassword')],
        intl.formatMessage({ id: 'validation.passwordsMustMatch' })
      ),
    confirmationCode: yup.string().min(4).required(),
  });

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <Form>
        <Space size={20} direction="vertical">
          <CircleButton onClick={goBack} icon={<ArrowLeftOutlined />} />
          <>
            <FormField
              name="confirmationCode"
              className="FullWidth"
              placeholderId="forgotPassword.placeholder.confirmationCode"
            >
              {({ field }) => (
                <Input
                  {...field}
                  size="large"
                  prefix={
                    <KeyOutlined style={{ color: theme['@primary-color'] }} />
                  }
                />
              )}
            </FormField>

            <FormField
              name="newPassword"
              placeholderId="forgotPassword.placeholder.newPassword"
            >
              {({ field }) => (
                <Input
                  {...field}
                  type="password"
                  size="large"
                  prefix={
                    <LockOutlined style={{ color: theme['@primary-color'] }} />
                  }
                />
              )}
            </FormField>

            <FormField
              name="newPasswordConfirmation"
              placeholderId="forgotPassword.placeholder.confirmNewPassword"
            >
              {({ field }) => (
                <Input
                  {...field}
                  type="password"
                  size="large"
                  prefix={
                    <LockOutlined style={{ color: theme['@primary-color'] }} />
                  }
                />
              )}
            </FormField>

            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <PrimaryButton
                htmlType="submit"
                labelId="forgotPassword.confirmNewPassword"
              />
            </div>
          </>
        </Space>
      </Form>
    </Formik>
  );
};
export default ConfirmNewPasswordForm;
