import { useState } from 'react';

import NonLoggedPageTemplate from '../messagesApp/layout/NonLoggedPageTemplate';
import ConfirmNewPasswordForm from './ConfirmNewPasswordForm';
import ResetPasswordForm from './ResetPasswordForm';

export default function ForgottenPasswordSteps() {
  const [email, setEmail] = useState('');
  const [step, setStep] = useState(1);

  return (
    <NonLoggedPageTemplate>
      {step === 1 && (
        <ResetPasswordForm
          onSubmit={newEmail => {
            setEmail(newEmail);
            setStep(old => old + 1);
          }}
          email={email}
        />
      )}
      {step === 2 && (
        <ConfirmNewPasswordForm
          email={email}
          goBack={() => setStep(old => old - 1)}
        />
      )}
    </NonLoggedPageTemplate>
  );
}
