import {
  isArray,
  isNil,
  isObject,
  isPlainObject,
  mapValues,
  omitBy,
} from 'lodash-es';

export function omitByDeep<T>(
  value: T,
  predicate: (val: any, key: string | number) => boolean
): Partial<T> {
  if (isArray(value)) {
    return value.map(val => omitByDeep(val, predicate)) as any;
  }
  if (isObject(value)) {
    return mapValues(omitBy(value, predicate), val =>
      omitByDeep(val, predicate)
    ) as any;
  }
  return value;
}

export function arrayMove<T>(
  array: T[],
  sourceIndex: number,
  destinationIndex: number
) {
  const copy = [...array];
  copy.splice(destinationIndex, 0, ...copy.splice(sourceIndex, 1));
  return copy;
}

export const asyncNoop = () => Promise.resolve();

export function hasNonNil(value: any) {
  if (isPlainObject(value)) {
    return Object.values(value).some(val => hasNonNil(val));
  }
  if (isArray(value)) {
    return value.some(val => !isNil(val));
  }
  return !isNil(value);
}
