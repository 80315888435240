import { Form as AntForm, Col, Row, Space, Spin } from 'antd';
import { useField } from 'formik';
import { FormattedMessage } from 'react-intl';

import FormField from '../../../../../../components/forms/FormField';
import { TemplateSelect } from '../../../../../../components/forms/fetchingSelects';
import { useGetTemplateMessages } from '../../../../templatesGraphql';
import style from '../MessageComposerWhatsappSubform.module.less';
import { TemplatePreview } from './TemplatePreview';

export const Template = ({ account }) => {
  const [selectedTemplate] = useField('template.nameAndLanguage');
  const { templates, loading } = useGetTemplateMessages({
    input: {
      fromMessagingAccountId: account.id,
      filter: {
        ...(selectedTemplate?.value && {
          name: selectedTemplate?.value.split('/')[0],
        }),
      },
    },
  });
  return (
    <Space
      direction="vertical"
      split={<div className={style.Divider} />}
      size={5}
    >
      <Row gutter={16} align="middle" justify="start">
        <Col span={2}>
          <AntForm.Item className={style['FormItem--Compact']}>
            <FormField
              name="template.nameAndLanguage"
              labelId="sendMessage.template"
            >
              {({ label }) => <span>{label}</span>}
            </FormField>
          </AntForm.Item>
        </Col>
        <Col span={18}>
          <FormField
            name="template.nameAndLanguage"
            labelId="sendMessage.template"
          >
            {({ field }) => (
              <AntForm.Item
                className={style['FormItem--Compact']}
                wrapperCol={{ span: 24 }}
              >
                <TemplateSelect
                  account={account}
                  getValue={t => t.value}
                  {...field}
                />
              </AntForm.Item>
            )}
          </FormField>
        </Col>
        <Col span={4}>
          <FormattedMessage
            id="sendMessage.handle-templates"
            values={{
              link: chunks => (
                <a
                  href="https://business.facebook.com/wa/manage/message-templates"
                  target="_blank"
                  rel="noreferrer"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </Col>
      </Row>

      {loading && <Spin />}
      {selectedTemplate?.value && !loading && (
        <TemplatePreview components={templates?.data?.[0]?.components ?? []} />
      )}
    </Space>
  );
};
