import { Form as AntForm, FormItemProps as AntFormItemProps } from 'antd';
import { useIntl } from 'react-intl';

import { TranslationId } from '../../types/appTypes';

type FormItemProps = AntFormItemProps & {
  labelId?: TranslationId;
  translationValues?: Record<string, any>;
};

export default function FormItem({
  labelId,
  translationValues,
  ...rest
}: FormItemProps) {
  const intl = useIntl();
  const label =
    labelId && intl.formatMessage({ id: labelId }, translationValues);
  return <AntForm.Item label={label} {...rest} />;
}
