import classNames from 'classnames';
import { Set } from 'immutable';
import { isEmpty } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { Route, Routes, generatePath, useNavigate } from 'react-router-dom';

import RouteDefinitions from '../../../app/routes';
import { ErrorAlert } from '../../../components/ErrorAlert';
import { OverlaySpinner } from '../../../components/OverlaySpinner';
import {
  GetMessagingThreadsQueryVariables,
  MessagingFolderType,
  MessagingThreadListFragmentFragment,
  MessagingThreadsFilterInput,
} from '../../../types/graphqlGenerated';
import { useExtendedPagination } from '../../common/pagination/paginationUtils';
import ThreadDetailSubpage from '../threadDetail/ThreadDetailSubpage';
import { makeThreadsSourceState } from '../threadDetail/ThreadPagination';
import { useThreadsQuery } from '../threadsGraphql';
import style from './ThreadsList.module.less';
import { ThreadsListContent } from './ThreadsListContent';
import { ThreadsListToolbar } from './ThreadsListToolbar';

interface Props {
  filter: MessagingThreadsFilterInput | null | undefined;
  activeFolderType?: MessagingFolderType;
}

export function ThreadsList({ filter, activeFolderType }: Props) {
  const { pageInfo, paginationInput, setIndex, setTotal } =
    useExtendedPagination();
  const [selectedKeys, setSelectedKeys] = useState<Set<number>>(Set());

  const threadsQueryVariables: GetMessagingThreadsQueryVariables = {
    pagination: paginationInput,
    filter,
  };
  const { loading, error, threads, pagination, refetch } = useThreadsQuery(
    threadsQueryVariables,
    {
      onCompleted: ({ pagination: newPagination }) =>
        setTotal(newPagination?.count ?? 0),
      skip: !filter,
    }
  );

  const selectedThreads: MessagingThreadListFragmentFragment[] = useMemo(
    () => threads?.filter(thread => selectedKeys.has(thread.id)) ?? [],
    [threads, selectedKeys]
  );

  const navigate = useNavigate();

  const [allSelected, setAllSelected] = useState(false);
  const fullPageSelected =
    Set(threads?.map(e => e.id)).isSubset(selectedKeys) && !isEmpty(threads);

  // Select all when "allSelected" is active
  useEffect(() => {
    const all = Set<number>(threads?.map(e => e.id));
    if (
      allSelected &&
      !loading &&
      (selectedKeys.isEmpty() || !selectedKeys.isSubset(all))
    ) {
      setSelectedKeys(all);
    }
  }, [threads, loading, selectedKeys, allSelected]);

  return (
    <Routes>
      <Route
        path={RouteDefinitions.threadDetailRelative}
        element={<ThreadDetailSubpage />}
      />
      <Route
        path="*"
        element={
          error ? (
            <ErrorAlert error={error} />
          ) : (
            <>
              <ThreadsListToolbar
                pageInfo={pageInfo}
                onPageIndexChange={index => {
                  setIndex(index);
                  setSelectedKeys(selectedKeys.clear());
                }}
                onRefresh={() => refetch()}
                onSelectFullPage={newValue => {
                  if (newValue) {
                    setSelectedKeys(Set(threads?.map(e => e.id)));
                  } else {
                    setAllSelected(false);
                    setSelectedKeys(selectedKeys.clear());
                  }
                }}
                fullPageSelected={fullPageSelected}
                allSelected={allSelected}
                onSelectAll={() => {
                  setAllSelected(true);
                  setSelectedKeys(Set(threads?.map(e => e.id)));
                }}
                selectedKeys={selectedKeys.toArray()}
                allItemsSize={pagination?.count ?? 0}
                filter={filter || {}}
                selectedThreads={selectedThreads}
                setSelectedKeys={setSelectedKeys}
                setAllSelected={setAllSelected}
              />
              <div
                className={classNames(
                  'Flex--1 Overflow--Auto',
                  style.ListContentWrapper
                )}
              >
                <ThreadsListContent
                  items={threads!}
                  selectedKeys={selectedKeys}
                  onSelectedKeysChange={newSelectedKeys => {
                    setSelectedKeys(oldKeys => {
                      if (!oldKeys.isSubset(newSelectedKeys)) {
                        setAllSelected(false);
                      }
                      return newSelectedKeys;
                    });
                  }}
                  onItemClick={item => {
                    const detailRoute = generatePath(
                      RouteDefinitions.threadDetailRelative,
                      {
                        threadId: `${item.id}`,
                      }
                    );
                    const state = {
                      threadsSourceState: makeThreadsSourceState(
                        threadsQueryVariables,
                        pageInfo.total,
                        threads
                      ),
                    };
                    navigate(detailRoute, { state });
                  }}
                  onMoveToFolderSuccess={() =>
                    setSelectedKeys(selectedKeys.clear())
                  }
                  activeFolderType={activeFolderType}
                  allSelected={allSelected}
                  allItemsSize={pagination?.count ?? 0}
                  filter={filter || {}}
                />
              </div>
              {/* We don't want to show this on reload */}
              {((loading && !threads) || !filter) && <OverlaySpinner />}
            </>
          )
        }
      />
    </Routes>
  );
}
