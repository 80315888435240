import { FieldInputProps, useFormikContext } from 'formik';
import { ReactElement, ReactNode, useCallback } from 'react';
import {
  DragDropContext,
  DragDropContextProps,
  Draggable,
  DraggableProvided,
  DraggableStateSnapshot,
  Droppable,
  DroppableProvided,
  DroppableStateSnapshot,
} from 'react-beautiful-dnd';

import { arrayMove } from '../../common/utils/funcUtils';

const DEFAULT_GET_KEY = item => `${item?.id}`;

export type SortableListProps<T> = FieldInputProps<T[]> & {
  getKey?: (val: T) => string;
  renderContainer: (info: {
    info: DroppableProvided;
    state: DroppableStateSnapshot;
    children: ReactNode;
  }) => ReactElement;
  renderItem: (info: {
    info: DraggableProvided;
    state: DraggableStateSnapshot;
    item: T;
  }) => ReactElement;
};

export function SortableList<T>({
  value,
  name,
  getKey = DEFAULT_GET_KEY,
  renderContainer,
  renderItem,
}: SortableListProps<T>) {
  const { setFieldValue } = useFormikContext();

  const onDragEnd = useCallback<DragDropContextProps['onDragEnd']>(
    result => {
      if (!result.destination) {
        return;
      }
      setFieldValue(
        name,
        arrayMove(value, result.source.index, result.destination.index)
      );
    },
    [name, setFieldValue, value]
  );

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={name}>
        {(droppableProvided, droppableSnapshot) =>
          renderContainer({
            info: droppableProvided,
            state: droppableSnapshot,
            children: (
              <>
                {value.map((item, i) => {
                  const key = getKey(item);
                  return (
                    <Draggable key={key} draggableId={key} index={i}>
                      {(draggableProvided, draggableSnapshot) =>
                        renderItem({
                          info: draggableProvided,
                          state: draggableSnapshot,
                          item,
                        })
                      }
                    </Draggable>
                  );
                })}
                {droppableProvided.placeholder}
              </>
            ),
          })
        }
      </Droppable>
    </DragDropContext>
  );
}
