import { MailFilled } from '@ant-design/icons';
import { Button, Checkbox, Col, Modal, Row, Space } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import RouteDefinitions from '../../app/routes';
import OptionalTooltip from '../../components/OptionalTooltip';
import { PrimaryButton, PrimaryButtonProps } from '../../components/buttons';
import { WhatsappIcon } from '../../components/icons';
import { TranslationId } from '../../types/appTypes';
import { MessagingAccountType } from '../../types/graphqlGenerated';
import styles from './ConnectAccountButton.module.less';
import { ConnectEmailAccountForm } from './connectAccount/ConnectEmailAccountForm';
import { ConnectWhatsappAccountWizard } from './connectAccount/ConnectWhatsappAccountWizard';

type ConnectAccountWizardProps = {
  onClose: () => void;
};

function ConnectAccountWizard({ onClose }: ConnectAccountWizardProps) {
  const [accountType, setAccountType] = useState<
    MessagingAccountType | undefined
  >();
  const [termsAcceptanceTimestamp, setTermsAcceptanceTimestamp] = useState<
    Date | undefined
  >(undefined);
  // const isTestSTage = `${process.env.REACT_APP_STAGE}` === 'test';

  if (!accountType || !termsAcceptanceTimestamp) {
    return (
      <Space direction="vertical" size={20}>
        <Row align="middle" gutter={20}>
          {/* eslint-disable-next-line eqeqeq */}
          {/*{!isTestSTage && (*/}
          <Col lg={8} md={12} sm={24}>
            <OptionalTooltip
              enabled={!termsAcceptanceTimestamp}
              titleId="connectAccount.dialog.acceptTerms.tooltip"
            >
              <Button
                className={styles['Wizard__IconButton']}
                size="large"
                icon={<MailFilled />}
                onClick={() => setAccountType(MessagingAccountType.Email)}
                disabled={!termsAcceptanceTimestamp}
              >
                <span>
                  <FormattedMessage id="messagingAccountType.EMAIL" />
                </span>
              </Button>
            </OptionalTooltip>
          </Col>
          {/*)}*/}
          <Col lg={8} md={12} sm={24}>
            <OptionalTooltip
              enabled={!termsAcceptanceTimestamp}
              titleId="connectAccount.dialog.acceptTerms.tooltip"
            >
              <Button
                className={styles['Wizard__IconButton']}
                size="large"
                icon={<WhatsappIcon />}
                onClick={() => setAccountType(MessagingAccountType.Whatsapp)}
                disabled={!termsAcceptanceTimestamp}
              >
                <span>
                  <FormattedMessage id="messagingAccountType.WHATSAPP" />
                </span>
              </Button>
            </OptionalTooltip>
          </Col>
        </Row>
        <div>
          <Checkbox
            checked={!!termsAcceptanceTimestamp}
            onChange={e =>
              setTermsAcceptanceTimestamp(
                e.target.checked ? new Date() : undefined
              )
            }
          >
            <FormattedMessage
              id="connectAccount.dialog.acceptTerms"
              values={{
                link: (
                  <Link to={RouteDefinitions.terms} target="_blank">
                    <FormattedMessage id="connectAccount.dialog.acceptTerms.linkText" />
                  </Link>
                ),
              }}
            />
          </Checkbox>
        </div>
      </Space>
    );
  }

  /**
   * TODO load FB api, on click on Whatsapp open sign up, get values and useConnectWhatsapp
   */
  switch (accountType) {
    case MessagingAccountType.Email:
      return (
        <ConnectEmailAccountForm
          onClose={onClose}
          termsAcceptanceTimestamp={termsAcceptanceTimestamp}
        />
      );
    case MessagingAccountType.Whatsapp:
      return (
        <ConnectWhatsappAccountWizard
          onClose={onClose}
          termsAcceptanceTimestamp={termsAcceptanceTimestamp}
        />
      );
    default:
      return null;
  }
}

type ConnectAccountDialogProps = {
  visible: boolean;
  onClose: () => void;
};

function ConnectAccountDialog({ visible, onClose }: ConnectAccountDialogProps) {
  return (
    <Modal
      title={<FormattedMessage id="connectAccount.dialog.title" />}
      visible={visible}
      onOk={onClose}
      onCancel={onClose}
      footer={null}
      destroyOnClose
    >
      <ConnectAccountWizard onClose={onClose} />
    </Modal>
  );
}

type ConnectAccountButtonProps = {
  labelId?: TranslationId;
} & Omit<PrimaryButtonProps, 'labelId' | 'onClick'>;

export const ConnectAccountButton = ({
  labelId = 'connectAccount.button.connect',
  ...rest
}: ConnectAccountButtonProps) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <PrimaryButton
        onClick={() => setVisible(true)}
        labelId={labelId}
        {...rest}
      />
      <ConnectAccountDialog
        visible={visible}
        onClose={() => setVisible(false)}
      />
    </>
  );
};
