import { isNumber, toNumber } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import RouteDefinitions from '../app/routes';
import { SearchBarForThreads } from '../modules/messagesApp/filters/SearchBarForThreads';
import { convertThreadFilterOutputToInput } from '../modules/messagesApp/filters/filterConversions';
import { useNamedFilterQuery } from '../modules/messagesApp/filters/filtersGraphql';
import { MessagesAppTemplate } from '../modules/messagesApp/layout/MessagesAppTemplate';
import {
  getThreadsFilter,
  useMessagesActions,
} from '../modules/messagesApp/messagesReducer';
import { useOnThreadUpdatedSubscription } from '../modules/messagesApp/threadsGraphql';
import { ThreadsList } from '../modules/messagesApp/threadsList/ThreadsList';
import { getPageSize } from '../modules/settings/settingsReducer';
import {
  MessagingFolderType,
  MessagingThreadsFilterInput,
} from '../types/graphqlGenerated';
import { UseParams } from '../types/libHelperTypes';

type ThreadsListPageBaseProps = {
  filter: MessagingThreadsFilterInput | null | undefined;
  activeFolderKey?: string;
  activeFolderType?: MessagingFolderType;
  activeFilterId?: number;
  filterActive?: boolean;
  contentKey: string | number;
};

function ThreadsListPageBase({
  filter,
  activeFolderKey,
  activeFolderType,
  activeFilterId,
  filterActive,
  contentKey,
}: ThreadsListPageBaseProps) {
  useOnThreadUpdatedSubscription();
  const pageSize = useSelector(getPageSize);

  return (
    <MessagesAppTemplate
      activeFolderKey={activeFolderKey}
      activeFilterId={activeFilterId}
      subHeaderContent={<SearchBarForThreads filterActive={!!filterActive} />}
    >
      <ThreadsList
        filter={filter}
        activeFolderType={activeFolderType}
        key={[contentKey, pageSize].join(',')}
      />
    </MessagesAppTemplate>
  );
}

export function FolderViewThreadsListPage() {
  const params = useParams<UseParams<RouteDefinitions.messagesFolderView>>();
  const folderIdRaw = toNumber(params.folderTypeOrId);
  const folderType = isNaN(folderIdRaw)
    ? (params.folderTypeOrId as unknown as MessagingFolderType)
    : undefined;
  const folderId = isNaN(folderIdRaw) ? undefined : folderIdRaw;

  const filter: MessagingThreadsFilterInput = {
    folder: {
      idEq: folderId,
      // "All" special folder is ignored and is treated as a virtual folder showing everything
      typeEq: folderType === MessagingFolderType.All ? null : folderType,
    },
  };

  return (
    <ThreadsListPageBase
      filter={filter}
      activeFolderKey={params.folderTypeOrId}
      activeFolderType={folderType}
      contentKey={params.folderTypeOrId!}
    />
  );
}

function CustomFilterViewThreadsListPage() {
  const [filterStateCounter, setFilterStateCounter] = useState<number>(0);
  const filter = useSelector(getThreadsFilter);
  useEffect(() => {
    setFilterStateCounter(old => old + 1);
  }, [filter]);
  return (
    <ThreadsListPageBase
      filter={filter || {}}
      filterActive
      contentKey={filterStateCounter}
    />
  );
}

type NamedFilterViewThreadsListPageProps = { filterId: number };

function NamedFilterViewThreadsListPage({
  filterId,
}: NamedFilterViewThreadsListPageProps) {
  const { filter } = useNamedFilterQuery({ id: filterId });
  const { setThreadsFilter } = useMessagesActions();
  const filterContent =
    filter?.content && convertThreadFilterOutputToInput(filter.content);
  useEffect(() => {
    if (filter) {
      setThreadsFilter(filterContent!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, setThreadsFilter]);

  return (
    <ThreadsListPageBase
      filter={filterContent}
      filterActive
      activeFilterId={filterId}
      contentKey={filterId}
    />
  );
}

export function FilterViewThreadsListPage() {
  const params = useParams<UseParams<RouteDefinitions.messagesFilterView>>();
  const filterIdRaw = toNumber(params.filterId);
  const filterId = isNaN(filterIdRaw) ? undefined : filterIdRaw;

  return isNumber(filterId) ? (
    <NamedFilterViewThreadsListPage filterId={filterId} />
  ) : (
    <CustomFilterViewThreadsListPage />
  );
}
