import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DeleteOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  MoreOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  Dropdown,
  Menu,
  Popover,
  Space,
  Tooltip,
} from 'antd';
import { Set } from 'immutable';
import { compact, isEmpty } from 'lodash-es';
import { FormattedMessage, useIntl } from 'react-intl';

import { useResponsiveQueries } from '../../../common/utils/layoutUtils';
import { showConfirm } from '../../../common/utils/messageUtils';
import { Whitespace } from '../../../components/Whitespace';
import { CircleButton } from '../../../components/buttons';
import { TranslationId } from '../../../types/appTypes';
import {
  MessagingThreadListFragmentFragment,
  MessagingThreadsFilterInput,
} from '../../../types/graphqlGenerated';
import { Pagination } from '../../common/pagination/paginationElements';
import { PageInfo } from '../../common/pagination/paginationTypes';
import { ReadUnReadBulkButton } from '../ReadUnreadButton';
import { useNamedFiltersQuery } from '../filters/filtersGraphql';
import { useFoldersQuery } from '../folders/foldersGraphql';
import { useMessagesAppViewMode } from '../messagesAppViewMode';
import { useDeleteThreadsMutation } from '../threadsGraphql';
import { QuickFilter } from './QuickFilter';
import style from './ThreadsListToolbar.module.less';
import { ThreeValueButtonQuickFilter } from './quickFilterElements';

type DeleteButtonProps = {
  threadIds: number[];
  allSelected: boolean;
  filter: MessagingThreadsFilterInput;
};

function DeleteButton({ threadIds, allSelected, filter }: DeleteButtonProps) {
  const [deleteThreads, { loading }] = useDeleteThreadsMutation();

  return (
    <CircleButton
      icon={<DeleteOutlined />}
      disabled={loading || (isEmpty(threadIds) && !allSelected)}
      loading={loading}
      onClick={() =>
        showConfirm({
          title: <FormattedMessage id="deleteThreads.modalTitle" />,
          content: <FormattedMessage id="deleteThreads.modalContent" />,
          onOk: async () => {
            await deleteThreads({
              filter: allSelected ? filter : { id: { in: threadIds } },
            });
          },
        })
      }
    />
  );
}

const LastMessageOutgoingQuickFilter = () => (
  <QuickFilter name="lastMessageOutgoing">
    {({ value, onChange }) => (
      <ThreeValueButtonQuickFilter
        value={value as boolean | undefined}
        onChange={onChange}
        falseIcon={<ArrowLeftOutlined />}
        falseTooltipId="toolbar.quickFilter.lastMessageIncoming"
        trueIcon={<ArrowRightOutlined />}
        trueTooltipId="toolbar.quickFilter.lastMessageOutgoing"
      />
    )}
  </QuickFilter>
);

const UnreadQuickFilter = () => (
  <QuickFilter name="unread">
    {({ value, onChange }) => (
      <ThreeValueButtonQuickFilter
        value={value as boolean | undefined}
        onChange={onChange}
        falseIcon={<EyeOutlined />}
        falseTooltipId="toolbar.quickFilter.read"
        trueIcon={<EyeInvisibleOutlined />}
        trueTooltipId="toolbar.quickFilter.unread"
      />
    )}
  </QuickFilter>
);

type ActiveViewLabelInnerProps = {
  type: 'folder' | 'filter';
  name?: string | number;
  nameId?: TranslationId;
};

function ActiveViewLabelInner({
  type,
  name,
  nameId,
}: ActiveViewLabelInnerProps) {
  const intl = useIntl();

  const label = intl.formatMessage({
    id: type === 'filter' ? 'toolbar.activeFilter' : 'toolbar.activeFolder',
  });
  const value = nameId ? intl.formatMessage({ id: nameId }) : name;

  if (!value) {
    return null;
  }
  return (
    <Tooltip title={`${label}: ${value}`}>
      <span>
        {label}:<Whitespace />
        <strong>{value}</strong>
      </span>
    </Tooltip>
  );
}

function ActiveViewLabel() {
  const { viewMode } = useMessagesAppViewMode();
  const { folders } = useFoldersQuery();
  const { filters } = useNamedFiltersQuery();

  const getFullDisplayPath = (folder): string => {
    if (folder?.parentNode?.id) {
      const parentNode = folders?.find(f => f.id === folder.parentNode.id);
      return `${getFullDisplayPath(parentNode)} / ${folder.displayName}`;
    }
    return folder?.displayName;
  };

  if (viewMode?.type === 'folder') {
    if (viewMode.folderType) {
      return (
        <ActiveViewLabelInner
          type="folder"
          nameId={`enums.FolderType.${viewMode.folderType}`}
        />
      );
    }
    const folder = folders?.find(f => f.id === viewMode.folderId);
    return (
      <ActiveViewLabelInner
        type="folder"
        name={getFullDisplayPath(folder) ?? viewMode.folderId}
      />
    );
  }

  if (viewMode?.type === 'namedFilter') {
    const filter = filters?.find(f => f.id === viewMode.filterId);
    return (
      <ActiveViewLabelInner
        type="filter"
        name={filter?.name ?? viewMode.filterId}
      />
    );
  }

  if (viewMode?.type === 'customFilter') {
    return <ActiveViewLabelInner type="filter" nameId="toolbar.customFilter" />;
  }

  return null;
}

type Props = {
  pageInfo: PageInfo;
  onPageIndexChange: (index: number) => void;
  onRefresh: () => void;
  onSelectFullPage: (newValue: boolean) => void;
  fullPageSelected: boolean;
  allSelected: boolean;
  onSelectAll: () => void;
  selectedKeys: number[];
  allItemsSize: number;
  filter: MessagingThreadsFilterInput;
  selectedThreads: MessagingThreadListFragmentFragment[];
  setSelectedKeys: (val: Set<number>) => void;
  setAllSelected: (val: boolean) => void;
};

export function ThreadsListToolbar({
  pageInfo,
  onPageIndexChange,
  onRefresh,
  onSelectFullPage,
  fullPageSelected,
  allSelected,
  onSelectAll,
  selectedKeys,
  allItemsSize,
  filter,
  selectedThreads,
  setSelectedKeys,
  setAllSelected,
}: Props) {
  const media = useResponsiveQueries();
  const isSmOrMd = media.sm || media.md;
  const showSelectAllPages =
    fullPageSelected && !allSelected && allItemsSize > selectedKeys.length;

  const smMenu = (
    <Menu
      items={compact([
        allSelected && {
          key: 'allSelected',
          label: (
            <div className={style.SelectionLabel}>
              <FormattedMessage
                id="selection.allSelected"
                values={{ number: allItemsSize }}
              />
            </div>
          ),
        },
        showSelectAllPages && {
          key: 'selectAllPages',
          label: (
            <>
              <FormattedMessage
                id="selection.fullPageSelected"
                values={{ number: selectedKeys.length }}
              />
              <Button
                type="link"
                onClick={onSelectAll}
                className={style.SelectAllButton}
              >
                <FormattedMessage
                  id="selection.selectAll"
                  values={{ number: allItemsSize }}
                />
              </Button>
            </>
          ),
        },
      ])}
    />
  );

  const selectFullPageCheckbox = (
    <Checkbox
      onChange={value => onSelectFullPage(value.target.checked)}
      checked={fullPageSelected}
    />
  );

  return (
    <div className={style.Container}>
      <div className={style.Controls}>
        {isSmOrMd ? (
          <Dropdown.Button overlay={smMenu} className={style.DropdownButton}>
            {selectFullPageCheckbox}
          </Dropdown.Button>
        ) : (
          selectFullPageCheckbox
        )}
        <CircleButton icon={<ReloadOutlined />} onClick={onRefresh} />
        <DeleteButton
          allSelected={allSelected}
          threadIds={selectedKeys}
          filter={filter}
        />
        <ReadUnReadBulkButton
          allSelected={allSelected}
          allSelectedRead={selectedThreads?.every(thread => !thread.unread)}
          threadIds={selectedKeys}
          filter={filter}
          setSelectedKeys={setSelectedKeys}
          setAllSelected={setAllSelected}
        />
        {!isSmOrMd && (
          <>
            {allSelected && (
              <div className={style.SelectionLabel}>
                <FormattedMessage
                  id="selection.allSelected"
                  values={{ number: allItemsSize }}
                />
              </div>
            )}
            {showSelectAllPages && (
              <div className={style.SelectionLabel}>
                <div>
                  <FormattedMessage
                    id="selection.fullPageSelected"
                    values={{ number: selectedKeys.length }}
                  />
                </div>
                <Button
                  type="link"
                  onClick={onSelectAll}
                  className={style.SelectAllText}
                >
                  <FormattedMessage
                    id="selection.selectAll"
                    values={{ number: allItemsSize }}
                  />
                </Button>
              </div>
            )}
          </>
        )}
        {media.sm ? (
          <Popover
            trigger="click"
            title={
              <Space direction="vertical" size={10}>
                <ActiveViewLabel />
                <LastMessageOutgoingQuickFilter />
                <UnreadQuickFilter />
              </Space>
            }
          >
            <CircleButton icon={<MoreOutlined />} />
          </Popover>
        ) : (
          <>
            <LastMessageOutgoingQuickFilter />
            <UnreadQuickFilter />
          </>
        )}
      </div>
      {!media.sm && (
        <div className={style.ActiveView}>
          <ActiveViewLabel />
        </div>
      )}
      <div className={style.Pagination}>
        <Pagination value={pageInfo} onChange={onPageIndexChange} />
      </div>
    </div>
  );
}
