import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { CircleButton } from '../../../components/buttons';
import {
  PageSize,
  getPageSize,
  useSettingsActions,
} from '../../settings/settingsReducer';
import style from './paginationElements.module.less';
import { PageInfo } from './paginationTypes';

type PaginationControlsProps = {
  onPaginate: (diff: number) => void;
  prevDisabled: boolean;
  nextDisabled: boolean;
  pageFrom?: number;
  pageTo?: number;
  itemIndex?: number;
  total: number;
  contentRight?: any;
};

export function PaginationControls(props: PaginationControlsProps) {
  const {
    onPaginate,
    prevDisabled,
    nextDisabled,
    pageFrom,
    pageTo,
    itemIndex,
    total,
  } = props;
  const pageLabel = itemIndex ? itemIndex : `${pageFrom} - ${pageTo}`;
  return (
    <>
      <span>
        {pageLabel}
        <FormattedMessage id="common.pagingLabelTotal" values={{ total }} />
      </span>
      <CircleButton
        disabled={prevDisabled}
        icon={<LeftOutlined />}
        onClick={() => onPaginate(-1)}
      />
      <CircleButton
        disabled={nextDisabled}
        icon={<RightOutlined />}
        onClick={() => onPaginate(1)}
      />
      {props.contentRight}
    </>
  );
}

const PAGINATION_OPTIONS = ([20, 50, 100] as PageSize[]).map(val => ({
  value: val,
  label: `${val}`,
}));

type PaginationProps = {
  value: PageInfo;
  onChange: (index: number) => void;
};

export function Pagination({ value, onChange }: PaginationProps) {
  const pageSize = useSelector(getPageSize);
  const { setPageSize } = useSettingsActions();
  const { index, total } = value;
  const indexFrom = index * pageSize;
  const pageFrom = total ? indexFrom + 1 : 0;
  const pageTo = Math.min(indexFrom + pageSize, total);
  const pageFromDisabled = pageFrom === 1;
  const pageToDisabled = pageTo >= total;
  return (
    <div className={style.Pagination}>
      <PaginationControls
        onPaginate={dir => onChange(value.index + dir)}
        prevDisabled={pageFromDisabled}
        nextDisabled={pageToDisabled}
        pageFrom={pageFrom}
        pageTo={pageTo}
        total={total}
        contentRight={
          <>
            <Select
              options={PAGINATION_OPTIONS}
              value={pageSize}
              onChange={val => setPageSize(val)}
              bordered={false}
            />
            <FormattedMessage id="common.pageSizeSuffix" />
          </>
        }
      />
    </div>
  );
}
