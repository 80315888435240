import { useMediaQuery } from 'react-responsive';

const breakpointValues = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export function useResponsiveQueries() {
  return {
    sm: useMediaQuery({ maxWidth: breakpointValues.md - 1 }),
    md: useMediaQuery({
      minWidth: breakpointValues.md,
      maxWidth: breakpointValues.lg - 1,
    }),
    lg: useMediaQuery({ minWidth: breakpointValues.lg }),
  };
}
