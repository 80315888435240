import { CloseCircleOutlined, FileAddOutlined } from '@ant-design/icons';
import { Button, Space, Spin, Upload } from 'antd';
import { FieldInputProps, useFormikContext } from 'formik';
import { isEmpty, stubFalse } from 'lodash-es';
import { useRef, useState } from 'react';
import { FixedCropper, ImageRestriction } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import { FormattedMessage } from 'react-intl';

import { blobToDataUrl, getCroppedCanvas } from '../../common/utils/fileUtils';
import { useAsync } from '../../common/utils/hookUtils';
import {
  FileUploadFile,
  UploadFunction,
  useExecuteAntUpload,
} from './FileUpload';
import style from './ImageUpload.module.less';

type ImageUploadProps = FieldInputProps<FileUploadFile[]> & {
  upload: UploadFunction;
};

export function ImageUpload({ name, value, upload }: ImageUploadProps) {
  const { setFieldValue } = useFormikContext();
  const { uploadRef, executeUpload } = useExecuteAntUpload({ name, upload });
  const cropperRef = useRef<any>(null);
  const { data: tempImgSrc } = useAsync(
    () => blobToDataUrl(value?.[0]?.originFileObj!),
    {
      skip: !value?.[0]?.originFileObj || !!value?.[0]?.status,
    }
  );
  const [uploading, setUploading] = useState(false);

  const isModifyStep = !isEmpty(value) && tempImgSrc && !value?.[0]?.status;
  const imgSrc = value?.[0]?.response?.src;

  return (
    <>
      <div style={isModifyStep ? { display: 'none' } : {}}>
        <Upload
          ref={uploadRef}
          fileList={value}
          onChange={({ fileList }) => setFieldValue(name, fileList)}
          multiple={false}
          maxCount={1}
          accept="image/*"
          showUploadList={false}
          beforeUpload={stubFalse}
        >
          <div className={style.Button}>
            <ButtonContent name={name} imgSrc={imgSrc} uploading={uploading} />
          </div>
        </Upload>
      </div>
      {isModifyStep && (
        <Space direction="vertical" size={10}>
          <FixedCropper
            ref={cropperRef}
            src={tempImgSrc}
            style={{ width: 300, height: 300 }}
            stencilSize={{
              width: 200,
              height: 200,
            }}
            imageRestriction={ImageRestriction.stencil}
          />
          <div className="FlexRow FlexRow--SpaceSm">
            <Button onClick={() => setFieldValue(name, [])}>
              <FormattedMessage id="labels.cancel" />
            </Button>
            <Button
              type="primary"
              loading={uploading}
              disabled={uploading}
              onClick={async () => {
                setUploading(true);
                try {
                  const coordinates = cropperRef.current.getCoordinates();
                  const img = document.createElement('img');
                  img.src = tempImgSrc!;
                  await new Promise(resolve => {
                    img.onload = () => resolve(null);
                  });
                  const croppedCanvas = getCroppedCanvas(img, coordinates, {
                    width: 200,
                    height: 200,
                  });
                  const blob: Blob = await new Promise((resolve, reject) =>
                    croppedCanvas.toBlob(res => (res ? resolve(res) : reject()))
                  );
                  const file = new File([blob], value[0].name, {
                    type: value[0].type,
                  });
                  (file as any).uid = value[0].uid;
                  await executeUpload({
                    ...value[0],
                    originFileObj: file as FileUploadFile['originFileObj'],
                  });
                } finally {
                  setUploading(false);
                }
              }}
            >
              <FormattedMessage id="labels.confirm" />
            </Button>
          </div>
        </Space>
      )}
    </>
  );
}

type ButtonContentProps = {
  name: string;
  imgSrc?: string;
  uploading: boolean;
};

function ButtonContent({ name, imgSrc, uploading }: ButtonContentProps) {
  const { setFieldValue } = useFormikContext();

  if (uploading) {
    return <Spin />;
  }
  if (!imgSrc) {
    return <FileAddOutlined />;
  }
  return (
    <>
      <img src={imgSrc} alt="" />
      <Button
        icon={<CloseCircleOutlined />}
        type="text"
        size="middle"
        onClick={e => {
          setFieldValue(name, []);
          e.stopPropagation();
        }}
        className={style['Button__Close']}
      />
    </>
  );
}
