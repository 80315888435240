import { Map } from 'immutable';
import { noop, pick } from 'lodash-es';
import {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import {
  BeforeCapture,
  DragDropContext,
  DragStart,
  DragUpdate,
  DropResult,
} from 'react-beautiful-dnd';

import { DragAndDropItemType } from './dragAndDropConstants';

type StartHandler = (event: DragStart) => any;
type StartHandlers = Map<DragAndDropItemType, StartHandler>;
type EndHandler = (event: DropResult, data: any) => void;
type EndHandlers = Map<DragAndDropItemType, EndHandler>;

type DragAndDropStateContextType = {
  willDrag?: string;
  lastDragUpdate?: { itemType: DragAndDropItemType } & Pick<
    DragUpdate,
    'draggableId' | 'source' | 'destination' | 'combine'
  >;
  registerStartHandler: (
    type: DragAndDropItemType,
    handler: StartHandler
  ) => void;
  unregisterStartHandler: (type: DragAndDropItemType) => void;
  registerEndHandler: (type: DragAndDropItemType, handler: EndHandler) => void;
  unregisterEndHandler: (type: DragAndDropItemType) => void;
};

const DragAndDropStateContext = createContext<DragAndDropStateContextType>({
  registerStartHandler: noop,
  unregisterStartHandler: noop,
  registerEndHandler: noop,
  unregisterEndHandler: noop,
});

export function useDragAndDropStateContext() {
  return useContext(DragAndDropStateContext);
}

const extractItemType = (event: DragUpdate | DragStart) => {
  const { destination, combine } = event as DragUpdate;
  if (destination) {
    return destination.droppableId as DragAndDropItemType;
  }
  if (combine) {
    return combine.droppableId as DragAndDropItemType;
  }
  if (event.source) {
    return event.source.droppableId as DragAndDropItemType;
  }
  return null;
};

export default function RichDragAndDropContextProvider({ children }) {
  const [willDrag, setWillDrag] = useState<string | undefined>();
  const [lastDragUpdate, setLastDragUpdate] =
    useState<DragAndDropStateContextType['lastDragUpdate']>();
  const data = useRef<any>();

  const [startHandlers, setStartHandlers] = useState<StartHandlers>(Map());
  const registerStartHandler = useCallback<
    DragAndDropStateContextType['registerStartHandler']
  >((type, handler) => {
    setStartHandlers(old => old.set(type, handler));
  }, []);
  const unregisterStartHandler = useCallback<
    DragAndDropStateContextType['unregisterStartHandler']
  >(type => {
    setStartHandlers(old => old.remove(type));
  }, []);

  const [endHandlers, setEndHandlers] = useState<EndHandlers>(Map());
  const registerEndHandler = useCallback<
    DragAndDropStateContextType['registerEndHandler']
  >((type, handler) => {
    setEndHandlers(old => old.set(type, handler));
  }, []);
  const unregisterEndHandler = useCallback<
    DragAndDropStateContextType['unregisterEndHandler']
  >(type => {
    setEndHandlers(old => old.remove(type));
  }, []);

  const onBeforeCapture = useCallback((before: BeforeCapture) => {
    setWillDrag(before.draggableId);
  }, []);

  const onDragStart = useCallback(
    (event: DragStart) => {
      setWillDrag(undefined);

      const itemType = extractItemType(event);
      const handler = itemType && startHandlers.get(itemType);
      if (itemType && handler) {
        data.current = handler(event);
      }
    },
    [startHandlers]
  );

  const onDragUpdate = useCallback((event: DragUpdate) => {
    const itemType = extractItemType(event);
    if (itemType) {
      setLastDragUpdate({
        itemType,
        ...pick(event, ['draggableId', 'source', 'destination', 'combine']),
      });
    }
  }, []);

  const onDragEnd = useCallback(
    (result: DropResult) => {
      try {
        if (result.reason !== 'DROP') {
          return;
        }

        const itemType = extractItemType(result);
        const handler = itemType && endHandlers.get(itemType);
        if (itemType && handler) {
          handler(result, data.current);
        }
      } finally {
        setLastDragUpdate(undefined);
        data.current = undefined;
      }
    },
    [data, endHandlers]
  );

  return (
    <DragDropContext
      onBeforeCapture={onBeforeCapture}
      onDragStart={onDragStart}
      onDragUpdate={onDragUpdate}
      onDragEnd={onDragEnd}
    >
      <DragAndDropStateContext.Provider
        value={{
          willDrag,
          lastDragUpdate,
          registerStartHandler,
          unregisterStartHandler,
          registerEndHandler,
          unregisterEndHandler,
        }}
      >
        {children}
      </DragAndDropStateContext.Provider>
    </DragDropContext>
  );
}
