import { InputNumber, InputNumberProps } from 'antd';
import { FieldInputProps, useFormikContext } from 'formik';

export type NumberInputProps = InputNumberProps & FieldInputProps<number>;

export function NumberInput({
  name,
  onChange: _onChange,
  ...rest
}: NumberInputProps) {
  const { setFieldValue } = useFormikContext();

  return (
    <InputNumber
      name={name}
      onChange={val => setFieldValue(name, val)}
      {...rest}
    />
  );
}
