import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import RouteDefinitions from '../app/routes';
import style from './Logo.module.less';

export function LogoFull() {
  return (
    <Link to={RouteDefinitions.root}>
      <h1 className={style.LogoFull}>
        <FormattedMessage id="brand.name" />
      </h1>
    </Link>
  );
}

export function LogoSmall() {
  return (
    <Link to={RouteDefinitions.root}>
      <h2 className={style.LogoSmall}>
        <FormattedMessage id="brand.name" />
      </h2>
    </Link>
  );
}
