import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

import {
  MessagingMessageFullFragmentFragment,
  MessagingMessageStatus,
} from '../../../types/graphqlGenerated';
import styles from './ChatMessageItem.module.less';

type MessageStatusProps = {
  message?: MessagingMessageFullFragmentFragment;
};

export const MessageStatus = ({ message }: MessageStatusProps) => {
  if (!message) {
    return null;
  }

  const renderStatusIcon = () => {
    switch (message.status) {
      case MessagingMessageStatus.Sent:
        return (
          <Tooltip title="Sent (not delivered yet)">
            <CheckOutlined style={{ color: 'gray' }} />
          </Tooltip>
        );
      case MessagingMessageStatus.Delivered:
      case MessagingMessageStatus.Read: {
        const isRead = message.status === MessagingMessageStatus.Read;
        return (
          <Tooltip title={isRead ? 'Read' : 'Delivered'}>
            <CheckOutlined
              style={{
                color: isRead ? 'blue' : 'gray',
              }}
            />
            <CheckOutlined
              style={{
                color: isRead ? 'blue' : 'gray',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translateY(-50%)',
              }}
            />
          </Tooltip>
        );
      }

      case MessagingMessageStatus.Failed:
        return (
          <Tooltip title={message?.failReason}>
            <CloseOutlined style={{ color: 'red' }} />
          </Tooltip>
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.MessageStatus}>
      <div className={styles.IconContainer}>{renderStatusIcon()}</div>
    </div>
  );
};
