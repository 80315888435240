import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, List, Space, Tooltip } from 'antd';
import { isEmpty } from 'lodash-es';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useMountEffect } from '../../../common/utils/hookUtils';
import { showConfirm } from '../../../common/utils/messageUtils';
import { ErrorAlert } from '../../../components/ErrorAlert';
import { OverlaySpinner } from '../../../components/OverlaySpinner';
import SidebarControls, {
  SidebarControlButton,
} from '../../../components/SidebarControls';
import { DEFAULT_CALENDAR_COLOR } from '../../../config/constants';
import { Calendar } from '../../../types/graphqlGenerated';
import { ConnectAccountButton } from '../../accounts/ConnectAccountButton';
import { MessagingAccountLabel } from '../../accounts/accountFormats';
import { AppSidebar } from '../../layout/AppSidebar';
import { useLayoutActions } from '../../layout/layoutReducer';
import { CreateCalendarEventButton } from '../calendarEventEditing/CreateCalendarEventModal';
import {
  useCalendarsQuery,
  useDeleteCalendarMutation,
  useUpdateCalendarMutation,
} from '../calendarGraphql';
import { CalendarListItem } from '../calendarView/CalendarListItem';
import CreateCalendarModal from '../calendarView/CreateCalendarModal';
import styles from './CalendarSidebar.module.less';

export function CalendarItem({ calendar }: { calendar: Calendar }) {
  const [updateCalendar] = useUpdateCalendarMutation();
  const [deleteCalendar] = useDeleteCalendarMutation();
  return (
    <CalendarListItem
      title={`${calendar.name} (${calendar.account.platformId})`}
      onCheckedChange={selected =>
        updateCalendar(calendar.id, {
          accountId: calendar.account.id,
          selected,
          color: calendar.color,
        })
      }
      checked={calendar.selected}
      checkboxColor={calendar.color!}
      onDelete={() => {
        showConfirm({
          title: <FormattedMessage id="labels.areYouSure" />,
          onOk: () => deleteCalendar({ id: calendar.id }),
        });
      }}
      onSelectColor={color => {
        updateCalendar(calendar.id, {
          color,
          selected: calendar.selected,
          accountId: calendar.account.id,
        });
      }}
      color={calendar.color || DEFAULT_CALENDAR_COLOR}
    >
      {calendar.name}
    </CalendarListItem>
  );
}

export default function CalendarSidebar() {
  const { loading, error, calendars, errors } = useCalendarsQuery();
  const [createModalOpen, setCreateModalOpen] = useState(false);

  if (error) {
    return <ErrorAlert error={error} />;
  }
  if (loading) {
    return <OverlaySpinner />;
  }
  if (isEmpty(calendars)) {
    return <EmptyCalendarSidebar />;
  }

  return (
    <AppSidebar>
      <ConnectAccountButton />
      <CreateCalendarEventButton />
      <List
        size="small"
        rowKey="id"
        dataSource={calendars}
        renderItem={(calendar: Calendar) => (
          <CalendarItem calendar={calendar} />
        )}
        className={styles.List}
        footer={
          <Space direction="vertical" size={10}>
            {!isEmpty(errors) && (
              <Alert
                type="error"
                message={
                  <div className={styles.ErrorLoading}>
                    <div>
                      <FormattedMessage id="error.loadingCalendars" />
                    </div>
                    {errors?.map(err => (
                      <div
                        key={err.account.id}
                        className={styles.ErrorLoading__Account}
                      >
                        <MessagingAccountLabel account={err.account} />
                        <Tooltip title={err.message}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                    ))}
                  </div>
                }
              />
            )}
            <SidebarControls>
              <SidebarControlButton
                tooltipId="calendarSidebar.addCalendar"
                onClick={() => setCreateModalOpen(true)}
                icon={<PlusOutlined />}
              />
            </SidebarControls>
          </Space>
        }
      />
      <CreateCalendarModal
        visible={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
      />
    </AppSidebar>
  );
}

function EmptyCalendarSidebar() {
  const { setSidebarCollapse } = useLayoutActions();
  useMountEffect(() => {
    setSidebarCollapse(false);
  });

  return (
    <AppSidebar>
      <ConnectAccountButton />
      <Alert
        type="info"
        message={
          <div className="FlexRow FlexRow--SpaceSm">
            <InfoCircleOutlined />
            <span>
              <FormattedMessage id="calendar.connectAccount.info" />
            </span>
          </div>
        }
      />
    </AppSidebar>
  );
}
