import { Input } from 'antd';
import { useFormik } from 'formik';
import { FormattedMessage } from 'react-intl';

import { ErrorCodes } from '../../../app/ErrorCodes';
import { extractErrorCode } from '../../../common/utils/errorUtils';
import { showDebouncedErrorMessage } from '../../../common/utils/messageUtils';
import { SimpleFormModal } from '../../../components/SimpleFormModal';
import FormField from '../../../components/forms/FormField';
import FormItem from '../../../components/forms/FormItem';
import { CircleColorPicker } from '../../../components/forms/colors';
import { MessagingAccountSelect } from '../../../components/forms/fetchingSelects';
import { useLocalizedYup } from '../../../config/intl/LocaleProvider';
import { useCreateCalendarMutation } from '../calendarGraphql';
import styles from './CreateCalendarModal.module.less';

const INITIAL_VALUES = {
  name: '',
  accountId: null,
  color: '',
};

export default function CreateCalendarModal({ visible, onClose }) {
  const [createCalendar] = useCreateCalendarMutation({
    onError: e => {
      if (extractErrorCode(e) === ErrorCodes.Api.UNALLOWED_OPERATION) {
        showDebouncedErrorMessage(
          <FormattedMessage id="calendar.dialog.create.error.unallowedOperation" />
        );
      }
    },
  });
  const yup = useLocalizedYup();
  const validationSchema = yup.object().shape({
    name: yup.string().required(),
    accountId: yup.number().required(),
  });
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema,
    async onSubmit(values) {
      await createCalendar({
        input: {
          accountId: values.accountId!,
          name: values.name,
          color: values.color || null,
        },
      });
    },
  });
  return (
    <SimpleFormModal
      titleId="createCalendar.title"
      visible={visible}
      onClose={onClose}
      formik={formik}
      className={styles.Container}
    >
      <FormItem labelId="createCalendar.account">
        <FormField
          component={MessagingAccountSelect}
          name="accountId"
          className="FullWidth"
        />
      </FormItem>
      <FormItem labelId="createCalendar.name">
        <FormField component={Input} name="name" />
      </FormItem>
      <FormItem labelId="createCalendar.account">
        <FormField component={CircleColorPicker} name="color" />
      </FormItem>
    </SimpleFormModal>
  );
}
