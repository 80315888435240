import { isNumber, toNumber } from 'lodash-es';
import { useParams } from 'react-router';

import RouteDefinitions from '../app/routes';
import CalendarEventDetailContent from '../modules/calendarApp/calendarEventDetail/CalendarEventDetailContent';
import CalendarEventDetailSidebar from '../modules/calendarApp/calendarEventDetail/CalendarEventDetailSidebar';
import { BaseLayout } from '../modules/layout/BaseLayout';
import { UseParams } from '../types/libHelperTypes';

export function CalendarEventDetailPage() {
  const { eventId, calendarId } =
    useParams<UseParams<RouteDefinitions.calendarEventDetail>>();
  const calendarIdNum = toNumber(calendarId);

  if (!eventId || !isNumber(calendarIdNum)) {
    return null;
  }

  return (
    <BaseLayout
      sidebar={<CalendarEventDetailSidebar />}
      content={
        <CalendarEventDetailContent
          eventId={eventId}
          calendarId={calendarIdNum}
        />
      }
    />
  );
}
