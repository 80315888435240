import { ReloadOutlined } from '@ant-design/icons';
import { Alert, Modal, Space } from 'antd';
import { subMinutes } from 'date-fns';
import { isAfter } from 'date-fns/esm';
import { isNumber } from 'lodash-es';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../common/utils/messageUtils';
import { Whitespace } from '../../../components/Whitespace';
import {
  MessagingAccount,
  MessagingAccountType,
} from '../../../types/graphqlGenerated';
import { DynamicMenuItem } from '../../common/dynamicMenu/dynamicMenuTypes';
import { MessagingAccountLabel } from '../accountFormats';
import {
  useAccountsQuery,
  useForceMessagingAccountFullLoad,
} from '../accountsGraphql';
import styles from './AccountsListItem.module.less';

const computeDisabled = (account: MessagingAccount) =>
  !!(
    !account.initialized &&
    !account.error &&
    (!account.lastData ||
      isAfter(new Date(account.lastData), subMinutes(new Date(), 10)))
  );

export function useForceReloadAccountMenuItem({
  account,
}: {
  account: MessagingAccount;
}): DynamicMenuItem {
  const intl = useIntl();

  const [selectedAccount, setSelectedAccount] = useState<number | undefined>(
    undefined
  );
  const [forceReload] = useForceMessagingAccountFullLoad();
  const { refetch: refetchAccounts } = useAccountsQuery();

  const disabled = computeDisabled(account);

  return {
    key: 'forceReload',
    visible: account.accountType === MessagingAccountType.Email,
    disabled,
    tooltip: disabled
      ? intl.formatMessage({
          id: 'connectedAccounts.forceReload.recentLastData',
        })
      : undefined,
    icon: <ReloadOutlined className={styles['Icon--Loading']} />,
    label: <FormattedMessage id="connectedAccounts.forceReload" />,
    onClick: () => setSelectedAccount(account.id),
    additionalElements: (
      <Modal
        visible={isNumber(selectedAccount)}
        onCancel={() => setSelectedAccount(undefined)}
        onOk={async () => {
          const accounts = await refetchAccounts();
          const newAccount = accounts?.find(acc => acc.id === account.id);
          if (!newAccount || computeDisabled(newAccount)) {
            showErrorMessage(
              <FormattedMessage id="connectedAccounts.forceReload.recentLastData" />
            );
          } else {
            await forceReload({ id: selectedAccount! });
            showSuccessMessage(
              <FormattedMessage id="connectedAccounts.forceReload.success" />
            );
          }

          setSelectedAccount(undefined);
        }}
        title={
          <FormattedMessage id="connectedAccounts.forceReload.modalTitle" />
        }
        destroyOnClose
      >
        <Space direction="vertical" size={20}>
          <Alert
            type="warning"
            message={
              <Space direction="vertical" size={10}>
                <div>
                  <FormattedMessage id="connectedAccounts.forceReload.modalContent" />
                </div>
                <div className="FlexRow FlexRow--SpaceSm">
                  <span>
                    <FormattedMessage id="connectedAccounts.forceReload.account" />
                    :<Whitespace />
                  </span>
                  <MessagingAccountLabel account={account} />
                </div>
              </Space>
            }
          />
        </Space>
      </Modal>
    ),
  };
}
