import {
  ObservableSubscription,
  gql,
  useApolloClient,
  useMutation,
} from '@apollo/client';
import { merge } from 'lodash-es';
import { useCallback, useState } from 'react';

import { createUseMutation } from '../../common/utils/graphqlUtils';
import {
  CreateMessagingFileDownloadLinkMutation,
  CreateMessagingFileDownloadLinkMutationVariables,
  CreateMessagingFileThumbnailDownloadLinkMutation,
  CreateMessagingFileThumbnailDownloadLinkMutationVariables,
  FinaliseMessagingFileUploadMutation,
  FinaliseMessagingFileUploadMutationVariables,
  MessagingFileFullFragmentFragment,
  OnRequestMessagingFileReuploadDataSubscription,
  OnRequestMessagingFileReuploadDataSubscriptionVariables,
  OnRequestMessagingFileReuploadResultSubscription,
  OnRequestMessagingFileReuploadResultSubscriptionVariables,
  PrepareFileUploadResult,
  PrepareIssuesFileUploadMutation,
  PrepareIssuesFileUploadMutationVariables,
  PrepareMessagingFileUploadMutation,
  PrepareMessagingFileUploadMutationVariables,
  PreparePublicFileUploadMutation,
  PreparePublicFileUploadMutationVariables,
  RequestMessagingFileReuploadMutation,
  RequestMessagingFileReuploadMutationVariables,
} from '../../types/graphqlGenerated';

export const FileFullFragment = gql`
  fragment MessagingFileFullFragment on MessagingFile {
    id
    contentType
    size
    filename
    contentId
    available
    hasThumbnail
  }
`;

const CREATE_MESSAGING_FILE_DOWNLOAD_LINK_MUTATION = gql`
  mutation CreateMessagingFileDownloadLink(
    $id: Int!
    $triggerDownload: Boolean!
  ) {
    createMessagingFileDownloadLink(id: $id, triggerDownload: $triggerDownload)
  }
`;

export const useCreateFileDownloadLinkMutation = createUseMutation<
  CreateMessagingFileDownloadLinkMutation,
  CreateMessagingFileDownloadLinkMutationVariables,
  CreateMessagingFileDownloadLinkMutation['createMessagingFileDownloadLink']
>(CREATE_MESSAGING_FILE_DOWNLOAD_LINK_MUTATION, {
  extractResult: resp => resp.createMessagingFileDownloadLink,
});

const CREATE_MESSAGING_FILE_THUMBNAIL_DOWNLOAD_LINK_MUTATION = gql`
  mutation CreateMessagingFileThumbnailDownloadLink(
    $id: Int!
    $triggerDownload: Boolean!
  ) {
    createMessagingFileThumbnailDownloadLink(
      id: $id
      triggerDownload: $triggerDownload
    )
  }
`;

export const useCreateFileThumbnailDownloadLinkMutation = createUseMutation<
  CreateMessagingFileThumbnailDownloadLinkMutation,
  CreateMessagingFileThumbnailDownloadLinkMutationVariables,
  CreateMessagingFileThumbnailDownloadLinkMutation['createMessagingFileThumbnailDownloadLink']
>(CREATE_MESSAGING_FILE_THUMBNAIL_DOWNLOAD_LINK_MUTATION, {
  extractResult: resp => resp.createMessagingFileThumbnailDownloadLink,
});

const PREPARE_MESSAGING_FILE_UPLOAD_MUTATION = gql`
  mutation PrepareMessagingFileUpload(
    $file: MessagingFileInput!
    $maxSize: Int
  ) {
    prepareMessagingFileUpload(file: $file, maxSize: $maxSize) {
      ticketToken
      uploadUrl
      fields {
        name
        value
      }
    }
  }
`;

const FINALISE_MESSAGING_FILE_UPLOAD_MUTATION = gql`
  ${FileFullFragment}
  mutation FinaliseMessagingFileUpload($ticketToken: String!) {
    finaliseMessagingFileUpload(ticketToken: $ticketToken) {
      ...MessagingFileFullFragment
    }
  }
`;

const createAbortable = () => {
  const state = { aborted: false };
  const abortController = new AbortController();
  const checkState = () => {
    if (state.aborted) {
      throw new Error('Aborted');
    }
  };
  const abort = () => {
    state.aborted = true;
    abortController.abort();
  };

  return { abortController, checkState, abort };
};

/**
 * This operation may take more than 30 seconds (which is the maximum timeout of API gateway) and therefore
 * has to be split into an "initialization" and then waiting for a result
 */
export function useMessagingFileUpload() {
  const [prepareUpload, { loading: preparingUpload }] = useMutation<
    PrepareMessagingFileUploadMutation,
    PrepareMessagingFileUploadMutationVariables
  >(PREPARE_MESSAGING_FILE_UPLOAD_MUTATION);
  const [finaliseUpload, { loading: finalisingUpload }] = useMutation<
    FinaliseMessagingFileUploadMutation,
    FinaliseMessagingFileUploadMutationVariables
  >(FINALISE_MESSAGING_FILE_UPLOAD_MUTATION);

  const executeUpload = useCallback(
    (
      file: File,
      info: PrepareMessagingFileUploadMutationVariables['file'],
      maxSize?: number
    ) => {
      const { abortController, checkState, abort } = createAbortable();

      const execute = async () => {
        checkState();
        const { data: pData } = await prepareUpload({
          variables: { file: info, maxSize },
        });
        const { fields, uploadUrl, ticketToken } =
          pData!.prepareMessagingFileUpload;
        const form = new FormData();
        fields.forEach(({ name, value }) => form.append(name, value));
        form.append('file', file);
        checkState();
        const res = await fetch(uploadUrl, {
          method: 'POST',
          body: form,
          signal: abortController.signal,
        });

        if (!res.ok) {
          throw merge(new Error('Uploading to upload URL failed'), {
            code: 'error.uploadingFile',
          });
        }

        checkState();
        const { data: fData } = await finaliseUpload({
          variables: { ticketToken },
        });
        return fData!.finaliseMessagingFileUpload;
      };

      return { result: execute(), abort };
    },
    [finaliseUpload, prepareUpload]
  );

  return { executeUpload, uploading: preparingUpload || finalisingUpload };
}

type UseFileUploadBaseOpts = {
  prepareUpload: (input: {
    contentType: string;
  }) => Promise<PrepareFileUploadResult>;
  preparing: boolean;
};

function useFileUploadBase({
  prepareUpload,
  preparing,
}: UseFileUploadBaseOpts) {
  const executeUpload = useCallback(
    (file: File) => {
      const { abortController, checkState, abort } = createAbortable();

      const execute = async () => {
        checkState();
        const { fields, uploadUrl, url } = await prepareUpload({
          contentType: file.type,
        });
        const form = new FormData();
        fields.forEach(({ name, value }) => form.append(name, value));
        form.append('file', file);
        checkState();
        const res = await fetch(uploadUrl, {
          method: 'POST',
          body: form,
          signal: abortController.signal,
        });

        if (!res.ok) {
          throw merge(new Error('Uploading to upload URL failed'), {
            code: 'error.uploadingFile',
          });
        }

        return url;
      };

      return { result: execute(), abort };
    },
    [prepareUpload]
  );

  return { executeUpload, uploading: preparing };
}

const PREPARE_PUBLIC_FILE_UPLOAD_MUTATION = gql`
  mutation PreparePublicFileUpload($input: FileUploadInput!) {
    preparePublicFileUpload(input: $input) {
      url
      uploadUrl
      fields {
        name
        value
      }
    }
  }
`;

/**
 * This operation may take more than 30 seconds (which is the maximum timeout of API gateway) and therefore
 * has to be split into an "initialization" and then waiting for a result
 */
export function usePublicFileUpload() {
  const [prepareUpload, { loading }] = useMutation<
    PreparePublicFileUploadMutation,
    PreparePublicFileUploadMutationVariables
  >(PREPARE_PUBLIC_FILE_UPLOAD_MUTATION);

  return useFileUploadBase({
    prepareUpload: async ({ contentType }) => {
      const result = await prepareUpload({
        variables: { input: { contentType } },
      });
      return result.data!.preparePublicFileUpload;
    },
    preparing: loading,
  });
}

const PREPARE_ISSUES_FILE_UPLOAD_MUTATION = gql`
  mutation PrepareIssuesFileUpload($input: FileUploadInput!) {
    prepareIssuesFileUpload(input: $input) {
      url
      uploadUrl
      fields {
        name
        value
      }
    }
  }
`;

/**
 * This operation may take more than 30 seconds (which is the maximum timeout of API gateway) and therefore
 * has to be split into an "initialization" and then waiting for a result
 */
export function useIssuesFileUpload() {
  const [prepareUpload, { loading }] = useMutation<
    PrepareIssuesFileUploadMutation,
    PrepareIssuesFileUploadMutationVariables
  >(PREPARE_ISSUES_FILE_UPLOAD_MUTATION);

  return useFileUploadBase({
    prepareUpload: async ({ contentType }) => {
      const result = await prepareUpload({
        variables: { input: { contentType } },
      });
      return result.data!.prepareIssuesFileUpload;
    },
    preparing: loading,
  });
}

const REQUEST_FILE_REUPLOAD = gql`
  mutation RequestMessagingFileReupload($fileId: Int!, $messageId: Int!) {
    requestMessagingFileReupload(fileId: $fileId, messageId: $messageId)
  }
`;

const useRequestFileReupload = createUseMutation<
  RequestMessagingFileReuploadMutation,
  RequestMessagingFileReuploadMutationVariables,
  RequestMessagingFileReuploadMutation['requestMessagingFileReupload']
>(REQUEST_FILE_REUPLOAD, {
  extractResult: resp => resp.requestMessagingFileReupload,
});

const ON_REQUEST_FILE_REUPLOAD_DATA_SUBSCRIPTION = gql`
  subscription OnRequestMessagingFileReuploadData($ticketToken: String!) {
    onRequestMessagingFileReuploadData(ticketToken: $ticketToken) {
      type
    }
  }
`;

const ON_REQUEST_FILE_REUPLOAD_RESULT_SUBSCRIPTION = gql`
  ${FileFullFragment}
  subscription OnRequestMessagingFileReuploadResult($ticketToken: String!) {
    onRequestMessagingFileReuploadResult(ticketToken: $ticketToken) {
      success
      errorCode
      errorMessage
      data {
        file {
          ...MessagingFileFullFragment
        }
      }
    }
  }
`;

type UseFileReuploadResult = [
  (
    fileId: number,
    messageId: number
  ) => Promise<MessagingFileFullFragmentFragment>,
  { events: string[] }
];

export function useFileReupload(): UseFileReuploadResult {
  const [requestReupload] = useRequestFileReupload();
  const client = useApolloClient();

  const [events, setEvents] = useState<string[]>([]);

  const initFlow = async (fileId: number, messageId: number) => {
    setEvents([]);
    const ticketToken = await requestReupload({
      fileId,
      messageId,
    });
    if (!ticketToken) {
      throw new Error('Unexpected data');
    }
    const sub1 = client
      .subscribe<
        OnRequestMessagingFileReuploadDataSubscription,
        OnRequestMessagingFileReuploadDataSubscriptionVariables
      >({
        query: ON_REQUEST_FILE_REUPLOAD_DATA_SUBSCRIPTION,
        variables: { ticketToken },
      })
      .subscribe({
        next: ({ data }) => {
          if (data?.onRequestMessagingFileReuploadData.type) {
            setEvents(evs => [
              ...evs,
              data.onRequestMessagingFileReuploadData.type,
            ]);
          }
        },
      });
    let sub2: ObservableSubscription;
    return new Promise<MessagingFileFullFragmentFragment>((resolve, reject) => {
      sub2 = client
        .subscribe<
          OnRequestMessagingFileReuploadResultSubscription,
          OnRequestMessagingFileReuploadResultSubscriptionVariables
        >({
          query: ON_REQUEST_FILE_REUPLOAD_RESULT_SUBSCRIPTION,
          variables: { ticketToken },
        })
        .subscribe({
          next: ({ data }) => {
            const { success, errorCode, errorMessage } =
              data?.onRequestMessagingFileReuploadResult || {};
            if (success) {
              resolve(data!.onRequestMessagingFileReuploadResult.data!.file);
            } else {
              const e = Object.assign(new Error(errorMessage || ''), {
                code: errorCode || '',
              });
              reject(e);
            }
          },
        });
    }).finally(() => {
      sub1?.unsubscribe();
      sub2?.unsubscribe();
    });
  };

  return [initFlow, { events }];
}
