export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date represented as string YYYY-MM-DD */
  Date: string;
  /** Date with time */
  DateTime: string;
  ObjectScalarType: any;
  StringOrStringArray: any;
  UnknownArray: any;
};

export type AsyncConnectMessagingAccountData = {
  __typename?: 'AsyncConnectMessagingAccountData';
  qrcode: Scalars['String'];
};

export type AsyncConnectMessagingAccountResult = {
  __typename?: 'AsyncConnectMessagingAccountResult';
  errorCode?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type Calendar = {
  __typename?: 'Calendar';
  account: MessagingAccount;
  color?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
};

export type CalendarEvent = {
  __typename?: 'CalendarEvent';
  accountId: Scalars['Int'];
  calendarId: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  masterEvent?: Maybe<CalendarEvent>;
  masterEventId?: Maybe<Scalars['String']>;
  participants?: Maybe<Array<CalendarEventParticipant>>;
  readOnly: Scalars['Boolean'];
  recurrence?: Maybe<Recurrence>;
  status: CalendarEventStatus;
  title: Scalars['String'];
  when: CalendarEventInstant;
};

export type CalendarEventInput = {
  calendarId: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  participants?: InputMaybe<Array<CalendarEventParticipantInput>>;
  recurrence?: InputMaybe<RecurrenceInput>;
  title: Scalars['String'];
  when: CalendarEventInstantInput;
};

export type CalendarEventInstant = DateInstant | DatespanInstant | TimeInstant | TimespanInstant;

export type CalendarEventInstantInput = {
  date?: InputMaybe<Scalars['Date']>;
  endDate?: InputMaybe<Scalars['Date']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['Date']>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  time?: InputMaybe<Scalars['DateTime']>;
};

export type CalendarEventParticipant = {
  __typename?: 'CalendarEventParticipant';
  email: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<CalendarEventParticipantStatus>;
};

export type CalendarEventParticipantInput = {
  email: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<CalendarEventParticipantStatus>;
};

export enum CalendarEventParticipantStatus {
  Maybe = 'maybe',
  No = 'no',
  Noreply = 'noreply',
  Yes = 'yes'
}

export type CalendarEventRef = {
  __typename?: 'CalendarEventRef';
  id: Scalars['String'];
};

export type CalendarEventSendRsvpInput = {
  calendarId: Scalars['Int'];
  eventId: Scalars['String'];
  newRSVP: CalendarEventParticipantStatus;
};

export enum CalendarEventStatus {
  Cancelled = 'cancelled',
  Confirmed = 'confirmed',
  Tentative = 'tentative'
}

export type CalendarEventsFilter = {
  accountId: Scalars['Int'];
  calendarId: Scalars['Int'];
  endsAfter?: InputMaybe<Scalars['DateTime']>;
  endsBefore?: InputMaybe<Scalars['DateTime']>;
  expandRecurring?: InputMaybe<Scalars['Boolean']>;
  startsAfter?: InputMaybe<Scalars['DateTime']>;
  startsBefore?: InputMaybe<Scalars['DateTime']>;
};

export enum CalendarRecurrenceEditScope {
  All = 'all',
  NotBefore = 'notBefore',
  One = 'one'
}

export type CalendarsForAccount = {
  __typename?: 'CalendarsForAccount';
  account: MessagingAccount;
  calendars?: Maybe<Array<Calendar>>;
  error?: Maybe<Error>;
};

export type ConnectEmailRedirectUrlInput = {
  emailAddress?: InputMaybe<Scalars['String']>;
  redirectUri: Scalars['String'];
  termsAcceptanceTimestamp: Scalars['DateTime'];
};

export type CreateCalendarInput = {
  accountId: Scalars['Int'];
  color?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type Cursor = {
  __typename?: 'Cursor';
  /** Total item count */
  count: Scalars['Int'];
  hasMore: Scalars['Boolean'];
  /** Chunk size */
  size: Scalars['Int'];
};

export type CursorInput = {
  /** Load items after the given id (for the defautl sort) */
  afterId?: InputMaybe<Scalars['Int']>;
  /** Load items before the given id (for the defautl sort) */
  beforeId?: InputMaybe<Scalars['Int']>;
  /** Chunk size */
  size?: InputMaybe<Scalars['Int']>;
};

export type Cursors = {
  __typename?: 'Cursors';
  after: Scalars['String'];
  before: Scalars['String'];
};

export type DateInstant = {
  __typename?: 'DateInstant';
  date: Scalars['Date'];
};

export type DatespanInstant = {
  __typename?: 'DatespanInstant';
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type EmailSignature = {
  __typename?: 'EmailSignature';
  active: Scalars['Boolean'];
  body: Scalars['String'];
};

export type EmailSignatureInput = {
  active: Scalars['Boolean'];
  body: Scalars['String'];
};

export type Error = {
  __typename?: 'Error';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type FileUploadInput = {
  contentType: Scalars['String'];
};

export type GetMessageTemplatesFilter = {
  category?: InputMaybe<TemplateCategory>;
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<TemplateStatus>;
};

export type GetMessageTemplatesInput = {
  filter?: InputMaybe<GetMessageTemplatesFilter>;
  fromMessagingAccountId: Scalars['Int'];
};

export type GetMessagingContactsResult = {
  __typename?: 'GetMessagingContactsResult';
  data: Array<MessagingContact>;
  pagination?: Maybe<Pagination>;
};

export type GetMessagingMessagesResult = {
  __typename?: 'GetMessagingMessagesResult';
  data: Array<MessagingMessage>;
  pagination?: Maybe<Pagination>;
};

export type GetMessagingThreadsResult = {
  __typename?: 'GetMessagingThreadsResult';
  data: Array<MessagingThread>;
  pagination?: Maybe<Pagination>;
};

export type GetTemplatesResponse = {
  __typename?: 'GetTemplatesResponse';
  data: Array<Maybe<TemplatesFields>>;
  paging?: Maybe<Paging>;
};

export type InitConnectWhatsappInput = {
  accountId: Scalars['String'];
  code: Scalars['String'];
  phoneNumberId: Scalars['String'];
  pin: Scalars['String'];
  termsAcceptanceTimestamp: Scalars['DateTime'];
};

export type MessagingAccount = {
  __typename?: 'MessagingAccount';
  accountType: MessagingAccountType;
  color: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  initialized: Scalars['Boolean'];
  lastData?: Maybe<Scalars['DateTime']>;
  platformId?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
};

export type MessagingAccountInput = {
  color?: InputMaybe<Scalars['String']>;
};

export enum MessagingAccountType {
  Email = 'EMAIL',
  Sms = 'SMS',
  Whatsapp = 'WHATSAPP'
}

export type MessagingAccountUpdatedNotification = {
  __typename?: 'MessagingAccountUpdatedNotification';
  id: Scalars['Int'];
};

/** Contact containing various information about the person */
export type MessagingContact = {
  __typename?: 'MessagingContact';
  emails: Array<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  messagingAccount?: Maybe<MessagingAccount>;
  middleName?: Maybe<Scalars['String']>;
  phoneNumbers: Array<Scalars['String']>;
  pictureUrl?: Maybe<Scalars['String']>;
  surname?: Maybe<Scalars['String']>;
  whatsappAccounts: Array<WhatsappAccountInfo>;
};

export type MessagingContactInput = {
  emails: Array<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  messagingAccountId?: InputMaybe<Scalars['Int']>;
  middleName?: InputMaybe<Scalars['String']>;
  phoneNumbers: Array<Scalars['String']>;
  pictureUrl?: InputMaybe<Scalars['String']>;
  surname?: InputMaybe<Scalars['String']>;
  whatsappNumbers: Array<Scalars['String']>;
};

export type MessagingContactsFilterInput = {
  emailOrPhoneNumber?: InputMaybe<MessagingFilter_EmailOrPhoneNumberInput>;
  has?: InputMaybe<MessagingFilter_HasContactFields>;
  messagingAccount?: InputMaybe<MessagingFilter_MessagingAccountInput>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

/** Attchament or other type of file */
export type MessagingFile = {
  __typename?: 'MessagingFile';
  available: Scalars['Boolean'];
  contentId?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  filename: Scalars['String'];
  hasThumbnail: Scalars['Boolean'];
  id: Scalars['Int'];
  size?: Maybe<Scalars['Int']>;
};

/** Attchament or other type of file */
export type MessagingFileInput = {
  contentType: Scalars['String'];
  filename: Scalars['String'];
  messagingAccountId: Scalars['Int'];
  size: Scalars['Int'];
};

export type MessagingFilter_Date = {
  __typename?: 'MessagingFilter_Date';
  /** Beginning of the range */
  from?: Maybe<Scalars['DateTime']>;
  /** End of the range */
  to?: Maybe<Scalars['DateTime']>;
};

export type MessagingFilter_DateInput = {
  /** Beginning of the range */
  from?: InputMaybe<Scalars['DateTime']>;
  /** End of the range */
  to?: InputMaybe<Scalars['DateTime']>;
};

export type MessagingFilter_EmailOrPhoneNumberInput = {
  /** Has email or phone number equal to */
  eq?: InputMaybe<Scalars['String']>;
};

export type MessagingFilter_FreeTextInput = {
  /** Any textual field designated for search contains this substring */
  contains?: InputMaybe<Scalars['String']>;
};

export type MessagingFilter_HasContactFields = {
  /** Has at least one email */
  email?: InputMaybe<Scalars['Boolean']>;
  /** Has at least one phone number */
  phoneNumber?: InputMaybe<Scalars['Boolean']>;
  /** Has at least one Whatsapp account */
  whatsappAccount?: InputMaybe<Scalars['Boolean']>;
};

export type MessagingFilter_Id = {
  __typename?: 'MessagingFilter_Id';
  /** Value equal to */
  eq?: Maybe<Scalars['Int']>;
  /** Value one of */
  in?: Maybe<Array<Scalars['Int']>>;
};

export type MessagingFilter_IdInput = {
  /** Id equal to */
  eq?: InputMaybe<Scalars['Int']>;
  /** Id one of */
  in?: InputMaybe<Array<Scalars['Int']>>;
};

export type MessagingFilter_MessagingAccount = {
  __typename?: 'MessagingFilter_MessagingAccount';
  /** Messaging account id equal to */
  idEq?: Maybe<Scalars['Int']>;
  /** Messaging account id one of */
  idIn?: Maybe<Array<Scalars['Int']>>;
};

export type MessagingFilter_MessagingAccountInput = {
  /** Messaging account id equal to */
  idEq?: InputMaybe<Scalars['Int']>;
  /** Messaging account id one of */
  idIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type MessagingFilter_MessagingFolder = {
  __typename?: 'MessagingFilter_MessagingFolder';
  /** Folder id equal to */
  idEq?: Maybe<Scalars['Int']>;
  /** Folder id one of */
  idIn?: Maybe<Array<Scalars['Int']>>;
  /** Folder type equal to */
  typeEq?: Maybe<MessagingFolderType>;
  /** Folder type one of */
  typeIn?: Maybe<Array<MessagingFolderType>>;
};

export type MessagingFilter_MessagingFolderInput = {
  /** Folder id equal to */
  idEq?: InputMaybe<Scalars['Int']>;
  /** Folder id one of */
  idIn?: InputMaybe<Array<Scalars['Int']>>;
  /** Folder type equal to */
  typeEq?: InputMaybe<MessagingFolderType>;
  /** Folder type one of */
  typeIn?: InputMaybe<Array<MessagingFolderType>>;
};

export type MessagingFilter_MessagingLabel = {
  __typename?: 'MessagingFilter_MessagingLabel';
  /** Label id equal to */
  idEq?: Maybe<Scalars['Int']>;
  /** Label id one of */
  idIn?: Maybe<Array<Scalars['Int']>>;
};

export type MessagingFilter_MessagingLabelInput = {
  /** Label id equal to */
  idEq?: InputMaybe<Scalars['Int']>;
  /** Label id one of */
  idIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type MessagingFilter_MessagingThread = {
  __typename?: 'MessagingFilter_MessagingThread';
  /** Thread id equal to */
  idEq?: Maybe<Scalars['Int']>;
  /** Thread id one of */
  idIn?: Maybe<Array<Scalars['Int']>>;
};

export type MessagingFilter_MessagingThreadInput = {
  /** Thread id equal to */
  idEq?: InputMaybe<Scalars['Int']>;
  /** Thread id one of */
  idIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type MessagingFilter_Participant = {
  __typename?: 'MessagingFilter_Participant';
  /** Platform specific reference equal to */
  refEq?: Maybe<Scalars['String']>;
  /** Platform specific reference one of */
  refIn?: Maybe<Array<Scalars['String']>>;
};

export type MessagingFilter_ParticipantInput = {
  /** Platform specific reference equal to */
  refEq?: InputMaybe<Scalars['String']>;
  /** Platform specific reference one of */
  refIn?: InputMaybe<Array<Scalars['String']>>;
};

/** Folder, potentially one of a predefined type */
export type MessagingFolder = {
  __typename?: 'MessagingFolder';
  displayName: Scalars['String'];
  displayed: Scalars['Boolean'];
  id: Scalars['Int'];
  messagingAccount?: Maybe<MessagingAccount>;
  system: Scalars['Boolean'];
  type?: Maybe<MessagingFolderType>;
};

/** Custom folder definition */
export type MessagingFolderInput = {
  displayName: Scalars['String'];
  displayed?: InputMaybe<Scalars['Boolean']>;
};

export type MessagingFolderStats = {
  __typename?: 'MessagingFolderStats';
  id: Scalars['Int'];
  /** Number of items that require attention (unread threads, unsent drafts, ...) */
  notificationCount: Scalars['Int'];
};

/** RFC 6154 Special-Use Mailbox */
export enum MessagingFolderType {
  All = 'all',
  Archive = 'archive',
  Drafts = 'drafts',
  Important = 'important',
  Inbox = 'inbox',
  Sent = 'sent',
  Spam = 'spam',
  Trash = 'trash'
}

/** Label to be assigned to messages */
export type MessagingLabel = {
  __typename?: 'MessagingLabel';
  displayName: Scalars['String'];
  id: Scalars['Int'];
  messagingAccount: MessagingAccount;
};

/** Single message in a communication */
export type MessagingMessage = {
  __typename?: 'MessagingMessage';
  bcc: Array<MessagingParticipant>;
  body?: Maybe<Scalars['String']>;
  cc: Array<MessagingParticipant>;
  date?: Maybe<Scalars['DateTime']>;
  failReason?: Maybe<Scalars['String']>;
  files: Array<MessagingFile>;
  folder?: Maybe<MessagingFolder>;
  from: Array<MessagingParticipant>;
  id: Scalars['Int'];
  isDraft: Scalars['Boolean'];
  isForwarded: Scalars['Boolean'];
  labels: Array<MessagingLabel>;
  messagingAccount: MessagingAccount;
  replyTo: Array<MessagingParticipant>;
  /** Row number in the full set. Only returned in subset mode */
  rowNumber?: Maybe<Scalars['Int']>;
  snippet?: Maybe<Scalars['String']>;
  starred: Scalars['Boolean'];
  status?: Maybe<MessagingMessageStatus>;
  subject?: Maybe<Scalars['String']>;
  to: Array<MessagingParticipant>;
  unread: Scalars['Boolean'];
};

/** Single message in a communication */
export type MessagingMessageInput = {
  bcc?: InputMaybe<Array<MessagingParticipantInput>>;
  body: Scalars['String'];
  cc?: InputMaybe<Array<MessagingParticipantInput>>;
  fileIds?: InputMaybe<Array<Scalars['Int']>>;
  forwardMessageId?: InputMaybe<Scalars['Int']>;
  fromMessagingAccountId: Scalars['Int'];
  replyTo?: InputMaybe<Array<MessagingParticipantInput>>;
  replyToMessageId?: InputMaybe<Scalars['Int']>;
  subject: Scalars['String'];
  template?: InputMaybe<MessagingTemplateInput>;
  threadId?: InputMaybe<Scalars['Int']>;
  to: Array<MessagingParticipantInput>;
};

/** Status of message */
export enum MessagingMessageStatus {
  Delivered = 'delivered',
  Failed = 'failed',
  Read = 'read',
  Sent = 'sent'
}

export type MessagingMessagesFilter = {
  __typename?: 'MessagingMessagesFilter';
  date?: Maybe<MessagingFilter_Date>;
  folder?: Maybe<MessagingFilter_MessagingFolder>;
  label?: Maybe<MessagingFilter_MessagingLabel>;
  messagingAccount?: Maybe<MessagingFilter_MessagingAccount>;
  thread?: Maybe<MessagingFilter_MessagingThread>;
  unread?: Maybe<Scalars['Boolean']>;
};

export type MessagingMessagesFilterInput = {
  date?: InputMaybe<MessagingFilter_DateInput>;
  folder?: InputMaybe<MessagingFilter_MessagingFolderInput>;
  label?: InputMaybe<MessagingFilter_MessagingLabelInput>;
  messagingAccount?: InputMaybe<MessagingFilter_MessagingAccountInput>;
  thread?: InputMaybe<MessagingFilter_MessagingThreadInput>;
  unread?: InputMaybe<Scalars['Boolean']>;
};

/** Participant in a communication */
export type MessagingParticipant = {
  __typename?: 'MessagingParticipant';
  name?: Maybe<Scalars['String']>;
  /** Platform specific reference (email, phone number, ...) */
  ref: Scalars['String'];
};

/** Participant in a communication */
export type MessagingParticipantInput = {
  name?: InputMaybe<Scalars['String']>;
  /** Platform specific reference (email, phone number, ...) */
  ref: Scalars['String'];
};

export type MessagingTemplateInput = {
  body?: InputMaybe<Array<InputMaybe<MessagingTemplateTextInput>>>;
  buttons?: InputMaybe<Array<InputMaybe<MessagingTemplateTextInput>>>;
  header?: InputMaybe<MessagingTemplateTextInput>;
  nameAndLanguage?: InputMaybe<Scalars['String']>;
};

export type MessagingTemplateTextInput = {
  text: Scalars['String'];
};

/** Communication thread grouping multiple messages */
export type MessagingThread = {
  __typename?: 'MessagingThread';
  firstMessageTimestamp?: Maybe<Scalars['DateTime']>;
  folders: Array<MessagingFolder>;
  from: Array<MessagingParticipant>;
  hasAttachments: Scalars['Boolean'];
  id: Scalars['Int'];
  labels: Array<MessagingLabel>;
  lastMessageOutgoing: Scalars['Boolean'];
  lastMessageReceivedTimestamp?: Maybe<Scalars['DateTime']>;
  lastMessageSentTimestamp?: Maybe<Scalars['DateTime']>;
  lastMessageTimestamp?: Maybe<Scalars['DateTime']>;
  messages: MessagingThreadMessagesResult;
  messagingAccount: MessagingAccount;
  participants: Array<MessagingParticipant>;
  snippet: Scalars['String'];
  starred: Scalars['Boolean'];
  subject: Scalars['String'];
  to: Array<MessagingParticipant>;
  unread: Scalars['Boolean'];
  userMetadata?: Maybe<MessagingThreadUserMetadata>;
};


/** Communication thread grouping multiple messages */
export type MessagingThreadMessagesArgs = {
  cursor?: InputMaybe<CursorInput>;
  subset?: InputMaybe<MessagingThreadMessagesSubsetInput>;
};

export type MessagingThreadMessagesResult = {
  __typename?: 'MessagingThreadMessagesResult';
  cursor: Cursor;
  data: Array<MessagingMessage>;
};

export type MessagingThreadMessagesSubsetInput = {
  /** If greater than 0, also N messages before and after each matching the search term will be returned */
  around?: InputMaybe<Scalars['Int']>;
  /**
   * Only messages matching the search term will be returned in the result, but they will have row number
   * matching the number in the list of all messages
   */
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type MessagingThreadUpdatedNotification = {
  __typename?: 'MessagingThreadUpdatedNotification';
  id: Scalars['Int'];
};

export type MessagingThreadUserMetadata = {
  __typename?: 'MessagingThreadUserMetadata';
  name?: Maybe<Scalars['String']>;
};

export type MessagingThreadUserMetadataInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type MessagingThreadsFilter = {
  __typename?: 'MessagingThreadsFilter';
  date?: Maybe<MessagingFilter_Date>;
  folder?: Maybe<MessagingFilter_MessagingFolder>;
  hasAttachments?: Maybe<Scalars['Boolean']>;
  id?: Maybe<MessagingFilter_Id>;
  label?: Maybe<MessagingFilter_MessagingLabel>;
  lastMessageOutgoing?: Maybe<Scalars['Boolean']>;
  messagingAccount?: Maybe<MessagingFilter_MessagingAccount>;
  participant?: Maybe<MessagingFilter_Participant>;
  searchTerm?: Maybe<Scalars['String']>;
  unread?: Maybe<Scalars['Boolean']>;
};

export type MessagingThreadsFilterInput = {
  date?: InputMaybe<MessagingFilter_DateInput>;
  folder?: InputMaybe<MessagingFilter_MessagingFolderInput>;
  hasAttachments?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<MessagingFilter_IdInput>;
  label?: InputMaybe<MessagingFilter_MessagingLabelInput>;
  lastMessageOutgoing?: InputMaybe<Scalars['Boolean']>;
  messagingAccount?: InputMaybe<MessagingFilter_MessagingAccountInput>;
  participant?: InputMaybe<MessagingFilter_ParticipantInput>;
  searchTerm?: InputMaybe<Scalars['String']>;
  unread?: InputMaybe<Scalars['Boolean']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Creates new calendar */
  createCalendar?: Maybe<Calendar>;
  /** Creates a new event and return the status code and the created id of the new event */
  createCalendarEvent: CalendarEvent;
  /** Creates a new contact */
  createMessagingContact: MessagingContact;
  /** Creates a temporary link to download an attachment */
  createMessagingFileDownloadLink: Scalars['String'];
  /** Creates a temporary link to download an attachment's thumbnail */
  createMessagingFileThumbnailDownloadLink: Scalars['String'];
  /** Creates a new named filter for messaging threads */
  createNamedMessagingThreadFilter: NamedMessagingThreadsFilter;
  /** Creates user feedback */
  createUserFeedback: Scalars['Int'];
  /** Deletes calendar */
  deleteCalendar?: Maybe<Calendar>;
  /** Deletes calendar event */
  deleteCalendarEvent: CalendarEventRef;
  /** Deletes a given messaging contact */
  deleteMessagingContact?: Maybe<Scalars['Boolean']>;
  /** Deletes the given messaging thread and returns the success flag */
  deleteMessagingThread: Scalars['Boolean'];
  /** Deletes the given messaging threads and returns the number of affected rows */
  deleteMessagingThreads: Scalars['Int'];
  /** Deletes a given named filter for messaging threads */
  deleteNamedMessagingThreadFilter?: Maybe<Scalars['Boolean']>;
  /** Disconnects the given account and deletes all the associated data */
  disconnectMessagingAccount?: Maybe<Scalars['Boolean']>;
  /**
   * Finalises file upload
   * Needs to be called after the upload prepared in `prepareMessagingFileUpload` was completed
   */
  finaliseMessagingFileUpload: MessagingFile;
  /** Forces a full load for the given account */
  forceMessagingAccountFullLoad?: Maybe<Scalars['Boolean']>;
  /** Generates a one-time redirect URL to connect a given email account */
  generateConnectEmailRedirectUrl: Scalars['String'];
  /** Initiates WhatsApp connect process and returns a token to use for subscriptions */
  initConnectWhatsapp: Scalars['String'];
  /** Moves the given messaging threads into the specified folder and returns the success flag */
  moveMessagingThreadsToFolder: Scalars['Boolean'];
  /** Moves the given messaging threads into the specified special folder and returns the success flag */
  moveMessagingThreadsToSpecialFolder: Scalars['Boolean'];
  ping?: Maybe<Scalars['Boolean']>;
  /** Prepares upload URL and a ticket for later steps */
  prepareIssuesFileUpload: PrepareFileUploadResult;
  /** Prepares upload URL and a ticket for later steps */
  prepareMessagingFileUpload: PrepareMessagingFileUploadResult;
  /** Prepares upload URL and a ticket for later steps */
  preparePublicFileUpload: PrepareFileUploadResult;
  /** Requests a file reupload and returns the ticket token to track the result */
  requestMessagingFileReupload: Scalars['String'];
  /** Send the new RSVP (attendance) */
  sendCalendarEventRSVP: CalendarEvent;
  /** Sends a message and returns the ticket token to track the result */
  sendMessagingMessage: Scalars['String'];
  /** Sends multiple messages and returns the ticket tokens to track the results */
  sendMessagingMessages: Array<Scalars['String']>;
  /** Sets the email signature for a given account and returns success flag */
  setEmailSignature: Scalars['Boolean'];
  /** Modifies the unread property of the given messaging threads and returns the number of affected rows */
  setMessagingThreadsReadUnread: Scalars['Int'];
  /** Updates calendar */
  updateCalendar?: Maybe<Calendar>;
  /** Updates calendar event */
  updateCalendarEvent: CalendarEvent;
  /** Updates the given account */
  updateMessagingAccount: MessagingAccount;
  /** Updates a given contact */
  updateMessagingContact: MessagingContact;
  /** Updates user metadata for a messaging thread */
  updateMessagingThreadUserMetadata: MessagingThread;
  /** Updates a given named filter for messaging threads */
  updateNamedMessagingThreadFilter: NamedMessagingThreadsFilter;
  /** Updates user settings */
  updateUserSettings: UserSettings;
};


export type MutationCreateCalendarArgs = {
  input: CreateCalendarInput;
};


export type MutationCreateCalendarEventArgs = {
  accountId: Scalars['Int'];
  input: CalendarEventInput;
  notifyParticipants: Scalars['Boolean'];
};


export type MutationCreateMessagingContactArgs = {
  input: MessagingContactInput;
};


export type MutationCreateMessagingFileDownloadLinkArgs = {
  id: Scalars['Int'];
  triggerDownload: Scalars['Boolean'];
};


export type MutationCreateMessagingFileThumbnailDownloadLinkArgs = {
  id: Scalars['Int'];
  triggerDownload: Scalars['Boolean'];
};


export type MutationCreateNamedMessagingThreadFilterArgs = {
  input: NamedMessagingThreadsFilterInput;
};


export type MutationCreateUserFeedbackArgs = {
  input: UserFeedbackInput;
};


export type MutationDeleteCalendarArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteCalendarEventArgs = {
  calendarId: Scalars['Int'];
  eventId: Scalars['String'];
  scope?: InputMaybe<CalendarRecurrenceEditScope>;
};


export type MutationDeleteMessagingContactArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteMessagingThreadArgs = {
  id: Scalars['Int'];
};


export type MutationDeleteMessagingThreadsArgs = {
  filter: MessagingThreadsFilterInput;
};


export type MutationDeleteNamedMessagingThreadFilterArgs = {
  id: Scalars['Int'];
};


export type MutationDisconnectMessagingAccountArgs = {
  id: Scalars['Int'];
};


export type MutationFinaliseMessagingFileUploadArgs = {
  ticketToken: Scalars['String'];
};


export type MutationForceMessagingAccountFullLoadArgs = {
  id: Scalars['Int'];
};


export type MutationGenerateConnectEmailRedirectUrlArgs = {
  input: ConnectEmailRedirectUrlInput;
};


export type MutationInitConnectWhatsappArgs = {
  input: InitConnectWhatsappInput;
};


export type MutationMoveMessagingThreadsToFolderArgs = {
  filter: MessagingThreadsFilterInput;
  folderId: Scalars['Int'];
};


export type MutationMoveMessagingThreadsToSpecialFolderArgs = {
  filter: MessagingThreadsFilterInput;
  folderType: MessagingFolderType;
};


export type MutationPrepareIssuesFileUploadArgs = {
  input: FileUploadInput;
};


export type MutationPrepareMessagingFileUploadArgs = {
  file: MessagingFileInput;
  maxSize?: InputMaybe<Scalars['Int']>;
};


export type MutationPreparePublicFileUploadArgs = {
  input: FileUploadInput;
};


export type MutationRequestMessagingFileReuploadArgs = {
  fileId: Scalars['Int'];
  messageId: Scalars['Int'];
};


export type MutationSendCalendarEventRsvpArgs = {
  input?: InputMaybe<CalendarEventSendRsvpInput>;
};


export type MutationSendMessagingMessageArgs = {
  input: MessagingMessageInput;
};


export type MutationSendMessagingMessagesArgs = {
  input: Array<MessagingMessageInput>;
};


export type MutationSetEmailSignatureArgs = {
  input: EmailSignatureInput;
  messagingAccountId: Scalars['Int'];
};


export type MutationSetMessagingThreadsReadUnreadArgs = {
  filter: MessagingThreadsFilterInput;
  unread: Scalars['Boolean'];
};


export type MutationUpdateCalendarArgs = {
  id: Scalars['Int'];
  input: UpdateCalendarInput;
};


export type MutationUpdateCalendarEventArgs = {
  id: Scalars['String'];
  input: CalendarEventInput;
  notifyParticipants: Scalars['Boolean'];
  scope?: InputMaybe<CalendarRecurrenceEditScope>;
};


export type MutationUpdateMessagingAccountArgs = {
  id: Scalars['Int'];
  input: MessagingAccountInput;
};


export type MutationUpdateMessagingContactArgs = {
  id: Scalars['Int'];
  input: MessagingContactInput;
};


export type MutationUpdateMessagingThreadUserMetadataArgs = {
  input: MessagingThreadUserMetadataInput;
  threadId: Scalars['Int'];
};


export type MutationUpdateNamedMessagingThreadFilterArgs = {
  id: Scalars['Int'];
  input: NamedMessagingThreadsFilterInput;
};


export type MutationUpdateUserSettingsArgs = {
  input: UserSettingsInput;
};

export type NamedMessagingThreadsFilter = {
  __typename?: 'NamedMessagingThreadsFilter';
  content: MessagingThreadsFilter;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type NamedMessagingThreadsFilterInput = {
  content: MessagingThreadsFilterInput;
  name: Scalars['String'];
};

export type Pagination = {
  __typename?: 'Pagination';
  /** Total item count */
  count: Scalars['Int'];
  /** 0-based page index */
  index: Scalars['Int'];
  /** Page size */
  size: Scalars['Int'];
};

export type PaginationInput = {
  /** 0-based page index */
  index: Scalars['Int'];
  /** Page size */
  size: Scalars['Int'];
};

export type Paging = {
  __typename?: 'Paging';
  cursors?: Maybe<Cursors>;
};

export type PrepareFileUploadField = {
  __typename?: 'PrepareFileUploadField';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type PrepareFileUploadResult = {
  __typename?: 'PrepareFileUploadResult';
  fields: Array<PrepareFileUploadField>;
  uploadUrl: Scalars['String'];
  url: Scalars['String'];
};

export type PrepareMessagingFileUploadResult = {
  __typename?: 'PrepareMessagingFileUploadResult';
  fields: Array<PrepareFileUploadField>;
  ticketToken: Scalars['String'];
  uploadUrl: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  /** Finds if user can send WA message in 24 hours window */
  canSendMessageIn24HoursWindow: Scalars['Boolean'];
  /** Returns calendar event with given id */
  getCalendarEventDetail: CalendarEvent;
  /** Returns calendar events with expanded recurrences */
  getCalendarEvents: Array<CalendarEvent>;
  /** Returns connected calendars */
  getCalendarsByAccount: Array<CalendarsForAccount>;
  /** Retrieves the email signature for a given account if it is set */
  getEmailSignature?: Maybe<EmailSignature>;
  /** Gets all connected accounts for this user */
  getMessagingAccounts?: Maybe<Array<MessagingAccount>>;
  /** Retrieves the specified contact */
  getMessagingContact: MessagingContact;
  /** Retrieves one page of contacts passing the filter */
  getMessagingContacts: GetMessagingContactsResult;
  /** Retrieves all folders, possibly limited to one account only */
  getMessagingFolders: Array<MessagingFolder>;
  getMessagingFoldersStats: Array<MessagingFolderStats>;
  /** Retrieves all labels, possibly limited to one account only */
  getMessagingLabels: Array<MessagingLabel>;
  /** Retrieves one page of messages passing the filter */
  getMessagingMessages: GetMessagingMessagesResult;
  /** Retrieves the specified thread */
  getMessagingThread: MessagingThread;
  /** Retrieves one page of threads passing the filter */
  getMessagingThreads: GetMessagingThreadsResult;
  /** Gets a given named filter for messaging threads */
  getNamedMessagingThreadFilter?: Maybe<NamedMessagingThreadsFilter>;
  /** Gets all named filters for messaging threads */
  getNamedMessagingThreadFilters: Array<NamedMessagingThreadsFilter>;
  /** Retrieves templates messages */
  getTemplateMessages: GetTemplatesResponse;
  metadata?: Maybe<Scalars['Boolean']>;
};


export type QueryCanSendMessageIn24HoursWindowArgs = {
  input: Verify24HourWindowInput;
};


export type QueryGetCalendarEventDetailArgs = {
  calendarId: Scalars['Int'];
  eventId: Scalars['String'];
};


export type QueryGetCalendarEventsArgs = {
  filter: CalendarEventsFilter;
};


export type QueryGetEmailSignatureArgs = {
  messagingAccountId: Scalars['Int'];
};


export type QueryGetMessagingContactArgs = {
  id: Scalars['Int'];
};


export type QueryGetMessagingContactsArgs = {
  filter?: InputMaybe<MessagingContactsFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryGetMessagingFoldersArgs = {
  messagingAccountId?: InputMaybe<Scalars['Int']>;
};


export type QueryGetMessagingFoldersStatsArgs = {
  messagingAccountId?: InputMaybe<Scalars['Int']>;
};


export type QueryGetMessagingLabelsArgs = {
  messagingAccountId?: InputMaybe<Scalars['Int']>;
};


export type QueryGetMessagingMessagesArgs = {
  filter?: InputMaybe<MessagingMessagesFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryGetMessagingThreadArgs = {
  id: Scalars['Int'];
};


export type QueryGetMessagingThreadsArgs = {
  filter?: InputMaybe<MessagingThreadsFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
};


export type QueryGetNamedMessagingThreadFilterArgs = {
  id: Scalars['Int'];
};


export type QueryGetTemplateMessagesArgs = {
  input: GetMessageTemplatesInput;
};

export type Recurrence = {
  __typename?: 'Recurrence';
  rrule: Array<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
};

export type RecurrenceInput = {
  rrule: Array<Scalars['String']>;
  timezone: Scalars['String'];
};

export type RequestMessagingFileReuploadData = {
  __typename?: 'RequestMessagingFileReuploadData';
  type: Scalars['String'];
};

export type RequestMessagingFileReuploadResult = {
  __typename?: 'RequestMessagingFileReuploadResult';
  data?: Maybe<RequestMessagingFileReuploadResultData>;
  errorCode?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type RequestMessagingFileReuploadResultData = {
  __typename?: 'RequestMessagingFileReuploadResultData';
  file: MessagingFile;
};

export type SendMessagingMessageResult = {
  __typename?: 'SendMessagingMessageResult';
  data?: Maybe<SendMessagingMessageResultData>;
  errorCode?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type SendMessagingMessageResultData = {
  __typename?: 'SendMessagingMessageResultData';
  message: MessagingMessage;
  thread?: Maybe<MessagingThread>;
};

export type Subscription = {
  __typename?: 'Subscription';
  onAsyncConnectMessagingAccountData: AsyncConnectMessagingAccountData;
  onAsyncConnectMessagingAccountResult: AsyncConnectMessagingAccountResult;
  onMessagingAccountUpdated: MessagingAccountUpdatedNotification;
  onMessagingThreadUpdated: MessagingThreadUpdatedNotification;
  onRequestMessagingFileReuploadData: RequestMessagingFileReuploadData;
  onRequestMessagingFileReuploadResult: RequestMessagingFileReuploadResult;
  onSendMessagingMessageResult: SendMessagingMessageResult;
  onTick?: Maybe<Scalars['Boolean']>;
};


export type SubscriptionOnAsyncConnectMessagingAccountDataArgs = {
  ticketToken: Scalars['String'];
};


export type SubscriptionOnAsyncConnectMessagingAccountResultArgs = {
  ticketToken: Scalars['String'];
};


export type SubscriptionOnRequestMessagingFileReuploadDataArgs = {
  ticketToken: Scalars['String'];
};


export type SubscriptionOnRequestMessagingFileReuploadResultArgs = {
  ticketToken: Scalars['String'];
};


export type SubscriptionOnSendMessagingMessageResultArgs = {
  ticketToken: Scalars['String'];
};

export type TemplateButton = {
  __typename?: 'TemplateButton';
  example?: Maybe<Scalars['StringOrStringArray']>;
  flow_action?: Maybe<TemplateButtonFlowAction>;
  flow_id?: Maybe<Scalars['String']>;
  navigate_screen?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  type: TemplateButtonType;
  url?: Maybe<Scalars['String']>;
};

export enum TemplateButtonFlowAction {
  DataExchange = 'data_exchange',
  Navigate = 'navigate'
}

export enum TemplateButtonType {
  CopyCode = 'COPY_CODE',
  Flow = 'FLOW',
  PhoneNumber = 'PHONE_NUMBER',
  QuickReply = 'QUICK_REPLY',
  Url = 'URL'
}

export enum TemplateCategory {
  Authentication = 'AUTHENTICATION',
  Marketing = 'MARKETING',
  Utility = 'UTILITY'
}

export type TemplateComponent = {
  __typename?: 'TemplateComponent';
  add_security_recommendation?: Maybe<Scalars['Boolean']>;
  buttons?: Maybe<Array<Maybe<TemplateButton>>>;
  cards?: Maybe<Scalars['UnknownArray']>;
  code_expiration_minutes?: Maybe<Scalars['Int']>;
  example?: Maybe<Scalars['ObjectScalarType']>;
  format?: Maybe<TemplateComponentFormat>;
  limited_time_offer?: Maybe<TemplateLimitedTimeOffer>;
  text?: Maybe<Scalars['String']>;
  type: TemplateComponentType;
};

export enum TemplateComponentFormat {
  Document = 'DOCUMENT',
  Image = 'IMAGE',
  Location = 'LOCATION',
  Text = 'TEXT',
  Video = 'VIDEO'
}

export enum TemplateComponentType {
  Body = 'BODY',
  Buttons = 'BUTTONS',
  Footer = 'FOOTER',
  Header = 'HEADER',
  LimitedTimeOffer = 'limited_time_offer'
}

export type TemplateLimitedTimeOffer = {
  __typename?: 'TemplateLimitedTimeOffer';
  has_expiration: Scalars['Boolean'];
  text: Scalars['String'];
};

export type TemplateQualityScore = {
  __typename?: 'TemplateQualityScore';
  date?: Maybe<Scalars['Int']>;
  reasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  score: Scalars['String'];
};

export enum TemplateRejectedReason {
  AbusiveContent = 'ABUSIVE_CONTENT',
  InvalidFormat = 'INVALID_FORMAT',
  None = 'NONE',
  Promotional = 'PROMOTIONAL',
  Scam = 'SCAM',
  TagContentMismatch = 'TAG_CONTENT_MISMATCH'
}

export enum TemplateStatus {
  Approved = 'APPROVED',
  Deleted = 'DELETED',
  Disabled = 'DISABLED',
  InAppeal = 'IN_APPEAL',
  LimitExceeded = 'LIMIT_EXCEEDED',
  Paused = 'PAUSED',
  Pending = 'PENDING',
  PendingDeletion = 'PENDING_DELETION',
  Rejected = 'REJECTED'
}

export type TemplatesFields = {
  __typename?: 'TemplatesFields';
  category: TemplateCategory;
  components: Array<TemplateComponent>;
  cta_url_link_tracking_opted_out?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  language: Scalars['String'];
  library_template_id?: Maybe<Scalars['String']>;
  message_send_ttl_seconds?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  previous_category?: Maybe<Scalars['String']>;
  quality_score?: Maybe<TemplateQualityScore>;
  rejected_reason?: Maybe<TemplateRejectedReason>;
  status: TemplateStatus;
  sub_category?: Maybe<Scalars['String']>;
};

export type TimeInstant = {
  __typename?: 'TimeInstant';
  time: Scalars['DateTime'];
};

export type TimespanInstant = {
  __typename?: 'TimespanInstant';
  endTime: Scalars['DateTime'];
  startTime: Scalars['DateTime'];
};

export type UpdateCalendarInput = {
  accountId: Scalars['Int'];
  color?: InputMaybe<Scalars['String']>;
  selected: Scalars['Boolean'];
};

export type UserFeedbackInput = {
  description?: InputMaybe<Scalars['String']>;
  screenshotFilePath?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  type: UserFeedbackType;
};

export enum UserFeedbackType {
  Bug = 'Bug',
  Idea = 'Idea'
}

export type UserSettings = {
  __typename?: 'UserSettings';
  /** ids of filters */
  filtersOrder: Array<Scalars['Int']>;
};

export type UserSettingsInput = {
  /** ids of filters */
  filtersOrder?: InputMaybe<Array<Scalars['Int']>>;
};

export type Verify24HourWindowInput = {
  accountId: Scalars['Int'];
  to: Scalars['String'];
};

export type WhatsappAccountInfo = {
  __typename?: 'WhatsappAccountInfo';
  id: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type GetEmailSignatureQueryVariables = Exact<{
  messagingAccountId: Scalars['Int'];
}>;


export type GetEmailSignatureQuery = { __typename?: 'Query', getEmailSignature?: { __typename?: 'EmailSignature', body: string, active: boolean } | null };

export type SetEmailSignatureMutationVariables = Exact<{
  messagingAccountId: Scalars['Int'];
  input: EmailSignatureInput;
}>;


export type SetEmailSignatureMutation = { __typename?: 'Mutation', setEmailSignature: boolean };

export type MessagingAccountFullFragmentFragment = { __typename?: 'MessagingAccount', id: number, accountType: MessagingAccountType, platformId?: string | null, provider?: string | null, initialized: boolean, error?: string | null, color: string, lastData?: string | null };

export type GetMessagingAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMessagingAccountsQuery = { __typename?: 'Query', getMessagingAccounts?: Array<{ __typename?: 'MessagingAccount', id: number, accountType: MessagingAccountType, platformId?: string | null, provider?: string | null, initialized: boolean, error?: string | null, color: string, lastData?: string | null }> | null };

export type UpdateMessagingAccountMutationVariables = Exact<{
  id: Scalars['Int'];
  input: MessagingAccountInput;
}>;


export type UpdateMessagingAccountMutation = { __typename?: 'Mutation', updateMessagingAccount: { __typename?: 'MessagingAccount', id: number, color: string } };

export type GenerateConnectEmailRedirectUrlMutationVariables = Exact<{
  input: ConnectEmailRedirectUrlInput;
}>;


export type GenerateConnectEmailRedirectUrlMutation = { __typename?: 'Mutation', generateConnectEmailRedirectUrl: string };

export type InitConnectWhatsappMutationVariables = Exact<{
  input: InitConnectWhatsappInput;
}>;


export type InitConnectWhatsappMutation = { __typename?: 'Mutation', initConnectWhatsapp: string };

export type ForceMessagingAccountFullLoadMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ForceMessagingAccountFullLoadMutation = { __typename?: 'Mutation', forceMessagingAccountFullLoad?: boolean | null };

export type OnAsyncConnectMessagingAccountDataSubscriptionVariables = Exact<{
  ticketToken: Scalars['String'];
}>;


export type OnAsyncConnectMessagingAccountDataSubscription = { __typename?: 'Subscription', onAsyncConnectMessagingAccountData: { __typename?: 'AsyncConnectMessagingAccountData', qrcode: string } };

export type OnAsyncConnectMessagingAccountResultSubscriptionVariables = Exact<{
  ticketToken: Scalars['String'];
}>;


export type OnAsyncConnectMessagingAccountResultSubscription = { __typename?: 'Subscription', onAsyncConnectMessagingAccountResult: { __typename?: 'AsyncConnectMessagingAccountResult', success: boolean, errorCode?: string | null, errorMessage?: string | null } };

export type DisconnectMessagingAccountMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DisconnectMessagingAccountMutation = { __typename?: 'Mutation', disconnectMessagingAccount?: boolean | null };

export type OnMessagingAccountUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnMessagingAccountUpdatedSubscription = { __typename?: 'Subscription', onMessagingAccountUpdated: { __typename?: 'MessagingAccountUpdatedNotification', id: number } };

export type GetCalendarsByAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCalendarsByAccountQuery = { __typename?: 'Query', getCalendarsByAccount: Array<{ __typename?: 'CalendarsForAccount', account: { __typename?: 'MessagingAccount', id: number, platformId?: string | null, accountType: MessagingAccountType, color: string }, calendars?: Array<{ __typename?: 'Calendar', id: number, name: string, color?: string | null, selected: boolean, account: { __typename?: 'MessagingAccount', id: number, accountType: MessagingAccountType, platformId?: string | null } }> | null, error?: { __typename?: 'Error', code: string, message: string } | null }> };

export type CreateCalendarMutationVariables = Exact<{
  input: CreateCalendarInput;
}>;


export type CreateCalendarMutation = { __typename?: 'Mutation', createCalendar?: { __typename?: 'Calendar', id: number } | null };

export type UpdateCalendarMutationVariables = Exact<{
  id: Scalars['Int'];
  input: UpdateCalendarInput;
}>;


export type UpdateCalendarMutation = { __typename?: 'Mutation', updateCalendar?: { __typename?: 'Calendar', id: number, color?: string | null, selected: boolean } | null };

export type DeleteCalendarMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCalendarMutation = { __typename?: 'Mutation', deleteCalendar?: { __typename?: 'Calendar', id: number } | null };

export type CalendarEventWhenFragmentFragment = { __typename?: 'CalendarEvent', when: { __typename: 'DateInstant', date: string } | { __typename: 'DatespanInstant', startDate: string, endDate: string } | { __typename: 'TimeInstant', time: string } | { __typename: 'TimespanInstant', startTime: string, endTime: string } };

export type CalendarEventFullFragmentFragment = { __typename?: 'CalendarEvent', id: string, title: string, accountId: number, calendarId: number, description?: string | null, readOnly: boolean, status: CalendarEventStatus, location?: string | null, participants?: Array<{ __typename?: 'CalendarEventParticipant', name?: string | null, email: string, status?: CalendarEventParticipantStatus | null }> | null, masterEvent?: { __typename?: 'CalendarEvent', recurrence?: { __typename?: 'Recurrence', rrule: Array<string> } | null, when: { __typename: 'DateInstant', date: string } | { __typename: 'DatespanInstant', startDate: string, endDate: string } | { __typename: 'TimeInstant', time: string } | { __typename: 'TimespanInstant', startTime: string, endTime: string } } | null, when: { __typename: 'DateInstant', date: string } | { __typename: 'DatespanInstant', startDate: string, endDate: string } | { __typename: 'TimeInstant', time: string } | { __typename: 'TimespanInstant', startTime: string, endTime: string } };

export type GetCalendarEventsQueryVariables = Exact<{
  filter: CalendarEventsFilter;
}>;


export type GetCalendarEventsQuery = { __typename?: 'Query', getCalendarEvents: Array<{ __typename?: 'CalendarEvent', id: string, title: string, accountId: number, calendarId: number, description?: string | null, readOnly: boolean, status: CalendarEventStatus, location?: string | null, participants?: Array<{ __typename?: 'CalendarEventParticipant', name?: string | null, email: string, status?: CalendarEventParticipantStatus | null }> | null, masterEvent?: { __typename?: 'CalendarEvent', recurrence?: { __typename?: 'Recurrence', rrule: Array<string> } | null, when: { __typename: 'DateInstant', date: string } | { __typename: 'DatespanInstant', startDate: string, endDate: string } | { __typename: 'TimeInstant', time: string } | { __typename: 'TimespanInstant', startTime: string, endTime: string } } | null, when: { __typename: 'DateInstant', date: string } | { __typename: 'DatespanInstant', startDate: string, endDate: string } | { __typename: 'TimeInstant', time: string } | { __typename: 'TimespanInstant', startTime: string, endTime: string } }> };

export type GetCalendarEventDetailQueryVariables = Exact<{
  eventId: Scalars['String'];
  calendarId: Scalars['Int'];
}>;


export type GetCalendarEventDetailQuery = { __typename?: 'Query', getCalendarEventDetail: { __typename?: 'CalendarEvent', id: string, title: string, accountId: number, calendarId: number, description?: string | null, readOnly: boolean, status: CalendarEventStatus, location?: string | null, participants?: Array<{ __typename?: 'CalendarEventParticipant', name?: string | null, email: string, status?: CalendarEventParticipantStatus | null }> | null, masterEvent?: { __typename?: 'CalendarEvent', recurrence?: { __typename?: 'Recurrence', rrule: Array<string> } | null, when: { __typename: 'DateInstant', date: string } | { __typename: 'DatespanInstant', startDate: string, endDate: string } | { __typename: 'TimeInstant', time: string } | { __typename: 'TimespanInstant', startTime: string, endTime: string } } | null, when: { __typename: 'DateInstant', date: string } | { __typename: 'DatespanInstant', startDate: string, endDate: string } | { __typename: 'TimeInstant', time: string } | { __typename: 'TimespanInstant', startTime: string, endTime: string } } };

export type CreateCalendarEventMutationVariables = Exact<{
  input: CalendarEventInput;
  notifyParticipants: Scalars['Boolean'];
  accountId: Scalars['Int'];
}>;


export type CreateCalendarEventMutation = { __typename?: 'Mutation', createCalendarEvent: { __typename?: 'CalendarEvent', id: string, title: string, accountId: number, calendarId: number, description?: string | null, readOnly: boolean, status: CalendarEventStatus, location?: string | null, participants?: Array<{ __typename?: 'CalendarEventParticipant', name?: string | null, email: string, status?: CalendarEventParticipantStatus | null }> | null, masterEvent?: { __typename?: 'CalendarEvent', recurrence?: { __typename?: 'Recurrence', rrule: Array<string> } | null, when: { __typename: 'DateInstant', date: string } | { __typename: 'DatespanInstant', startDate: string, endDate: string } | { __typename: 'TimeInstant', time: string } | { __typename: 'TimespanInstant', startTime: string, endTime: string } } | null, when: { __typename: 'DateInstant', date: string } | { __typename: 'DatespanInstant', startDate: string, endDate: string } | { __typename: 'TimeInstant', time: string } | { __typename: 'TimespanInstant', startTime: string, endTime: string } } };

export type DeleteCalendarEventMutationVariables = Exact<{
  eventId: Scalars['String'];
  calendarId: Scalars['Int'];
  scope?: InputMaybe<CalendarRecurrenceEditScope>;
}>;


export type DeleteCalendarEventMutation = { __typename?: 'Mutation', deleteCalendarEvent: { __typename?: 'CalendarEventRef', id: string } };

export type UpdateCalendarEventMutationVariables = Exact<{
  id: Scalars['String'];
  input: CalendarEventInput;
  notifyParticipants: Scalars['Boolean'];
  scope?: InputMaybe<CalendarRecurrenceEditScope>;
}>;


export type UpdateCalendarEventMutation = { __typename?: 'Mutation', updateCalendarEvent: { __typename?: 'CalendarEvent', id: string, title: string, accountId: number, calendarId: number, description?: string | null, readOnly: boolean, status: CalendarEventStatus, location?: string | null, participants?: Array<{ __typename?: 'CalendarEventParticipant', name?: string | null, email: string, status?: CalendarEventParticipantStatus | null }> | null, masterEvent?: { __typename?: 'CalendarEvent', recurrence?: { __typename?: 'Recurrence', rrule: Array<string> } | null, when: { __typename: 'DateInstant', date: string } | { __typename: 'DatespanInstant', startDate: string, endDate: string } | { __typename: 'TimeInstant', time: string } | { __typename: 'TimespanInstant', startTime: string, endTime: string } } | null, when: { __typename: 'DateInstant', date: string } | { __typename: 'DatespanInstant', startDate: string, endDate: string } | { __typename: 'TimeInstant', time: string } | { __typename: 'TimespanInstant', startTime: string, endTime: string } } };

export type SendCalendarEventRsvpMutationVariables = Exact<{
  input: CalendarEventSendRsvpInput;
}>;


export type SendCalendarEventRsvpMutation = { __typename?: 'Mutation', sendCalendarEventRSVP: { __typename?: 'CalendarEvent', id: string, title: string, accountId: number, calendarId: number, description?: string | null, readOnly: boolean, status: CalendarEventStatus, location?: string | null, participants?: Array<{ __typename?: 'CalendarEventParticipant', name?: string | null, email: string, status?: CalendarEventParticipantStatus | null }> | null, masterEvent?: { __typename?: 'CalendarEvent', recurrence?: { __typename?: 'Recurrence', rrule: Array<string> } | null, when: { __typename: 'DateInstant', date: string } | { __typename: 'DatespanInstant', startDate: string, endDate: string } | { __typename: 'TimeInstant', time: string } | { __typename: 'TimespanInstant', startTime: string, endTime: string } } | null, when: { __typename: 'DateInstant', date: string } | { __typename: 'DatespanInstant', startDate: string, endDate: string } | { __typename: 'TimeInstant', time: string } | { __typename: 'TimespanInstant', startTime: string, endTime: string } } };

export type MessagingContactFullFragmentFragment = { __typename?: 'MessagingContact', id: number, givenName?: string | null, middleName?: string | null, surname?: string | null, pictureUrl?: string | null, emails: Array<string>, phoneNumbers: Array<string>, whatsappAccounts: Array<{ __typename?: 'WhatsappAccountInfo', id: string, phoneNumber: string }>, messagingAccount?: { __typename?: 'MessagingAccount', id: number, accountType: MessagingAccountType, platformId?: string | null, provider?: string | null, initialized: boolean, error?: string | null, color: string, lastData?: string | null } | null };

export type GetMessagingContactsQueryVariables = Exact<{
  filter?: InputMaybe<MessagingContactsFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
}>;


export type GetMessagingContactsQuery = { __typename?: 'Query', getMessagingContacts: { __typename?: 'GetMessagingContactsResult', pagination?: { __typename?: 'Pagination', index: number, size: number, count: number } | null, data: Array<{ __typename?: 'MessagingContact', id: number, givenName?: string | null, middleName?: string | null, surname?: string | null, pictureUrl?: string | null, emails: Array<string>, phoneNumbers: Array<string>, whatsappAccounts: Array<{ __typename?: 'WhatsappAccountInfo', id: string, phoneNumber: string }>, messagingAccount?: { __typename?: 'MessagingAccount', id: number, accountType: MessagingAccountType, platformId?: string | null, provider?: string | null, initialized: boolean, error?: string | null, color: string, lastData?: string | null } | null }> } };

export type GetMessagingContactQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetMessagingContactQuery = { __typename?: 'Query', getMessagingContact: { __typename?: 'MessagingContact', id: number, givenName?: string | null, middleName?: string | null, surname?: string | null, pictureUrl?: string | null, emails: Array<string>, phoneNumbers: Array<string>, whatsappAccounts: Array<{ __typename?: 'WhatsappAccountInfo', id: string, phoneNumber: string }>, messagingAccount?: { __typename?: 'MessagingAccount', id: number, accountType: MessagingAccountType, platformId?: string | null, provider?: string | null, initialized: boolean, error?: string | null, color: string, lastData?: string | null } | null } };

export type CreateMessagingContactMutationVariables = Exact<{
  input: MessagingContactInput;
}>;


export type CreateMessagingContactMutation = { __typename?: 'Mutation', createMessagingContact: { __typename?: 'MessagingContact', id: number, givenName?: string | null, middleName?: string | null, surname?: string | null, pictureUrl?: string | null, emails: Array<string>, phoneNumbers: Array<string>, whatsappAccounts: Array<{ __typename?: 'WhatsappAccountInfo', id: string, phoneNumber: string }>, messagingAccount?: { __typename?: 'MessagingAccount', id: number, accountType: MessagingAccountType, platformId?: string | null, provider?: string | null, initialized: boolean, error?: string | null, color: string, lastData?: string | null } | null } };

export type UpdateMessagingContactMutationVariables = Exact<{
  id: Scalars['Int'];
  input: MessagingContactInput;
}>;


export type UpdateMessagingContactMutation = { __typename?: 'Mutation', updateMessagingContact: { __typename?: 'MessagingContact', id: number, givenName?: string | null, middleName?: string | null, surname?: string | null, pictureUrl?: string | null, emails: Array<string>, phoneNumbers: Array<string>, whatsappAccounts: Array<{ __typename?: 'WhatsappAccountInfo', id: string, phoneNumber: string }>, messagingAccount?: { __typename?: 'MessagingAccount', id: number, accountType: MessagingAccountType, platformId?: string | null, provider?: string | null, initialized: boolean, error?: string | null, color: string, lastData?: string | null } | null } };

export type DeleteMessagingContactMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteMessagingContactMutation = { __typename?: 'Mutation', deleteMessagingContact?: boolean | null };

export type MessagingFileFullFragmentFragment = { __typename?: 'MessagingFile', id: number, contentType?: string | null, size?: number | null, filename: string, contentId?: string | null, available: boolean, hasThumbnail: boolean };

export type CreateMessagingFileDownloadLinkMutationVariables = Exact<{
  id: Scalars['Int'];
  triggerDownload: Scalars['Boolean'];
}>;


export type CreateMessagingFileDownloadLinkMutation = { __typename?: 'Mutation', createMessagingFileDownloadLink: string };

export type CreateMessagingFileThumbnailDownloadLinkMutationVariables = Exact<{
  id: Scalars['Int'];
  triggerDownload: Scalars['Boolean'];
}>;


export type CreateMessagingFileThumbnailDownloadLinkMutation = { __typename?: 'Mutation', createMessagingFileThumbnailDownloadLink: string };

export type PrepareMessagingFileUploadMutationVariables = Exact<{
  file: MessagingFileInput;
  maxSize?: InputMaybe<Scalars['Int']>;
}>;


export type PrepareMessagingFileUploadMutation = { __typename?: 'Mutation', prepareMessagingFileUpload: { __typename?: 'PrepareMessagingFileUploadResult', ticketToken: string, uploadUrl: string, fields: Array<{ __typename?: 'PrepareFileUploadField', name: string, value: string }> } };

export type FinaliseMessagingFileUploadMutationVariables = Exact<{
  ticketToken: Scalars['String'];
}>;


export type FinaliseMessagingFileUploadMutation = { __typename?: 'Mutation', finaliseMessagingFileUpload: { __typename?: 'MessagingFile', id: number, contentType?: string | null, size?: number | null, filename: string, contentId?: string | null, available: boolean, hasThumbnail: boolean } };

export type PreparePublicFileUploadMutationVariables = Exact<{
  input: FileUploadInput;
}>;


export type PreparePublicFileUploadMutation = { __typename?: 'Mutation', preparePublicFileUpload: { __typename?: 'PrepareFileUploadResult', url: string, uploadUrl: string, fields: Array<{ __typename?: 'PrepareFileUploadField', name: string, value: string }> } };

export type PrepareIssuesFileUploadMutationVariables = Exact<{
  input: FileUploadInput;
}>;


export type PrepareIssuesFileUploadMutation = { __typename?: 'Mutation', prepareIssuesFileUpload: { __typename?: 'PrepareFileUploadResult', url: string, uploadUrl: string, fields: Array<{ __typename?: 'PrepareFileUploadField', name: string, value: string }> } };

export type RequestMessagingFileReuploadMutationVariables = Exact<{
  fileId: Scalars['Int'];
  messageId: Scalars['Int'];
}>;


export type RequestMessagingFileReuploadMutation = { __typename?: 'Mutation', requestMessagingFileReupload: string };

export type OnRequestMessagingFileReuploadDataSubscriptionVariables = Exact<{
  ticketToken: Scalars['String'];
}>;


export type OnRequestMessagingFileReuploadDataSubscription = { __typename?: 'Subscription', onRequestMessagingFileReuploadData: { __typename?: 'RequestMessagingFileReuploadData', type: string } };

export type OnRequestMessagingFileReuploadResultSubscriptionVariables = Exact<{
  ticketToken: Scalars['String'];
}>;


export type OnRequestMessagingFileReuploadResultSubscription = { __typename?: 'Subscription', onRequestMessagingFileReuploadResult: { __typename?: 'RequestMessagingFileReuploadResult', success: boolean, errorCode?: string | null, errorMessage?: string | null, data?: { __typename?: 'RequestMessagingFileReuploadResultData', file: { __typename?: 'MessagingFile', id: number, contentType?: string | null, size?: number | null, filename: string, contentId?: string | null, available: boolean, hasThumbnail: boolean } } | null } };

export type CreateUserFeedbackMutationVariables = Exact<{
  input: UserFeedbackInput;
}>;


export type CreateUserFeedbackMutation = { __typename?: 'Mutation', createUserFeedback: number };

export type NamedMessagingThreadsFilterFullFragmentFragment = { __typename?: 'NamedMessagingThreadsFilter', id: number, name: string, content: { __typename?: 'MessagingThreadsFilter', unread?: boolean | null, lastMessageOutgoing?: boolean | null, searchTerm?: string | null, messagingAccount?: { __typename?: 'MessagingFilter_MessagingAccount', idEq?: number | null, idIn?: Array<number> | null } | null, folder?: { __typename?: 'MessagingFilter_MessagingFolder', idEq?: number | null, idIn?: Array<number> | null, typeEq?: MessagingFolderType | null, typeIn?: Array<MessagingFolderType> | null } | null, label?: { __typename?: 'MessagingFilter_MessagingLabel', idEq?: number | null, idIn?: Array<number> | null } | null, date?: { __typename?: 'MessagingFilter_Date', from?: string | null, to?: string | null } | null, participant?: { __typename?: 'MessagingFilter_Participant', refEq?: string | null, refIn?: Array<string> | null } | null } };

export type GetNamedMessagingThreadFiltersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNamedMessagingThreadFiltersQuery = { __typename?: 'Query', getNamedMessagingThreadFilters: Array<{ __typename?: 'NamedMessagingThreadsFilter', id: number, name: string, content: { __typename?: 'MessagingThreadsFilter', unread?: boolean | null, lastMessageOutgoing?: boolean | null, searchTerm?: string | null, messagingAccount?: { __typename?: 'MessagingFilter_MessagingAccount', idEq?: number | null, idIn?: Array<number> | null } | null, folder?: { __typename?: 'MessagingFilter_MessagingFolder', idEq?: number | null, idIn?: Array<number> | null, typeEq?: MessagingFolderType | null, typeIn?: Array<MessagingFolderType> | null } | null, label?: { __typename?: 'MessagingFilter_MessagingLabel', idEq?: number | null, idIn?: Array<number> | null } | null, date?: { __typename?: 'MessagingFilter_Date', from?: string | null, to?: string | null } | null, participant?: { __typename?: 'MessagingFilter_Participant', refEq?: string | null, refIn?: Array<string> | null } | null } }> };

export type GetNamedMessagingThreadFilterQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetNamedMessagingThreadFilterQuery = { __typename?: 'Query', getNamedMessagingThreadFilter?: { __typename?: 'NamedMessagingThreadsFilter', id: number, name: string, content: { __typename?: 'MessagingThreadsFilter', unread?: boolean | null, lastMessageOutgoing?: boolean | null, searchTerm?: string | null, messagingAccount?: { __typename?: 'MessagingFilter_MessagingAccount', idEq?: number | null, idIn?: Array<number> | null } | null, folder?: { __typename?: 'MessagingFilter_MessagingFolder', idEq?: number | null, idIn?: Array<number> | null, typeEq?: MessagingFolderType | null, typeIn?: Array<MessagingFolderType> | null } | null, label?: { __typename?: 'MessagingFilter_MessagingLabel', idEq?: number | null, idIn?: Array<number> | null } | null, date?: { __typename?: 'MessagingFilter_Date', from?: string | null, to?: string | null } | null, participant?: { __typename?: 'MessagingFilter_Participant', refEq?: string | null, refIn?: Array<string> | null } | null } } | null };

export type CreateNamedMessagingThreadFilterMutationVariables = Exact<{
  input: NamedMessagingThreadsFilterInput;
}>;


export type CreateNamedMessagingThreadFilterMutation = { __typename?: 'Mutation', createNamedMessagingThreadFilter: { __typename?: 'NamedMessagingThreadsFilter', id: number, name: string } };

export type UpdateNamedMessagingThreadFilterMutationVariables = Exact<{
  id: Scalars['Int'];
  input: NamedMessagingThreadsFilterInput;
}>;


export type UpdateNamedMessagingThreadFilterMutation = { __typename?: 'Mutation', updateNamedMessagingThreadFilter: { __typename?: 'NamedMessagingThreadsFilter', id: number, name: string } };

export type DeleteNamedMessagingThreadFilterMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteNamedMessagingThreadFilterMutation = { __typename?: 'Mutation', deleteNamedMessagingThreadFilter?: boolean | null };

export type GetMessagingFoldersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMessagingFoldersQuery = { __typename?: 'Query', getMessagingFolders: Array<{ __typename?: 'MessagingFolder', id: number, type?: MessagingFolderType | null, displayName: string, displayed: boolean, system: boolean, messagingAccount?: { __typename?: 'MessagingAccount', id: number, accountType: MessagingAccountType, platformId?: string | null, provider?: string | null, initialized: boolean, error?: string | null, color: string, lastData?: string | null } | null }> };

export type GetMessagingFoldersStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMessagingFoldersStatsQuery = { __typename?: 'Query', getMessagingFoldersStats: Array<{ __typename?: 'MessagingFolderStats', id: number, notificationCount: number }> };

export type GetMessagingLabelsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMessagingLabelsQuery = { __typename?: 'Query', getMessagingLabels: Array<{ __typename?: 'MessagingLabel', id: number, displayName: string, messagingAccount: { __typename?: 'MessagingAccount', id: number, accountType: MessagingAccountType, platformId?: string | null, provider?: string | null, initialized: boolean, error?: string | null, color: string, lastData?: string | null } }> };

export type SendMessagingMessageMutationVariables = Exact<{
  input: MessagingMessageInput;
}>;


export type SendMessagingMessageMutation = { __typename?: 'Mutation', sendMessagingMessage: string };

export type OnSendMessagingMessageResultSubscriptionVariables = Exact<{
  ticketToken: Scalars['String'];
}>;


export type OnSendMessagingMessageResultSubscription = { __typename?: 'Subscription', onSendMessagingMessageResult: { __typename?: 'SendMessagingMessageResult', success: boolean, errorCode?: string | null, errorMessage?: string | null, data?: { __typename?: 'SendMessagingMessageResultData', thread?: { __typename?: 'MessagingThread', id: number, subject: string, snippet: string, lastMessageTimestamp?: string | null, unread: boolean, hasAttachments: boolean, lastMessageOutgoing: boolean, from: Array<{ __typename?: 'MessagingParticipant', ref: string, name?: string | null }>, to: Array<{ __typename?: 'MessagingParticipant', ref: string, name?: string | null }>, participants: Array<{ __typename?: 'MessagingParticipant', ref: string, name?: string | null }>, messagingAccount: { __typename?: 'MessagingAccount', id: number, accountType: MessagingAccountType, platformId?: string | null, provider?: string | null, initialized: boolean, error?: string | null, color: string, lastData?: string | null }, userMetadata?: { __typename?: 'MessagingThreadUserMetadata', name?: string | null } | null } | null, message: { __typename?: 'MessagingMessage', id: number, date?: string | null, subject?: string | null, body?: string | null, isForwarded: boolean, status?: MessagingMessageStatus | null, failReason?: string | null, from: Array<{ __typename?: 'MessagingParticipant', name?: string | null, ref: string }>, to: Array<{ __typename?: 'MessagingParticipant', name?: string | null, ref: string }>, cc: Array<{ __typename?: 'MessagingParticipant', name?: string | null, ref: string }>, bcc: Array<{ __typename?: 'MessagingParticipant', name?: string | null, ref: string }>, replyTo: Array<{ __typename?: 'MessagingParticipant', name?: string | null, ref: string }>, files: Array<{ __typename?: 'MessagingFile', id: number, contentType?: string | null, size?: number | null, filename: string, contentId?: string | null, available: boolean, hasThumbnail: boolean }> } } | null } };

export type SendMessagingMessagesMutationVariables = Exact<{
  input: Array<MessagingMessageInput> | MessagingMessageInput;
}>;


export type SendMessagingMessagesMutation = { __typename?: 'Mutation', sendMessagingMessages: Array<string> };

export type CanSendMessageIn24HoursWindowQueryVariables = Exact<{
  input: Verify24HourWindowInput;
}>;


export type CanSendMessageIn24HoursWindowQuery = { __typename?: 'Query', canSendMessageIn24HoursWindow: boolean };

export type TemplateFieldsFragmentFragment = { __typename?: 'TemplatesFields', id: string, name: string, category: TemplateCategory, language: string, components: Array<{ __typename?: 'TemplateComponent', type: TemplateComponentType, text?: string | null, format?: TemplateComponentFormat | null, example?: any | null, buttons?: Array<{ __typename?: 'TemplateButton', type: TemplateButtonType, text: string, example?: any | null, url?: string | null } | null> | null }> };

export type GetTemplateMessagesQueryVariables = Exact<{
  input: GetMessageTemplatesInput;
}>;


export type GetTemplateMessagesQuery = { __typename?: 'Query', getTemplateMessages: { __typename?: 'GetTemplatesResponse', data: Array<{ __typename?: 'TemplatesFields', id: string, name: string, category: TemplateCategory, language: string, components: Array<{ __typename?: 'TemplateComponent', type: TemplateComponentType, text?: string | null, format?: TemplateComponentFormat | null, example?: any | null, buttons?: Array<{ __typename?: 'TemplateButton', type: TemplateButtonType, text: string, example?: any | null, url?: string | null } | null> | null }> } | null> } };

export type GetMessagingThreadsQueryVariables = Exact<{
  filter?: InputMaybe<MessagingThreadsFilterInput>;
  pagination?: InputMaybe<PaginationInput>;
}>;


export type GetMessagingThreadsQuery = { __typename?: 'Query', getMessagingThreads: { __typename?: 'GetMessagingThreadsResult', pagination?: { __typename?: 'Pagination', count: number } | null, data: Array<{ __typename?: 'MessagingThread', id: number, subject: string, snippet: string, lastMessageTimestamp?: string | null, unread: boolean, hasAttachments: boolean, lastMessageOutgoing: boolean, from: Array<{ __typename?: 'MessagingParticipant', ref: string, name?: string | null }>, to: Array<{ __typename?: 'MessagingParticipant', ref: string, name?: string | null }>, participants: Array<{ __typename?: 'MessagingParticipant', ref: string, name?: string | null }>, messagingAccount: { __typename?: 'MessagingAccount', id: number, accountType: MessagingAccountType, platformId?: string | null, provider?: string | null, initialized: boolean, error?: string | null, color: string, lastData?: string | null }, userMetadata?: { __typename?: 'MessagingThreadUserMetadata', name?: string | null } | null }> } };

export type GetMessagingThreadQueryVariables = Exact<{
  id: Scalars['Int'];
  cursor?: InputMaybe<CursorInput>;
  subset?: InputMaybe<MessagingThreadMessagesSubsetInput>;
}>;


export type GetMessagingThreadQuery = { __typename?: 'Query', getMessagingThread: { __typename?: 'MessagingThread', id: number, subject: string, unread: boolean, from: Array<{ __typename?: 'MessagingParticipant', name?: string | null, ref: string }>, to: Array<{ __typename?: 'MessagingParticipant', name?: string | null, ref: string }>, participants: Array<{ __typename?: 'MessagingParticipant', ref: string, name?: string | null }>, folders: Array<{ __typename?: 'MessagingFolder', id: number, type?: MessagingFolderType | null }>, messages: { __typename?: 'MessagingThreadMessagesResult', cursor: { __typename?: 'Cursor', size: number, count: number, hasMore: boolean }, data: Array<{ __typename?: 'MessagingMessage', rowNumber?: number | null, id: number, date?: string | null, subject?: string | null, body?: string | null, isForwarded: boolean, status?: MessagingMessageStatus | null, failReason?: string | null, from: Array<{ __typename?: 'MessagingParticipant', name?: string | null, ref: string }>, to: Array<{ __typename?: 'MessagingParticipant', name?: string | null, ref: string }>, cc: Array<{ __typename?: 'MessagingParticipant', name?: string | null, ref: string }>, bcc: Array<{ __typename?: 'MessagingParticipant', name?: string | null, ref: string }>, replyTo: Array<{ __typename?: 'MessagingParticipant', name?: string | null, ref: string }>, files: Array<{ __typename?: 'MessagingFile', id: number, contentType?: string | null, size?: number | null, filename: string, contentId?: string | null, available: boolean, hasThumbnail: boolean }> }> }, messagingAccount: { __typename?: 'MessagingAccount', id: number, accountType: MessagingAccountType, platformId?: string | null, provider?: string | null, initialized: boolean, error?: string | null, color: string, lastData?: string | null }, userMetadata?: { __typename?: 'MessagingThreadUserMetadata', name?: string | null } | null } };

export type GetMessagingThreadSimpleQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetMessagingThreadSimpleQuery = { __typename?: 'Query', getMessagingThread: { __typename?: 'MessagingThread', id: number, unread: boolean } };

export type OnMessagingThreadUpdatedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnMessagingThreadUpdatedSubscription = { __typename?: 'Subscription', onMessagingThreadUpdated: { __typename?: 'MessagingThreadUpdatedNotification', id: number } };

export type SetMessagingThreadsReadUnreadMutationVariables = Exact<{
  filter: MessagingThreadsFilterInput;
  unread: Scalars['Boolean'];
}>;


export type SetMessagingThreadsReadUnreadMutation = { __typename?: 'Mutation', setMessagingThreadsReadUnread: number };

export type UpdateMessagingThreadUserMetadataMutationVariables = Exact<{
  threadId: Scalars['Int'];
  input: MessagingThreadUserMetadataInput;
}>;


export type UpdateMessagingThreadUserMetadataMutation = { __typename?: 'Mutation', updateMessagingThreadUserMetadata: { __typename?: 'MessagingThread', id: number, userMetadata?: { __typename?: 'MessagingThreadUserMetadata', name?: string | null } | null } };

export type MoveMessagingThreadsToFolderMutationVariables = Exact<{
  filter: MessagingThreadsFilterInput;
  folderId: Scalars['Int'];
}>;


export type MoveMessagingThreadsToFolderMutation = { __typename?: 'Mutation', moveMessagingThreadsToFolder: boolean };

export type MoveMessagingThreadsToSpecialFolderMutationVariables = Exact<{
  filter: MessagingThreadsFilterInput;
  folderType: MessagingFolderType;
}>;


export type MoveMessagingThreadsToSpecialFolderMutation = { __typename?: 'Mutation', moveMessagingThreadsToSpecialFolder: boolean };

export type DeleteMessagingThreadMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteMessagingThreadMutation = { __typename?: 'Mutation', deleteMessagingThread: boolean };

export type DeleteMessagingThreadsMutationVariables = Exact<{
  filter: MessagingThreadsFilterInput;
}>;


export type DeleteMessagingThreadsMutation = { __typename?: 'Mutation', deleteMessagingThreads: number };

export type MessagingMessageFullFragmentFragment = { __typename?: 'MessagingMessage', id: number, date?: string | null, subject?: string | null, body?: string | null, isForwarded: boolean, status?: MessagingMessageStatus | null, failReason?: string | null, from: Array<{ __typename?: 'MessagingParticipant', name?: string | null, ref: string }>, to: Array<{ __typename?: 'MessagingParticipant', name?: string | null, ref: string }>, cc: Array<{ __typename?: 'MessagingParticipant', name?: string | null, ref: string }>, bcc: Array<{ __typename?: 'MessagingParticipant', name?: string | null, ref: string }>, replyTo: Array<{ __typename?: 'MessagingParticipant', name?: string | null, ref: string }>, files: Array<{ __typename?: 'MessagingFile', id: number, contentType?: string | null, size?: number | null, filename: string, contentId?: string | null, available: boolean, hasThumbnail: boolean }> };

export type MessagingThreadListFragmentFragment = { __typename?: 'MessagingThread', id: number, subject: string, snippet: string, lastMessageTimestamp?: string | null, unread: boolean, hasAttachments: boolean, lastMessageOutgoing: boolean, from: Array<{ __typename?: 'MessagingParticipant', ref: string, name?: string | null }>, to: Array<{ __typename?: 'MessagingParticipant', ref: string, name?: string | null }>, participants: Array<{ __typename?: 'MessagingParticipant', ref: string, name?: string | null }>, messagingAccount: { __typename?: 'MessagingAccount', id: number, accountType: MessagingAccountType, platformId?: string | null, provider?: string | null, initialized: boolean, error?: string | null, color: string, lastData?: string | null }, userMetadata?: { __typename?: 'MessagingThreadUserMetadata', name?: string | null } | null };

export type MessagingThreadDetailFragmentFragment = { __typename?: 'MessagingThread', id: number, subject: string, unread: boolean, from: Array<{ __typename?: 'MessagingParticipant', name?: string | null, ref: string }>, to: Array<{ __typename?: 'MessagingParticipant', name?: string | null, ref: string }>, participants: Array<{ __typename?: 'MessagingParticipant', ref: string, name?: string | null }>, folders: Array<{ __typename?: 'MessagingFolder', id: number, type?: MessagingFolderType | null }>, messages: { __typename?: 'MessagingThreadMessagesResult', cursor: { __typename?: 'Cursor', size: number, count: number, hasMore: boolean }, data: Array<{ __typename?: 'MessagingMessage', rowNumber?: number | null, id: number, date?: string | null, subject?: string | null, body?: string | null, isForwarded: boolean, status?: MessagingMessageStatus | null, failReason?: string | null, from: Array<{ __typename?: 'MessagingParticipant', name?: string | null, ref: string }>, to: Array<{ __typename?: 'MessagingParticipant', name?: string | null, ref: string }>, cc: Array<{ __typename?: 'MessagingParticipant', name?: string | null, ref: string }>, bcc: Array<{ __typename?: 'MessagingParticipant', name?: string | null, ref: string }>, replyTo: Array<{ __typename?: 'MessagingParticipant', name?: string | null, ref: string }>, files: Array<{ __typename?: 'MessagingFile', id: number, contentType?: string | null, size?: number | null, filename: string, contentId?: string | null, available: boolean, hasThumbnail: boolean }> }> }, messagingAccount: { __typename?: 'MessagingAccount', id: number, accountType: MessagingAccountType, platformId?: string | null, provider?: string | null, initialized: boolean, error?: string | null, color: string, lastData?: string | null }, userMetadata?: { __typename?: 'MessagingThreadUserMetadata', name?: string | null } | null };

export type UpdateUserSettingsMutationVariables = Exact<{
  input: UserSettingsInput;
}>;


export type UpdateUserSettingsMutation = { __typename?: 'Mutation', updateUserSettings: { __typename?: 'UserSettings', filtersOrder: Array<number> } };
