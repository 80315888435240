import { noop } from 'lodash-es';
import { createContext, useContext, useMemo } from 'react';

import { AuthService } from './cognitoAuthService';

const typeSafeNoop = noop as any;

const AuthServiceContext = createContext<AuthService>({
  getCurrentUser: typeSafeNoop,
  getUserSession: typeSafeNoop,
  getUserData: typeSafeNoop,
  signUp: typeSafeNoop,
  confirmRegistration: typeSafeNoop,
  login: typeSafeNoop,
  resetPassword: typeSafeNoop,
  resendConfirmationCode: typeSafeNoop,
  confirmNewPassword: typeSafeNoop,
});

export function useAuthService() {
  return useContext(AuthServiceContext);
}

interface Props {
  children: React.ReactNode;
  setup: () => AuthService;
}

export function AuthServiceProvider({ setup, children }: Props) {
  const authService = useMemo(() => setup(), [setup]);

  return (
    <AuthServiceContext.Provider value={authService}>
      {children}
    </AuthServiceContext.Provider>
  );
}
