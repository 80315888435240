import { ObservableSubscription, gql, useApolloClient } from '@apollo/client';

import {
  createUseMutation,
  createUseQuery,
  createUseSubscription,
  deleteQueryFromCache,
} from '../../common/utils/graphqlUtils';
import {
  DisconnectMessagingAccountMutation,
  DisconnectMessagingAccountMutationVariables,
  ForceMessagingAccountFullLoadMutation,
  ForceMessagingAccountFullLoadMutationVariables,
  GenerateConnectEmailRedirectUrlMutation,
  GenerateConnectEmailRedirectUrlMutationVariables,
  GetMessagingAccountsQuery,
  GetMessagingAccountsQueryVariables,
  InitConnectWhatsappMutation,
  InitConnectWhatsappMutationVariables,
  OnAsyncConnectMessagingAccountDataSubscription,
  OnAsyncConnectMessagingAccountDataSubscriptionVariables,
  OnAsyncConnectMessagingAccountResultSubscription,
  OnAsyncConnectMessagingAccountResultSubscriptionVariables,
  OnMessagingAccountUpdatedSubscription,
  OnMessagingAccountUpdatedSubscriptionVariables,
  UpdateMessagingAccountMutation,
  UpdateMessagingAccountMutationVariables,
} from '../../types/graphqlGenerated';

export const AccountFullFragment = gql`
  fragment MessagingAccountFullFragment on MessagingAccount {
    id
    accountType
    platformId
    provider
    initialized
    error
    color
    lastData
  }
`;

const ACCOUNTS_QUERY = gql`
  ${AccountFullFragment}
  query GetMessagingAccounts {
    getMessagingAccounts {
      ...MessagingAccountFullFragment
    }
  }
`;

const useAccountsQueryInner = createUseQuery<
  GetMessagingAccountsQuery,
  GetMessagingAccountsQueryVariables,
  'accounts',
  GetMessagingAccountsQuery['getMessagingAccounts']
>(ACCOUNTS_QUERY, {
  extractResult: resp => resp.getMessagingAccounts,
  resultName: 'accounts',
});
export const useAccountsQuery: typeof useAccountsQueryInner = () => {
  useOnAccountUpdatedSubscription();
  return useAccountsQueryInner();
};

const UPDATE_ACCOUNT_MUTATION = gql`
  mutation UpdateMessagingAccount($id: Int!, $input: MessagingAccountInput!) {
    updateMessagingAccount(id: $id, input: $input) {
      id
      color
    }
  }
`;

export const useUpdateAccountMutation = createUseMutation<
  UpdateMessagingAccountMutation,
  UpdateMessagingAccountMutationVariables,
  UpdateMessagingAccountMutation['updateMessagingAccount']
>(UPDATE_ACCOUNT_MUTATION, {
  extractResult: resp => resp.updateMessagingAccount,
});

const GENERATE_CONNECT_EMAIL_ACCOUNT_URL_MUTATION = gql`
  mutation GenerateConnectEmailRedirectUrl(
    $input: ConnectEmailRedirectUrlInput!
  ) {
    generateConnectEmailRedirectUrl(input: $input)
  }
`;

export const useConnectEmailAccountViaRedirect = createUseMutation<
  GenerateConnectEmailRedirectUrlMutation,
  GenerateConnectEmailRedirectUrlMutationVariables,
  GenerateConnectEmailRedirectUrlMutation['generateConnectEmailRedirectUrl']
>(GENERATE_CONNECT_EMAIL_ACCOUNT_URL_MUTATION, {
  extractResult: resp => resp.generateConnectEmailRedirectUrl,
  onCompleted: resp => {
    if (resp) {
      window.location.href = resp;
    }
  },
});

const INIT_CONNECT_WHATSAPP_MUTATION = gql`
  mutation InitConnectWhatsapp($input: InitConnectWhatsappInput!) {
    initConnectWhatsapp(input: $input)
  }
`;

const useInitConnectWhatsapp = createUseMutation<
  InitConnectWhatsappMutation,
  InitConnectWhatsappMutationVariables,
  InitConnectWhatsappMutation['initConnectWhatsapp']
>(INIT_CONNECT_WHATSAPP_MUTATION, {
  extractResult: resp => resp.initConnectWhatsapp,
});

const FORCE_MESSAGING_ACCOUNT_FULL_LOAD_MUTATION = gql`
  mutation ForceMessagingAccountFullLoad($id: Int!) {
    forceMessagingAccountFullLoad(id: $id)
  }
`;

export const useForceMessagingAccountFullLoad = createUseMutation<
  ForceMessagingAccountFullLoadMutation,
  ForceMessagingAccountFullLoadMutationVariables,
  ForceMessagingAccountFullLoadMutation['forceMessagingAccountFullLoad']
>(FORCE_MESSAGING_ACCOUNT_FULL_LOAD_MUTATION, {
  extractResult: resp => resp.forceMessagingAccountFullLoad,
});

const ON_ASYNC_CONNECT_DATA_SUBSCRIPTION = gql`
  subscription OnAsyncConnectMessagingAccountData($ticketToken: String!) {
    onAsyncConnectMessagingAccountData(ticketToken: $ticketToken) {
      qrcode
    }
  }
`;

const ON_ASYNC_CONNECT_RESULT_SUBSCRIPTION = gql`
  subscription OnAsyncConnectMessagingAccountResult($ticketToken: String!) {
    onAsyncConnectMessagingAccountResult(ticketToken: $ticketToken) {
      success
      errorCode
      errorMessage
    }
  }
`;

type UseConnectWhatsappParams = {
  setIsConnecting: (val: boolean) => void;
};

type ConnectWhatsappParams = {
  termsAcceptanceTimestamp: Date;
  code: string;
  wabaId: string;
  phoneNumberId: string;
  pin: string;
};

export function useConnectWhatsapp({
  setIsConnecting,
}: UseConnectWhatsappParams) {
  const [initConnect] = useInitConnectWhatsapp();
  const client = useApolloClient();

  return async ({
    wabaId,
    termsAcceptanceTimestamp,
    code,
    phoneNumberId,
    pin,
  }: ConnectWhatsappParams) => {
    setIsConnecting(true);
    const ticketToken = await initConnect({
      input: {
        phoneNumberId,
        code,
        pin,
        termsAcceptanceTimestamp: termsAcceptanceTimestamp.toISOString(),
        accountId: wabaId,
      },
    });
    if (!ticketToken) {
      setIsConnecting(false);
      throw new Error('Unexpected data');
    }
    let sub: ObservableSubscription;
    return new Promise((resolve, reject) => {
      sub = client
        .subscribe<
          OnAsyncConnectMessagingAccountResultSubscription,
          OnAsyncConnectMessagingAccountResultSubscriptionVariables
        >({
          query: ON_ASYNC_CONNECT_RESULT_SUBSCRIPTION,
          variables: { ticketToken },
        })
        .subscribe({
          next: ({ data }) => {
            const { success, errorCode, errorMessage } =
              data?.onAsyncConnectMessagingAccountResult || {};
            if (success) {
              resolve(null);
            } else {
              const e = Object.assign(new Error(errorMessage || ''), {
                code: errorCode || '',
              });
              reject(e);
            }
          },
        });
    }).finally(() => {
      sub?.unsubscribe();
      setIsConnecting(false);
    });
  };
}

const DISCONNECT_ACCOUNT_MUTATION = gql`
  mutation DisconnectMessagingAccount($id: Int!) {
    disconnectMessagingAccount(id: $id)
  }
`;

export const useDisconnectAccountMutation = createUseMutation<
  DisconnectMessagingAccountMutation,
  DisconnectMessagingAccountMutationVariables,
  DisconnectMessagingAccountMutation['disconnectMessagingAccount']
>(DISCONNECT_ACCOUNT_MUTATION, {
  extractResult: resp => resp.disconnectMessagingAccount,
  onCompleted: (res, _vars, { cache }) => {
    if (!!res) {
      deleteQueryFromCache(cache, 'getMessagingAccounts');
      deleteQueryFromCache(cache, 'getMessagingThreads');
      deleteQueryFromCache(cache, 'getMessagingFolders');
      return true;
    } else {
      return false;
    }
  },
});

const ON_ACCOUNT_UPDATED_SUBSCRIPTION = gql`
  subscription OnMessagingAccountUpdated {
    onMessagingAccountUpdated {
      id
    }
  }
`;

const useOnAccountUpdatedSubscription = createUseSubscription<
  OnMessagingAccountUpdatedSubscription,
  OnMessagingAccountUpdatedSubscriptionVariables,
  OnMessagingAccountUpdatedSubscription['onMessagingAccountUpdated']
>(ON_ACCOUNT_UPDATED_SUBSCRIPTION, {
  extractResult: resp => resp.onMessagingAccountUpdated,
  onNext: (_, client) => {
    client.refetchQueries({
      include: [
        'GetMessagingAccounts',
        'GetMessagingFolders',
        'GetMessagingFoldersStats',
      ],
    });
  },
});
