import { Button, Divider, Spin } from 'antd';
import classNames from 'classnames';
import { last, noop } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
import { isNumber } from 'react-advanced-cropper';
import InfiniteScroll from 'react-infinite-scroller';
import { FormattedMessage } from 'react-intl';
import { Location, useLocation, useNavigate } from 'react-router';

import { getScrollYParent } from '../../../common/utils/scrollUtils';
import { PAGNIATION_ALL } from '../../../config/constants';
import {
  MessagingAccountType,
  MessagingMessageFullFragmentFragment,
  MessagingThreadDetailFragmentFragment,
} from '../../../types/graphqlGenerated';
import { InlineMessageComposer } from '../sendMessage/MessageComposer';
import { MessageComposerReactionMessageType } from '../sendMessage/messageComposer/messageComposerTypes';
import { useThreadDetailQuery } from '../threadsGraphql';
import { ChatMessageItem } from './ChatMessageItem';
import { EmailMessageItem } from './EmailMessageItem';
import styles from './ThreadMessageList.module.less';

type Thread = MessagingThreadDetailFragmentFragment;
type Message = MessagingMessageFullFragmentFragment;
type InlineComposerProps = {
  thread: Thread;
  message: Message;
  type: MessageComposerReactionMessageType;
  onCancel: () => void;
  initialValues?: any;
};

function InlineComposer({
  thread,
  message,
  type,
  onCancel,
  initialValues,
}: InlineComposerProps) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onMessageSendStart = () => {
    onCancel();
  };

  const restoreEditState = values => {
    navigate(pathname, {
      state: {
        messageId: message.id,
        reactionType: type,
        initialValues: values,
      },
    });
  };

  return (
    <div
      className={classNames(
        styles.InlineComposer,
        'Print--Hide',
        thread.messagingAccount.accountType === MessagingAccountType.Email &&
          styles['InlineComposer--Email']
      )}
    >
      <InlineMessageComposer
        reaction={{ type, thread, message }}
        onSendStart={onMessageSendStart}
        restoreEditState={restoreEditState}
        initialValues={initialValues}
      />
      <div>
        <Button onClick={onCancel}>
          <FormattedMessage id="threadDetail.action.close" />
        </Button>
      </div>
    </div>
  );
}

type ThreadMessageItemProps = {
  thread: Thread;
  message: Message;
};

function ThreadMessageItem({ thread, message }: ThreadMessageItemProps) {
  const { state, pathname }: Location & { state: any } = useLocation();
  const navigate = useNavigate();
  const inlineComposerRef = useRef<HTMLDivElement | null>(null);

  let initialReactionType: MessageComposerReactionMessageType | undefined =
    undefined;
  let initialValues: any = undefined;
  if (state?.messageId === message.id) {
    initialReactionType = state.reactionType;
    initialValues = state.initialValues;
  }

  const [inlineComposerOpen, setInlineComposerOpen] = useState<
    MessageComposerReactionMessageType | undefined
  >(initialReactionType);

  useEffect(() => {
    if (initialReactionType) {
      setInlineComposerOpen(initialReactionType);
    }
  }, [initialReactionType]);

  useEffect(() => {
    // scroll to the top of the inline composer after autofocus of rich text editor
    let timerId;
    if (inlineComposerOpen && inlineComposerRef.current) {
      timerId = setTimeout(() => {
        inlineComposerRef.current!.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [inlineComposerOpen]);

  const { messagingAccount } = thread;

  return (
    <div className={styles.Item}>
      {inlineComposerOpen && (
        <div ref={inlineComposerRef}>
          <InlineComposer
            thread={thread}
            message={message}
            type={inlineComposerOpen}
            onCancel={() => {
              setInlineComposerOpen(undefined);
              // Clear whatever state
              navigate(pathname);
            }}
            initialValues={initialValues}
          />
        </div>
      )}
      {messagingAccount.accountType === MessagingAccountType.Email ? (
        <EmailMessageItem
          thread={thread}
          message={message}
          inlineComposerOpen={inlineComposerOpen}
          setInlineComposerOpen={setInlineComposerOpen}
        />
      ) : (
        <ChatMessageItem
          thread={thread}
          message={message}
          inlineComposerOpen={inlineComposerOpen}
          setInlineComposerOpen={setInlineComposerOpen}
        />
      )}
    </div>
  );
}

type ThreadMessageListProps = {
  thread: MessagingThreadDetailFragmentFragment;
};

export function ScrollableThreadMessageList({
  thread,
}: ThreadMessageListProps) {
  // "ref" is in state, because rendering depends on it
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const containerRef = useRef((ref: HTMLDivElement) => setContainer(ref));

  // This is for fetching new messages
  const { fetchMore } = useThreadDetailQuery(
    { id: thread.id },
    { skip: true, fetchPolicy: 'network-only' }
  );
  const refetching = useRef(false);

  const accType = thread.messagingAccount.accountType;

  return (
    <div
      className={classNames(
        styles.Container,
        accType === MessagingAccountType.Email && styles['Container--Email'],
        accType === MessagingAccountType.Whatsapp &&
          styles['Container--Whatsapp']
      )}
      ref={containerRef.current}
    >
      {/*
      We will not render until we have container ref, because incorrect `getScrollParent` result
      then results in an inconsistent `InfiniteScroll` behaviour for a moment
    */}
      {container && (
        <InfiniteScroll
          pageStart={0}
          loadMore={async () => {
            if (!refetching.current) {
              refetching.current = true;
              try {
                await fetchMore({
                  cursor: { afterId: last(thread.messages.data)?.id },
                });
              } finally {
                refetching.current = false;
              }
            }
          }}
          hasMore={thread.messages.cursor.hasMore}
          loader={
            <div className="FlexRow Flex--Center" key="loader">
              <Spin />
            </div>
          }
          useWindow={false}
          getScrollParent={() => getScrollYParent(container)}
          threshold={150}
        >
          {thread.messages.data.map(message => (
            <ThreadMessageItem
              thread={thread}
              message={message}
              key={message.id}
            />
          ))}
        </InfiniteScroll>
      )}
    </div>
  );
}

type SearchViewHoleProps = {
  threadId: number;
  count: number;
  idBefore?: number;
  idAfter?: number;
};

function SearchViewHole({
  threadId,
  count,
  idBefore,
  idAfter,
}: SearchViewHoleProps) {
  // This is for fetching new messages
  const { fetchMore } = useThreadDetailQuery(
    {
      id: threadId,
      cursor: { size: PAGNIATION_ALL, afterId: idBefore, beforeId: idAfter },
    },
    {
      skip: true,
      fetchPolicy: 'network-only',
    }
  );
  const [loading, setLoading] = useState(false);
  const load = async () => {
    try {
      setLoading(true);
      await fetchMore();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.DividerContainer}>
      <Divider type="horizontal">
        <Button
          size="large"
          shape="circle"
          onClick={loading ? noop : load}
          disabled={loading}
        >
          {loading ? <Spin /> : count}
        </Button>
      </Divider>
    </div>
  );
}

export function SearchViewThreadMessageList({
  thread,
}: ThreadMessageListProps) {
  const [lastRowNumber, messagesAndHoles] = thread.messages.data.reduce(
    ([prevRowNumber, agg], cur, i) => {
      const curNumber = isNumber(cur.rowNumber)
        ? cur.rowNumber
        : prevRowNumber + 1;

      if (curNumber - prevRowNumber > 1) {
        agg.push({
          type: 'hole',
          count: curNumber - prevRowNumber - 1,
          key: prevRowNumber,
          idBefore: thread.messages.data[i - 1]?.id,
          idAfter: cur.id,
        });
      }
      agg.push({ type: 'message', data: cur });
      return [curNumber, agg];
    },
    [0, []] as [
      number,
      (
        | {
            type: 'message';
            data: MessagingThreadDetailFragmentFragment['messages']['data'][0];
          }
        | {
            type: 'hole';
            count: number;
            idBefore?: number;
            idAfter?: number;
            key: number;
          }
      )[]
    ]
  );
  if (thread.messages.cursor.count > lastRowNumber) {
    messagesAndHoles.push({
      type: 'hole',
      count: thread.messages.cursor.count - lastRowNumber,
      key: lastRowNumber,
      idBefore: last(thread.messages.data)?.id,
    });
  }

  const accType = thread.messagingAccount.accountType;

  return (
    <div
      className={classNames(
        styles.Container,
        accType === MessagingAccountType.Email && styles['Container--Email'],
        accType === MessagingAccountType.Whatsapp &&
          styles['Container--Whatsapp']
      )}
    >
      {messagesAndHoles.map(msgOrHole =>
        msgOrHole.type === 'hole' ? (
          <SearchViewHole
            key={`hole-${msgOrHole.key}`}
            threadId={thread.id}
            count={msgOrHole.count}
            idBefore={msgOrHole.idBefore}
            idAfter={msgOrHole.idAfter}
          />
        ) : (
          <ThreadMessageItem
            thread={thread}
            message={msgOrHole.data}
            key={msgOrHole.data.id}
          />
        )
      )}
    </div>
  );
}
