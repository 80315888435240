import { Tabs } from 'antd';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import RouteDefinitions from '../app/routes';
import LoginForm from '../modules/auth/LoginForm';
import SignupSteps from '../modules/auth/SignupSteps';
import NonLoggedPageTemplate from '../modules/messagesApp/layout/NonLoggedPageTemplate';

export const InitialAuthTab = {
  LOGIN: 'login',
  SIGNUP: 'signup',
};

const TAB_TO_ROUTE = {
  [InitialAuthTab.LOGIN]: RouteDefinitions.login,
  [InitialAuthTab.SIGNUP]: RouteDefinitions.signup,
};

interface Props {
  tab: string;
}

export default function InitialAuthPage(props: Props) {
  const navigate = useNavigate();
  const onTabChange = useCallback(
    tabId => navigate(TAB_TO_ROUTE[tabId]),
    [navigate]
  );

  const isTestSTage = `${process.env.REACT_APP_STAGE}` === 'test';

  return (
    <NonLoggedPageTemplate>
      <Tabs activeKey={props.tab} onChange={onTabChange}>
        {/* eslint-disable-next-line eqeqeq */}
        {!isTestSTage && (
          <Tabs.TabPane
            key={InitialAuthTab.SIGNUP}
            tab={<FormattedMessage id="initialAuth.tabs.signup" />}
          >
            <SignupSteps />
          </Tabs.TabPane>
        )}
        <Tabs.TabPane
          key={InitialAuthTab.LOGIN}
          tab={<FormattedMessage id="initialAuth.tabs.login" />}
        >
          <LoginForm />
        </Tabs.TabPane>
      </Tabs>
    </NonLoggedPageTemplate>
  );
}
