import { InfoCircleOutlined } from '@ant-design/icons';
import { Alert, Typography } from 'antd';
import classNames from 'classnames';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { TranslationId } from '../../../types/appTypes';
import styles from './ConnectResult.module.less';

export const ConnectResult = ({ children }: { children: ReactNode }) => (
  <div className={styles.Outer}>
    <div className={styles.Inner}>{children}</div>
  </div>
);

export const ConnectResultText = ({
  titleId,
  infoId,
  values,
}: {
  titleId?: TranslationId;
  infoId?: TranslationId;
  values?: any;
}) => (
  <div>
    {titleId && (
      <Typography.Title level={1}>
        <FormattedMessage id={titleId} />
      </Typography.Title>
    )}
    {infoId && (
      <Alert
        type="info"
        message={
          <div
            className={classNames('FlexRow FlexRow--SpaceLg', styles.InfoText)}
          >
            <InfoCircleOutlined />
            <div className="Flex--1">
              <FormattedMessage id={infoId} values={values} />
            </div>
          </div>
        }
      />
    )}
  </div>
);
