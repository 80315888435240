import { Form as AntForm, Collapse, Input, Typography } from 'antd';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { ObjectSchema } from 'yup';

import RouteDefinitions from '../../../app/routes';
import { OverlaySpinner } from '../../../components/OverlaySpinner';
import FormField from '../../../components/forms/FormField';
import { useLocalizedYup } from '../../../config/intl/LocaleProvider';
import { useConnectWhatsapp } from '../accountsGraphql';
import styles from './ConnectWhatsappAccountWizard.module.less';

type ConnectWhatsappAccountWizardProps = {
  onClose: () => void;
  termsAcceptanceTimestamp: Date;
};

export function ConnectWhatsappAccountWizard({
  onClose,
  termsAcceptanceTimestamp,
}: ConnectWhatsappAccountWizardProps) {
  const navigate = useNavigate();
  const intl = useIntl();
  const yup = useLocalizedYup();

  const [code, setCode] = useState<undefined | string>();
  const [phoneNumberId, setPhoneNumberId] = useState<undefined | string>();
  const [wabaId, setWabaId] = useState<undefined | string>();
  const [isConnecting, setIsConnecting] = useState(false);
  const [activePanel, setActivePanel] = useState(['1', '2']);

  const connect = useConnectWhatsapp({
    setIsConnecting,
  });

  const isFbConnected =
    Boolean(code) && Boolean(phoneNumberId) && Boolean(wabaId);

  const validationSchema: ObjectSchema<any> = yup.object().shape({
    pin: yup
      .string()
      .matches(
        /^\d{6}$/,
        intl.formatMessage({ id: 'connectAccount.dialog.whatsapp.pin-error' })
      )
      .required(),
  });
  const onSubmit = async ({ pin }: { pin: string }) => {
    if (code && phoneNumberId && wabaId) {
      try {
        await connect({
          code,
          termsAcceptanceTimestamp,
          phoneNumberId,
          wabaId,
          pin,
        });
        onClose();
        navigate(RouteDefinitions.waConnectSuccess);
      } catch (e) {
        onClose();
        navigate(RouteDefinitions.connectFail);
      }
    }
  };
  const initWaSignup = async () => {
    FB.login(
      response => {
        if (response.authResponse) {
          const code = response.authResponse.code;
          setCode(code);
        }
      },
      {
        config_id: process.env.REACT_APP_FB_CONFIG_ID,
        response_type: 'code',
        override_default_response_type: true,
        extras: {
          feature: 'whatsapp_embedded_signup',
          sessionInfoVersion: 2,
        },
      } as any
    );
  };

  const sessionInfoListener = (event: any) => {
    if (event.origin !== 'https://www.facebook.com') {
      return;
    }
    try {
      const data = JSON.parse(event.data);
      if (data.type === 'WA_EMBEDDED_SIGNUP') {
        // if user finishes the Embedded Signup flow
        if (data.event === 'FINISH') {
          const { phone_number_id, waba_id } = data.data;
          setPhoneNumberId(phone_number_id);
          setWabaId(waba_id);
          setActivePanel(['2']);
        } else {
          // user canceled Embedded SignUp flow
          onClose();
        }
      }
    } catch {} // event without data in json format not important
  };

  useEffect(() => {
    window.addEventListener('message', sessionInfoListener);
    return () => {
      window.removeEventListener('message', sessionInfoListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Formik
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      initialValues={{ pin: '' }}
    >
      <Form>
        <Typography.Title level={4}>
          <FormattedMessage id="connectAccount.dialog.whatsapp.title" />
        </Typography.Title>
        <Collapse
          activeKey={activePanel}
          onChange={(key: any) => setActivePanel(key)}
        >
          <Collapse.Panel
            header={
              <Typography.Title
                level={5}
                style={{ color: isFbConnected ? 'green' : '' }}
              >
                <FormattedMessage id="connectAccount.dialog.whatsapp.step1" />
              </Typography.Title>
            }
            key="1"
          >
            <Typography.Paragraph>
              <FormattedMessage id="connectAccount.dialog.whatsapp.step1-note" />
            </Typography.Paragraph>
            <div className={styles.ButtonContainer}>
              <button
                type="button"
                className={styles.FacebookButton}
                disabled={isFbConnected}
                onClick={() => initWaSignup()}
              >
                {intl.formatMessage({
                  id: 'connectAccount.dialog.whatsapp.button-facebook',
                })}
              </button>
            </div>
          </Collapse.Panel>

          <Collapse.Panel
            header={
              <Typography.Title level={5}>
                <FormattedMessage id="connectAccount.dialog.whatsapp.step2" />
              </Typography.Title>
            }
            key="2"
          >
            <Typography.Paragraph>
              <FormattedMessage id="connectAccount.dialog.whatsapp.step2-note-1" />
            </Typography.Paragraph>
            <Typography.Paragraph>
              <FormattedMessage
                id="connectAccount.dialog.whatsapp.step2-note-2"
                values={{
                  link: chunks => (
                    <a
                      href="https://business.facebook.com/wa/manage/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {chunks}
                    </a>
                  ),
                }}
              />
            </Typography.Paragraph>
            <Typography.Paragraph>
              <FormattedMessage id="connectAccount.dialog.whatsapp.step2-note-3" />
            </Typography.Paragraph>
            <div className={styles.PinContainer}>
              <FormField name="pin" label="PIN">
                {({ field, label }) => (
                  <AntForm.Item label={label} className={styles.FormItem}>
                    <Input.Password
                      bordered
                      {...field}
                      className={styles.PinInput}
                    />
                  </AntForm.Item>
                )}
              </FormField>
              <button
                disabled={!isFbConnected}
                type="submit"
                className={styles.SubmitButton}
              >
                {intl.formatMessage({
                  id: 'connectAccount.dialog.whatsapp.button-register-number',
                })}
              </button>
            </div>
          </Collapse.Panel>
          {isConnecting && (
            <div>
              <OverlaySpinner />
            </div>
          )}
        </Collapse>
      </Form>
    </Formik>
  );
}
