import { find, isNumber, sumBy } from 'lodash-es';
import { ReactNode } from 'react';
import { FormattedNumber } from 'react-intl';

import { getMessageListRoute } from '../../../app/routes';
import { useFormattedMessage } from '../../../common/utils/intlUtils';
import { VerticalMenuItem } from '../../../components/VerticalMenu';
import { TranslationId } from '../../../types/appTypes';
import {
  GetMessagingFoldersQuery,
  GetMessagingFoldersStatsQuery,
  MessagingFolderType,
} from '../../../types/graphqlGenerated';
import styles from './MessagingFolders.module.less';

type FolderLabelBaseProps = {
  to: string;
  icon: ReactNode;
  label: string;
  active: boolean;
  notificationCount?: number;
};

function FolderMenuItemBase({
  icon,
  to,
  label,
  active,
  notificationCount,
}: FolderLabelBaseProps) {
  return (
    <VerticalMenuItem
      to={to}
      leftContent={icon}
      rightContent={
        <div className={styles['MenuItem__Buttons']}>
          {isNumber(notificationCount) && notificationCount > 0 && (
            <FormattedNumber value={notificationCount} />
          )}
        </div>
      }
      active={active}
    >
      {label}
    </VerticalMenuItem>
  );
}

type Stats = GetMessagingFoldersStatsQuery['getMessagingFoldersStats'];
type Folders = GetMessagingFoldersQuery['getMessagingFolders'];

type SpecialFolderLabelProps = {
  icon: ReactNode;
  folderType: string;
  folders: Folders;
  foldersStats?: Stats;
  activeFolderKey?: string;
};

const DONT_SHOW_NOTIFICATION_COUNT: MessagingFolderType[] = [
  MessagingFolderType.Sent,
  MessagingFolderType.All,
];

export function SpecialFolderMenuItem({
  icon,
  folders,
  foldersStats,
  folderType,
  activeFolderKey,
}: SpecialFolderLabelProps) {
  return (
    <FolderMenuItemBase
      icon={icon}
      to={getMessageListRoute(folderType)}
      label={useFormattedMessage(
        `enums.FolderType.${folderType}` as TranslationId
      )}
      active={activeFolderKey === folderType}
      notificationCount={
        foldersStats &&
        !DONT_SHOW_NOTIFICATION_COUNT.includes(
          folderType as MessagingFolderType
        )
          ? sumBy(
              folders.filter(f => f.type === folderType),
              folder =>
                find(foldersStats, f => f.id === folder.id)
                  ?.notificationCount || 0
            )
          : undefined
      }
    />
  );
}
