function makeKey(key: string) {
  return `Vifzack/${key}`;
}

export const localStorageAdapter = {
  getItem: (key: string) => {
    if (!localStorage) {
      return null;
    }
    const val = localStorage.getItem(makeKey(key));
    try {
      return JSON.parse(val!);
    } catch (e) {
      return val;
    }
  },
  setItem: (key: string, val: any) => {
    if (!localStorage) {
      return;
    }
    try {
      localStorage.setItem(makeKey(key), JSON.stringify(val));
    } catch (e) {
      localStorage.setItem(makeKey(key), val);
    }
  },
  removeItem: (key: string) => {
    if (!localStorage) {
      return;
    }
    localStorage.removeItem(makeKey(key));
  },
};
