// https://github.com/jakubroztocil/rrule/issues/13#issuecomment-496997046
const rruleDe = {
  language: {
    dayNames: [
      'Sonntag',
      'Montag',
      'Dienstag',
      'Mittwoch',
      'Donnerstag',
      'Freitag',
      'Samstag',
    ],
    monthNames: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
  },
  strings: {
    every: 'jedes',
    until: 'bis',
    day: 'Tag',
    days: 'Tage',
    week: 'Woche',
    weeks: 'Wochen',
    on: 'ein',
    in: 'in',
    'on the': 'auf dem',
    for: 'für',
    and: 'und',
    or: 'oder',
    at: 'bei',
    last: 'zuletzt',
    '(~ approximate)': '(~ approximativ)',
    times: 'Zeiten',
    time: 'Zeit',
    minutes: 'Minuten',
    hours: 'Stunden',
    weekdays: 'Wochentage',
    weekday: 'Wochentag',
    months: 'Monate',
    month: 'Monat',
    years: 'Jahre',
    year: 'Jahr',
  },
};

export default rruleDe;
