import {
  CalendarOutlined,
  ContactsOutlined,
  FlagOutlined,
  LogoutOutlined,
  MailOutlined,
  MenuOutlined,
} from '@ant-design/icons';
import { Menu, Select, Space, Tooltip } from 'antd';
import { values } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import routes, { messagesFolderViewInboxRoute } from '../../../app/routes';
import { useResponsiveQueries } from '../../../common/utils/layoutUtils';
import { LogoSmall } from '../../../components/Logo';
import Version from '../../../components/Version';
import { CircleButton } from '../../../components/buttons';
import { Locale, getLocaleData } from '../../../config/intl/intl';
import { useAuthActions } from '../../auth/authReducer';
import { getLocale, useSettingsActions } from '../../settings/settingsReducer';
import { getSidebarCollapse, useLayoutActions } from '../layoutReducer';
import style from './DesktopHeader.module.less';

function appKeyFromPath(path) {
  return path.split('/')[1];
}

export function NavigationMenu(props) {
  const { pathname } = useLocation();
  const appKey = appKeyFromPath(pathname);
  return (
    <Menu
      mode="horizontal"
      selectedKeys={[appKey]}
      {...props}
      items={[
        {
          key: 'messages',
          icon: <MailOutlined />,
          label: (
            <Link to={messagesFolderViewInboxRoute}>
              <FormattedMessage id="navigation.messages" />
            </Link>
          ),
        },
        {
          key: 'calendar',
          icon: <CalendarOutlined />,
          label: (
            <Link to={routes.calendar}>
              <FormattedMessage id="navigation.calendar" />
            </Link>
          ),
        },
        {
          key: 'contacts',
          icon: <ContactsOutlined />,
          label: (
            <Link to={routes.contacts}>
              <FormattedMessage id="navigation.contacts" />
            </Link>
          ),
        },
      ]}
    />
  );
}

const LOCALE_OPTIONS = values(Locale).map(l => ({
  value: l,
  label: getLocaleData(l).label,
}));
function LocaleSelector() {
  const locale = useSelector(getLocale);
  const { setLocale } = useSettingsActions();

  return (
    <Select
      suffixIcon={<FlagOutlined />}
      options={LOCALE_OPTIONS}
      value={locale}
      onChange={val => setLocale(val)}
    />
  );
}

function ToolbarButton({ onClick, tooltip, icon }) {
  return (
    <Tooltip title={tooltip}>
      <CircleButton icon={icon} onClick={onClick} />
    </Tooltip>
  );
}

export function DesktopHeader() {
  const { logoutUser } = useAuthActions();

  const { md: isMediumScreen } = useResponsiveQueries();

  const sidebarCollapse = useSelector(getSidebarCollapse);

  const { setSidebarCollapse } = useLayoutActions();

  const toggleSidebarCollapse = () => {
    setSidebarCollapse(!sidebarCollapse);
  };

  return (
    <div className={style.Container}>
      {isMediumScreen && (
        <MenuOutlined
          onClick={() => toggleSidebarCollapse()}
          className={style.MenuIcon}
        />
      )}
      <div className={style.Logo}>
        <LogoSmall />
      </div>
      <div className={style.Menu}>
        <NavigationMenu />
      </div>
      <div className={style.Actions}>
        <Space direction="horizontal" size={30}>
          <LocaleSelector />
          <Version />
          <a
            href="https://instea.slab.com/posts/vifzack-user-docs-draft-dgr8xzlb#ho8ql-sending-whats-app-message"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage id="base.docs" />
          </a>
          <ToolbarButton
            tooltip={<FormattedMessage id="labels.logout" />}
            icon={<LogoutOutlined />}
            onClick={() => logoutUser()}
          />
        </Space>
      </div>
    </div>
  );
}
