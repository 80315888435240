import { CreateContactForm } from '../modules/contacts/contactEditing/CreateContactForm';
import { ContactsAppTemplate } from '../modules/contacts/layout/ContactsAppTemplate';

export default function CreateContactPage() {
  return (
    <ContactsAppTemplate>
      <CreateContactForm />
    </ContactsAppTemplate>
  );
}
