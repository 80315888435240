import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Divider, Input, Row, Space } from 'antd';
import { Form, Formik, FormikProps } from 'formik';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import RouteDefinitions from '../../app/routes';
import { formatError } from '../../common/utils/errorUtils';
import { showErrorMessage } from '../../common/utils/messageUtils';
import FormField from '../../components/forms/FormField';
import { GoogleIcon } from '../../components/icons';
import { useLocalizedYup } from '../../config/intl/LocaleProvider';
import { theme } from '../../styles/theme';
import { useAuthActions } from './authReducer';
import { useAuthService } from './authService';

export default function LoginForm() {
  const initialValues = {
    email: '',
    password: '',
    rememberMe: true,
  };

  const yup = useLocalizedYup();
  const { loginUser } = useAuthActions();
  const authService = useAuthService();

  const onSubmit = useCallback(
    async values => {
      try {
        const { session, user } = await authService.login(
          values.email,
          values.password
        );
        loginUser(user, session);
      } catch (e) {
        showErrorMessage(formatError(e));
      }
    },
    [authService, loginUser]
  );

  const validationSchema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().required(),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      component={LoginFormInner}
    />
  );
}

interface PropsLoginFormInner extends FormikProps<any> {}

function LoginFormInner(props: PropsLoginFormInner) {
  return (
    <Form>
      <Space direction="vertical" size={25}>
        <FormField name="email" placeholderId="initialAuth.placeholder.email">
          {({ field }) => (
            <Input
              {...field}
              size="large"
              prefix={
                <UserOutlined style={{ color: theme['@primary-color'] }} />
              }
            />
          )}
        </FormField>

        <FormField
          name="password"
          placeholderId="initialAuth.placeholder.password"
        >
          {({ field }) => (
            <Input
              {...field}
              type="password"
              size="large"
              prefix={
                <LockOutlined style={{ color: theme['@primary-color'] }} />
              }
            />
          )}
        </FormField>

        <Row align="middle" justify="space-between">
          <Col>
            <FormField
              type="checkbox"
              name="rememberMe"
              labelId="initialAuth.label.rememberMe"
            >
              {({ field: { value, ...checkboxField }, label }) => (
                <Checkbox {...checkboxField} checked={value} disabled>
                  {label}
                </Checkbox>
              )}
            </FormField>
          </Col>

          <Col>
            <Link to={RouteDefinitions.forgotPassword}>
              <FormattedMessage id="initialAuth.label.forgotPassword" />
            </Link>
          </Col>
        </Row>

        <Button
          className="FullWidth"
          type="primary"
          size="large"
          htmlType="submit"
          disabled={props.isSubmitting}
          loading={props.isSubmitting}
        >
          <FormattedMessage id="initialAuth.button.login" />
        </Button>

        <Divider type="horizontal" className="ant-divider--NoSpace">
          <div className="Circle-28 Text--UpperCase">
            <FormattedMessage id="divider.or" />
          </div>
        </Divider>
        <Button
          className="FullWidth"
          icon={<GoogleIcon />}
          type="primary"
          size="large"
          disabled
        >
          <span>
            <FormattedMessage id="initialAuth.button.login.google" />
          </span>
        </Button>
      </Space>
    </Form>
  );
}
