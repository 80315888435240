import { PoweroffOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { isNil } from 'lodash-es';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { TranslationId } from '../../../types/appTypes';

type ThreeValueButtonQuickFilterProps = {
  value?: boolean;
  onChange: (val?: boolean) => void;
  offTooltipId?: TranslationId;
  falseIcon: ReactNode;
  trueIcon: ReactNode;
  falseTooltipId: TranslationId;
  trueTooltipId: TranslationId;
};

export function ThreeValueButtonQuickFilter({
  value,
  onChange,
  offTooltipId = 'toolbar.quickFilter.off',
  falseIcon,
  falseTooltipId,
  trueIcon,
  trueTooltipId,
}: ThreeValueButtonQuickFilterProps) {
  return (
    <Button.Group size="small">
      <Tooltip title={<FormattedMessage id={offTooltipId} />}>
        <Button
          icon={<PoweroffOutlined />}
          type={isNil(value) ? 'primary' : 'default'}
          onClick={() => onChange(undefined)}
        />
      </Tooltip>
      <Tooltip title={<FormattedMessage id={falseTooltipId} />}>
        <Button
          icon={falseIcon}
          type={value === false ? 'primary' : 'default'}
          onClick={() => onChange(false)}
        />
      </Tooltip>
      <Tooltip title={<FormattedMessage id={trueTooltipId} />}>
        <Button
          icon={trueIcon}
          type={value === true ? 'primary' : 'default'}
          onClick={() => onChange(true)}
        />
      </Tooltip>
    </Button.Group>
  );
}
