import { ArrowRightOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { CircleButton } from '../../../components/buttons';
import { ReplyAllIcon, ReplyIcon } from '../../../components/icons';
import { MessageComposerReactionMessageType } from '../sendMessage/messageComposer/messageComposerTypes';
import styles from './ActionButtons.module.less';

type ActionButtonsProps = {
  withCaptions: boolean;
  setInlineComposerOpen: (mode: MessageComposerReactionMessageType) => void;
  enableReply?: boolean;
  enableReplyAll?: boolean;
  enableForward?: boolean;
  compact?: boolean;
};

export function ActionButtons({
  withCaptions,
  setInlineComposerOpen,
  enableReply = true,
  enableReplyAll = true,
  enableForward = true,
  compact,
}: ActionButtonsProps) {
  const ButtonComponent = withCaptions ? Button : CircleButton;
  return (
    <Space
      direction="horizontal"
      className={classNames(
        'Print--Hide',
        styles.Container,
        compact && styles['Container--Compact']
      )}
    >
      {enableReply && (
        <ButtonComponent
          icon={<ReplyIcon />}
          onClick={() => setInlineComposerOpen('reply')}
        >
          {withCaptions && (
            <span>
              <FormattedMessage id="threadDetail.action.reply" />
            </span>
          )}
        </ButtonComponent>
      )}
      {enableReplyAll && (
        <ButtonComponent
          icon={<ReplyAllIcon />}
          onClick={() => setInlineComposerOpen('replyAll')}
          className={styles['Button--Blue']}
        >
          {withCaptions && (
            <span>
              <FormattedMessage id="threadDetail.action.replyAll" />
            </span>
          )}
        </ButtonComponent>
      )}
      {enableForward && (
        <ButtonComponent
          icon={<ArrowRightOutlined />}
          onClick={() => setInlineComposerOpen('forward')}
        >
          {withCaptions && (
            <span>
              <FormattedMessage id="threadDetail.action.forward" />
            </span>
          )}
        </ButtonComponent>
      )}
    </Space>
  );
}
