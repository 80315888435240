import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { Set } from 'immutable';
import { isEmpty } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import { CircleButton } from '../../components/buttons';
import { MessagingThreadsFilterInput } from '../../types/graphqlGenerated';
import {
  useSetThreadsReadUnreadMutation,
  useThreadSimpleQuery,
} from './threadsGraphql';

type ReadUnReadButtonBaseProps = {
  unreadTargetValue: boolean;
  disabled: boolean;
  loading: boolean;
  onClick: () => void;
};

function ReadUnReadButtonBase({
  unreadTargetValue,
  disabled,
  loading,
  onClick,
}: ReadUnReadButtonBaseProps) {
  return (
    <CircleButton
      icon={
        unreadTargetValue ? (
          <Tooltip title={<FormattedMessage id="toolbar.markNotRead" />}>
            <EyeInvisibleOutlined />
          </Tooltip>
        ) : (
          <Tooltip title={<FormattedMessage id="toolbar.markRead" />}>
            <EyeOutlined />
          </Tooltip>
        )
      }
      disabled={disabled}
      loading={loading}
      onClick={onClick}
    />
  );
}

export type ReadUnReadBulkButtonProps = {
  threadIds: number[];
  allSelected: boolean;
  allSelectedRead: boolean;
  setSelectedKeys: (val: Set<number>) => void;
  setAllSelected: (val: boolean) => void;
  filter?: MessagingThreadsFilterInput;
};

export function ReadUnReadBulkButton({
  threadIds,
  allSelected,
  allSelectedRead,
  filter = {},
  setSelectedKeys,
  setAllSelected,
}: ReadUnReadBulkButtonProps) {
  const [setThreadsReadUnread, { loading }] = useSetThreadsReadUnreadMutation();
  const disabled = loading || (isEmpty(threadIds) && !allSelected);

  const targetValue = allSelected ? false : allSelectedRead;

  return (
    <ReadUnReadButtonBase
      unreadTargetValue={targetValue && !disabled}
      disabled={disabled}
      loading={loading}
      onClick={async () => {
        await setThreadsReadUnread({
          filter: allSelected ? filter : { id: { in: threadIds } },
          unread: targetValue,
        });
        setAllSelected(false);
        setSelectedKeys(Set());
      }}
    />
  );
}

export type ReadUnReadSingleButtonProps = {
  threadId: number;
};

export function ReadUnReadSingleButton({
  threadId,
}: ReadUnReadSingleButtonProps) {
  const [setThreadsReadUnread, { loading: setReadUnreadLoading }] =
    useSetThreadsReadUnreadMutation();

  const { thread } = useThreadSimpleQuery({ id: threadId });

  return (
    <ReadUnReadButtonBase
      unreadTargetValue={!thread?.unread}
      disabled={setReadUnreadLoading}
      loading={setReadUnreadLoading}
      onClick={async () => {
        await setThreadsReadUnread({
          filter: { id: { eq: threadId } },
          unread: !thread?.unread,
        });
      }}
    />
  );
}
