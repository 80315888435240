export enum WhatsappMediaAcceptedFileTypes {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  PLAIN_TEXT = 'text/plain',
  PDF = 'application/pdf',
  MS_POWERPOINT = 'application/vnd.ms-powerpoint',
  MS_WORD = 'application/msword',
  MS_EXCEL = 'application/vnd.ms-excel',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  PPTX = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  AAC = 'audio/aac',
  MP4_AUDIO = 'audio/mp4',
  MPEG_AUDIO = 'audio/mpeg',
  AMR = 'audio/amr',
  OGG_AUDIO = 'audio/ogg',
  OPUS_AUDIO = 'audio/opus',
  MP4_VIDEO = 'video/mp4',
  _3GP_VIDEO = 'video/3gp',
  WEBP = 'image/webp',
}
