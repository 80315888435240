import { MailOutlined, SendOutlined } from '@ant-design/icons';

import { MessagingFolderType } from '../../../types/graphqlGenerated';

const FIXED_FOLDER_TYPE_PROPS = {
  [MessagingFolderType.Inbox]: {
    style: { color: '#DE5145', opacity: 85 },
  },
  [MessagingFolderType.All]: {
    style: { color: '#30BF39' },
  },
  [MessagingFolderType.Important]: {
    style: { color: '#1890FF', opacity: 85 },
  },
  [MessagingFolderType.Sent]: {
    Icon: SendOutlined,
  },
};

export function getFolderIcon(type: MessagingFolderType) {
  const props = FIXED_FOLDER_TYPE_PROPS[type] as any;
  const Icon = props?.Icon || MailOutlined;
  const style = props?.style;
  return <Icon style={style} />;
}

export const SPECIAL_FOLDERS: MessagingFolderType[] = [
  MessagingFolderType.Inbox,
  MessagingFolderType.All,
  MessagingFolderType.Sent,
  MessagingFolderType.Drafts,
  MessagingFolderType.Important,
  MessagingFolderType.Archive,
  MessagingFolderType.Trash,
  MessagingFolderType.Spam,
];
