import { useApolloClient } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import { messagesFolderViewInboxRoute } from '../app/routes';
import { useMountEffect } from '../common/utils/hookUtils';
import { PrimaryButton } from '../components/buttons';
import { SuccessIconLarge } from '../components/icons';
import { ConnectAccountButton } from '../modules/accounts/ConnectAccountButton';
import {
  ConnectResult,
  ConnectResultText,
} from '../modules/accounts/connectAccount/ConnectResult';
import { MessagesAppTemplate } from '../modules/messagesApp/layout/MessagesAppTemplate';
import { TranslationId } from '../types/appTypes';

export function ConnectSuccessPage({
  infoTextId,
  infoTextValues,
  showLinkAnotherAccount = false,
}: {
  infoTextId: TranslationId;
  infoTextValues?: any;
  showLinkAnotherAccount?: boolean;
}) {
  const client = useApolloClient();
  const navigate = useNavigate();
  useMountEffect(() => {
    client.refetchQueries({
      include: ['GetMessagingAccounts'],
    });
  });

  return (
    <MessagesAppTemplate>
      <ConnectResult>
        <SuccessIconLarge />
        <ConnectResultText
          titleId="connectAccount.successMessage"
          infoId={infoTextId}
          values={infoTextValues}
        />
        <PrimaryButton
          onClick={() => navigate(messagesFolderViewInboxRoute)}
          labelId="connectAccount.button.goToMessageList"
        />
        {showLinkAnotherAccount && (
          <ConnectAccountButton
            labelId="connectAccount.button.connectAnother"
            type="link"
          />
        )}
      </ConnectResult>
    </MessagesAppTemplate>
  );
}
