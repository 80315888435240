import { ArrowRightOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { some } from 'lodash-es';
import { FormattedMessage } from 'react-intl';

import { DateLabel } from '../../../components/DateLabel';
import {
  MessagingMessageFullFragmentFragment,
  MessagingThreadDetailFragmentFragment,
} from '../../../types/graphqlGenerated';
import { ParticipantsLabel } from '../../contacts/participantFormats';
import { MessageComposerReactionMessageType } from '../sendMessage/messageComposer/messageComposerTypes';
import { ActionButtons } from './ActionButtons';
import styles from './ChatMessageItem.module.less';
import { MessageStatus } from './MessageStatus';
import { TextMessageBody } from './TextMessageBody';

type Thread = MessagingThreadDetailFragmentFragment;
type Message = MessagingMessageFullFragmentFragment;

type ChatMessageItemProps = {
  thread: Thread;
  message: Message;
  inlineComposerOpen?: MessageComposerReactionMessageType;
  setInlineComposerOpen: (mode: MessageComposerReactionMessageType) => void;
};

export function ChatMessageItem({
  thread,
  message,
  setInlineComposerOpen,
}: ChatMessageItemProps) {
  const { messagingAccount } = thread;
  const { from, date: rawDate, body, files, isForwarded } = message;
  const date = rawDate && new Date(rawDate);
  const isFromMe = some(from, ({ ref }) => ref === messagingAccount.platformId);

  const shouldShowMeta = isForwarded;

  return (
    <div
      className={classNames(
        styles.Container,
        isFromMe ? styles['Container--FromMe'] : styles['Container--FromOther']
      )}
    >
      <div className={styles.Header}>
        {shouldShowMeta && (
          <div className={styles.MetaInfo}>
            {isForwarded && (
              <div className="FlexRow FlexRow--SpaceSm">
                <ArrowRightOutlined />
                <FormattedMessage id="message.forwarded" />
              </div>
            )}
          </div>
        )}
        <div className={styles.FromLabel}>
          <ParticipantsLabel
            participants={from}
            me={messagingAccount.platformId || ''}
          />
        </div>
      </div>
      <div className={styles.ActionButtons}>
        <ActionButtons
          setInlineComposerOpen={setInlineComposerOpen}
          withCaptions={false}
          enableReplyAll={false}
          enableReply={!isFromMe}
          compact
        />
      </div>
      <TextMessageBody files={files} body={body || ''} message={message} />
      <div className={styles.MessageStatusContainer}>
        {date && (
          <div className={styles.DateLabel}>
            <DateLabel date={date} />
          </div>
        )}
        <div>
          <MessageStatus message={message} />
        </div>
      </div>
    </div>
  );
}
