import { BgColorsOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Space } from 'antd';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import FormField from '../../../components/forms/FormField';
import { ColorPicker } from '../../../components/forms/colors';
import { MessagingAccount } from '../../../types/graphqlGenerated';
import { DynamicMenuItem } from '../../common/dynamicMenu/dynamicMenuTypes';
import { useUpdateAccountMutation } from '../accountsGraphql';

type ColorChangeFormProps = {
  account: MessagingAccount;
  close: () => void;
};

function ColorChangeForm({ account, close }: ColorChangeFormProps) {
  const [updateAccount, { loading }] = useUpdateAccountMutation();
  return (
    <Formik
      initialValues={{ color: account.color }}
      onSubmit={async values => {
        await updateAccount({ id: account.id, input: { color: values.color } });
        close();
      }}
    >
      <Form>
        <Space direction="vertical" size={10}>
          <FormField name="color">
            {({ field }) => <ColorPicker {...field} />}
          </FormField>
          <Row align="middle" justify="end" gutter={10}>
            <Col>
              <Button onClick={close} disabled={loading}>
                <FormattedMessage id="connectedAccounts.form.cancel" />
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                disabled={loading}
                loading={loading}
              >
                <FormattedMessage id="connectedAccounts.form.save" />
              </Button>
            </Col>
          </Row>
        </Space>
      </Form>
    </Formik>
  );
}

export function useColorChangeAccountMenuItem({
  account,
}: {
  account: MessagingAccount;
}): DynamicMenuItem {
  const [modalOpen, setModalOpen] = useState(false);

  return {
    key: 'colorChange',
    visible: true,
    icon: <BgColorsOutlined style={{ color: account.color }} />,
    label: <FormattedMessage id="connectedAccounts.changeColor" />,
    onClick: () => setModalOpen(true),
    additionalElements: (
      <Modal
        visible={modalOpen}
        onCancel={() => setModalOpen(false)}
        width="280px"
        destroyOnClose
        footer={null}
      >
        <ColorChangeForm account={account} close={() => setModalOpen(false)} />
      </Modal>
    ),
  };
}
