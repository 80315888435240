import { gql } from '@apollo/client';

import {
  createUseMutation,
  createUseQuery,
} from '../../common/utils/graphqlUtils';
import {
  CreateMessagingContactMutation,
  CreateMessagingContactMutationVariables,
  DeleteMessagingContactMutation,
  DeleteMessagingContactMutationVariables,
  GetMessagingContactQuery,
  GetMessagingContactQueryVariables,
  GetMessagingContactsQuery,
  GetMessagingContactsQueryVariables,
  UpdateMessagingContactMutation,
  UpdateMessagingContactMutationVariables,
} from '../../types/graphqlGenerated';
import { AccountFullFragment } from '../accounts/accountsGraphql';

export const ContactFullFragment = gql`
  ${AccountFullFragment}
  fragment MessagingContactFullFragment on MessagingContact {
    id
    givenName
    middleName
    surname
    pictureUrl
    emails
    phoneNumbers
    whatsappAccounts {
      id
      phoneNumber
    }
    messagingAccount {
      ...MessagingAccountFullFragment
    }
  }
`;

const CONTACTS_QUERY = gql`
  ${ContactFullFragment}
  query GetMessagingContacts(
    $filter: MessagingContactsFilterInput
    $pagination: PaginationInput
  ) {
    getMessagingContacts(filter: $filter, pagination: $pagination) {
      pagination {
        index
        size
        count
      }
      data {
        ...MessagingContactFullFragment
      }
    }
  }
`;

const useContactsQueryInner = createUseQuery<
  GetMessagingContactsQuery,
  GetMessagingContactsQueryVariables,
  'data',
  GetMessagingContactsQuery['getMessagingContacts']
>(CONTACTS_QUERY, {
  extractResult: resp => resp.getMessagingContacts,
  resultName: 'data',
  opts: {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  },
});
type UseContactsQueryArgs = Parameters<typeof useContactsQueryInner>;

export function useContactsQuery(
  variables?: UseContactsQueryArgs[0],
  opts?: UseContactsQueryArgs[1]
) {
  const { data, loading, error } = useContactsQueryInner(variables, opts);
  return {
    contacts: data?.data,
    pagination: data?.pagination,
    loading,
    error,
  };
}

const CONTACT_QUERY = gql`
  ${ContactFullFragment}
  query GetMessagingContact($id: Int!) {
    getMessagingContact(id: $id) {
      ...MessagingContactFullFragment
    }
  }
`;

export const useContactQuery = createUseQuery<
  GetMessagingContactQuery,
  GetMessagingContactQueryVariables,
  'contact',
  GetMessagingContactQuery['getMessagingContact']
>(CONTACT_QUERY, {
  extractResult: resp => resp.getMessagingContact,
  resultName: 'contact',
});

const CREATE_CONTACT_MUTATION = gql`
  ${ContactFullFragment}
  mutation CreateMessagingContact($input: MessagingContactInput!) {
    createMessagingContact(input: $input) {
      ...MessagingContactFullFragment
    }
  }
`;

export const useCreateContactMutation = createUseMutation<
  CreateMessagingContactMutation,
  CreateMessagingContactMutationVariables,
  CreateMessagingContactMutation['createMessagingContact']
>(CREATE_CONTACT_MUTATION, {
  extractResult: resp => resp.createMessagingContact,
  refetchQueries: ['GetMessagingContacts'],
});

const UPDATE_CONTACT_MUTATION = gql`
  ${ContactFullFragment}
  mutation UpdateMessagingContact($id: Int!, $input: MessagingContactInput!) {
    updateMessagingContact(id: $id, input: $input) {
      ...MessagingContactFullFragment
    }
  }
`;

export const useUpdateContactMutation = createUseMutation<
  UpdateMessagingContactMutation,
  UpdateMessagingContactMutationVariables,
  UpdateMessagingContactMutation['updateMessagingContact']
>(UPDATE_CONTACT_MUTATION, {
  extractResult: resp => resp.updateMessagingContact,
  refetchQueries: ['GetMessagingContacts'],
});

const DELETE_CONTACT_MUTATION = gql`
  mutation DeleteMessagingContact($id: Int!) {
    deleteMessagingContact(id: $id)
  }
`;

export const useDeleteContactMutation = createUseMutation<
  DeleteMessagingContactMutation,
  DeleteMessagingContactMutationVariables,
  DeleteMessagingContactMutation['deleteMessagingContact']
>(DELETE_CONTACT_MUTATION, {
  extractResult: resp => resp.deleteMessagingContact,
  refetchQueries: ['GetMessagingContacts'],
});
