import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { fromUnixTs } from 'vifzack-common-utils';

import { useMountEffect } from '../../common/utils/hookUtils';
import { remoteError, remoteLog } from '../../common/utils/logUtils';
import { showErrorMessage } from '../../common/utils/messageUtils';
import { getLoggedIn, getUserSession, useAuthActions } from './authReducer';
import { useAuthService } from './authService';

function useLoadSessionOnInit(): void {
  const { loadSessionWrapper, logoutUser } = useAuthActions();
  const authService = useAuthService();

  useMountEffect(() => {
    const execute = async () => {
      await loadSessionWrapper(async () => {
        const user = authService.getCurrentUser();
        // This uses refresh token if needed
        const session = await authService.getUserSession(user);

        return (user && session && { user, session }) ?? undefined;
      });

      {
        // Check remotely if still valid
        const user = authService.getCurrentUser();
        if (user) {
          try {
            await authService.getUserSession(user);
            await authService.getUserData(user);
          } catch (e) {
            console.error(
              'Error during initial session loading -> logging out',
              e
            );
            remoteLog(
              'useLoadSessionOnInit: initial session load error',
              {
                errorMessage: e?.message,
              },
              'warning'
            );
            showErrorMessage(<FormattedMessage id="auth.autoLogout" />);
            logoutUser();
          }
        }
      }
    };
    execute();
  });
}

function useLoginStatusLogger() {
  const loggedIn = useSelector(getLoggedIn);
  const userSession = useSelector(getUserSession);

  useEffect(() => {
    if (!loggedIn) {
      remoteError('useLoginStatusLogger: logout', 'info');
    }
  }, [loggedIn]);

  useEffect(() => {
    if (userSession) {
      remoteLog(
        'useLoginStatusLogger: session info',
        {
          isValid: userSession?.isValid(),
          accessTokenExpiration: fromUnixTs(
            userSession?.getAccessToken().getExpiration()
          )?.toISOString(),
          idTokenExpiration: fromUnixTs(
            userSession?.getIdToken().getExpiration()
          )?.toISOString(),
        },
        'info'
      );
    }
  }, [userSession]);
}

export default function AuthInitializer() {
  useLoadSessionOnInit();
  useLoginStatusLogger();

  return null;
}
