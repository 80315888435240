import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';

import RouteDefinitions from '../app/routes';
import { MessagesAppTemplate } from '../modules/messagesApp/layout/MessagesAppTemplate';
import { useMessagesAppViewMode } from '../modules/messagesApp/messagesAppViewMode';
import MessageComposer from '../modules/messagesApp/sendMessage/MessageComposer';
import { MessagingFolderType } from '../types/graphqlGenerated';

export default function SendMessagePage() {
  const navigate = useNavigate();
  const { state }: { state: any } = useLocation();
  const { setFolderMode } = useMessagesAppViewMode();

  const onMessageSendStart = useCallback(() => {
    setFolderMode(MessagingFolderType.Sent);
  }, [setFolderMode]);

  const restoreEditState = values => {
    navigate(RouteDefinitions.sendMessage, {
      state: { initialValues: values },
    });
  };

  return (
    <MessagesAppTemplate>
      <MessageComposer
        onSendStart={onMessageSendStart}
        restoreEditState={restoreEditState}
        initialValues={state?.initialValues}
      />
    </MessagesAppTemplate>
  );
}
