import { Spin } from 'antd';
import { FormattedMessage } from 'react-intl';

import { TranslationId } from '../types/appTypes';

interface Props {
  title?: string;
  titleId?: TranslationId;
}

function LoadingPage(props: Props) {
  return (
    <div className="FullSize Flex Flex--Center">
      <Spin
        size="large"
        tip={
          props.titleId ? <FormattedMessage id={props.titleId} /> : props.title
        }
      />
    </div>
  );
}

export default LoadingPage;
