import {
  CloseCircleOutlined,
  FileImageOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { Form as AntForm, Button, Col, Row, Space, Spin, Tooltip } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import classNames from 'classnames';
import { Form, Formik, useField, useFormikContext } from 'formik';
import { isEmpty, isNumber, last, some, startsWith } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import Textarea from 'react-autosize-textarea';
import { FormattedMessage, useIntl } from 'react-intl';
import { ObjectSchema } from 'yup';

import { formatError } from '../../../../../common/utils/errorUtils';
import { useMountAndUpdateEffect } from '../../../../../common/utils/hookUtils';
import { showErrorMessage } from '../../../../../common/utils/messageUtils';
import { FileIcon } from '../../../../../components/AttachmentButton';
import { ErrorAlert } from '../../../../../components/ErrorAlert';
import OptionalTooltip from '../../../../../components/OptionalTooltip';
import { EmojiPicker } from '../../../../../components/forms/EmojiPicker';
import {
  FileUploadButton,
  FileUploadFile,
  UploadFunction as FileUploadUploadFunction,
} from '../../../../../components/forms/FileUpload';
import FormField from '../../../../../components/forms/FormField';
import { BasicSwitch } from '../../../../../components/forms/booleanFields';
import { WhatsappParticipantSelect } from '../../../../../components/forms/fetchingSelects';
import { SendIcon } from '../../../../../components/icons';
import { useLocalizedYup } from '../../../../../config/intl/LocaleProvider';
import { MessagingAccount } from '../../../../../types/graphqlGenerated';
import { useBackgroundOperation } from '../../../../common/backgroundOperations/backgroundOperations';
import {
  useCreateFileDownloadLinkMutation,
  useMessagingFileUpload,
} from '../../../../files/filesGraphql';
import { WhatsappMediaAcceptedFileTypes } from '../../../constants/whatsapp-supporeted-media';
import {
  useCanSendMessageIn24HoursWindowQuery,
  useSendMessages,
} from '../../../messagesGraphql';
import { TextMessageBody } from '../../../threadDetail/TextMessageBody';
import { MessageFullFragment } from '../../../threadsMessagesGraphql';
import { MessageComposerReaction } from '../messageComposerTypes';
import style from './MessageComposerWhatsappSubform.module.less';
import { Template } from './templates/Template';
import {
  WhatsappMessageComposerFormValues,
  useWhatsappMessageComposerInitialValues,
  whatsappFormToGraphQlInput,
} from './whatsappSubformUtils';

const MAX_FILE_SIZE = 1024 * 1024 * 64;
function ToField({ account }) {
  return (
    <FormField name="to" labelId="sendMessage.to">
      {({ field, label }) => (
        <Row>
          <Col span={2}>
            <AntForm.Item
              label={label}
              className={style['FormItem--Compact']}
              labelCol={{ span: 24 }}
            >
              {/* Empty Form.Item for label only */}
            </AntForm.Item>
          </Col>
          <Col span={22}>
            <AntForm.Item
              className={style['FormItem--Compact']}
              wrapperCol={{ span: 24 }}
            >
              <WhatsappParticipantSelect
                {...field}
                tokenSeparators={[', ']}
                filterOverride={{ messagingAccount: { idEq: account.id } }}
              />
            </AntForm.Item>
          </Col>
        </Row>
      )}
    </FormField>
  );
}

function QuoteMessageField() {
  return (
    <FormField name="quoteMessage" labelId="sendMessage.quoteMessage">
      {({ field, label }) => (
        <AntForm.Item label={label} className={style['FormItem--Compact']}>
          <BasicSwitch {...field}>{label}</BasicSwitch>
        </AntForm.Item>
      )}
    </FormField>
  );
}

function useUploadFile({ account }) {
  const [fileLinkMutate] = useCreateFileDownloadLinkMutation();
  const { executeUpload } = useMessagingFileUpload();
  return useCallback<FileUploadUploadFunction>(
    async file => {
      try {
        const { result } = executeUpload(
          file.originFileObj!,
          {
            messagingAccountId: account.id,
            contentType: file.type!,
            filename: file.name,
            size: file.size!,
          },
          MAX_FILE_SIZE
        );
        const { id, contentId } = await result;
        const url = await fileLinkMutate({ id, triggerDownload: false });
        const src = url!;
        return { id, contentId: contentId!, src };
      } catch (e) {
        showErrorMessage(formatError(e));
        throw e;
      }
    },
    [account.id, executeUpload, fileLinkMutate]
  );
}

function FilePreviewInner({ file }: { file: FileUploadFile }) {
  const fileClass = file.type?.split('/')[0];

  if (file.error) {
    return <ErrorAlert error={file.error} />;
  }
  if (file.status !== 'done') {
    return (
      <Spin>
        <FormattedMessage id="labels.uploadingFile" />
      </Spin>
    );
  }
  const url = file.response?.src;
  if (!url) {
    return null;
  }

  if (fileClass === 'image') {
    return <img src={url} alt={file.name} />;
  }
  if (fileClass === 'video') {
    return (
      <video controls>
        <source src={url} type={file.type!} />
        <FormattedMessage id="video.notSupported" />
      </video>
    );
  }
  if (fileClass === 'audio') {
    return (
      <audio controls>
        <source src={url} type={file.type!} />
        <FormattedMessage id="audio.notSupported" />
      </audio>
    );
  }
  return (
    <div className="FlexRow FlexRow--SpaceSm">
      <FileIcon contentType={file.type} />
      <span>{file.name}</span>
    </div>
  );
}

function useRemoveMessage({ index }) {
  const { setFieldValue, values } =
    useFormikContext<WhatsappMessageComposerFormValues>();

  return () => {
    const withoutThis = values.messages?.filter((_data, i) => i !== index);
    setFieldValue(
      'messages',
      isEmpty(withoutThis) ? [{ body: '' }] : withoutThis
    );
  };
}

function useRemoveFile({ file }) {
  const { setFieldValue, values } =
    useFormikContext<WhatsappMessageComposerFormValues>();

  return () => {
    const withoutThis = values.files?.filter(f => f.uid !== file.uid);
    setFieldValue('files', isEmpty(withoutThis) ? [] : withoutThis);
  };
}

function validateFile(file: FileUploadFile) {
  return (
    file &&
    Object.values(WhatsappMediaAcceptedFileTypes).includes(
      file?.type as WhatsappMediaAcceptedFileTypes
    ) &&
    (file?.size ?? 0) <= MAX_FILE_SIZE
  );
}

function FilePreview({ index }) {
  const removeMessage = useRemoveMessage({ index });
  const { values } = useFormikContext<WhatsappMessageComposerFormValues>();
  const file = values.messages?.[index]?.file;

  const removeFile = useRemoveFile({ file });

  useEffect(() => {
    if (file && !validateFile(file)) {
      removeMessage();
      removeFile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return file ? (
    <div className={style.FilePreview}>
      <FilePreviewInner file={file} />
      <Button
        icon={<CloseCircleOutlined />}
        type="text"
        size="middle"
        onClick={e => {
          removeMessage();
          e.stopPropagation();
        }}
        className={style['FilePreview__Close']}
      />
    </div>
  ) : null;
}

const isCaptionAllowed = (file?: FileUploadFile) => {
  const fileMime = file?.type;
  return (
    !fileMime || startsWith(fileMime, 'image') || startsWith(fileMime, 'video')
  );
};

function BodyField({ index }) {
  const removeMessage = useRemoveMessage({ index });
  const { values, setFieldValue } =
    useFormikContext<WhatsappMessageComposerFormValues>();
  const name = `messages.${index}.body`;

  return (
    <FormField name={name} placeholderId="sendMessage.body">
      {({ field }) =>
        isCaptionAllowed(values.messages?.[index]?.file) && (
          <div className={style.EditorWrapper}>
            <Textarea
              className={classNames(style.Body, 'ant-input')}
              {...field}
              rows={3}
              autoFocus
            />
            <EmojiPicker
              onSelect={info => setFieldValue(name, field.value + info.emoji)}
            />
            <Button
              icon={<CloseCircleOutlined />}
              type="text"
              size="middle"
              onClick={e => {
                removeMessage();
                e.stopPropagation();
              }}
            />
          </div>
        )
      }
    </FormField>
  );
}

function BodyAndFileField({ index }) {
  return (
    <div className={style.BodyAndFile}>
      <FilePreview index={index} />
      <BodyField index={index} />
    </div>
  );
}

function FilesSynchronizer({ value }: { value?: FileUploadFile[] }) {
  const { values, setFieldValue } =
    useFormikContext<WhatsappMessageComposerFormValues>();
  useMountAndUpdateEffect(
    {
      onUpdate: () => {
        let messages = values.messages || [];
        for (const file of value || []) {
          const message = messages.find(msg => msg.file?.uid === file.uid);
          // Update
          if (message) {
            messages = messages.map(msg =>
              msg.file?.uid === file.uid ? { ...msg, file } : msg
            );
          }
          // Add
          else {
            // Replace last if there is no file and no text would be lost
            const lastMsg = last(messages);
            const shouldReplace =
              lastMsg &&
              !lastMsg.file &&
              (isEmpty(lastMsg.body) || isCaptionAllowed(file));
            if (shouldReplace) {
              messages = [...messages.slice(0, -1), { ...lastMsg!, file }];
            } else {
              messages = [...messages, { body: '', file }];
            }
          }
        }
        setFieldValue('messages', messages);
        if (some(value, file => file.status === 'done')) {
          setFieldValue(
            'files',
            value!.filter(file => file.status !== 'done')
          );
        }
      },
    },
    [value]
  );

  return null;
}

function AddFilesButton({ account, sending }) {
  const uploadFile = useUploadFile({ account });
  return (
    <FormField name="files">
      {({ field }) => (
        <FileUploadButton
          {...field}
          multiple
          maxSize={1024 * 1024 * 64}
          accept={Object.values(WhatsappMediaAcceptedFileTypes).join(',')}
          upload={uploadFile}
          disabled={sending}
        >
          <FilesSynchronizer value={field.value} />
          <Tooltip title={<FormattedMessage id="sendMessage.addAttachment" />}>
            <Button icon={<FileImageOutlined />} size="large" type="text" />
          </Tooltip>
        </FileUploadButton>
      )}
    </FormField>
  );
}

function AddTextButton() {
  const { values, setFieldValue } =
    useFormikContext<WhatsappMessageComposerFormValues>();

  return (
    <Tooltip title={<FormattedMessage id="sendMessage.addText" />}>
      <Button
        icon={<PlusCircleOutlined />}
        size="large"
        type="text"
        onClick={() =>
          setFieldValue('messages', [...(values.messages || []), { body: '' }])
        }
      />
    </Tooltip>
  );
}

function SendButton({ sending }) {
  return (
    <FormField name="files">
      {({ field }) => {
        const files = field.value as UploadFile[];
        const isUploading = some(files, f => f.status === 'uploading');
        return (
          <OptionalTooltip
            titleId="sendMessage.sendDisabled.uploading"
            enabled={isUploading}
            color="red"
          >
            <Button
              icon={<SendIcon />}
              size="large"
              type="text"
              htmlType="submit"
              loading={sending}
              disabled={sending || isUploading}
            />
          </OptionalTooltip>
        );
      }}
    </FormField>
  );
}

function ForwardPreviewContent({ id }) {
  const client = useApolloClient();
  const message = client.readFragment({
    id: `MessagingMessage:${id}`,
    fragment: MessageFullFragment,
    fragmentName: 'MessagingMessageFullFragment',
  });

  return (
    message && (
      <TextMessageBody body={message.body || ''} files={message.files} />
    )
  );
}

function FormContent({ sending, account, isReply, forwardId }) {
  const [templateName] = useField('template.nameAndLanguage');
  const [to] = useField('to');
  const [canSendMessageIn24HoursWindow, setCanSendMessageIn24HoursWindow] =
    useState<boolean | undefined>(undefined);
  const intl = useIntl();

  const { refetch, loading } = useCanSendMessageIn24HoursWindowQuery({
    input: {
      to: '1',
      accountId: account.id,
    },
  });

  const isForward = isNumber(forwardId);
  const canSendFreeTextMessage =
    to.value && !loading && canSendMessageIn24HoursWindow !== undefined
      ? canSendMessageIn24HoursWindow
      : true;
  const isTemplateMessage = Boolean(templateName.value);
  const showTemplateField = !isReply && !isForward;
  const showForwardContent = !isTemplateMessage && isForward;
  const showMessageBody = !isTemplateMessage && !isForward;
  const showActionButtons = !isForward && !isTemplateMessage;

  useEffect(() => {
    if (to.value) {
      const verifyCanSendMessage = async () => {
        const canSendMessage = await refetch({
          input: {
            to: to.value,
            accountId: account.id,
          },
        });
        setCanSendMessageIn24HoursWindow(canSendMessage);
      };
      verifyCanSendMessage();
    }
  }, [to.value, account, refetch]);

  return (
    <Space direction="vertical" size={5}>
      {isReply && <QuoteMessageField />}
      {!isReply && <ToField account={account} />}
      {!canSendFreeTextMessage && (
        <div className={style.BlueText}>
          {intl.formatMessage({ id: 'sendMessage.more-than-24-hours' })}
        </div>
      )}
      {showTemplateField && <Template account={account} />}
      {showForwardContent && (
        <div className={style.ForwardPreview}>
          <ForwardPreviewContent id={forwardId} />
        </div>
      )}
      <div className={style.Divider} />

      {showMessageBody && (
        <div className={style.Messages}>
          <FormField name="messages">
            {({ field }) =>
              isEmpty(field.value) ? (
                <BodyAndFileField index={0} />
              ) : (
                field.value?.map((_file, i: number) => (
                  <BodyAndFileField index={i} key={i} />
                ))
              )
            }
          </FormField>
        </div>
      )}
      <Space direction="horizontal" size={20}>
        {showActionButtons && (
          <>
            <AddFilesButton account={account} sending={sending} />
            <AddTextButton />
          </>
        )}
        <SendButton sending={sending} />
      </Space>
    </Space>
  );
}

type Props = {
  account: MessagingAccount;
  reaction?: MessageComposerReaction;
  onSendStart: () => void;
  restoreEditState: (values: WhatsappMessageComposerFormValues) => void;
  initialValues?: WhatsappMessageComposerFormValues;
};

export default function MessageComposerWhatsappSubform({
  account,
  reaction,
  onSendStart,
  restoreEditState,
  initialValues: initialValuesOuter,
}: Props) {
  const yup = useLocalizedYup();

  let validationSchema: ObjectSchema<any> = yup.object().shape({
    to: yup.string().required(),
  });
  if (reaction?.type === 'reply') {
    validationSchema = yup.object().shape({});
  }
  if (reaction?.type === 'forward') {
    validationSchema = yup.object().shape({
      to: yup.string().required(),
    });
  }

  const [sendWhatsappMessages, { loading: sending }] = useSendMessages();
  const [handleBGOp] = useBackgroundOperation();
  const intl = useIntl();

  const computedInitialValues = useWhatsappMessageComposerInitialValues({
    reaction,
  });

  const initialValues = initialValuesOuter || computedInitialValues;
  const client = useApolloClient();
  const onSubmit = async (values: WhatsappMessageComposerFormValues) => {
    const messagePromises = await sendWhatsappMessages({
      input: await whatsappFormToGraphQlInput({
        values,
        account,
        reaction,
      }),
    });
    for (const msgPromise of messagePromises) {
      handleBGOp(async () => msgPromise, {
        loadingText: intl.formatMessage({
          id: 'sendMessage.operation.loading',
        }),
        errorText: intl.formatMessage({ id: 'sendMessage.operation.error' }),
        errorActions: [
          {
            text: intl.formatMessage({
              id: 'sendMessage.operation.error.restore',
            }),
            handler: () => restoreEditState(values),
          },
        ],
        successText: intl.formatMessage({
          id: 'sendMessage.operation.success',
        }),
        onStart: onSendStart,
        onError: e => showErrorMessage(formatError(e)),
        onSuccess: () => {
          client.refetchQueries({ include: ['GetMessagingThreads'] });
        },
      });
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      <Form>
        <FormContent
          sending={sending}
          account={account}
          isReply={reaction?.type === 'reply'}
          forwardId={
            reaction?.type === 'forward' ? reaction.message.id : undefined
          }
        />
      </Form>
    </Formik>
  );
}
