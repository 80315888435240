import { DefaultOptionType } from 'antd/lib/select';
import { useFormikContext } from 'formik';
import { identity, isEmpty, isEqual, isNil, uniqBy, uniqWith } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useDebouncedVariable } from '../../common/utils/hookUtils';
import {
  MessagingAccountLabel,
  formatMessagingAccount,
} from '../../modules/accounts/accountFormats';
import { useAccountsQuery } from '../../modules/accounts/accountsGraphql';
import { useCalendarsQuery } from '../../modules/calendarApp/calendarGraphql';
import { formatContact } from '../../modules/contacts/contactFormats';
import { useContactsQuery } from '../../modules/contacts/contactsGraphql';
import { useLabelsQuery } from '../../modules/messagesApp/labels/labelsGraphql';
import { useGetTemplateMessages } from '../../modules/messagesApp/templatesGraphql';
import {
  GetMessageTemplatesFilter,
  GetMessagingAccountsQuery,
  GetMessagingContactsQuery,
  GetTemplateMessagesQuery,
  MessagingAccount,
  MessagingAccountType,
  MessagingContactsFilterInput,
} from '../../types/graphqlGenerated';
import { ErrorAlert } from '../ErrorAlert';
import { BasicSelect, BasicSelectProps } from './selects';

type FetchingSelectProps<T> = Omit<BasicSelectProps, 'options'> & {
  autoSelectFirst?: boolean;
  loading: boolean;
  error: any;
  rows: T[] | null | undefined;
  rowToOption: (row: T) => DefaultOptionType;
  sortRows?: (rows: T[]) => T[];
};

export function FetchingSelect<T>({
  autoSelectFirst,
  name,
  value,
  rows,
  rowToOption,
  sortRows = identity,
  loading,
  error,
  ...rest
}: FetchingSelectProps<T>) {
  const { setFieldValue } = useFormikContext();

  const options = useMemo(
    () => sortRows(rows || []).map(rowToOption) ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows]
  );
  useEffect(() => {
    if (autoSelectFirst && !isEmpty(rows) && isNil(value)) {
      setFieldValue(name, options[0].value);
    }
  }, [autoSelectFirst, name, options, rows, setFieldValue, value]);

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <BasicSelect
      name={name}
      value={value}
      options={options}
      loading={loading}
      {...rest}
    />
  );
}

type SpecificFetchingSelectProps = Omit<
  FetchingSelectProps<unknown>,
  'rows' | 'rowToOption' | 'loading' | 'error' | 'sortRows'
>;

type MessagingAccountSelectProps = SpecificFetchingSelectProps & {
  accountType?: MessagingAccountType;
};

export function MessagingAccountSelect({
  accountType,
  ...rest
}: MessagingAccountSelectProps) {
  const { accounts, loading, error } = useAccountsQuery();
  const filteredAccounts = accountType
    ? accounts?.filter(acc => acc.accountType === accountType)
    : accounts;
  return (
    <FetchingSelect
      rows={filteredAccounts}
      rowToOption={account => ({
        value: account.id,
        label: (
          <div className="FlexRow FlexRow--SpaceSm">
            <MessagingAccountLabel account={account} />
          </div>
        ),
      })}
      loading={loading}
      error={error}
      {...rest}
    />
  );
}

export function MessagingLabelSelect(props: SpecificFetchingSelectProps) {
  const { labels, loading, error } = useLabelsQuery();
  return (
    <FetchingSelect
      rows={labels}
      rowToOption={label => ({
        value: label.id,
        label: `${label.displayName} (${formatMessagingAccount(
          label.messagingAccount
        )})`,
      })}
      loading={loading}
      error={error}
      {...props}
    />
  );
}

type ExtContact =
  GetMessagingContactsQuery['getMessagingContacts']['data'][0] & {
    value: string;
  };
type MessagingParticipantSelectProps = SpecificFetchingSelectProps & {
  getValueArray: (contact: Omit<ExtContact, 'value'>) => string[];
  getValue?: (contact: ExtContact) => string;
  getLabel?: (contact: ExtContact) => string;
  filterOverride?: Partial<MessagingContactsFilterInput>;
};

type ExtTemplate =
  GetTemplateMessagesQuery['getTemplateMessages']['data'][0] & {
    value: string;
  };

type ExtAccount = NonNullable<
  GetMessagingAccountsQuery['getMessagingAccounts']
>[0] & {
  value: string;
};

export function MessagingParticipantSelect({
  getValueArray,
  getValue = c => c.value,
  getLabel = c => formatContact(c),
  onSearch: onSearchOuter,
  filterOverride,
  ...rest
}: MessagingParticipantSelectProps) {
  const [searchValue, setSearchValue] = useState('');
  const debSearchValue = useDebouncedVariable(searchValue);
  const { contacts, loading, error } = useContactsQuery({
    filter: {
      searchTerm: debSearchValue,
      ...filterOverride,
    },
  });

  const onSearch = useCallback(
    val => {
      setSearchValue(val);
      onSearchOuter?.(val);
    },
    [onSearchOuter]
  );

  const normalizedContacts = useMemo(
    () =>
      uniqBy(
        contacts?.flatMap(c =>
          getValueArray(c).map(value => ({
            ...c,
            value,
          }))
        ),
        'value'
      ) ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contacts]
  );

  return (
    <FetchingSelect
      rows={normalizedContacts}
      rowToOption={contact => ({
        value: getValue(contact),
        label: getLabel(contact),
      })}
      loading={loading}
      error={error}
      onSearch={onSearch}
      showSearch
      disableLocalFilter
      {...rest}
    />
  );
}

export function EmailParticipantSelect({
  filterOverride,
  ...rest
}: Omit<
  MessagingParticipantSelectProps,
  'getValueArray' | 'getValue' | 'getLabel'
>) {
  return (
    <MessagingParticipantSelect
      getValueArray={c => c.emails}
      getLabel={formatContact}
      getValue={formatContact}
      filterOverride={{ has: { email: true }, ...filterOverride }}
      {...rest}
    />
  );
}

export function WhatsappParticipantSelect({
  filterOverride,
  ...rest
}: Omit<
  MessagingParticipantSelectProps,
  'getValueArray' | 'getValue' | 'getLabel'
>) {
  return (
    <MessagingParticipantSelect
      getValueArray={c => c.whatsappAccounts.map(w => w.id)}
      getLabel={formatContact}
      getValue={c => c.value}
      filterOverride={{ has: { whatsappAccount: true }, ...filterOverride }}
      {...rest}
    />
  );
}

export function CalendarSelect({ ...rest }: SpecificFetchingSelectProps) {
  const { calendars = [], loading, error } = useCalendarsQuery();

  return (
    <FetchingSelect
      rows={calendars}
      rowToOption={calendar => ({
        value: calendar.id,
        label: calendar.name,
      })}
      loading={loading}
      error={error}
      {...rest}
    />
  );
}

type AccountSelectProps = SpecificFetchingSelectProps & {
  getValue?: (account: ExtAccount) => string;
  getLabel?: (account: ExtAccount) => string;
};

export const AccountSelect = ({
  getValue = a => a.value,
  getLabel = a => `${a.accountType} - ${a.platformId}`,
  ...rest
}: AccountSelectProps) => {
  const { accounts, loading, error } = useAccountsQuery();

  const normalizedAccounts = useMemo(
    () =>
      uniqWith(
        accounts?.map(a => ({
          ...a,
          value: a.id,
        })),
        isEqual
      ) ?? [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [accounts]
  );

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <FetchingSelect
      rows={normalizedAccounts}
      rowToOption={(account: any) => ({
        value: getValue(account),
        label: getLabel(account),
      })}
      loading={loading}
      error={error}
      showSearch
      disableLocalFilter
      {...rest}
    />
  );
};

type TemplateSelectProps = SpecificFetchingSelectProps & {
  getValue?: (template: ExtTemplate) => string;
  getLabel?: (template: ExtTemplate) => string;
  filterOverride?: Partial<GetMessageTemplatesFilter>;
  account: MessagingAccount;
};

export function TemplateSelect({
  getValue = t => t.value,
  getLabel = t => `${t.name} (${t.category} - ${t.language})`, // TODO use lodash to get rid off snake case
  onSearch: onSearchOuter,
  filterOverride,
  account,
  ...rest
}: TemplateSelectProps) {
  const [searchValue, setSearchValue] = useState('');
  const debSearchValue = useDebouncedVariable(searchValue);

  const { templates, loading, error } = useGetTemplateMessages({
    input: {
      fromMessagingAccountId: account.id,
      filter: {
        ...(debSearchValue && { name: debSearchValue }),
        ...filterOverride,
      },
    },
  });

  const onSearch = useCallback(
    val => {
      setSearchValue(val);
      onSearchOuter?.(val);
    },
    [onSearchOuter]
  );

  const normalizedTemplates = useMemo(
    () =>
      uniqWith(
        templates?.data?.map(t => ({
          ...t,
          value: `${t?.name}/${t?.language}`,
        })),
        isEqual
      ) ?? [], // template name doesn't allow / in the string
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [templates]
  );

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <FetchingSelect
      rows={normalizedTemplates}
      rowToOption={(template: any) => ({
        value: getValue(template),
        label: getLabel(template),
      })}
      loading={loading}
      error={error}
      showSearch
      disableLocalFilter
      onSearch={onSearch}
      allowClear
      {...rest}
    />
  );
}
