import { DeleteOutlined, MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, List, Menu } from 'antd';
import { CirclePicker } from 'react-color';
import { FormattedMessage } from 'react-intl';

import ColoredCheckbox from '../../../components/ColoredCheckbox';
import styles from './CalendarListItem.module.less';

export function CalendarListItem({
  title,
  onCheckedChange,
  checked,
  children,
  checkboxColor,
  color,
  onDelete,
  onSelectColor,
}) {
  function toggleHandler() {
    onCheckedChange(!checked);
  }
  return (
    <List.Item
      onClick={toggleHandler}
      title={title}
      className={styles.Item}
      extra={
        <Dropdown
          overlay={
            <Menu
              onClick={e => e.domEvent.stopPropagation()}
              items={[
                {
                  key: 'delete',
                  icon: <DeleteOutlined />,
                  onClick: onDelete,
                  label: <FormattedMessage id="labels.deleteCalendar" />,
                },
                { type: 'divider' },
                {
                  key: 'color',
                  className: styles.NoStylesMenuItem,
                  label: (
                    <CirclePicker
                      color={color}
                      circleSize={20}
                      circleSpacing={6}
                      width="160px"
                      styles={{ default: { card: { margin: 10 } } }}
                      onChange={clr => onSelectColor(clr.hex)}
                    />
                  ),
                },
              ]}
            />
          }
          trigger={['click']}
          className={styles.ContextMenu}
          destroyPopupOnHide
        >
          <Button
            size="small"
            shape="circle"
            type="text"
            icon={<MoreOutlined />}
            onClick={e => e.stopPropagation()}
          />
        </Dropdown>
      }
    >
      <ColoredCheckbox
        onClick={e => e.stopPropagation()}
        color={checkboxColor}
        checked={checked}
        onChange={toggleHandler}
      />
      <span className={styles.ItemLabel}>{children}</span>
    </List.Item>
  );
}
