import { identity } from 'lodash-es';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';

import { authReducer } from '../modules/auth/authReducer';
import { layoutReducer } from '../modules/layout/layoutReducer';
import { messagesReducer } from '../modules/messagesApp/messagesReducer';
import { settingsReducer } from '../modules/settings/settingsReducer';
import { ReduxContext } from '../types/appTypes';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: () => any;
  }
}

export function makeStore(context: ReduxContext) {
  return createStore(
    combineReducers({
      auth: authReducer,
      settings: settingsReducer,
      messages: messagesReducer,
      layout: layoutReducer,
    }),
    {},
    compose(
      applyMiddleware(thunk.withExtraArgument(context)),
      window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : identity
    )
  );
}
