import { parseAddressList } from 'email-addresses';
import { isArray, isString } from 'lodash-es';
import { TestConfig } from 'yup';

import { useFormattedMessage } from './intlUtils';

const STRONG_PASSWORD_REGEXP = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\^$*.[\]{}()?\-"!@#%&/,><':;|_~`])\S{8,99}$/
);

export function useCustomValidations() {
  const emailListMessage = useFormattedMessage('error.validation.emailList');
  const trueValueListMessage = useFormattedMessage(
    'error.validation.notEmptyList'
  );
  const strongPasswordMessage = useFormattedMessage(
    'validation.notStrongPassword'
  );

  const emailListValidation: TestConfig = {
    name: 'email-list',
    message: emailListMessage,
    test: (value: string | string[]) => {
      const strValue = isString(value) ? value : value.join(', ');
      return (
        strValue === '' ||
        !!parseAddressList({
          input: strValue,
          atInDisplayName: true,
          rfc6532: true,
        })
      );
    },
  };

  const notEmptyListValidation: TestConfig = {
    name: 'not-empty-list',
    message: trueValueListMessage,
    test: (value: any[]) => isArray(value) && value.some(v => !!v),
  };

  const strongPasswordValidation: TestConfig = {
    name: 'strong-password',
    message: strongPasswordMessage,
    test: (value: any) => STRONG_PASSWORD_REGEXP.test(value),
  };

  return {
    emailListValidation,
    notEmptyListValidation,
    strongPasswordValidation,
  };
}
