import { isEmpty } from 'lodash-es';
import { useSelector } from 'react-redux';
import { generatePath, useLocation } from 'react-router';

import RouteDefinitions from '../../../../app/routes';
import { useMessagesAppViewMode } from '../../messagesAppViewMode';
import { getThreadsFilter } from '../../messagesReducer';

type UseThreadDetailParentRoute = { threadId: number };

export function useThreadDetailParentRoute({
  threadId,
}: UseThreadDetailParentRoute) {
  const { pathname } = useLocation();
  return pathname.replace(
    generatePath(RouteDefinitions.threadDetailRelative, {
      threadId: `${threadId}`,
    }),
    ''
  );
}

export function useThreadHighlight() {
  const searchTerm = useSelector(getThreadsFilter)?.searchTerm;
  const { viewMode } = useMessagesAppViewMode();
  const searchMode = viewMode?.type === 'customFilter' && !isEmpty(searchTerm);

  return { searchMode, searchTerm };
}
