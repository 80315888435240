import {
  ArrowLeftOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  MoreOutlined,
  PrinterOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Input, Menu, Popover, Space, Tooltip } from 'antd';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import { useResponsiveQueries } from '../../../common/utils/layoutUtils';
import { showConfirm } from '../../../common/utils/messageUtils';
import { Whitespace } from '../../../components/Whitespace';
import { CircleButton } from '../../../components/buttons';
import FormField from '../../../components/forms/FormField';
import FormItem from '../../../components/forms/FormItem';
import { MessagingThreadDetailFragmentFragment } from '../../../types/graphqlGenerated';
import { MessagingAccountLabel } from '../../accounts/accountFormats';
import { SubHeader } from '../../layout/header/MobileAndTabletHeader';
import { ReadUnReadSingleButton } from '../ReadUnreadButton';
import { ThreadParticipantsList } from '../ThreadParticipantsList';
import {
  useDeleteThreadMutation,
  useUpdateThreadUserMetadataMutation,
} from '../threadsGraphql';
import styles from './ThreadHeader.module.less';
import { ThreadPagination } from './ThreadPagination';
import { useThreadDetailParentRoute } from './helpers/threadUtils';

type DeleteButtonProps = {
  thread: MessagingThreadDetailFragmentFragment;
  goBackUrl: string;
};

function DeleteButton({ thread, goBackUrl }: DeleteButtonProps) {
  const [deleteThread, { loading }] = useDeleteThreadMutation();
  const navigate = useNavigate();

  return (
    <CircleButton
      icon={<DeleteOutlined />}
      onClick={() =>
        showConfirm({
          title: <FormattedMessage id="deleteThread.modalTitle" />,
          content: <FormattedMessage id="deleteThread.modalContent" />,
          onOk: async () => {
            await deleteThread({ id: thread.id });
            navigate(goBackUrl);
          },
        })
      }
      disabled={loading}
    />
  );
}

function PrintButton() {
  return (
    <CircleButton icon={<PrinterOutlined />} onClick={() => window.print()} />
  );
}

type InfoPopupProps = {
  thread: MessagingThreadDetailFragmentFragment;
};

function InfoPopup({ thread }: InfoPopupProps) {
  return (
    <Popover
      title={<FormattedMessage id="threadHeader.info.header" />}
      content={
        <div>
          <div>
            <FormattedMessage id="threadHeader.messagesCount" />:<Whitespace />
            {thread.messages.cursor.count}
          </div>
        </div>
      }
    >
      <CircleButton icon={<InfoCircleOutlined />} />
    </Popover>
  );
}

type ThreadNameInlineEditorProps = {
  thread: MessagingThreadDetailFragmentFragment;
};

function ThreadNameInlineEditor({ thread }: ThreadNameInlineEditorProps) {
  const [editing, setEditing] = useState(false);
  const [mutate] = useUpdateThreadUserMetadataMutation();

  if (!editing) {
    return (
      <div className={styles.NameEditor}>
        <FormattedMessage id="threadHeader.name" />:<Whitespace />
        {thread.userMetadata?.name ? (
          <strong>{thread.userMetadata.name}</strong>
        ) : (
          '-'
        )}
        <Tooltip title={<FormattedMessage id="labels.edit" />}>
          <CircleButton
            icon={<EditOutlined />}
            onClick={() => setEditing(true)}
            size="small"
          />
        </Tooltip>
      </div>
    );
  }

  const initialValues = { name: thread.userMetadata?.name };
  const onSubmit = async values => {
    await mutate({ threadId: thread.id, input: { name: values.name } });
    setEditing(false);
  };

  return (
    <div className={styles.NameEditor}>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form>
            <FormItem labelId="threadHeader.name">
              <Space direction="horizontal" size={10}>
                <FormField name="name">
                  {({ field }) => (
                    <Input {...field} className={styles.NameInput} />
                  )}
                </FormField>
                <Tooltip title={<FormattedMessage id="labels.submit" />}>
                  <Button
                    icon={<SaveOutlined />}
                    htmlType="submit"
                    type="primary"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  />
                </Tooltip>
                <Tooltip title={<FormattedMessage id="labels.cancel" />}>
                  <Button
                    icon={<CloseOutlined />}
                    onClick={() => setEditing(false)}
                    disabled={isSubmitting}
                  />
                </Tooltip>
              </Space>
            </FormItem>
          </Form>
        )}
      </Formik>
    </div>
  );
}

type Props = {
  className?: string;
  thread: MessagingThreadDetailFragmentFragment;
};

export function ThreadHeader({ thread, className }: Props) {
  const goBackUrl = useThreadDetailParentRoute({ threadId: thread.id });

  const { sm: isSmallScreen, md: isMediumScreen } = useResponsiveQueries();

  return isSmallScreen || isMediumScreen ? (
    <div className={classNames(styles.Container, className)}>
      <div className={styles.TopRow}>
        <SubHeader
          leftCornerNodes={
            <Link to={goBackUrl}>
              <CircleButton icon={<ArrowLeftOutlined />} />
            </Link>
          }
          pageName={
            <Space
              direction="vertical"
              size={10}
              className={styles.MobileHeaderInfo}
            >
              <div className="FlexRow FlexRow--SpaceSm">
                <MessagingAccountLabel account={thread.messagingAccount} />
              </div>
              <span>{thread.subject}</span>
              <ThreadNameInlineEditor thread={thread} />
            </Space>
          }
          rightCornerNodes={<ThreadPagination threadId={thread.id} />}
        />
      </div>
      <div className={styles.BottomRow}>
        <div className={styles.Dropdown}>
          <InfoPopup thread={thread} />
          <Dropdown
            trigger={['click', 'hover']}
            overlay={
              <Menu
                items={[
                  {
                    key: 'delete',
                    icon: (
                      <DeleteButton thread={thread} goBackUrl={goBackUrl} />
                    ),
                    label: <FormattedMessage id="threadHeader.delete" />,
                  },
                  {
                    key: 'readUnread',
                    icon: <ReadUnReadSingleButton threadId={thread.id} />,
                    label: <FormattedMessage id="threadHeader.readUnread" />,
                  },
                  {
                    key: 'print',
                    icon: <PrintButton />,
                    label: <FormattedMessage id="threadHeader.print" />,
                  },
                ]}
              />
            }
          >
            <MoreOutlined />
          </Dropdown>
        </div>
      </div>
    </div>
  ) : (
    <div className={classNames(styles.Container, className)}>
      <div className={styles.TopRow}>
        <div>
          <Link to={goBackUrl}>
            <CircleButton icon={<ArrowLeftOutlined />} />
          </Link>
        </div>
        <div className={styles['TopRow__Content']}>
          <div className="FlexRow FlexRow--SpaceSm">
            <MessagingAccountLabel account={thread.messagingAccount} />
          </div>
          <ThreadParticipantsList
            thread={thread}
            modifyPrefix={node => <strong>{node}</strong>}
            showFull
            allowExpand
          />
          <ThreadNameInlineEditor thread={thread} />
        </div>
        <div>
          <ThreadPagination threadId={thread.id} />
        </div>
      </div>
      <div className={styles.BottomRow}>
        <InfoPopup thread={thread} />
        <ReadUnReadSingleButton threadId={thread.id} />
        <DeleteButton thread={thread} goBackUrl={goBackUrl} />
        <PrintButton />
      </div>
    </div>
  );
}
