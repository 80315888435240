import { Record } from 'immutable';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Action, bindActionCreators } from 'redux';

import {
  MessagingThreadsFilterInput,
  NamedMessagingThreadsFilter,
} from '../../types/graphqlGenerated';
import { LOGOUT } from '../auth/authReducer';

type MessagesState = typeof INITIAL_STATE;
type PartialState = { messages: MessagesState };

export type MessageView = 'folder' | 'filter';

type InitialStateType = {
  threadsFilter?: MessagingThreadsFilterInput;
  messageView: MessageView;
  filterEditorOpen: boolean;
  filterEditorEditedFilter?: NamedMessagingThreadsFilter;
};

const InitialState = Record<InitialStateType>({
  threadsFilter: undefined,
  messageView: 'folder',
  filterEditorOpen: false,
  filterEditorEditedFilter: undefined,
});
const INITIAL_STATE = InitialState();

const SET_THREADS_FILTER = 'MESSAGES/SET_FILTER';
const SET_MESSAGE_VIEW = 'MESSAGES/SET_MESSAGE_VIEW';
const SET_FILTER_EDITOR_OPEN = 'MESSAGES/SET_FILTER_EDITOR_OPEN';

export function messagesReducer(
  state: MessagesState = INITIAL_STATE,
  action: Action
) {
  switch (action.type) {
    case SET_THREADS_FILTER: {
      const typedAction = action as SetThreadsFilterAction;
      return state.set('threadsFilter', typedAction.threadsFilter);
    }
    case SET_MESSAGE_VIEW: {
      const typedAction = action as SetMessageViewAction;
      return state.set('messageView', typedAction.messageView);
    }
    case SET_FILTER_EDITOR_OPEN: {
      const typedAction = action as SetFilterEditorOpenAction;
      return state
        .set('filterEditorOpen', typedAction.filterEditorOpen)
        .set(
          'filterEditorEditedFilter',
          typedAction.filterEditorOpen
            ? typedAction.filterEditorEditedFilter
            : undefined
        );
    }
    case LOGOUT: {
      return state.clear();
    }
    default:
      return state;
  }
}

export const getThreadsFilter = (state: PartialState) =>
  state.messages.threadsFilter;
export const getMessageView = (state: PartialState) =>
  state.messages.messageView;
export const isFilterEditorOpen = (state: PartialState) =>
  state.messages.filterEditorOpen;
export const getFilterEditorEditedFilter = (state: PartialState) =>
  state.messages.filterEditorEditedFilter;

type SetThreadsFilterAction = Action & Pick<InitialStateType, 'threadsFilter'>;
function setThreadsFilter(
  threadsFilter: MessagingThreadsFilterInput
): SetThreadsFilterAction {
  return { type: SET_THREADS_FILTER, threadsFilter };
}

type SetMessageViewAction = Action & Pick<InitialStateType, 'messageView'>;
function setMessageView(messageView: MessageView): SetMessageViewAction {
  return { type: SET_MESSAGE_VIEW, messageView };
}

type SetFilterEditorOpenAction = Action &
  Pick<InitialStateType, 'filterEditorOpen' | 'filterEditorEditedFilter'>;
function setFilterEditorOpen(
  filterEditorOpen: boolean,
  editedFilter?: NamedMessagingThreadsFilter
): SetFilterEditorOpenAction {
  return {
    type: SET_FILTER_EDITOR_OPEN,
    filterEditorOpen,
    filterEditorEditedFilter: editedFilter,
  };
}

export function useMessagesActions() {
  const dispatch = useDispatch();
  return useMemo(
    () => ({
      setThreadsFilter: bindActionCreators(setThreadsFilter, dispatch),
      setMessageView: bindActionCreators(setMessageView, dispatch),
      setFilterEditorOpen: bindActionCreators(setFilterEditorOpen, dispatch),
    }),
    [dispatch]
  );
}
