import { makeStore } from './app';
import { makeApolloClient } from './graphql';

export const {
  apolloClient,
  subscriptionClient,
  setReduxStore: setApolloReduxStore,
} = makeApolloClient();
export const store = makeStore({ apolloClient, subscriptionClient });

setApolloReduxStore(store);
