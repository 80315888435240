import { Typography } from 'antd';
import {
  ErrorMessage,
  Field,
  FieldConfig,
  FieldProps,
  GenericFieldHTMLAttributes,
} from 'formik';
import { isFunction, isString } from 'lodash-es';
import { ComponentType, ForwardRefExoticComponent, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { TranslationId } from '../../types/appTypes';

type FieldAttributesTypeSafe<T> = GenericFieldHTMLAttributes &
  Omit<FieldConfig<T>, 'component'>;

export type FormFieldProps = FieldAttributesTypeSafe<any> & {
  children?: (
    props: FieldProps & {
      field: {
        disabled?: boolean;
        placeholder?: string;
      };
      label: ReactNode;
    }
  ) => ReactNode;
  placeholder?: string;
  placeholderId?: TranslationId;
  label?: ReactNode;
  labelId?: TranslationId;
  disabled?: boolean;
  component?: string | ComponentType<any> | ForwardRefExoticComponent<any>;
};

export default function FormField({
  // formik's form field props
  name,
  component: Component,
  // special props
  label: labelOuter,
  labelId,
  // input component props
  placeholder: placeholderOuter,
  placeholderId,
  children: childrenOuter,
  disabled = false,
  validate,
  ...rest
}: FormFieldProps) {
  const intl = useIntl();
  const label = labelId ? intl.formatMessage({ id: labelId }) : labelOuter;
  const placeholder = placeholderId
    ? intl.formatMessage({ id: placeholderId })
    : placeholderOuter;

  const children = Component
    ? ({ field }) => <Component {...field} {...rest} />
    : childrenOuter;

  return (
    <>
      <Field name={name} validate={validate}>
        {isFunction(children)
          ? ({ field, form, meta }: FieldProps) =>
              children({
                field: {
                  ...field,
                  disabled: disabled || form.isSubmitting,
                  placeholder,
                },
                form,
                meta,
                label,
              })
          : children}
      </Field>
      <ErrorMessage
        name={name?.toString() ?? ''}
        render={msg => {
          if (!isString(msg)) {
            // in nested fields like 'recurrence' this is triggered with an object on parent
            // therefore ignoring as this is supposed to show end-field error message
            return null;
          }
          return <Typography.Text type="danger">{msg}</Typography.Text>;
        }}
      />
    </>
  );
}
