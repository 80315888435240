import { Avatar, List } from 'antd';
import { isEmpty } from 'lodash-es';
import { FormattedMessage } from 'react-intl';
import { generatePath } from 'react-router';

import RouteDefinitions from '../../../app/routes';
import { Whitespace } from '../../../components/Whitespace';
import { GetMessagingContactsQuery } from '../../../types/graphqlGenerated';
import { formatContactFullName } from '../contactFormats';
import { useContactsNavigation } from '../contactsNavigation';
import style from './ContactsListContent.module.less';

type Contacts = GetMessagingContactsQuery['getMessagingContacts']['data'];
type Contact = Contacts[0];

type ContactsListContentProps = {
  items: Contacts;
};

export function ContactsListContent({ items }: ContactsListContentProps) {
  return (
    <List
      dataSource={items}
      rowKey="id"
      renderItem={item => <Item contact={item} />}
    />
  );
}

type ItemProps = {
  contact: Contact;
};

function Item({ contact }: ItemProps) {
  const { navigate } = useContactsNavigation();

  return (
    <List.Item
      className={style.Item}
      onClick={() =>
        navigate(
          generatePath(RouteDefinitions.contactDetail, {
            contactId: `${contact.id}`,
          })
        )
      }
    >
      <List.Item.Meta
        className={style.ItemContent}
        avatar={<Avatar src={contact.pictureUrl} />}
        title={
          formatContactFullName(contact) || (
            <FormattedMessage id="contacts.unknownName" />
          )
        }
        description={
          <div>
            {!isEmpty(contact.emails) && (
              <div>
                <FormattedMessage
                  id="contacts.emails"
                  values={{ count: contact.emails.length }}
                />
                :<Whitespace />
                {contact.emails.join(', ')}
              </div>
            )}
            {!isEmpty(contact.phoneNumbers) && (
              <div>
                <FormattedMessage
                  id="contacts.phoneNumbers"
                  values={{ count: contact.phoneNumbers.length }}
                />
                :<Whitespace />
                {contact.phoneNumbers.join(', ')}
              </div>
            )}
            {!isEmpty(contact.whatsappAccounts) && (
              <div>
                <FormattedMessage
                  id="contacts.whatsappNumbers"
                  values={{ count: contact.whatsappAccounts.length }}
                />
                :<Whitespace />
                {contact.whatsappAccounts.map(w => w.phoneNumber).join(', ')}
              </div>
            )}
          </div>
        }
      />
    </List.Item>
  );
}
