import { useState } from 'react';
import { useSelector } from 'react-redux';

import { PaginationInput } from '../../../types/graphqlGenerated';
import {
  getPageSize,
  useSettingsActions,
} from '../../settings/settingsReducer';
import { ExtendedPageInfo, PageInfo } from './paginationTypes';

export function usePagination() {
  const [pageInfo, setPageInfo] = useState<PageInfo>({
    index: 0,
    total: 0,
  });

  return {
    pageInfo,
    setPageInfo,
    setIndex: (index: number) => setPageInfo(old => ({ ...old, index })),
    setTotal: (total: number) => setPageInfo(old => ({ ...old, total })),
  };
}

export function useExtendedPagination() {
  const { pageInfo, ...rest } = usePagination();

  const pageSize = useSelector(getPageSize);
  const { setPageSize } = useSettingsActions();

  return {
    pageInfo: { ...pageInfo, size: pageSize } as ExtendedPageInfo,
    paginationInput: {
      index: pageInfo.index,
      size: pageSize,
    } as PaginationInput,
    ...rest,
    setPageSize,
  };
}
