import { Input } from 'antd';
import { useEffect, useState } from 'react';

import { useFormattedMessage } from '../../../common/utils/intlUtils';
import style from './SearchBarForContacts.module.less';

const { Search } = Input;

type Props = {
  searchTerm?: string;
  onSearch: (searchTerm: string) => void;
};

export function SearchBarForContacts({ searchTerm = '', onSearch }: Props) {
  const [value, setValue] = useState(searchTerm);
  useEffect(() => {
    setValue(searchTerm);
  }, [searchTerm]);

  const resetSearchTerm = () => {
    onSearch('');
  };

  return (
    <div className={style.SearchBarWrapper}>
      <Search
        placeholder={useFormattedMessage('labels.search.placeholder')}
        onSearch={onSearch}
        enterButton
        allowClear={true}
        onReset={resetSearchTerm}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
    </div>
  );
}
