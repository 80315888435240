import { CognitoUser } from 'amazon-cognito-identity-js';
import { Button, Input, Space, Typography } from 'antd';
import { Form, Formik } from 'formik';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import { formatError } from '../../common/utils/errorUtils';
import { showErrorMessage } from '../../common/utils/messageUtils';
import FormField from '../../components/forms/FormField';
import { useLocalizedYup } from '../../config/intl/LocaleProvider';
import { useAuthService } from './authService';

interface PropsSignupVerifyFormInner {
  isSubmitting: boolean;
}

function SignupVerifyFormInner(props: PropsSignupVerifyFormInner) {
  return (
    <Form>
      <Space direction="vertical" size={25}>
        <div>
          <Typography.Title level={4}>
            <FormattedMessage id="initialAuth.signup.verify.title" />
          </Typography.Title>
          <Typography.Text>
            <FormattedMessage id="initialAuth.signup.verify.text" />
          </Typography.Text>
        </div>
        <FormField name="code" placeholderId="initialAuth.placeholder.code">
          {({ field }) => <Input {...field} size="large" />}
        </FormField>
        <Button
          className="FullWidth"
          type="primary"
          size="large"
          htmlType="submit"
          disabled={props.isSubmitting}
          loading={props.isSubmitting}
        >
          <FormattedMessage id="initialAuth.button.verify" />
        </Button>
      </Space>
    </Form>
  );
}

interface PropsSignupVerifyForm {
  user: CognitoUser;
  onSuccess?: () => void;
}

export default function SignupVerifyForm({
  onSuccess,
  user,
}: PropsSignupVerifyForm) {
  const initialValues = {
    code: '',
  };

  const yup = useLocalizedYup();
  const authService = useAuthService();

  const onSubmit = useCallback(
    async values => {
      try {
        await authService.confirmRegistration(user, values.code);
        onSuccess?.();
      } catch (e) {
        showErrorMessage(formatError(e));
      }
    },
    [authService, onSuccess, user]
  );

  const validationSchema = yup.object().shape({
    code: yup.string().required(),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      component={SignupVerifyFormInner}
    />
  );
}
