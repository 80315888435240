import { FieldInputProps, useFormikContext } from 'formik';
import { CirclePicker, SketchPicker } from 'react-color';

type ColorPickerProps = FieldInputProps<string>;

export function CircleColorPicker({ name, value }: ColorPickerProps) {
  const { setFieldValue } = useFormikContext();

  return (
    <CirclePicker
      color={value}
      onChange={clr => setFieldValue(name, clr.hex)}
    />
  );
}

export function ColorPicker({ name, value }: ColorPickerProps) {
  const { setFieldValue } = useFormikContext();

  return (
    <SketchPicker
      color={value}
      onChange={clr => setFieldValue(name, clr.hex)}
    />
  );
}
