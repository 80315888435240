import { compact, identity, isEmpty } from 'lodash-es';
import { IntlShape } from 'react-intl';

import {
  MessagingMessage,
  MessagingParticipant,
} from '../../types/graphqlGenerated';

export function formatEmailParticipant(p: MessagingParticipant) {
  return p.name ? `${p.name} <${p.ref}>` : p.ref;
}

export type EmailFormatterOptions = {
  intl: IntlShape;
  sanitizeBody?: (body: string) => string | Promise<string>;
};

function escapeHTML(html: string) {
  const textarea = document.createElement('textarea');
  textarea.textContent = html;
  return textarea.innerHTML;
}

export function formatEmailHeaders(
  msg: MessagingMessage,
  { intl }: Pick<EmailFormatterOptions, 'intl'>
) {
  return compact([
    `${intl.formatMessage({
      id: 'message.from',
    })}: ${msg.from.map(formatEmailParticipant)}`,
    `${intl.formatMessage({
      id: 'email.subject',
    })}: ${msg.subject}`,
    msg.date &&
      `${intl.formatMessage({
        id: 'email.date',
      })}: ${intl.formatDate(msg.date)} ${intl.formatTime(msg.date)}`,
    !isEmpty(msg.to) &&
      `${intl.formatMessage({
        id: 'email.to',
      })}: ${msg.to.map(formatEmailParticipant)}`,
    !isEmpty(msg.cc) &&
      `${intl.formatMessage({
        id: 'email.cc',
      })}: ${msg.cc.map(formatEmailParticipant)}`,
  ]).join('\n');
}

export async function getForwardEmailInitialBody(
  msg: MessagingMessage,
  { intl, sanitizeBody = identity }: EmailFormatterOptions
) {
  return [
    escapeHTML(
      `---------- ${intl.formatMessage({
        id: 'email.forward.prefix',
      })} ---------`
    ),
    escapeHTML(formatEmailHeaders(msg, { intl })),
    await sanitizeBody(msg.body || ''),
  ].join('\n\n');
}

export async function getReplyEmailInitialBody(
  msg: MessagingMessage,
  { intl, sanitizeBody = identity }: EmailFormatterOptions
) {
  const date = msg.date && new Date(msg.date);
  return [
    escapeHTML(
      intl.formatMessage(
        { id: 'email.reply.prefix' },
        {
          date: date && `${intl.formatDate(date)} ${intl.formatTime(date)}`,
          from: msg.from.map(formatEmailParticipant).join(', '),
        }
      )
    ),
    `<blockquote>${await sanitizeBody(msg.body || '')}</blockquote>`,
  ].join('');
}
