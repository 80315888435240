import { ObservableSubscription, gql, useApolloClient } from '@apollo/client';
import { useIntl } from 'react-intl';

import {
  createUseMutation,
  createUseQuery,
} from '../../common/utils/graphqlUtils';
import { useStateIfMounted } from '../../common/utils/reactUtils';
import {
  CanSendMessageIn24HoursWindowQuery,
  CanSendMessageIn24HoursWindowQueryVariables,
  OnSendMessagingMessageResultSubscription,
  OnSendMessagingMessageResultSubscriptionVariables,
  SendMessagingMessageMutation,
  SendMessagingMessageMutationVariables,
  SendMessagingMessagesMutation,
  SendMessagingMessagesMutationVariables,
} from '../../types/graphqlGenerated';
import { readThreadFromCache, writeThreadToCache } from './threadsGraphql';
import {
  MessageFullFragment,
  ThreadListFragment,
} from './threadsMessagesGraphql';

const SEND_MESSAGE_MUTATION = gql`
  mutation SendMessagingMessage($input: MessagingMessageInput!) {
    sendMessagingMessage(input: $input)
  }
`;

const ON_SEND_MESSAGE_RESULT_SUBSCRIPTION = gql`
  ${MessageFullFragment}
  ${ThreadListFragment}
  subscription OnSendMessagingMessageResult($ticketToken: String!) {
    onSendMessagingMessageResult(ticketToken: $ticketToken) {
      success
      errorCode
      errorMessage
      data {
        thread {
          ...MessagingThreadListFragment
        }
        message {
          ...MessagingMessageFullFragment
        }
      }
    }
  }
`;

const useSendMessageMutation = createUseMutation<
  SendMessagingMessageMutation,
  SendMessagingMessageMutationVariables,
  SendMessagingMessageMutation['sendMessagingMessage']
>(SEND_MESSAGE_MUTATION, { extractResult: resp => resp.sendMessagingMessage });

type UseSendMessageOpts = Parameters<typeof useSendMessageMutation>[0];
type UseSendMessageVars = Parameters<
  ReturnType<typeof useSendMessageMutation>[0]
>[0];
type UseSendMessageResult =
  OnSendMessagingMessageResultSubscription['onSendMessagingMessageResult']['data'];
type UseSendMessageReturn = [
  (variables: UseSendMessageVars) => Promise<UseSendMessageResult>,
  { loading: boolean }
];

const SEND_MESSAGES_MUTATION = gql`
  mutation SendMessagingMessages($input: [MessagingMessageInput!]!) {
    sendMessagingMessages(input: $input)
  }
`;

const useSendMessagesMutation = createUseMutation<
  SendMessagingMessagesMutation,
  SendMessagingMessagesMutationVariables,
  SendMessagingMessagesMutation['sendMessagingMessages']
>(SEND_MESSAGES_MUTATION, {
  extractResult: resp => resp.sendMessagingMessages,
});

type UseSendMessagesOpts = Parameters<typeof useSendMessagesMutation>[0];
type UseSendMessagesVars = Parameters<
  ReturnType<typeof useSendMessagesMutation>[0]
>[0];
type UseSendMessagesReturn = [
  (variables: UseSendMessagesVars) => Promise<Promise<UseSendMessageResult>[]>,
  { loading: boolean }
];

const MAXIMUM_SEND_MESSAGE_TIMEOUT = 3 * 60 * 1000;

const CAN_SEND_MESSAGE_IN_24_HOUR_WINDOW0 = gql`
  query CanSendMessageIn24HoursWindow($input: Verify24HourWindowInput!) {
    canSendMessageIn24HoursWindow(input: $input)
  }
`;

export const useCanSendMessageIn24HoursWindowQuery = createUseQuery<
  CanSendMessageIn24HoursWindowQuery,
  CanSendMessageIn24HoursWindowQueryVariables,
  'canSendMessageIn24HoursWindow',
  CanSendMessageIn24HoursWindowQuery['canSendMessageIn24HoursWindow']
>(CAN_SEND_MESSAGE_IN_24_HOUR_WINDOW0, {
  extractResult: resp => resp.canSendMessageIn24HoursWindow,
  resultName: 'canSendMessageIn24HoursWindow',
  // opts: {
  //   fetchPolicy: 'cache-and-network',
  // },
});

function useMessageSubscriptionTracking() {
  const client = useApolloClient();
  const intl = useIntl();

  return (ticketToken: string) => {
    let sub: ObservableSubscription;
    return Promise.race([
      new Promise((_resolve, reject) =>
        setTimeout(
          () =>
            reject(
              Object.assign(
                new Error(intl.formatMessage({ id: 'error.timeoutExceeded' })),
                { code: 'error.timeoutExceeded' }
              )
            ),
          MAXIMUM_SEND_MESSAGE_TIMEOUT
        )
      ),
      new Promise((resolve, reject) => {
        sub = client
          .subscribe<
            OnSendMessagingMessageResultSubscription,
            OnSendMessagingMessageResultSubscriptionVariables
          >({
            query: ON_SEND_MESSAGE_RESULT_SUBSCRIPTION,
            variables: { ticketToken },
          })
          .subscribe({
            next: ({ data }) => {
              const {
                success,
                errorCode,
                errorMessage,
                data: resultData,
              } = data?.onSendMessagingMessageResult || {};
              if (success && resultData) {
                // Update cache by directly inserting the message into the thread
                const thread =
                  resultData.thread?.id &&
                  readThreadFromCache(resultData.thread.id, client.cache);
                if (thread) {
                  writeThreadToCache(
                    thread.id,
                    {
                      ...thread,
                      messages: {
                        cursor: thread.messages.cursor,
                        data: [
                          { ...resultData.message, rowNumber: null },
                          ...thread.messages.data,
                        ],
                      },
                    },
                    client.cache
                  );
                }

                resolve(resultData);
              } else {
                const e = Object.assign(new Error(errorMessage || ''), {
                  code: errorCode || '',
                });
                reject(e);
              }
            },
          });
      }).finally(() => {
        sub?.unsubscribe();
      }),
    ]) as Promise<UseSendMessageResult>;
  };
}

/**
 * This operation may take more than 30 seconds (which is the maximum timeout of API gateway) and therefore
 * has to be split into an "initialization" and then waiting for a result
 */
export function useSendMessage(
  opts?: UseSendMessageOpts
): UseSendMessageReturn {
  const [startSend] = useSendMessageMutation(opts);
  const [waiting, setWaiting] = useStateIfMounted(false);
  const trackMessage = useMessageSubscriptionTracking();

  const send = async (variables: UseSendMessageVars) => {
    setWaiting(true);
    try {
      const ticketToken = await startSend(variables);
      if (!ticketToken) {
        throw new Error('Unexpected data');
      }
      return trackMessage(ticketToken);
    } finally {
      setWaiting(false);
    }
  };

  return [send, { loading: waiting }];
}

/**
 * This operation may take more than 30 seconds (which is the maximum timeout of API gateway) and therefore
 * has to be split into an "initialization" and then waiting for a result
 */
export function useSendMessages(
  opts?: UseSendMessagesOpts
): UseSendMessagesReturn {
  const [startSend] = useSendMessagesMutation(opts);
  const [waiting, setWaiting] = useStateIfMounted(false);
  const trackMessage = useMessageSubscriptionTracking();

  const send = async (variables: UseSendMessageVars) => {
    setWaiting(true);
    try {
      const ticketTokens = await startSend(variables);
      return ticketTokens?.map(trackMessage) || [];
    } finally {
      setWaiting(false);
    }
  };

  return [send, { loading: waiting }];
}

export const canSendMessageIn24HourWindow = async () => {};
