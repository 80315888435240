import Icon, {
  CheckCircleOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/Icon';

import iconGoogle from '../resources/img/icon-google.png';
import iconReplyAll from '../resources/img/icon-reply-all.png';
import iconReply from '../resources/img/icon-reply.png';
import iconSend from '../resources/img/icon-send.png';
import iconSms from '../resources/img/icon-sms.png';
import iconWhatsapp from '../resources/img/icon-whatsapp.png';
import styles from './icons.module.less';

function GoogleIconImage() {
  return <img src={iconGoogle} alt="Google" />;
}

export function GoogleIcon() {
  return <Icon component={GoogleIconImage} />;
}

function WhatsappIconImage() {
  return <img src={iconWhatsapp} alt="Whatsapp" />;
}

export function WhatsappIcon(props: Pick<IconComponentProps, 'style'>) {
  return <Icon component={WhatsappIconImage} {...props} />;
}

function SmsIconImage() {
  return <img src={iconSms} alt="SMS" />;
}

export function SmsIcon(props: Pick<IconComponentProps, 'style'>) {
  return <Icon component={SmsIconImage} {...props} />;
}

function SendIconImage() {
  return <img src={iconSend} alt="Send" />;
}

export function SendIcon() {
  return <Icon component={SendIconImage} />;
}

function ReplyIconImage() {
  return <img src={iconReply} alt="Reply" />;
}

export function ReplyIcon() {
  return <Icon component={ReplyIconImage} />;
}

function ReplyAllIconImage() {
  return <img src={iconReplyAll} alt="Reply all" />;
}

export function ReplyAllIcon() {
  return <Icon component={ReplyAllIconImage} />;
}

export function SuccessIconLarge() {
  return <CheckCircleOutlined className={styles.LargePrimary} />;
}

export function FailureIconLarge() {
  return <CloseCircleOutlined className={styles.LargePrimary} />;
}
