import { MailFilled, QuestionCircleFilled } from '@ant-design/icons';
import { IconComponentProps } from '@ant-design/icons/lib/components/AntdIcon';

import { SmsIcon, WhatsappIcon } from '../../components/icons';
import {
  MessagingAccount,
  MessagingAccountType,
} from '../../types/graphqlGenerated';

type MessagingAccountSimple = Pick<
  MessagingAccount,
  'platformId' | 'accountType'
> &
  Partial<Pick<MessagingAccount, 'color'>>;

type MessagingAccountIconProps = {
  account: MessagingAccountSimple;
};

export function MessagingAccountIcon({ account }: MessagingAccountIconProps) {
  const style: IconComponentProps['style'] = account.color
    ? { color: account.color }
    : {};

  if (account.accountType === MessagingAccountType.Email) {
    return <MailFilled style={style} />;
  }
  if (account.accountType === MessagingAccountType.Sms) {
    return <SmsIcon style={style} />;
  }
  if (account.accountType === MessagingAccountType.Whatsapp) {
    return <WhatsappIcon style={style} />;
  }
  return <QuestionCircleFilled />;
}

type Props = {
  account: MessagingAccountSimple;
  labelClassName?: string;
};

export function formatMessagingAccount(account: MessagingAccountSimple) {
  return account.platformId || '';
}

export function MessagingAccountLabel({ account, labelClassName }: Props) {
  return (
    <>
      <MessagingAccountIcon account={account} />
      <div className={labelClassName} title={formatMessagingAccount(account)}>
        {formatMessagingAccount(account)}
      </div>
    </>
  );
}
