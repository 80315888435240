// This factory function is needed in order to prevent webpack from evaluating process.env at build time.
function makeConfig(env: any) {
  return {
    version: env.REACT_APP_VERSION,
    serverName: env.REACT_APP_SERVER_NAME,
    cognitoUserPoolId: env.REACT_APP_COGNITO_USER_POOL_ID,
    cognitoClientId: env.REACT_APP_COGNITO_CLIENT_ID,
    graphqlUrl: env.REACT_APP_GRAPHQL_URL,
    graphqlWsUrl: env.REACT_APP_GRAPHQL_WS_URL,
    sentryDsn: env.REACT_APP_SENTRY_DSN,
    sentryEnv: env.REACT_APP_SENTRY_ENV,
    googleAnalyticsMeasurementId: env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID,
  };
}

export const config = makeConfig(process.env);
