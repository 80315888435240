import { SmileOutlined } from '@ant-design/icons';
import { Button, ButtonProps, Pagination, Popover, Space, Spin } from 'antd';
import { useState } from 'react';

import { useAsync } from '../../common/utils/hookUtils';
import styles from './EmojiPicker.module.less';
import { EmojiInfo } from './EmojiTypes';

export type EmojiPickerProps = { onSelect?: (info: EmojiInfo) => void } & Pick<
  ButtonProps,
  'disabled' | 'size'
>;

const PAGE_SIZE = 9 * 4;

export function EmojiPicker({ onSelect, disabled }: EmojiPickerProps) {
  const [visible, setVisible] = useState(false);
  const [page, setPage] = useState(0);
  const { loading, data: module } = useAsync(
    () => import('./EmojiData') as any
  );
  const EmojiData = (module as any)?.default as EmojiInfo[];

  const pageData = (EmojiData || []).slice(
    page * PAGE_SIZE,
    (page + 1) * PAGE_SIZE
  );

  return (
    <Popover
      visible={visible}
      onVisibleChange={setVisible}
      trigger="click"
      content={
        <div className={styles.Popover}>
          {loading ? (
            <Spin />
          ) : (
            <Space direction="vertical" size={5}>
              <Pagination
                pageSize={PAGE_SIZE}
                total={EmojiData?.length ?? 0}
                current={page + 1}
                onChange={pageNum => setPage(pageNum - 1)}
                showSizeChanger={false}
                responsive
              />
              <div className={styles['Popover__Icons']}>
                {pageData.map(info => (
                  <Button
                    key={info.name}
                    size="large"
                    shape="circle"
                    type="text"
                    onClick={() => {
                      onSelect?.(info);
                      setVisible(false);
                    }}
                  >
                    {info.emoji}
                  </Button>
                ))}
              </div>
            </Space>
          )}
        </div>
      }
    >
      <Button type="text" icon={<SmileOutlined />} disabled={disabled} />
    </Popover>
  );
}
