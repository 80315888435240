import { gql } from '@apollo/client';

import { createUseQuery } from '../../../common/utils/graphqlUtils';
import {
  GetMessagingLabelsQuery,
  GetMessagingLabelsQueryVariables,
} from '../../../types/graphqlGenerated';
import { AccountFullFragment } from '../../accounts/accountsGraphql';

const LABELS_QUERY = gql`
  ${AccountFullFragment}
  query GetMessagingLabels {
    getMessagingLabels {
      id
      displayName
      messagingAccount {
        ...MessagingAccountFullFragment
      }
    }
  }
`;

export const useLabelsQuery = createUseQuery<
  GetMessagingLabelsQuery,
  GetMessagingLabelsQueryVariables,
  'labels',
  GetMessagingLabelsQuery['getMessagingLabels']
>(LABELS_QUERY, {
  extractResult: resp => resp.getMessagingLabels,
  resultName: 'labels',
});
