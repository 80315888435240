import { addBreadcrumb, captureException } from '@sentry/react';

type Level = 'info' | 'log' | 'debug' | 'error' | 'warning';
type Method = 'info' | 'log' | 'debug' | 'error' | 'warn';

export function remoteLog(
  message: string,
  data?: Record<string, any>,
  level: Level = 'log'
) {
  addBreadcrumb({ message, data, level });
  console[level === 'warning' ? 'warn' : level](message, data);
}

export function remoteError(err: any, method: Method = 'error') {
  console[method](err);
  captureException(err);
}
