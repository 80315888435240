import { Radio, RadioGroupProps, Switch, SwitchProps } from 'antd';
import { FieldInputProps, useFormikContext } from 'formik';
import { isBoolean } from 'lodash-es';

import { useFormattedMessage } from '../../common/utils/intlUtils';
import { TranslationId } from '../../types/appTypes';

type BasicSwitchProps = SwitchProps & FieldInputProps<boolean>;

export function BasicSwitch({
  name,
  value,
  onChange: _onChange,
  onBlur: _onBlur,
  ...rest
}: BasicSwitchProps) {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  return (
    <Switch
      {...rest}
      checked={!!value}
      onChange={val => {
        setFieldValue(name, val);
        setFieldTouched(name, true);
      }}
    />
  );
}

type NullableBooleanControlProps = Omit<
  RadioGroupProps,
  'options' | 'children'
> &
  FieldInputProps<boolean> & {
    trueLabelId?: TranslationId;
    falseLabelId?: TranslationId;
    nullLabelId?: TranslationId;
  };

const BooleanControlMapping = {
  true: true,
  false: false,
};

export function NullableBooleanControl({
  name,
  value,
  onChange: _onChange,
  trueLabelId = 'common.boolean.true',
  falseLabelId = 'common.boolean.false',
  nullLabelId = 'common.boolean.null',
  ...rest
}: NullableBooleanControlProps) {
  const { setFieldValue } = useFormikContext();
  const strValue = isBoolean(value) ? `${value}` : 'null';

  return (
    <Radio.Group
      value={strValue}
      onChange={e =>
        setFieldValue(name, BooleanControlMapping[e.target.value] ?? null)
      }
      options={[
        { label: useFormattedMessage(nullLabelId), value: 'null' },
        { label: useFormattedMessage(trueLabelId), value: 'true' },
        { label: useFormattedMessage(falseLabelId), value: 'false' },
      ]}
      {...rest}
    />
  );
}
