import { Card, Popover, PopoverProps } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { uniq } from 'lodash-es';

import { useFormattedMessage } from '../../common/utils/intlUtils';
import { ClippedLabelList } from '../../components/LabelList';
import { MessagingParticipant } from '../../types/graphqlGenerated';
import { formatContactFullName } from './contactFormats';
import { useContactsQuery } from './contactsGraphql';

type ParticipantPopoverContentProps = {
  participant: MessagingParticipant;
};

function ParticipantPopoverContent({
  participant,
}: ParticipantPopoverContentProps) {
  const { contacts } = useContactsQuery({
    filter: { emailOrPhoneNumber: { eq: participant.ref } },
  });
  const pictureUrl = (contacts || []).find(c => c.pictureUrl)?.pictureUrl;
  const nameContact = (contacts || []).find(c => c.givenName || c.surname);
  const fullName = nameContact
    ? formatContactFullName(nameContact)
    : participant.name;

  const emailsAndPhoneNumbers = uniq([
    ...(contacts || []).flatMap(c => c.emails),
    ...(contacts || []).flatMap(c => c.phoneNumbers),
    participant.ref,
  ]);

  return (
    <Card bordered={false}>
      <Card.Meta
        avatar={pictureUrl && <Avatar size="large" src={pictureUrl} />}
        title={fullName}
        description={
          <>
            {emailsAndPhoneNumbers.map((text, i) => (
              <div key={i}>{text}</div>
            ))}
          </>
        }
      />
    </Card>
  );
}

type ParticipantPopoverProps = Omit<PopoverProps, 'content'> &
  ParticipantPopoverContentProps;

export function ParticipantPopover({
  participant,
  ...rest
}: ParticipantPopoverProps) {
  return (
    <Popover
      content={<ParticipantPopoverContent participant={participant} />}
      {...rest}
    />
  );
}

type ParticipantsLabelProps = {
  participants: MessagingParticipant[];
  allowExpand?: boolean;
  showFull?: boolean;
  me?: string;
};

export const ParticipantsLabel = ({
  participants,
  allowExpand = false,
  showFull = false,
  me,
}: ParticipantsLabelProps) => {
  const meLabel = useFormattedMessage('participant.special.me');

  return (
    <ClippedLabelList
      key={JSON.stringify(participants)}
      clip
      allowExpand={allowExpand}
    >
      {participants.map((p, i) => {
        const name = p.name || (p.ref === me ? meLabel : undefined);
        const namePrefix = name ? `${name} ` : '';
        const fullText = `${namePrefix}<${p.ref}>`;
        return (
          <ParticipantPopover participant={p} key={i}>
            {showFull ? fullText : name || p.ref}
          </ParticipantPopover>
        );
      })}
    </ClippedLabelList>
  );
};
