import { useCallback, useRef, useState } from 'react';

import { useMountEffect } from './hookUtils';

export function useMountedIndicator({
  logPrefix,
}: { logPrefix?: string } = {}) {
  const mounted = useRef(true);
  useMountEffect(() => {
    mounted.current = true;
    if (logPrefix) {
      console.debug(logPrefix, 'mount');
    }
    return () => {
      mounted.current = false;
      if (logPrefix) {
        console.debug(logPrefix, 'unmount');
      }
    };
  });
  return mounted;
}

export function useStateIfMounted<T>(initialValue: T): [T, (val: T) => void] {
  const mounted = useMountedIndicator();
  const [state, setState] = useState<T>(initialValue);
  const safeSetState = useCallback(
    (val: T) => {
      if (mounted.current) {
        setState(val);
      }
    },
    [mounted]
  );

  return [state, safeSetState];
}
