import { TypePolicy, gql } from '@apollo/client';
import { isBoolean, isEmpty, isNumber, last, sortBy, uniqBy } from 'lodash-es';
import { parseDateTime } from 'vifzack-common-utils';

import { AccountFullFragment } from '../accounts/accountsGraphql';
import { FileFullFragment } from '../files/filesGraphql';

export const MessageFullFragment = gql`
  ${FileFullFragment}
  fragment MessagingMessageFullFragment on MessagingMessage {
    id
    from {
      name
      ref
    }
    to {
      name
      ref
    }
    cc {
      name
      ref
    }
    bcc {
      name
      ref
    }
    replyTo {
      name
      ref
    }
    date
    subject
    body
    files {
      ...MessagingFileFullFragment
    }
    isForwarded
    status
    failReason
  }
`;

export const ThreadListFragment = gql`
  ${AccountFullFragment}
  fragment MessagingThreadListFragment on MessagingThread {
    id
    from {
      ref
      name
    }
    to {
      ref
      name
    }
    participants {
      ref
      name
    }
    subject
    snippet
    lastMessageTimestamp
    unread
    hasAttachments
    lastMessageOutgoing
    messagingAccount {
      ...MessagingAccountFullFragment
    }
    userMetadata {
      name
    }
  }
`;

/**
 * Requires `$cursor: CursorInput, $subset: MessagingThreadMessagesSubsetInput` variables defined in the operation
 */
export const ThreadDetailFragment = gql`
  ${AccountFullFragment}
  ${MessageFullFragment}
  fragment MessagingThreadDetailFragment on MessagingThread {
    id
    subject
    unread
    from {
      name
      ref
    }
    to {
      name
      ref
    }
    participants {
      ref
      name
    }
    folders {
      id
      type
    }
    messages(cursor: $cursor, subset: $subset) {
      cursor {
        size
        count
        hasMore @client
      }
      data {
        ...MessagingMessageFullFragment
        rowNumber
      }
    }
    messagingAccount {
      ...MessagingAccountFullFragment
    }
    userMetadata {
      name
    }
  }
`;

function getMessages(data, cursor, { args, readField }) {
  const origHasMore = isBoolean(cursor.hasMore) ? cursor.hasMore : true;

  const messages = sortBy(
    uniqBy(data, msg => readField('id', msg)),
    msg => {
      const date = readField('date', msg);
      return date ? -parseDateTime(date).getTime() : 0;
    },
    msg => -readField('id', msg)
  );
  const lastMessageRowNumber = readField('rowNumber', last(messages));

  let hasMore: boolean = messages.length < cursor.count;
  if (args?.beforeId) {
    hasMore = origHasMore;
  }
  if (isNumber(lastMessageRowNumber)) {
    hasMore = lastMessageRowNumber < cursor.count;
  }

  return {
    data: messages,
    cursor: {
      ...cursor,
      size: messages.length,
      hasMore,
    },
  };
}

export const MessagingThreadTypePolicy: TypePolicy = {
  merge: true,
  fields: {
    messages: {
      keyArgs: [],
      merge(existing, incoming, { args, readField }) {
        const shouldClear = !isEmpty(args?.subset?.searchTerm);

        if (!existing || shouldClear) {
          return getMessages(incoming.data, incoming.cursor, {
            args,
            readField,
          });
        }

        return getMessages(
          [...incoming.data, ...existing.data],
          {
            ...existing.cursor,
            ...incoming.cursor,
          },
          { args, readField }
        );
      },
    },
  },
};
