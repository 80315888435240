import { CloseCircleOutlined, FilterOutlined } from '@ant-design/icons';
import { Button, Input, Tooltip } from 'antd';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import { hasNonNil } from '../../../common/utils/funcUtils';
import { useFormattedMessage } from '../../../common/utils/intlUtils';
import { useResponsiveQueries } from '../../../common/utils/layoutUtils';
import { useMessagesAppViewMode } from '../messagesAppViewMode';
import { getThreadsFilter, useMessagesActions } from '../messagesReducer';
import { MessagingFiltersEditor } from './MessagingFiltersEditor';
import style from './SearchBarForThreads.module.less';

const { Search } = Input;

type Props = {
  filterActive: boolean;
};

export function SearchBarForThreads({ filterActive = false }: Props) {
  const currentFilter = useSelector(getThreadsFilter);
  const [value, setValue] = useState(currentFilter?.searchTerm || '');
  useEffect(() => {
    setValue(currentFilter?.searchTerm || '');
  }, [currentFilter]);
  const { setCustomFilterMode } = useMessagesAppViewMode();

  const { setFilterEditorOpen } = useMessagesActions();
  const onSearch = (searchTerm: string) => {
    setCustomFilterMode(
      {
        ...currentFilter,
        searchTerm: searchTerm || undefined,
      },
      !!searchTerm
    );
  };
  const resetSearchTerm = () => {
    onSearch('');
  };
  const resetFilter = () => setCustomFilterMode({});
  const filterNonEmpty = !hasNonNil(currentFilter);

  const media = useResponsiveQueries();

  return (
    <div className={style.SearchBarWrapper}>
      <Search
        className={classNames(
          style.SearchBar,
          filterActive &&
            currentFilter?.searchTerm &&
            style['SearchBar--Active']
        )}
        placeholder={useFormattedMessage('labels.threadsSearch.placeholder')}
        onSearch={onSearch}
        enterButton
        allowClear={true}
        onReset={resetSearchTerm}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
      <Button
        className={classNames(
          style.FilterButton,
          filterActive && !filterNonEmpty && style['FilterButton--Active']
        )}
        icon={<FilterOutlined />}
        onClick={() => setFilterEditorOpen(true)}
      >
        {!media.sm && (
          <span>
            <FormattedMessage id="searchBar.advancedFilter" />
          </span>
        )}
      </Button>
      <Tooltip title={<FormattedMessage id="searchBar.clearFilter" />}>
        <Button
          shape="circle"
          icon={<CloseCircleOutlined />}
          onClick={resetFilter}
          disabled={filterNonEmpty}
        />
      </Tooltip>
      <MessagingFiltersEditor />
    </div>
  );
}
