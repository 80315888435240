import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { isEmpty, isNil } from 'lodash-es';

export type RichEditorImageUploadFunction = (
  file: File
) => Promise<{ abort?: () => void; src: string; contentId: string }>;

export class RichEditorUploadPlugin {
  editor: ClassicEditor;

  constructor(editor: ClassicEditor) {
    this.editor = editor;
  }

  private extendSchemaImage(imageModelName: string) {
    const editor = this.editor;

    editor.model.schema.extend(imageModelName, {
      allowAttributes: 'contentId',
    });

    editor.conversion.for('upcast').attributeToAttribute({
      view: 'data-cid',
      model: 'contentId',
    });
    editor.conversion.for('downcast').add(dispatcher => {
      dispatcher.on(
        `attribute:contentId:${imageModelName}`,
        (event, data, conversionApi) => {
          if (!conversionApi.consumable.consume(data.item, event.name)) {
            return;
          }

          const viewWriter = conversionApi.writer;
          const parent = conversionApi.mapper.toViewElement(data.item);

          const img = parent?.hasAttribute('src')
            ? parent
            : (parent!.getChild(0) as any);

          if (!isEmpty(data.attributeNewValue)) {
            viewWriter.setAttribute('data-cid', data.attributeNewValue, img);
          } else if (!isNil(data.attributeOldValue)) {
            viewWriter.removeAttribute('data-cid', img);
          }
        }
      );
    });
  }

  private extendSchema() {
    this.extendSchemaImage('imageBlock');
    this.extendSchemaImage('imageInline');
  }

  private registerListeners() {
    const editor = this.editor;

    const imageUploadEditing = editor.plugins.get('ImageUploadEditing');

    imageUploadEditing.on(
      'uploadComplete',
      (_event, { data, imageElement }) => {
        editor.model.change(writer => {
          writer.setAttribute('contentId', data.contentId, imageElement);
        });
      }
    );
  }

  init() {
    const editor = this.editor;

    this.registerListeners();
    this.extendSchema();

    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      let abortRef: (() => void) | undefined;
      return {
        upload: async () => {
          const file = await loader.file;
          const uploadFunction: RichEditorImageUploadFunction = (
            editor.config as any
          )._config.imageUploadFunction;
          if (file && uploadFunction) {
            const { contentId, src, abort } = await uploadFunction(file);
            abortRef = abort;
            return { default: src, contentId };
          } else {
            throw new Error('Missing file or upload function');
          }
        },
        abort: () => {
          if (abortRef) {
            abortRef();
          }
        },
      };
    };
  }

  get requires() {
    return ['ImageBlockEditing', 'ImageInlineEditing'];
  }
}
