export const ErrorCodes = {
  Api: {
    FORBIDDEN: 'FORBIDDEN',
    UNALLOWED_OPERATION: 'UNALLOWED_OPERATION',
    NOT_IMPLEMENTED: 'NOT_IMPLEMENTED',
    NOT_FOUND: 'NOT_FOUND',
    DUPLICATE: 'DUPLICATE',
    INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  },
  Whatsapp: {
    QR_EXPIRED: 'QR_EXPIRED',
    AUTH_FAILUTE: 'AUTH_FAILURE',
    UNKNOWN: 'UNKNOWN',
  },
};
