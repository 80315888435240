import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';
import { List } from 'immutable';
import { noop, omit, uniqueId } from 'lodash-es';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { HIDE_BUBBLE_TIMEOUT } from '../../../config/constants';
import { getLoggedIn } from '../../auth/authReducer';
import styles from './backgroundOperations.module.less';

type BackgroundOperationStatus = 'LOADING' | 'SUCCESS' | 'ERROR';
type BackgroundOperationAction<T> = {
  text: string;
  handler: (input: T) => void;
};

type BackgroundOperationBase<T> = {
  id: string;
  status: BackgroundOperationStatus;
  loadingText: string;
  loadingActions?: BackgroundOperationAction<void>[];
  successText: string;
  successActions?: BackgroundOperationAction<T>[];
  errorText: string;
  errorActions?: BackgroundOperationAction<Error>[];
};

type BackgroundOperation<T> = BackgroundOperationBase<T> &
  (
    | { status: 'LOADING' }
    | { status: 'SUCCESS'; result: T }
    | { status: 'ERROR'; error: Error }
  );

const BackgroundOperationsContext = createContext({
  operations: List<BackgroundOperation<any>>(),
  addOperation: noop as (op: BackgroundOperation<any>) => void,
  updateOperation: noop as <T>(
    id: string,
    updater: (current: BackgroundOperation<T>) => BackgroundOperation<T>
  ) => void,
  removeOperation: noop as (id: string) => void,
});

type CardProps<T> = {
  icon?: ReactNode;
  text: string;
  className?: string;
  actions?: BackgroundOperationAction<T>[];
  actionsArg: T;
  onClose: () => void;
  closeable?: boolean;
};

function Card<T>({
  icon,
  text,
  className,
  actions,
  actionsArg,
  onClose,
  closeable,
}: CardProps<T>) {
  return (
    <div className={classNames(styles.Card, className)}>
      {icon}
      {text}
      {actions?.map(({ text: actionText, handler }, i) => (
        <a
          key={i}
          className={styles.ActionLink}
          onClick={() => {
            onClose();
            return handler(actionsArg);
          }}
        >
          {actionText}
        </a>
      ))}
      {closeable && (
        <Button
          shape="circle"
          size="small"
          icon={<CloseCircleOutlined />}
          onClick={onClose}
        />
      )}
    </div>
  );
}

type OperationStatusProps<T> = {
  operation: BackgroundOperation<T>;
};

function OperationStatus<T>({ operation }: OperationStatusProps<T>) {
  const { removeOperation } = useContext(BackgroundOperationsContext);
  const status = operation.status;
  useEffect(() => {
    if (status === 'SUCCESS') {
      const timeout = setTimeout(
        () => removeOperation(operation.id),
        HIDE_BUBBLE_TIMEOUT
      );
      return () => clearTimeout(timeout);
    }
    return noop;
  }, [operation.id, removeOperation, status]);

  if (operation.status === 'LOADING') {
    return (
      <Card
        icon={<LoadingOutlined />}
        text={operation.loadingText}
        actions={operation.loadingActions}
        actionsArg={undefined as void}
        onClose={() => removeOperation(operation.id)}
      />
    );
  }
  if (operation.status === 'ERROR') {
    return (
      <Card
        className={styles['Card--Error']}
        icon={<WarningOutlined />}
        text={operation.errorText}
        actions={operation.errorActions}
        actionsArg={operation.error}
        onClose={() => removeOperation(operation.id)}
        closeable
      />
    );
  }
  return (
    <Card
      icon={<CheckCircleOutlined />}
      text={operation.successText}
      actions={operation.successActions}
      actionsArg={operation.result}
      onClose={() => removeOperation(operation.id)}
      closeable
    />
  );
}

function BackgroundOperationsProviderInner({ children }) {
  const [operations, setOperations] = useState<List<BackgroundOperation<any>>>(
    List()
  );

  const addOperation = useCallback(
    (op: BackgroundOperation<any>) =>
      setOperations(ops =>
        ops.find(item => item.id === op.id) ? ops : ops.push(op)
      ),
    []
  );
  const updateOperation = useCallback(
    (
      id: string,
      updater: (current: BackgroundOperation<any>) => BackgroundOperation<any>
    ) =>
      setOperations(ops => {
        const index = ops.findIndex(item => item.id === id);
        return index < 0 ? ops : ops.update(index, updater);
      }),
    []
  );
  const removeOperation = useCallback(
    (id: string) =>
      setOperations(ops => {
        const index = ops.findIndex(item => item.id === id);
        return index < 0 ? ops : ops.delete(index);
      }),
    []
  );

  return (
    <BackgroundOperationsContext.Provider
      value={{ operations, addOperation, updateOperation, removeOperation }}
    >
      {children}
      <div className={styles.Container}>
        <TransitionGroup component={null}>
          {operations.map(op => (
            <CSSTransition key={op.id} timeout={300} classNames="from-bottom">
              <OperationStatus operation={op} />
            </CSSTransition>
          ))}
        </TransitionGroup>
      </div>
    </BackgroundOperationsContext.Provider>
  );
}

export function BackgroundOperationsProvider({ children }) {
  const isLoggedIn = useSelector(getLoggedIn);
  return (
    <BackgroundOperationsProviderInner key={`${isLoggedIn}`}>
      {children}
    </BackgroundOperationsProviderInner>
  );
}

type UseBackgroundOperationDescriptor = Omit<
  BackgroundOperation<any>,
  'id' | 'status'
>;

type UseBackgroundOperationHandler<T> = (
  execute: () => Promise<T>,
  opts: UseBackgroundOperationDescriptor & {
    onStart?: () => void;
    onError?: (e: Error) => void;
    onSuccess?: (res: T) => void;
  }
) => Promise<void>;

type UseBackgroundOperationResult<T> = [
  UseBackgroundOperationHandler<T>,
  { loading: boolean; error?: Error; data?: T[] }
];

export function useBackgroundOperation<T>(): UseBackgroundOperationResult<T> {
  const { addOperation, updateOperation, operations } = useContext(
    BackgroundOperationsContext
  );
  const [ops, setOps] = useState<[string, T | undefined][]>([]);

  const handler: UseBackgroundOperationHandler<T> = async (execute, opts) => {
    const id = uniqueId();
    const op = { id, ...omit(opts, ['onStart', 'onError', 'onSuccess']) };
    setOps(old => [...old, [id, undefined]]);
    try {
      const promise = execute();
      opts.onStart?.();
      addOperation({ ...op, status: 'LOADING' });
      const res = await promise;
      setOps(old => {
        const index = old.findIndex(val => val[0] === id);
        if (index === -1) {
          return old;
        }
        const copy = [...old];
        copy[index] = [id, res];
        return copy;
      });
      opts.onSuccess?.(res);
      updateOperation(id, cur => ({
        ...cur,
        status: 'SUCCESS',
        result: res,
      }));
    } catch (e) {
      opts.onError?.(e);
      updateOperation(id, cur => ({
        ...cur,
        status: 'ERROR',
        error: e,
      }));
    }
  };

  const operationIds = ops.map(([id]) => id);
  const operationsSubset = operations.filter(bgOp =>
    operationIds.includes(bgOp.id)
  );

  return [
    handler,
    {
      data: ops.map(([id, res]) => res).filter(res => !!res) as T[],
      loading: operationsSubset.some(bgOp => bgOp.status === 'LOADING'),
      error: operationsSubset
        .map(bgOp => (bgOp.status === 'ERROR' ? bgOp.error : undefined))
        .find(err => !!err),
    },
  ];
}
