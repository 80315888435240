import { Spin } from 'antd';
import { throttle } from 'lodash-es';
import { useMemo, useRef, useState } from 'react';

import {
  SanitizeTransformers,
  useSanitizedContent,
} from '../../../common/utils/sanitizeUtils';
import { useHighlightTransformer } from '../../../components/Highlighter';
import { MessagingMessageFullFragmentFragment } from '../../../types/graphqlGenerated';
import { useEmailImageTransformation } from '../../emails/emailHooks';
import { EmailQuoteExpander } from './EmailQuoteExpander';
import style from './HtmlMessageBody.module.less';
import { parseMessageQuote } from './helpers/parseMessageQuote';
import { useThreadHighlight } from './helpers/threadUtils';

const RESIZE_WINDOW_THROTTLE_MS = 500;

function computeIFrameHeight(el) {
  return el.contentDocument.scrollingElement.scrollHeight;
}

function injectStyle(into: Document) {
  const styleElement = into.createElement('style');
  const rules: string[] = [];

  for (const st of document.styleSheets) {
    try {
      for (const r of st.cssRules) {
        if (r.cssText.includes(style.Content)) {
          rules.push(r.cssText);
        }
      }
    } catch (e) {
      console.warn('Cannot access cssRules for: ', st);
    }
  }

  styleElement.innerHTML = rules.join('\n');
  into.head.appendChild(styleElement);
  into.body.className = style.Content;
  into.body.style.overflow = 'visible';
}

type Files = MessagingMessageFullFragmentFragment['files'];

type Props = {
  body: string;
  id: number;
  files: Files;
};

function AutoSizedIFrame({ title, srcDoc }) {
  const ref: any = useRef();
  const [height, setHeight] = useState(0);
  return (
    <iframe
      ref={ref}
      className={style.Iframe}
      style={{ height }}
      // NOTE: using sandbox="" to apply extra security restrictions
      sandbox="allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      onLoad={async e => {
        const doc = (e.target as HTMLIFrameElement).contentDocument!;
        const win = (e.target as HTMLIFrameElement).contentWindow!;
        injectStyle(doc);
        setHeight(computeIFrameHeight(ref.current));
        function onWindowResize() {
          setHeight(computeIFrameHeight(ref.current));
        }
        const listener = throttle(onWindowResize, RESIZE_WINDOW_THROTTLE_MS);
        win.addEventListener('resize', listener);
      }}
      title={title}
      srcDoc={srcDoc}
    />
  );
}

export function HtmlMessageBody({ body, id, files }: Props) {
  const { searchMode, searchTerm } = useThreadHighlight();
  const addHighlightMarks = useHighlightTransformer({
    searchTerm,
    enabled: searchMode,
  });

  // NOTE: sanitization of the of the email body is crucial to prevent XSS attacks
  const { dataGetter: imageDataGetter, transformer: imageTransformer } =
    useEmailImageTransformation(files);
  const { data, loading } = useSanitizedContent(body, {
    dataGetters: { image: imageDataGetter },
    transformers: {
      image: imageTransformer,
      body: addHighlightMarks,
      any: [SanitizeTransformers.linkInNewWindow],
    },
  });

  const { emailBody, emailQuote } = useMemo(
    () => parseMessageQuote(data),
    [data]
  );

  return loading ? (
    <Spin />
  ) : (
    <div className="FlexCol">
      <AutoSizedIFrame title={`${id}`} srcDoc={emailBody} />
      {emailQuote && (
        <EmailQuoteExpander>
          <AutoSizedIFrame title={`${id}-quote`} srcDoc={emailQuote} />
        </EmailQuoteExpander>
      )}
    </div>
  );
}
