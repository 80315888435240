import { Col, Input, Row } from 'antd';
import { useField, useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import { replacePlaceholders } from '../../../../../../common/utils/stringUtils';
import FormField from '../../../../../../components/forms/FormField';
import { TranslationId } from '../../../../../../types/appTypes';
import {
  TemplateComponent,
  TemplateComponentFormat,
} from '../../../../../../types/graphqlGenerated';
import style from '../MessageComposerWhatsappSubform.module.less';

const TemplateButtonPreview = ({ text }: { text?: string | null }) => {
  if (text) {
    return <div className={style.TemplateButton}>{text}</div>;
  }
  return null;
};

const TemplatePlaceholderField = ({ name, placeholderId }) => (
  <FormField
    name={name}
    placeholderId={`sendMessage.templates.${placeholderId}` as TranslationId}
  >
    {({ field }) => (
      <div className={style.TemplatePlaceholderField}>
        <Input className="ant-input" {...field} autoFocus />
      </div>
    )}
  </FormField>
);

const TemplatePlaceholderFields = ({
  components,
}: {
  components: TemplateComponent[];
}) => (
  <div className={style.TemplateContainer}>
    {components?.map(component => {
      // https://developers.facebook.com/docs/whatsapp/business-management-api/message-templates
      const templateType = Object.keys(component.example ?? {})[0];
      switch (component.type) {
        case 'HEADER': {
          if (component.text && component.example) {
            return (
              <Row gutter={[16, 16]} align="middle">
                <Col span={2}>
                  <label />
                </Col>
                <Col span={22}>
                  <TemplatePlaceholderField
                    name="template.header.text"
                    placeholderId={templateType}
                  />
                </Col>
              </Row>
            );
          }
          return null;
        }
        case 'BODY': {
          if (component.example) {
            return (
              <>
                {component.example['body_text'][0].map(
                  (previewText: string, index) => (
                    <Row key={previewText} gutter={[16, 16]} align="middle">
                      <Col span={2}>
                        <label>{`{{${index + 1}}}:`}</label>
                      </Col>
                      <Col span={22}>
                        <TemplatePlaceholderField
                          key={`${previewText}`}
                          name={`template.body.${index}.text`}
                          placeholderId={templateType}
                        />
                      </Col>
                    </Row>
                  )
                )}
              </>
            );
          }
          return null;
        }
        case 'BUTTONS': {
          return null;
          /**
           * NOT SUPPORTED YET
           * below as much as I hate it keeping commented out code of initial implementation of just url params
           * buttons
           * This needs to be adjusted ->
           * ALL of the buttons will be rendered, based on button.type (URL, COPY_CODE, etc) render the button and
           * in case, input is needed, render input
           * all of the typing of WhatsappMessageComposerFormValues and Graphql MessagingMessageInput needs to be
           * adjusted to be able to send button subtype and index, possible more properties than just text
           * eg. in case of COPY_CODE instead of text we need to use coupon_code
           *
           * const buttonsWithPlaceholderUrl = component.buttons?.filter(
           *               button => button?.url?.includes('{{1}}')
           *             );
           *             return (
           *               <div className={style.TemplateButtons}>
           *                 {buttonsWithPlaceholderUrl?.map((button, index) => (
           *                   <Row key={button?.url} gutter={[16, 16]} align="middle">
           *                     <Col span={14}>
           *                       <TemplateButtonPreview
           *                         text={`${button?.text} - ${replacePlaceholders(
           *                           button!.url!,
           *                           [buttonsUrls?.value?.[index]?.text]
           *                         )}`}
           *                       />
           *                     </Col>
           *                     <Col span={10}>
           *                       <TemplatePlaceholderField
           *                         name={`template.buttons.${index}.text`}
           *                         placeholderId="button_text"
           *                       />
           *                     </Col>
           *                   </Row>
           *                 ))}
           *               </div>
           *             );
           */
        }

        default:
          return null;
      }
    })}
  </div>
);

export const TemplatePreview = ({
  components,
}: {
  components: TemplateComponent[];
}) => {
  const intl = useIntl();
  const { setFieldValue } = useFormikContext();
  const [headerText] = useField('template.header.text');
  const [bodyText] = useField('template.body');
  const isWithPlaceholders = useMemo(
    () =>
      components.some(
        component =>
          (component.text && component.example) ||
          component.buttons?.some(button => button?.url?.includes('{{1}}'))
      ),
    [components]
  );

  useEffect(() => {
    setFieldValue('template.header', undefined);
    setFieldValue('template.body', undefined);
    setFieldValue('template.buttons', undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [components]);

  return (
    <div style={{ padding: '5px' }}>
      <Row gutter={[16, 16]}>
        <Col span={6}>
          <h4>{intl.formatMessage({ id: 'sendMessage.template.preview' })}</h4>
          <div className={style.TemplateContainer}>
            {components?.map(component => {
              switch (component.type) {
                case 'HEADER': {
                  if (TemplateComponentFormat.Text === component.format) {
                    return (
                      <div className={style.TemplateHeader}>
                        {replacePlaceholders(component.text as string, [
                          headerText?.value,
                        ])}
                      </div>
                    );
                  }
                  return null;
                }
                case 'BODY':
                  return (
                    <div className={style.TemplateBody}>
                      {replacePlaceholders(
                        component.text as string,
                        bodyText?.value?.map(text => text.text)
                      )}
                    </div>
                  );
                case 'FOOTER':
                  return (
                    <div className={style.TemplateFooter}>{component.text}</div>
                  );
                case 'BUTTONS':
                  return (
                    <div className={style.TemplateButtons}>
                      {component.buttons?.map(button => (
                        <TemplateButtonPreview
                          text={button?.text}
                          key={button?.text}
                        />
                      ))}
                    </div>
                  );
                default:
                  return null;
              }
            })}
          </div>
        </Col>
        <Col span={12}>
          {isWithPlaceholders && (
            <>
              <h4>
                {intl.formatMessage({ id: 'sendMessage.template.fill-in' })}
              </h4>
              <TemplatePlaceholderFields components={components} />
            </>
          )}
        </Col>
        <Col span={6}>
          <h4>
            {intl.formatMessage(
              { id: 'sendMessage.template.explain_flow' },
              {
                br: <br />,
              }
            )}
          </h4>
        </Col>
      </Row>
    </div>
  );
};
