import { MenuOutlined } from '@ant-design/icons';
import { Button, Col, Layout, Row } from 'antd';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { LogoSmall } from '../../../components/Logo';
import { getSidebarCollapse, useLayoutActions } from '../layoutReducer';
import style from './MobileAndTabletHeader.module.less';

export const MainHeader = () => {
  const sidebarCollapse = useSelector(getSidebarCollapse);

  const isSidebarCollapsed = useSelector(getSidebarCollapse);
  const { setSidebarCollapse } = useLayoutActions();

  const toggleSidebarCollapse = () => {
    setSidebarCollapse(!sidebarCollapse);
  };

  return (
    <Layout.Header>
      <Layout.Content className={style.PageHeaderContent}>
        {isSidebarCollapsed ? (
          <Button
            onClick={() => toggleSidebarCollapse()}
            type="text"
            size="large"
            className={style.SidebarToggle}
          >
            <MenuOutlined />
          </Button>
        ) : (
          <div />
        )}
        <LogoSmall />
        <div />
      </Layout.Content>
    </Layout.Header>
  );
};

type SubHeaderProps = {
  pageName: ReactNode;
  leftCornerNodes?: ReactNode;
  rightCornerNodes?: ReactNode;
};

export const SubHeader = ({
  pageName,
  leftCornerNodes,
  rightCornerNodes,
}: SubHeaderProps) => (
  <Layout.Header className={style.SubHeader}>
    <Layout.Content className={style.SubHeaderContent}>
      <Row className={style.HeaderContentRow}>
        <Col className={style.HeaderRow}>
          {leftCornerNodes}
          {pageName}
        </Col>
        <Col className={style.RightCornerNodes}>{rightCornerNodes}</Col>
      </Row>
    </Layout.Content>
  </Layout.Header>
);
