import { ReactNode } from 'react';

import styles from './DragPlaceholder.module.less';

type DragPlaceholderProps = {
  children: ReactNode;
};

export function DragPlaceholder({ children }: DragPlaceholderProps) {
  return <div className={styles.Item}>{children}</div>;
}
