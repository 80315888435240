// fullcalendar has to be imported before plugins
/* eslint-disable import/order */
import FullCalendarReact, { EventClickArg } from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { CalendarOptions } from '@fullcalendar/common';
import dayGridPlugin from '@fullcalendar/daygrid';
/* eslint-enable import/order */

import { useSelector } from 'react-redux';

import { getLocaleData } from '../config/intl/intl';
import { getLocale } from '../modules/settings/settingsReducer';
import './FullCalendar.module.less';

export type FullCalendarEvent = {
  id: string;
  title: string;
  backgroundColor: string;
  borderColor: string;
  start?: string | Date;
  end?: string | Date;
  allDay?: boolean;
  calendarId: number;
};

type Props = CalendarOptions & {
  onEventClick: (clickInfo: EventClickArg) => void;
  events: FullCalendarEvent[];
};

export default function FullCalendar({
  onEventClick,
  events,
  ...other
}: Props) {
  const localeName = useSelector(getLocale);
  const { fcLocale } = getLocaleData(localeName);

  return (
    <FullCalendarReact
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      nowIndicator
      selectMirror
      dayMaxEventRows
      locale={fcLocale}
      unselectAuto
      unselectCancel=".ant-modal-body,.ant-select-item-option,.ant-modal-title"
      selectOverlap
      headerToolbar={{
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay',
      }}
      events={events}
      eventClick={onEventClick}
      {...other}
    />
  );
}
