import { Spin, Typography } from 'antd';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { ErrorAlert } from '../../components/ErrorAlert';
import styles from './AccountsList.module.less';
import { useAccountsQuery } from './accountsGraphql';
import { AccountsListItem } from './accountsList/AccountsListItem';

type Props = {
  className?: string;
};

export const AccountsList = ({ className }: Props = {}) => {
  const { accounts, loading, error } = useAccountsQuery();

  if (loading) {
    return <Spin />;
  }
  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <div className={classNames(styles.Container, className)}>
      <Typography.Title level={4}>
        <FormattedMessage id="connectedAccounts.title" />
      </Typography.Title>
      <div className={styles.Items}>
        {(accounts || []).map(acc => (
          <AccountsListItem key={acc.id} account={acc} />
        ))}
      </div>
    </div>
  );
};
