import { Pagination } from '../../common/pagination/paginationElements';
import { PageInfo } from '../../common/pagination/paginationTypes';
import style from './ContactsListToolbar.module.less';

type Props = {
  pageInfo: PageInfo;
  onPageIndexChange: (index: number) => void;
};

export function ContactsListToolbar({ pageInfo, onPageIndexChange }: Props) {
  return (
    <div className={style.Container}>
      <div className={style.Left} />
      <div className={style.Right}>
        <Pagination value={pageInfo} onChange={onPageIndexChange} />
      </div>
    </div>
  );
}
