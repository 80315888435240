import { toNumber } from 'lodash-es';
import { useParams } from 'react-router-dom';

import RouteDefinitions from '../../../app/routes';
import { UseParams } from '../../../types/libHelperTypes';
import { ThreadDetail } from './ThreadDetail';

export default function ThreadDetailSubpage() {
  const { threadId } =
    useParams<UseParams<RouteDefinitions.threadDetailRelative>>();
  return <ThreadDetail threadId={toNumber(threadId)} />;
}
