import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createHttpLink } from '@apollo/client/link/http';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { enableExperimentalFragmentVariables } from 'graphql-tag';
import { StatusCodes } from 'http-status-codes';
import { FormattedMessage } from 'react-intl';
import { Action, Store } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import {
  extractNetworkErrorStatusCode,
  formatError,
} from '../common/utils/errorUtils';
import { remoteLog } from '../common/utils/logUtils';
import { showDebouncedErrorMessage } from '../common/utils/messageUtils';
import { config } from '../config/config';
import { getUser, logoutUser } from '../modules/auth/authReducer';
import { getFreshCognitoUserSession } from '../modules/auth/cognitoAuthService';
import { MessagingThreadTypePolicy } from '../modules/messagesApp/threadsMessagesGraphql';

enableExperimentalFragmentVariables();

const GraphQLErrors = {
  UNAUTHENTICATED: 'UNAUTHENTICATED',
};

type ApolloClientResult = {
  apolloClient: ApolloClient<NormalizedCacheObject>;
  subscriptionClient: SubscriptionClient;
  setReduxStore: (store: Store) => void;
};

export function makeApolloClient(): ApolloClientResult {
  // store dependency should be initialized as soon as available
  let store: Store & {
    dispatch: ThunkDispatch<unknown, unknown, Action<unknown>>;
  };

  async function makeHeaders() {
    const user = getUser(store.getState());
    // This ensures we always refresh the session if needed before placing requests
    try {
      // This uses refresh token if needed
      const session = await getFreshCognitoUserSession(user);

      return {
        'X-AccessToken': session?.getAccessToken()?.getJwtToken() || '',
        'X-IdToken': session?.getIdToken()?.getJwtToken() || '',
      };
    } catch (e) {
      remoteLog(
        'GraphQL makeHeaders: error getting session',
        {
          errorMessage: e?.message,
        },
        'warning'
      );
      throw e;
    }
  }

  const httpLink = createHttpLink({
    uri: config.graphqlUrl,
  });

  const subscriptionClient = new SubscriptionClient(config.graphqlWsUrl, {
    lazy: true,
    reconnect: true,
    timeout: 60000,
    connectionParams: async () => ({ headers: await makeHeaders() }),
  });

  const wsLink = new WebSocketLink(subscriptionClient);

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  );

  const authLink = setContext(async (_request, { headers }) => ({
    headers: {
      ...(await makeHeaders()),
      ...headers,
    },
  }));

  const logoutLink = onError(({ networkError, graphQLErrors }) => {
    if (
      extractNetworkErrorStatusCode(networkError) ===
        StatusCodes.UNAUTHORIZED ||
      graphQLErrors?.[0]?.extensions.code === GraphQLErrors.UNAUTHENTICATED
    ) {
      remoteLog(
        'graphQL logoutLink: unauthenticated',
        {
          networkError,
          graphQLErrors,
        },
        'warning'
      );
      showDebouncedErrorMessage(<FormattedMessage id="auth.autoLogout" />);
      store.dispatch(logoutUser());
    }
  });

  const errorLink = onError(err => {
    const operation = err?.operation || {};
    showDebouncedErrorMessage(intl =>
      formatError(
        new ApolloError({
          graphQLErrors: err.graphQLErrors,
          networkError: err.networkError,
        }),
        intl
      )
    );
    console.error(`GraphQL error (operation: ${operation.operationName})`, err);
  });

  const cache = new InMemoryCache({
    typePolicies: {
      UserSettings: { keyFields: [] },
      MessagingAccount: { merge: true },
      MessagingFile: { merge: true },
      MessagingThread: MessagingThreadTypePolicy,
      CalendarEvent: { merge: true },
    },
  });

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([logoutLink, errorLink, authLink, splitLink]),
    connectToDevTools: true,
    cache,
  });

  return {
    apolloClient,
    subscriptionClient,
    // enhancement making store available for the client
    setReduxStore: (reduxStore: Store) => {
      store = reduxStore;
    },
  };
}
