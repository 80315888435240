import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import RouteDefinitions from '../../../app/routes';
import {
  GetMessagingThreadsQuery,
  GetMessagingThreadsQueryVariables,
} from '../../../types/graphqlGenerated';
import { PaginationControls } from '../../common/pagination/paginationElements';
import { useFetchThreadDetail, useFetchThreads } from '../threadsGraphql';
import { useThreadDetailParentRoute } from './helpers/threadUtils';

type ThreadsSourceState = {
  threadsQueryVariables: GetMessagingThreadsQueryVariables;
  currentThreadsIds: number[];
  total: number;
};

export function makeThreadsSourceState(
  variables: GetMessagingThreadsQueryVariables,
  total: number,
  threads?: GetMessagingThreadsQuery['getMessagingThreads']['data']
) {
  return {
    threadsQueryVariables: variables,
    currentThreadsIds: (threads || []).map(t => t.id),
    total,
  };
}

function useDetailNavigation(threadId, threadsSourceState) {
  const { currentThreadsIds, threadsQueryVariables } = threadsSourceState;
  const navigate = useNavigate();
  const fetchThreads = useFetchThreads();
  const prefetchThreadDetail = useFetchThreadDetail();
  const parentRoute = useThreadDetailParentRoute({ threadId });

  async function fetchNewState(pageIndex) {
    const newThreadsQueryVariables = {
      ...threadsQueryVariables,
      pagination: {
        ...threadsQueryVariables.pagination,
        index: pageIndex,
      },
    };
    const newData = await fetchThreads(newThreadsQueryVariables);
    return makeThreadsSourceState(
      newThreadsQueryVariables,
      newData.pagination.count,
      newData.threads
    );
  }

  async function navigateToDetail(newThreadsSourceState, threadIndex) {
    const detailRoute = `${parentRoute}${generatePath(
      RouteDefinitions.threadDetailRelative,
      {
        threadId: `${newThreadsSourceState.currentThreadsIds[threadIndex]}`,
      }
    )}`;
    await prefetchThreadDetail(
      newThreadsSourceState.currentThreadsIds[threadIndex]
    );
    navigate(detailRoute, {
      state: {
        threadsSourceState: newThreadsSourceState,
      },
    });
  }

  async function paginateThread(direction) {
    const idx = currentThreadsIds.findIndex(id => id === threadId) + direction;

    const firstIdx = 0;
    const lastIdx = threadsQueryVariables.pagination.size - 1;

    if (idx < 0) {
      // fetch prev page to determine prev thread id
      const newThreadsSourceState = await fetchNewState(
        threadsQueryVariables.pagination.index - 1
      );
      await prefetchThreadDetail(
        newThreadsSourceState.currentThreadsIds[lastIdx]
      );
      return navigateToDetail(newThreadsSourceState, lastIdx);
    }
    if (idx >= threadsQueryVariables.pagination.size) {
      // fetch next page to determine next thread id
      const newThreadsSourceState = await fetchNewState(
        threadsQueryVariables.pagination.index + 1
      );
      return navigateToDetail(newThreadsSourceState, firstIdx);
    }
    return navigateToDetail(threadsSourceState, idx);
  }
  return { paginateThread };
}

function ThreadPaginationControls({
  threadId,
  relativeThreadIdx,
  threadsSourceState,
}) {
  const { total, threadsQueryVariables } = threadsSourceState;
  const { paginateThread } = useDetailNavigation(threadId, threadsSourceState);
  const { pagination } = threadsQueryVariables;
  if (relativeThreadIdx < 0) {
    return null;
  }

  const absoluteThreadIdx =
    pagination.index * pagination.size + relativeThreadIdx;

  const prevDisabled = absoluteThreadIdx === 0;
  const nextDisabled = absoluteThreadIdx === total - 1;
  return (
    <PaginationControls
      onPaginate={dir => paginateThread(dir)}
      prevDisabled={prevDisabled}
      nextDisabled={nextDisabled}
      itemIndex={absoluteThreadIdx + 1}
      total={total}
    />
  );
}

export function ThreadPagination({ threadId }) {
  const { state = {} } = useLocation();
  const threadsSourceState = (state as any)
    ?.threadsSourceState as ThreadsSourceState;
  if (!threadsSourceState) {
    return null;
  }
  const relativeThreadIdx = threadsSourceState.currentThreadsIds.findIndex(
    id => id === threadId
  );
  if (relativeThreadIdx < 0) {
    return null;
  }

  return (
    <ThreadPaginationControls
      threadsSourceState={threadsSourceState}
      threadId={threadId}
      relativeThreadIdx={relativeThreadIdx}
    />
  );
}
