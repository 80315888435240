import { Record } from 'immutable';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Action, bindActionCreators } from 'redux';

import { localStorageAdapter } from '../../common/utils/localStorageUtils';

type LayoutState = typeof INITIAL_STATE;
type PartialState = { layout: LayoutState };

type InitialStateType = {
  sidebarCollapse: boolean;
};

function saveLayout(settings: LayoutState) {
  localStorageAdapter.setItem('Layout', settings);
}

const InitialState = Record<InitialStateType>(loadInitialSettings());
const INITIAL_STATE = InitialState();

const CHANGE_SIDEBAR_COLLAPSE = 'LAYOUT/CHANGE_SIDEBAR_COLLAPSE';

function loadInitialSettings(): InitialStateType {
  const layout = localStorageAdapter.getItem('Layout');
  return {
    sidebarCollapse: layout?.sidebarCollapse ?? true,
  };
}

export const getSidebarCollapse = (state: PartialState) =>
  state.layout.sidebarCollapse;

type SetSidebarCollapseAction = Action &
  Pick<InitialStateType, 'sidebarCollapse'>;

function setSidebarCollapse(
  sidebarCollapse: boolean
): SetSidebarCollapseAction {
  return { type: CHANGE_SIDEBAR_COLLAPSE, sidebarCollapse };
}

export function layoutReducerInner(
  state: LayoutState = INITIAL_STATE,
  action: Action
) {
  switch (action.type) {
    case CHANGE_SIDEBAR_COLLAPSE: {
      const typedAction = action as SetSidebarCollapseAction;
      return state.set('sidebarCollapse', typedAction.sidebarCollapse);
    }
    default:
      return state;
  }
}

export function layoutReducer(
  state: LayoutState = INITIAL_STATE,
  action: Action
) {
  const newState = layoutReducerInner(state, action);
  saveLayout(newState);
  return newState;
}

export function useLayoutActions() {
  const dispatch = useDispatch();
  return useMemo(
    () => ({
      setSidebarCollapse: bindActionCreators(setSidebarCollapse, dispatch),
    }),
    [dispatch]
  );
}
