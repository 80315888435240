import { FieldInputProps, useFormik } from 'formik';
import { FormattedMessage } from 'react-intl';

import {
  SimpleFormModal,
  SimpleFormModalProps,
} from '../../../components/SimpleFormModal';
import FormField from '../../../components/forms/FormField';
import { RadioGroup } from '../../../components/forms/radioFields';
import { useLocalizedYup } from '../../../config/intl/LocaleProvider';
import { CalendarRecurrenceEditScope } from '../../../types/graphqlGenerated';

type RecurrenceEditScopeDialogProps<T> = Omit<
  SimpleFormModalProps<T>,
  'formik' | 'children'
> & {
  onConfirm: (value: CalendarRecurrenceEditScope) => Promise<void>;
};

export function RecurrenceEditScopeDialog<T>({
  onConfirm,
  ...rest
}: RecurrenceEditScopeDialogProps<T>) {
  const yup = useLocalizedYup();
  const formik = useFormik({
    initialValues: { editScope: CalendarRecurrenceEditScope.One },
    validationSchema: yup.object().shape({
      editScope: yup.string().required(),
    }),
    onSubmit: async values => onConfirm(values.editScope),
  });

  return (
    <SimpleFormModal formik={formik} {...rest}>
      <FormField name="editScope" component={RecurrenceEditScopeField} />
    </SimpleFormModal>
  );
}

const OPTIONS = [
  CalendarRecurrenceEditScope.One,
  CalendarRecurrenceEditScope.NotBefore,
  CalendarRecurrenceEditScope.All,
].map(value => ({
  value,
  label: (
    <FormattedMessage id={`calendar.dialog.recurrence.editScope.${value}`} />
  ),
}));

type RecurrenceEditScopeFieldProps =
  FieldInputProps<CalendarRecurrenceEditScope>;

function RecurrenceEditScopeField(props: RecurrenceEditScopeFieldProps) {
  return <RadioGroup vertical options={OPTIONS} {...props} />;
}
