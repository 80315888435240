import { gql } from '@apollo/client';

import { createUseMutation } from '../../common/utils/graphqlUtils';
import {
  CreateUserFeedbackMutation,
  CreateUserFeedbackMutationVariables,
} from '../../types/graphqlGenerated';

const CREATE_USER_FEEDBACK_MUTATION = gql`
  mutation CreateUserFeedback($input: UserFeedbackInput!) {
    createUserFeedback(input: $input)
  }
`;

export const useCreateUserFeedback = createUseMutation<
  CreateUserFeedbackMutation,
  CreateUserFeedbackMutationVariables,
  CreateUserFeedbackMutation['createUserFeedback']
>(CREATE_USER_FEEDBACK_MUTATION, {
  extractResult: resp => resp.createUserFeedback,
});
