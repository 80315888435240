import { Modal, ModalFuncProps, message } from 'antd';
import { isFunction } from 'formik';
import { debounce } from 'lodash-es';
import { ReactNode } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { Provider as ReduxProvider } from 'react-redux';

import { store } from '../../app/appSingletons';
import LocaleProvider from '../../config/intl/LocaleProvider';

type ElementOrIntlFunc =
  | ReactNode
  | ((props: { intl: IntlShape }) => ReactNode);

interface PropsIntlInjector {
  children: ElementOrIntlFunc;
}

function IntlInjector(props: PropsIntlInjector) {
  const intl = useIntl();
  return (
    <>
      {isFunction(props.children) ? props.children({ intl }) : props.children}
    </>
  );
}

interface PropsMessageWrapper {
  children: ElementOrIntlFunc;
}

function MessageWrapper(props: PropsMessageWrapper) {
  return (
    <ReduxProvider store={store}>
      <LocaleProvider skipYupInit>
        <IntlInjector>{props.children}</IntlInjector>
      </LocaleProvider>
    </ReduxProvider>
  );
}

export function showSuccessMessage(content: ElementOrIntlFunc): void {
  message.success(<MessageWrapper>{content}</MessageWrapper>);
}

export function showErrorMessage(content: ElementOrIntlFunc): void {
  message.error(<MessageWrapper>{content}</MessageWrapper>);
}

export const showDebouncedErrorMessage: (content: ElementOrIntlFunc) => void =
  debounce(showErrorMessage, 500);

type ShowConfirmProps = ModalFuncProps & {
  title?: ElementOrIntlFunc;
  content?: ElementOrIntlFunc;
  okText?: ElementOrIntlFunc;
  cancelText?: ElementOrIntlFunc;
};
export function showConfirm({
  title,
  content,
  okText,
  cancelText,
  ...rest
}: ShowConfirmProps): void {
  Modal.confirm({
    ...rest,
    title: title ? <MessageWrapper>{title}</MessageWrapper> : undefined,
    content: content ? <MessageWrapper>{content}</MessageWrapper> : undefined,
    okText: okText ? <MessageWrapper>{okText}</MessageWrapper> : undefined,
    cancelText: cancelText ? (
      <MessageWrapper>{cancelText}</MessageWrapper>
    ) : undefined,
  });
}
