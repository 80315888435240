import { Select, SelectProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { FieldInputProps, useFormikContext } from 'formik';
import { isNil, isString, stubTrue } from 'lodash-es';

import { normalizeSearchString } from '../../common/utils/stringUtils';

export type BasicSelectProps = FieldInputProps<any> &
  SelectProps & {
    getOptionText?: (option: DefaultOptionType) => string | null;
    disableLocalFilter?: boolean;
  };

const DEFAULT_GET_OPTION_TEXT: BasicSelectProps['getOptionText'] = option =>
  isString(option?.label) ? normalizeSearchString(option.label) : null;

export function BasicSelect({
  name,
  getOptionText = DEFAULT_GET_OPTION_TEXT,
  disableLocalFilter = false,
  ...rest
}: BasicSelectProps) {
  const { setFieldValue } = useFormikContext();
  const filterOption = (input: string, option: DefaultOptionType) => {
    const optText = getOptionText?.(option);
    if (isNil(optText)) {
      return true;
    }
    const inputText = normalizeSearchString(input);
    return optText.indexOf(inputText) > -1;
  };

  return (
    <Select
      filterOption={disableLocalFilter ? stubTrue : filterOption}
      {...rest}
      onChange={val => setFieldValue(name, val)}
    />
  );
}
