import { isEmpty, isNil } from 'lodash-es';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { onlyNonNull } from 'vifzack-common-utils';

import { MessagingThreadsFilter } from '../../../types/graphqlGenerated';
import { useMessagesAppViewMode } from '../messagesAppViewMode';
import { getThreadsFilter } from '../messagesReducer';

type QuickFilterContext<V> = {
  value: V;
  onChange: (val: V) => void;
};

type QuickFilterProps<
  N extends keyof MessagingThreadsFilter,
  V extends MessagingThreadsFilter[N]
> = {
  name: keyof MessagingThreadsFilter;
  children: (context: QuickFilterContext<V>) => ReactNode;
};

function useHandleFolderView(name: string) {
  const { viewMode, setCustomFilterMode } = useMessagesAppViewMode();

  return val => {
    if (viewMode?.type !== 'folder') {
      return;
    }
    if (isNil(val)) {
      return;
    }
    setCustomFilterMode({
      [name]: val,
      folder: viewMode.folderType
        ? { typeIn: [viewMode.folderType] }
        : { idIn: [viewMode.folderId!] },
    });
  };
}

function useHandleFilterViewBase(name: string) {
  const currentFilter = useSelector(getThreadsFilter);
  const { setFolderMode, setCustomFilterMode } = useMessagesAppViewMode();

  return val => {
    const { folder, ...filterRest } = { ...currentFilter, [name]: val };
    const folderIdsAndTypes = [
      ...(folder?.idIn ?? []),
      ...(folder?.typeIn ?? []),
    ];

    // Go back to folder view if the filter is now one folder only
    if (
      isEmpty(Object.values(onlyNonNull(filterRest))) &&
      folderIdsAndTypes.length === 1
    ) {
      setFolderMode(folderIdsAndTypes[0]);
    } else {
      setCustomFilterMode({ folder, ...filterRest });
    }
  };
}

function useHandleNamedFilterView(name: string) {
  const currentFilter = useSelector(getThreadsFilter);
  const { viewMode } = useMessagesAppViewMode();
  const handleBase = useHandleFilterViewBase(name);

  return val => {
    if (viewMode?.type !== 'namedFilter') {
      return;
    }
    // Skip if in named filter and no change was made
    if (currentFilter?.[name] === val) {
      return;
    }
    handleBase(val);
  };
}

function useHandleCustomFilterView(name: string) {
  const { viewMode } = useMessagesAppViewMode();
  const handleBase = useHandleFilterViewBase(name);

  return val => {
    if (viewMode?.type !== 'customFilter') {
      return;
    }
    handleBase(val);
  };
}

export function QuickFilter<
  N extends keyof MessagingThreadsFilter,
  V extends MessagingThreadsFilter[N]
>({ name, children }: QuickFilterProps<N, V>) {
  const currentFilter = useSelector(getThreadsFilter);
  const { viewMode } = useMessagesAppViewMode();

  const handleFolderView = useHandleFolderView(name);
  const handleCustomFilterView = useHandleCustomFilterView(name);
  const handleNamedFilterView = useHandleNamedFilterView(name);

  const value = viewMode?.type !== 'folder' ? currentFilter?.[name] : undefined;

  const onChange = val => {
    if (viewMode?.type === 'folder') {
      handleFolderView(val);
    } else if (viewMode?.type === 'customFilter') {
      handleCustomFilterView(val);
    } else if (viewMode?.type === 'namedFilter') {
      handleNamedFilterView(val);
    }
  };

  return <>{children({ value, onChange })}</>;
}
